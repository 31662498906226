import { Dispatch, SetStateAction, useEffect } from 'react'
import { fetchInsuranceFeeDeposits } from './depositNavbarContent.helpers'
import EventEmitter, { UPDATE_EVENT_INSURANCE_FEE_DEPOSIT } from 'utils/events'
import { IAPIInsuranceFeeDeposit, IAPIInsuranceProduct } from 'api/interfaces'

type IHook = (params: {
  selectedInsuranceProduct: IAPIInsuranceProduct | null | undefined
  setInsuranceFeeDeposits: Dispatch<SetStateAction<IAPIInsuranceFeeDeposit[] | null | undefined>>
}) => void

export const useDepositNavbarContent: IHook = ({
  selectedInsuranceProduct,
  setInsuranceFeeDeposits,
}) => {
  useEffect(() => {
    if (!selectedInsuranceProduct) {
      return
    }

    // setInsuranceFeeDeposits(undefined)

    fetchInsuranceFeeDeposits(selectedInsuranceProduct.id).then((insuranceFeeDeposits) =>
      setInsuranceFeeDeposits(insuranceFeeDeposits)
    )
  }, [selectedInsuranceProduct, setInsuranceFeeDeposits])

  useEffect(() => {
    if (!selectedInsuranceProduct) {
      return
    }

    EventEmitter.subscribe(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT, () =>
      fetchInsuranceFeeDeposits(selectedInsuranceProduct.id).then((insuranceFeeDeposits) =>
        setInsuranceFeeDeposits(insuranceFeeDeposits)
      )
    )

    return () => EventEmitter.remove(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
  })
}
