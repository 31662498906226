import React, { Component } from 'react'
import LayoutModal from 'component/Layouts/LayoutModal'
import EventEmitter, {
  UPDATE_EVENT_CLIENTS_HAS_SUPPORT,
  UPDATE_EVENT_MODAL_CONFIRM,
} from 'utils/events'
import { Button } from '@mui/material'
import Loader from 'component/Loader'

interface ModalConfirmProps {
  onRequestClose: any
  title: any
  text: any
  buttonText: string
  onClickConfirm: any
}

interface ModalConfirmState {
  isLoading: boolean
  hasSupport: boolean
}

class ModalConfirmLayout extends Component<ModalConfirmProps, ModalConfirmState> {
  state = {
    isLoading: false,
    hasSupport: false,
  }

  componentDidMount() {
    EventEmitter.subscribe(UPDATE_EVENT_MODAL_CONFIRM, this.toggleLoading)
    EventEmitter.subscribe(UPDATE_EVENT_CLIENTS_HAS_SUPPORT, this.toggleHasSupport)
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading })
  }

  toggleHasSupport = () => {
    this.setState({ hasSupport: true })
  }

  componentWillUnmount() {
    EventEmitter.remove(UPDATE_EVENT_MODAL_CONFIRM)
    EventEmitter.remove(UPDATE_EVENT_CLIENTS_HAS_SUPPORT)
  }

  render() {
    const { onClickConfirm, onRequestClose, title, text, buttonText } = this.props

    return (
      <LayoutModal className="modal--confirm" onRequestClose={onRequestClose}>
        <h3 className="mb-1">{title}</h3>
        <p
          className="ft-secondary c-blue-400 mb-0"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <div className="modal__footer mt-6">
          <Button
            className="btn--full f-jc-center"
            onClick={onClickConfirm}
            disabled={this.state.isLoading}
          >
            {this.state.isLoading ? <Loader /> : buttonText}
          </Button>
          {this.state.hasSupport && (
            <span className="f ft-secondary c-red mt-2">
              Ce client est lié à un document, la suppression n'est pas possible.
            </span>
          )}
        </div>
      </LayoutModal>
    )
  }
}

export default ModalConfirmLayout
