import * as React from 'react'

import moment from 'moment'
import { IAPIDocket } from 'api/interfaces'

import { getImports } from './Imports.service'
import { IImportRow, IImportRowCommissionType } from '..'

import { GridRowsProp } from '@mui/x-data-grid-premium'
import { SelectProps } from '@mui/material'

export const handleSearchChange = (
  setSearch: React.Dispatch<React.SetStateAction<string>>
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    setSearch(e.target.value)
  }
}

export const handleSearchReset = (
  setSearch: React.Dispatch<React.SetStateAction<string>>
): React.MouseEventHandler => {
  return () => {
    setSearch('')
  }
}

export const handleFilterChange = (
  setFilter: React.Dispatch<React.SetStateAction<string>>
): NonNullable<SelectProps['onChange']> => {
  return (e) => {
    setFilter(e.target.value as string)
  }
}

export const handleModalTriggerClick = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  open: boolean,
  setInsuranceFeeReportId: React.Dispatch<React.SetStateAction<number>>,
  insuranceFeeReportId: number
): React.MouseEventHandler => {
  return () => {
    setModalOpen(open)
    setInsuranceFeeReportId(insuranceFeeReportId)
  }
}

export const getRows = (dockets: IAPIDocket[]): GridRowsProp<IImportRow> => {
  return dockets.map<IImportRow>((docket) => {
    const commissionTypes: IImportRowCommissionType[] = []

    // TODO attribute the full Commission Type object rather than just a string
    if (docket.arbitrationCount > 0) {
      commissionTypes.push('arbitration')
    }

    if (docket.depositCount > 0) {
      commissionTypes.push('deposit')
    }

    if (docket.outstandingContractCount > 0) {
      commissionTypes.push('outstandingContract')
    }

    if (docket.outstandingDelegateCount > 0) {
      commissionTypes.push('outstandingDelegate')
    }

    if (docket.outstandingOverrideCount > 0) {
      commissionTypes.push('outstandingOverride')
    }

    if (docket.outstandingUcitsCount > 0) {
      commissionTypes.push('outstandingUcits')
    }

    if (docket.unknownCount > 0) {
      commissionTypes.push('unknown')
    }

    if (docket.upfrontCount > 0) {
      commissionTypes.push('upfront')
    }

    const createdAt = moment(docket.createdAt)

    return {
      id: docket.id,
      insurance: docket.insurance.provider,
      name: {
        filename: docket.fileName,
        startDate: moment(docket.startDate).format('MM/YYYY'),
        endDate: moment(docket.endDate).format('MM/YYYY'),
      },
      upload: {
        uploader: `${docket.user.firstName} ${docket.user.lastName}`,
        uploadDate: createdAt.format('DD/MM/YYYY'),
        uploadTime: createdAt.format('HH[h]mm'),
      },
      commissionTypes,
    }
  })
}

export const getNoContentMessage = ({
  filter,
  debouncedSearch,
}: {
  filter: string
  debouncedSearch: string
}): string => {
  return filter === '' && debouncedSearch === ''
    ? `Aucun bordereau n'a été importé pour le moment`
    : `Aucun bordereau ne correspond à vos critères de recherche`
}

export const updateAlldata = async ({
  setLoading,
  debouncedSearch,
  filter,
  setRows,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  debouncedSearch: string
  filter: string
  setRows: React.Dispatch<React.SetStateAction<readonly IImportRow[] | undefined>>
}) => {
  const imports = await getImports({
    setLoading,
    debouncedSearch,
    filter,
  })

  if (!imports) {
    return
  }

  setRows(getRows(imports))
}
