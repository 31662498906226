export const DOCKETS_DISPLAY_FILTERS = [
  {
    value: 'all',
    display: 'Toutes les lignes',
  },
  {
    value: 'errors',
    display: 'Erreurs uniquement',
  },
]

export const DEFAULT_DOCKETS_DISPLAY_FILTER_INDEX = 0
export const DEFAULT_DOCKETS_YEAR_FILTER_INDEX = 0
export const DEFAULT_DOCKETS_COMMISSION_KIND_INDEX = 0
