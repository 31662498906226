import { IconButton, Stack, Typography, styled } from '@mui/material'
// import HelpContainer from 'component/HelpContainer'
import { AlertCircleIcon, FilePlusIcon, Trash2Icon } from 'icons'
import { Moment } from 'moment'
import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react'
import { getProviderLogo } from 'utils/commissions'

interface IFilesInput {
  files: File[]
  setFiles: Dispatch<SetStateAction<File[]>>
  insurance: string
  isAbeilleOutstandingContract: boolean
  period: Moment | null
  setIsAbeilleOutstandingContract: React.Dispatch<React.SetStateAction<boolean>>
  error: string | undefined
  setError: Dispatch<SetStateAction<string | undefined>>
}

export const FilesInput = ({
  setFiles,
  files,
  insurance,
  isAbeilleOutstandingContract,
  period,
  setIsAbeilleOutstandingContract,
  error,
  setError,
}: IFilesInput) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files) {
      return
    }

    const newFiles = Array.from(e.target.files).filter(
      (file) => !files.find((f) => f.name === file.name)
    )
    setFiles([...files, ...newFiles])
  }

  const abeilleContract = useMemo(() => {
    return insurance === 'abeille'
  }, [insurance])

  const handleRemoveFile = () => {
    setFiles([])
    setIsAbeilleOutstandingContract(false)
    setError(undefined)
  }

  return (
    <>
      <DropZone direction="column" alignItems="center" p={6}>
        <input
          type="file"
          onChange={handleFileChange}
          accept=".xls,.csv, .xlsx"
          multiple={!abeilleContract}
        />
        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <img
            height="40px"
            width="40px"
            src={getProviderLogo(insurance)}
            alt={`${insurance} logo`}
          />
          {abeilleContract && files.length > 0 ? (
            <Stack
              direction="row"
              alignItems="center"
              mb={1}
              key={files[0].name}
              border={1}
              borderColor={
                error
                  ? 'error.light'
                  : !period && isAbeilleOutstandingContract
                  ? 'warningLight.dark'
                  : 'palette.divider'
              }
              borderRadius={1}
              px={2}
              ml={1}
            >
              {((!period && isAbeilleOutstandingContract) || error) && (
                <AlertCircleIcon
                  sx={{
                    color: error ? 'error.main' : 'warning.main',
                    mr: 1,
                  }}
                />
              )}
              <Typography variant="subtitle1" color="text.secondary">
                {files[0].name}
              </Typography>

              <IconButton size="small" sx={{ ml: 4 }} onClick={handleRemoveFile}>
                <Trash2Icon />
              </IconButton>
            </Stack>
          ) : (
            <FilePlusIcon sx={{ opacity: 0.7, width: 40, height: 40 }} />
          )}
        </Stack>
        {(!files.length || (!abeilleContract && files.length > 0)) && (
          <>
            <Typography
              variant="body2"
              textAlign="center"
              color="text.secondary"
              sx={{ u: { color: 'primary.main' } }}
            >
              Glissez-déposez un ou plusieurs bordereaux ou <u>cliquez ici</u> pour les ajouter
            </Typography>
            <Typography variant="subtitle1" textAlign="center" sx={{ opacity: 0.7 }}>
              (csv, xlsx ou xls uniquement, jusqu’à 10mb)
            </Typography>
          </>
        )}
        {!period && isAbeilleOutstandingContract && (
          <Stack>
            <Typography variant="subtitle1" textAlign="center" color="text.secondary">
              Vous essayez d’importer un bordereau d’Encours AFER / ABEILLE.
            </Typography>
            <Typography variant="subtitle1" textAlign="center" color="text.secondary">
              Veuillez préciser la période couverte par le bordereau pour continuer.
            </Typography>
          </Stack>
        )}
        {error && abeilleContract && (
          <Stack>
            <Typography variant="subtitle1" textAlign="center" color="error.main">
              {error}
            </Typography>
          </Stack>
        )}
      </DropZone>
      {/* todo uncomment when url will be available */}
      {/* {!abeilleContract && (
        <HelpContainer
          helpURL="https://sourceinteractive.notion.site/R-f-rentiel-des-types-de-commissions-par-assureur-5dc930dec4714beca3b874151f316926"
          message="Quel fichier choisir ? Quel format est attendu ?"
          size="small"
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        />
      )} */}
    </>
  )
}

const DropZone = styled(Stack)(({ theme }) => ({
  background: '#F4F4F1',
  border: `1px dashed ${theme.palette.divider}`,
  cursor: 'pointer',
  position: 'relative',

  input: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    appearance: 'none',
    opacity: 0,
    cursor: 'pointer',
  },
}))
