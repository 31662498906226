import { LOADING_UPDATE } from 'store/constants/loader'

export const userState = {
  loading: false,
}

export function loader(state = userState, action: any) {
  switch (action.type) {
    case LOADING_UPDATE:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
