import React from 'react'
import { Button, Paper, Typography } from '@mui/material'

import { useHistory } from 'react-router-dom'

function SubscriptionWelcome() {
  let history = useHistory()

  const onDiscoverClick = () => {
    localStorage.removeItem('resumeKey')
    history.push('/')
  }

  return (
    <>
      <Typography variant="h2b" mb={4} component="h2" align="center">
        Bienvenue sur Sendraise !
      </Typography>

      <Paper elevation={2} sx={{ p: '32px 48px', mb: 4, textAlign: 'center' }}>
        <Typography variant="body2" fontWeight={500} color="success.main" mb={1}>
          Votre période d’essai est désormais active.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" mb={4}>
          Vous serez invité, avant la fin de cette période, à saisir vos coordonnées bancaires afin de finaliser votre souscription.
        </Typography>

        <Button color="primary" size="large" onClick={onDiscoverClick}>
          Découvrir Sendraise
        </Button>
      </Paper>
    </>
  )
}

export default SubscriptionWelcome
