import { PaletteOptions } from '@mui/material'

// Transforming tokens
// MUI provides 3 tones for each given color : main, light and dark.
// It also gives you a "contrastText object" that can be overriden

// Texts have 3 default values : primary, secondary and disabled
// Backgrounds have "paper" (= modals, sidebars) and "default"
// Also a greyPalette with 10 values + 7 alpha values

// theme.neutral.borderSubdued = divider
// theme.neutral.bg-01 = default
// theme.neutral.bg-00 = paper
// theme.neutral.bg-02 = background02
// theme.neutral.bg-03 = background03
// theme.neutral.bg-inverted = inverted

// main -> surface, text, border (for now)
// mainHover -> handled by actions (dark property)
// mainPressed -> handled by actions (light property)
// textSecondary: rgba(main, 0.7)
// textInverted: handled by contrastText
// textSecondaryInverted: rgba(textInverted, 0.7)

// dark: -> not defined
// border: -> not defined

// Each lead tint has a "light" variant to handle background tokens.
// We separate it from the lead tint to keep MUI "light" and "dark" available for action colors (hover=light, pressed=dark)
// background : new tint {tint}Light
// backgroundHover: handled by actions

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#3081db',
    light: '#529aeb',
    dark: '#296db9',
    contrastText: '#F1F7FE',
  },
  primaryLight: {
    main: '#DFECFB',
    light: '#F1F7FE',
    dark: '#ADCFF5',
    contrastText: '#3081DB',
  },
  secondary: {
    main: '#3f505e',
    light: '#616f7b',
    dark: '#1c2f3f',
    contrastText: '#F1F2F3',
  },
  secondaryLight: {
    main: '#E3E6E8',
    light: '#F1F2F3',
    dark: '#ACB3B9',
    contrastText: '#1C2F3F',
  },
  error: {
    main: '#A40D00',
    light: '#B93226',
    dark: '#8A0B00',
    contrastText: '#FBF4F3',
  },
  errorLight: {
    main: '#F3DBD8',
    light: '#FBF4F3',
    dark: '#E6B6B2',
    contrastText: '#A40D00',
  },
  success: {
    main: '#297c40',
    light: '#4b955f',
    dark: '#226836',
    contrastText: '#F5F9F6',
  },
  successLight: {
    main: '#DFECE2',
    light: '#F5F9F6',
    dark: '#BFD9C6',
    contrastText: '#297C40',
  },
  warning: {
    main: '#BC5F06',
    light: '#CF7B2B',
    dark: '#974d04',
    contrastText: '#FCF8F3',
  },
  warningLight: {
    main: '#F6E5D5',
    light: '#FCF8F3',
    dark: '#ECCCAC',
    contrastText: '#BC5F06',
  },
  neutral: {
    main: '#7A796E',
    light: '#969587',
    dark: '#5e5d55',
    contrastText: '#FBFBF9',
  },
  neutralLight: {
    main: '#FBFBF9',
    light: '#ffffff',
    dark: '#F4F4F1',
    contrastText: '#262522',
  },
  dataviz: {
    crimsonDark: {
      main: '#583749',
      contrastText: '#FFFFFF',
    },
    crimsonMid: {
      main: '#82556D',
      contrastText: '#FFFFFF',
    },
    crimsonLight: {
      main: '#CDB2C0',
      contrastText: '#3E2834',
    },
    greenishDark: {
      main: '#2B4323',
      contrastText: '#FFFFFF',
    },
    greenishMid: {
      main: '#4D743F',
      contrastText: '#FFFFFF',
    },
    greenishLight: {
      main: '#A9C89D',
      contrastText: '#21311C',
    },
    turquoise: {
      main: '#3F5F6F',
      contrastText: '#FFFFFF',
    },
  },
  accentHelp: {
    main: '#2A6768',
    light: '#337E7F',
    dark: '#1D4849',
    contrastText: '#FFFFFF',
  },
  accentHelpLight: {
    main: '#DDEEEE',
    light: '#EAF5F5',
    dark: '#BADDDE',
    contrastText: '#2A6768',
  },
  text: {
    primary: '#262522',
    secondary: '#7a796e',
    tertiary: '#b3b1a1',
    disabled: '#363645',
  },
  divider: 'rgb(218, 217, 208)',
  grey: {
    50: 'rgb(248, 248, 246)',
    100: 'rgb(228, 227, 221)',
    200: 'rgb(248, 248, 246)',
    300: 'rgb(208, 207, 195)',
    400: 'rgb(198, 196, 182)',
    500: 'rgb(179, 177, 161)',
    600: 'rgb(150, 149, 135)',
    700: 'rgb(122, 121, 110)',
    800: 'rgb(94, 93, 85)',
    900: 'rgb(38, 37, 34)',
  },
  background: {
    paper: '#FBFBF9', // ex 100, bg-00 in tokens
    default: '#F4F4F1', // ex 200, bg-01 in tokens
    background02: '#E4E3DD', // ex-300, bg-02 in tokens, apparently used nowhere ?
    background03: '#DAD9D0', // ex-400, bg-03 in tokens
    inverted: '#1C2F3F', // ex-500, bg-inverted in tokens
  },
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    paper: string
    default: string
    background02: string
    background03: string
    inverted: string
  }

  interface TypeText {
    tertiary: string
    quaternary: string
  }

  interface Palette {
    neutral: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    neutralLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    primaryLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    secondaryLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    errorLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    successLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    warningLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    accentHelp: {
      main: string
      light: string
      dark: string
      contrastText: string
    }
    accentHelpLight: {
      main: string
      light: string
      dark: string
      contrastText: string
    }
    dataviz: {
      crimsonDark: {
        main: string
        contrastText: string
      }
      crimsonMid: {
        main: string
        contrastText: string
      }
      crimsonLight: {
        main: string
        contrastText: string
      }
      greenishDark: {
        main: string
        contrastText: string
      }
      greenishMid: {
        main: string
        contrastText: string
      }
      greenishLight: {
        main: string
        contrastText: string
      }
      turquoise: {
        main: string
        contrastText: string
      }
    }
  }

  interface PaletteOptions {
    neutral: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    neutralLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    primaryLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    secondaryLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    errorLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    successLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    warningLight: {
      main: string
      contrastText: string
      dark: string
      light: string
    }
    accentHelp: {
      main: string
      dark: string
      light: string
      contrastText: string
    }
    accentHelpLight: {
      main: string
      dark: string
      light: string
      contrastText: string
    }
    dataviz: {
      crimsonDark: {
        main: string
        contrastText: string
      }
      crimsonMid: {
        main: string
        contrastText: string
      }
      crimsonLight: {
        main: string
        contrastText: string
      }
      greenishDark: {
        main: string
        contrastText: string
      }
      greenishMid: {
        main: string
        contrastText: string
      }
      greenishLight: {
        main: string
        contrastText: string
      }
      turquoise: {
        main: string
        contrastText: string
      }
    }
  }
}

export default palette
