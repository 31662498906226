import React, { useEffect, useState } from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'

import PricingCard from './PrincingCard'
import { getStripePlans } from '../core/Subscription.service'
import Loader from 'component/Loader'
import SubscriptionError from '../SubscriptionError'

function SubscriptionPayment() {
  const [plans, setPlans] = useState([])
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    fetchPlans()
  }, [])

  const fetchPlans = async () => {
    try {
      const res = await getStripePlans()
      setPlans(
        res.data.sort(
          (plan1: any, plan2: any) =>
            plan2.default_price.unit_amount - plan1.default_price.unit_amount
        )
      )
    } catch (e: any) {
      setError(e.message)
    }
  }

  const onClearError = () => {
    setError(null)

    if (!plans.length) {
      fetchPlans()
    }
  }

  return (
    <>
      <Box sx={{ ml: 6 }}>
        <Typography variant="body2" color="text.secondary" mb={0.5}>
          Étape 5
        </Typography>
        <Typography variant="h2" mb={4} component="h2">
          Choix de l'offre
        </Typography>
      </Box>

      <Paper elevation={2} sx={{ p: '32px 48px' }}>
        {error ? (
          <SubscriptionError error={error} clearError={onClearError} />
        ) : (
          <>
            <Typography variant="subtitle1" fontWeight={500} mb={1.5}>
              Votre souscription
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" mb={4}>
              Choisissez ci-dessous l’offre la plus adaptée à votre besoin.
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" mb={4}>
              Quelques jours avant la fin de votre période d’essai, vous serez invité à saisir vos
              coordonnées bancaires pour finaliser votre souscription.
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="stretch" mb={2} spacing={2}>
              {plans.length ? (
                plans.map((plan: any, index) => (
                  <PricingCard
                    key={plan.id}
                    title={plan.name}
                    desc={plan.description}
                    price={plan.default_price.unit_amount}
                    trialDuration={plan.metadata.trial_days}
                    priceId={plan.default_price.id}
                    bestOffer={index === plans.length - 1}
                    setError={setError}
                  />
                ))
              ) : (
                <Loader />
              )}
            </Stack>

            <Typography
              variant="subtitle2"
              color="text.secondary"
              align="center"
              sx={{ fontStyle: 'italic' }}
            >
              Vos coordonnées bancaires seront gérées par notre partenaire
              <img
                className="ml-2 mr-2"
                src={process.env.PUBLIC_URL + '/static/images/logo-stripe.svg'}
                alt="Stripe logo"
                loading="lazy"
              />
            </Typography>
          </>
        )}
      </Paper>
    </>
  )
}

export default SubscriptionPayment
