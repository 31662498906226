import EventEmitter, {
  UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE,
  UPDATE_EVENT_INSURANCE_FEE_DEPOSIT,
  UPDATE_EVENT_INSURANCE_FEE_UPFRONT,
  UPDATE_EVENT_INSURANCE_PRODUCTS,
} from 'utils/events'

import { patchConfigurationPropagation } from './ModalEditFeesPropagation.service'

export const getInsuranceProductsPropagatedToDifference = ({
  defaultInsuranceProductsPropagatedToIds,
  insuranceProductsPropagatedToIds,
}: {
  defaultInsuranceProductsPropagatedToIds: number[]
  insuranceProductsPropagatedToIds: number[]
}): { additions: number; removals: number } => {
  return {
    additions: insuranceProductsPropagatedToIds.filter(
      (id) => !defaultInsuranceProductsPropagatedToIds.includes(id)
    ).length,
    removals: defaultInsuranceProductsPropagatedToIds.filter(
      (id) => !insuranceProductsPropagatedToIds.includes(id)
    ).length,
  }
}

export const getChipLabel = (count: number) => {
  if (count > 1) {
    return `${count} produits`
  }

  return `${count} produit`
}

export const updatePropagation = async ({
  setLoading,
  setError,
  insuranceProductsPropagatedToIds,
  feesType,
  editedConfigurationId,
  goToNextStep,
}: {
  setLoading: StateSetter<boolean>
  setError: StateSetter<string | undefined>
  insuranceProductsPropagatedToIds: number[]
  feesType: 'arbitration' | 'deposit' | 'upfront'
  editedConfigurationId: number
  goToNextStep: () => void
}) => {
  setLoading(true)
  setError(undefined)

  await patchConfigurationPropagation({
    feesType,
    editedConfigurationId,
    insuranceProductIds: insuranceProductsPropagatedToIds,
  }).catch((err) => {
    setError(
      `Erreur lors de l'édition de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  switch (feesType) {
    case 'arbitration':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE)
      break
    case 'deposit':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
      break
    case 'upfront':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_UPFRONT)
      break
  }

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
}
