import { Components, Theme } from '@mui/material'

const MuiToggleButton: NonNullable<Components<Theme>['MuiToggleButton']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1.25, 2),
      fontWeight: 400,

      '&.Mui-selected': {
        fontWeight: 500,
        backgroundColor: theme.palette.primaryLight.main,
        color: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: theme.palette.primaryLight.main,
        },
      },
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(1, 1.5),
      ...theme.typography.subtitle2,
    }),
  },
}

export default MuiToggleButton
