import * as React from 'react'

import { Modal } from 'component'
import Loader from 'component/Loader'

import { Box, Stack, styled, Typography } from '@mui/material'
interface IProps {
  progress: number
  file: File
  error: string | undefined
}

const ImportStep: React.FC<IProps> = (props) => {
  const { progress, file, error } = props

  return (
    <Modal.Content>
      <Wrapper alignItems="center" justifyContent="center">
        <Box mb={2}>
          <Loader />
        </Box>
        <Typography variant="body2" mb={0.5} sx={{ color: 'text.primary' }}>
          Fichier en cours d'importation : {progress}%
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {file.name}
        </Typography>
        {error !== undefined ? <Typography sx={{ color: 'error.text' }}>{error}</Typography> : null}
      </Wrapper>
    </Modal.Content>
  )
}

export default ImportStep

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default, // TOCHECK
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(6),
}))
