import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const RotateCcwIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.5C8.35789 4.5 5.00003 7.85786 5.00003 12C5.00003 12.6624 5.08568 13.304 5.24644 13.9149L5.87886 12.0604C6.05713 11.5377 6.62539 11.2584 7.14812 11.4367C7.67084 11.615 7.95008 12.1832 7.77182 12.706L6.41757 16.677C6.33197 16.928 6.15015 17.1348 5.91212 17.2517C5.67409 17.3687 5.39934 17.3863 5.14832 17.3007L1.17725 15.9465C0.654529 15.7682 0.375289 15.1999 0.553552 14.6772C0.731816 14.1545 1.30008 13.8753 1.8228 14.0535L3.35357 14.5756C3.1232 13.756 3.00003 12.892 3.00003 12C3.00003 6.75329 7.25332 2.5 12.5 2.5C17.7467 2.5 22 6.75329 22 12C22 17.2467 17.7467 21.5 12.5 21.5C11.9477 21.5 11.5 21.0523 11.5 20.5C11.5 19.9477 11.9477 19.5 12.5 19.5C16.6422 19.5 20 16.1421 20 12C20 7.85786 16.6422 4.5 12.5 4.5Z"
      />
    </SvgIcon>
  )
}

export default RotateCcwIcon
