import { Button, Stack, Typography, styled } from '@mui/material'
import ExternalLinkIcon from 'icons/ExternalLinkIcon'
import { Link as RouterLink } from 'react-router-dom'

interface IEmptyBlock {
  title?: string
  description: string
  icon?: React.ReactNode
  cta?: {
    label: string
    onClick: () => void
    icon: React.ReactNode
  }
  link?: {
    label: string
    url: string
  }
}

const EmptyBlock = ({ title, description, icon, cta, link }: IEmptyBlock) => {
  return (
    <CustomStack direction="column" alignItems="center" mb={3}>
      {icon}
      {title && (
        <Typography
          component="p"
          variant="body2"
          textAlign="center"
          fontWeight={500}
          mb="4px"
          color="neutralLight.contrastText"
          fontSize={14}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          component="p"
          variant="body2"
          textAlign="center"
          color="neutralLight.contrastText"
          fontSize={14}
        >
          {description}
        </Typography>
      )}
      {cta && (
        <Button color="neutralLight" onClick={cta.onClick} sx={{ width: 'fit-content', mt: 2 }}>
          {cta.icon}
          {cta.label}
        </Button>
      )}
      {link && (
        <Button
          component={RouterLink}
          to={link.url}
          variant="text"
          color="neutralLight"
          sx={{ mt: 2 }}
        >
          <ExternalLinkIcon sx={{ mr: '4px' }} /> {link.label}
        </Button>
      )}
    </CustomStack>
  )
}

export default EmptyBlock

const CustomStack = styled(Stack)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(4),
  borderRadius: 4,
}))
