import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const LinkIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L12.7062 11.292C12.7068 11.2926 12.7074 11.2932 12.708 11.2938L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L16.5599 17.9742C16.3754 17.9913 16.1886 18 16 18H14C13.4477 18 13 17.5523 13 17C13 16.4477 13.4477 16 14 16H14.5858L11.5858 13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H9.58579L6.77618 8.1904C5.16568 8.70718 4 10.2183 4 12C4 14.2091 5.79086 16 8 16H10C10.5523 16 11 16.4477 11 17C11 17.5523 10.5523 18 10 18H8C4.68629 18 2 15.3137 2 12C2 9.67639 3.32022 7.66245 5.25132 6.66553L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289ZM13 7C13 6.44772 13.4477 6 14 6H16C19.3137 6 22 8.68629 22 12C22 13.1478 21.6767 14.2232 21.1157 15.1366C20.8266 15.6072 20.2108 15.7544 19.7402 15.4653C19.2696 15.1763 19.1224 14.5604 19.4115 14.0898C19.7846 13.4823 20 12.7678 20 12C20 9.79086 18.2091 8 16 8H14C13.4477 8 13 7.55228 13 7Z"
      />
    </SvgIcon>
  )
}

export default LinkIcon
