import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const ExpandableIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g opacity="0.7">
        <path
          d="M13.5 3V6C13.5 6.13261 13.4473 6.25979 13.3536 6.35355C13.2598 6.44732 13.1326 6.5 13 6.5C12.8674 6.5 12.7402 6.44732 12.6464 6.35355C12.5527 6.25979 12.5 6.13261 12.5 6V4.20687L9.35375 7.35375C9.25993 7.44757 9.13268 7.50028 9 7.50028C8.86732 7.50028 8.74007 7.44757 8.64625 7.35375C8.55243 7.25993 8.49972 7.13268 8.49972 7C8.49972 6.86732 8.55243 6.74007 8.64625 6.64625L11.7931 3.5H10C9.86739 3.5 9.74021 3.44732 9.64645 3.35355C9.55268 3.25979 9.5 3.13261 9.5 3C9.5 2.86739 9.55268 2.74021 9.64645 2.64645C9.74021 2.55268 9.86739 2.5 10 2.5H13C13.1326 2.5 13.2598 2.55268 13.3536 2.64645C13.4473 2.74021 13.5 2.86739 13.5 3ZM6.64625 8.64625L3.5 11.7931V10C3.5 9.86739 3.44732 9.74021 3.35355 9.64645C3.25979 9.55268 3.13261 9.5 3 9.5C2.86739 9.5 2.74021 9.55268 2.64645 9.64645C2.55268 9.74021 2.5 9.86739 2.5 10V13C2.5 13.1326 2.55268 13.2598 2.64645 13.3536C2.74021 13.4473 2.86739 13.5 3 13.5H6C6.13261 13.5 6.25979 13.4473 6.35355 13.3536C6.44732 13.2598 6.5 13.1326 6.5 13C6.5 12.8674 6.44732 12.7402 6.35355 12.6464C6.25979 12.5527 6.13261 12.5 6 12.5H4.20687L7.35375 9.35375C7.44757 9.25993 7.50028 9.13268 7.50028 9C7.50028 8.86732 7.44757 8.74007 7.35375 8.64625C7.25993 8.55243 7.13268 8.49972 7 8.49972C6.86732 8.49972 6.74007 8.55243 6.64625 8.64625Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}

export default ExpandableIcon
