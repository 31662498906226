import * as React from 'react'

import { AccordionDetails, AccordionDetailsProps, styled } from '@mui/material'

const AccordionContent: React.FC<AccordionDetailsProps> = (props) => {
  return <StyledAccordionDetails {...props} />
}

export default AccordionContent

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `0 ${theme.spacing(2.25)} ${theme.spacing(2)} ${theme.spacing(6.5)}`,
}))
