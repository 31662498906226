import * as React from 'react'

import { AlertOffIcon, AlertCircleIcon } from 'icons'
import { BaseInput, Checkbox } from 'component/Inputs'
import { IAPIDocketItemFlagDescriptionProperty } from 'api/interfaces'

import { getErrorTitle, getFormattedValue } from './core/ErrorDisabler.helpers'

import { CheckboxProps, Stack, Tooltip, Typography } from '@mui/material'

interface IProps {
  field: IAPIDocketItemFlagDescriptionProperty
  value: any
  type: string
  message: string
  disabled: boolean
  comment: string | undefined
  onDisabledChange: CheckboxProps['onChange']
  onCommentChange: React.ChangeEventHandler<HTMLInputElement>
}

const ErrorDisabler: React.FC<IProps> = (props) => {
  const { field, value, type, message, disabled, comment, onDisabledChange, onCommentChange } =
    props

  return (
    <Stack
      direction="row"
      p={2}
      pl={4}
      gap={4}
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: disabled ? theme.palette.primary.main : theme.palette.divider,
        backgroundColor: disabled ? theme.palette.primaryLight.main : undefined,
        borderRadius: 1,
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Stack direction="row" alignItems="center" gap={4} flex={1}>
        <Checkbox checked={disabled} onChange={onDisabledChange} />
        <Stack gap={0.5}>
          <Typography variant="subtitle1" fontWeight={500}>
            {getErrorTitle(field)}
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography
              variant="subtitle1"
              fontWeight={500}
              color={disabled ? 'text.secondary' : 'warning.main'}
            >
              {getFormattedValue(field, value)}
            </Typography>
            <Tooltip
              slotProps={{ popper: { sx: { maxWidth: 300 } } }}
              placement="top"
              title={
                <Stack gap={1}>
                  <Typography variant="subtitle1" fontWeight={500} color="text.primary">
                    {type}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {message}
                  </Typography>
                </Stack>
              }
            >
              <Stack direction="row" alignItems="center">
                {disabled ? (
                  <AlertOffIcon sx={{ fontSize: 16, color: 'text.secondary', cursor: 'pointer' }} />
                ) : (
                  <AlertCircleIcon
                    sx={{ fontSize: 16, color: 'warning.main', cursor: 'pointer' }}
                  />
                )}
              </Stack>
            </Tooltip>
          </Stack>
          {field === 'ucits' ? (
            <Typography variant="subtitle1" color="text.secondary">
              {value.isin}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      {disabled ? (
        <BaseInput
          id="comment"
          multiline
          value={comment}
          onChange={onCommentChange}
          sx={{ flexBasis: 300 }}
          placeholder="Justifiez votre désactivation"
        />
      ) : null}
    </Stack>
  )
}

export default ErrorDisabler
