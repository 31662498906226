import { Stack, styled } from '@mui/material'
import React, { FC } from 'react'
import NavbarLink from '../NavbarLink'
import { IProps } from './typescript/navbarHeader.interfaces'
import { IComposition } from './typescript/navbarHeader.interfaces'

export type {
  IComposition as INavbarHeaderComposition,
  IProps as INavbarHeaderProps,
} from './typescript/navbarHeader.interfaces'

const NavbarHeader: FC<React.PropsWithChildren<IProps>> & IComposition = (props) => {
  const { children, className, variant = 'light' } = props

  return (
    <NavbarHeaderContainer
      className={`navbar__header ${className || ''}`}
      variant={variant}
      direction="row"
      alignItems="stretch"
      spacing={3}
    >
      {children}
    </NavbarHeaderContainer>
  )
}

NavbarHeader.Link = NavbarLink

const NavbarHeaderContainer = styled(Stack, {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: string }>(({ theme, variant }) => ({
  height: '48px',
  padding: `0 ${theme.spacing(4)}`,
  overflowX: 'auto',
  whiteSpace: 'nowrap',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  ...(variant === 'light' && {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),

  ...(variant === 'dark' && {
    backgroundColor: theme.palette.secondary.dark,
  }),
}))

export default NavbarHeader
