import * as React from 'react'

import Loader from 'component/Loader'
import { capitalize, roundDecimals } from 'utils'
import { GlobalStyles, styled, Typography, Stack } from '@mui/material'
import { CSSObject } from '@emotion/react'

import { DataGridPremium, DataGridPremiumProps, GridColTypeDef } from '@mui/x-data-grid-premium'
import { MoreHorizontalIcon } from 'icons'

interface IProps extends DataGridPremiumProps {
  noContentMessage?: string
  loadingMessage?: string
}

const BaseTable: React.FC<IProps> = (props) => {
  const { noContentMessage, loadingMessage, ...otherProps } = props

  const slots = {
    noRowsOverlay: () => (
      <NoContentWrapper flex={1} justifyContent="center" alignItems="center">
        <Typography color="text.primary" variant="body2">
          {noContentMessage || 'Aucune donnée pour le moment'}
        </Typography>
      </NoContentWrapper>
    ),
    loadingOverlay: () => (
      <NoContentWrapper flex={1} justifyContent="center" alignItems="center">
        <Typography color="text.primary" variant="body2">
          {loadingMessage || 'Chargement des données'}
        </Typography>
        <Loader />
      </NoContentWrapper>
    ),
    moreActionsIcon: () => <MoreHorizontalIcon />,
    ...props.slots,
  }

  return (
    <div style={{ width: '100%' }}>
      <MUIDataGrid
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        disableColumnReorder
        hideFooter
        getRowHeight={(props) => {
          if (Number.isInteger(props.id)) {
            return 56
          }
          return 40
        }}
        slots={{ ...slots }}
        {...otherProps}
      />
      {GridActionsCellItemStyle}
    </div>
  )
}

export default BaseTable

const MUIDataGrid = styled(DataGridPremium)(({ theme, getDetailPanelContent }) => ({
  border: 'none',
  boxShadow: theme.shadows[2],
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  background: theme.palette.background.paper,

  '.MuiDataGrid-main': {
    overflow: 'visible',
    zIndex: 1,
  },

  '.MuiDataGrid-columnHeaders': {
    background: theme.palette.background.inverted,
    color: 'white',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderColor: theme.palette.secondary.main,

    '.MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },

    '.MuiDataGrid-columnHeader--alignRight': {
      '.MuiDataGrid-columnHeaderTitle': {
        textAlign: 'right',
      },
    },
  },

  '.MuiDataGrid-row': {
    background: theme.palette.background.paper,
  },
  '.MuiDataGrid-row--grouped': {
    background: theme.palette.secondary.main,
    color: 'white',
    pointerEvent: 'none',
    '&:hover, &.Mui-hovered': {
      background: theme.palette.secondary.main,
    },
    '.MuiDataGrid-cell': {
      overflow: 'visible!important',
    },
  },
  '.MuiDataGrid-row--detailPanelExpanded': {
    transform: 'scale(1.01)',
    borderRadius: '4px 4px 0 4px',
    boxShadow:
      '0px 0px 9px rgba(29, 49, 66, 0.01), 0px 0px 8px rgba(29, 49, 66, 0.04), 0px 0px 6px rgba(29, 49, 66, 0.07), 0px 0px 3px rgba(29, 49, 66, 0.08), 0px 0px 0px rgba(29, 49, 66, 0.08)',

    '&.Mui-hovered': {
      background: theme.palette.background.paper,
    },
  },
  '.MuiDataGrid-groupingCriteriaCellToggle': {
    display: 'none',
  },
  '.MuiDataGrid-virtualScroller': {
    overflow: 'visible !important',
    zIndex: 100001,
  },
  '.MuiDataGrid-detailPanelToggleCell': {
    color: '#B6BCC3',
    '.MuiSvgIcon-root': {
      fontSize: 16,
    },
    '&.Mui-disabled': {
      display: 'none',
    },
  },

  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },

  '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
    padding: `0 ${theme.spacing(1)}`,
    outline: 'none !important',

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },

  '.MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.subtitle1,
    fontWeight: 500,
  },

  '.MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    ...theme.typography.subtitle1,

    '&.cell-number, .cell-number': {
      ...theme.typography.number1,
    },

    '&.cell-text-primary': {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },

    '&.cell-text-secondary': {
      color: theme.palette.text.secondary,
    },
  },

  '.MuiDataGrid-detailPanel': {
    overflow: 'visible',
    padding: `0 50px ${theme.spacing(2)} 50px`,
    background: theme.palette.background.paper,
    transform: 'scale(1.01)',
    borderRadius: '0 0 4px 4px',
    boxShadow:
      '0px 0px 9px rgba(29, 49, 66, 0.01), 0px 0px 8px rgba(29, 49, 66, 0.04), 0px 0px 6px rgba(29, 49, 66, 0.07), 0px 0px 3px rgba(29, 49, 66, 0.08), 0px 0px 0px rgba(29, 49, 66, 0.08)',

    '&::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: 10,
      top: -9,
      left: 0,
      background: theme.palette.background.paper,
    },
  },
}))

const NoContentWrapper = styled(Stack)(() => ({
  height: '100%',
}))

// TODO : remove export when dockets table are migrated to BaseTable
export const GridActionsCellItemStyle = (
  <GlobalStyles
    styles={(theme) => ({
      '.MuiDataGrid-menuList': {
        padding: `0 !important`,

        '.MuiMenuItem-root': {
          padding: theme.spacing(1.25, 1.5),
          ...(theme.typography.subtitle1 as CSSObject),
        },

        '.MuiListItemIcon-root': {
          minWidth: 'unset !important',
          marginRight: theme.spacing(1),

          svg: {
            fontSize: 16,
          },
        },
      },
    })}
  />
)

// Custom Cell types
export const primaryPercentageCell: GridColTypeDef = {
  type: 'number',
  valueFormatter: (params) =>
    params.value !== undefined && params.value !== null ? `${roundDecimals(params.value, 4)} %` : null,
  cellClassName: 'cell-number cell-text-primary',
}

export const percentageCell: GridColTypeDef = {
  type: 'number',
  valueFormatter: (params) =>
    params.value !== undefined && params.value !== null ? `${roundDecimals(params.value, 4)} %` : null,
  cellClassName: 'cell-number',
}

export const currencyCell: GridColTypeDef = {
  type: 'number',
  valueFormatter: (params) =>
    params.value !== undefined && params.value !== null ? `${roundDecimals(params.value, 4)} €` : null,
  cellClassName: 'cell-number',
}

export const textSecondaryCell: GridColTypeDef = {
  type: 'string',
  cellClassName: 'cell-text-secondary',
}

export const textPrimaryCell: GridColTypeDef = {
  type: 'string',
  cellClassName: 'cell-text-primary',
}

export const capitalizedCell: GridColTypeDef = {
  type: 'string',
  valueFormatter: (params) => (params.value ? capitalize(params.value) : ''),
  cellClassName: 'cell-text-capitalized',
}
