import * as React from 'react'

import { Modal } from 'component'

import { parseFlagDescription, parseInvalidationFlagDescription } from 'utils/commissions'
import { IAPIDocketItem } from 'api/interfaces'

import { ErrorsStep, FinalStep } from './components'
import { Stack, Typography } from '@mui/material'

interface IProps {
  step: number
  setStep: StateSetter<number>
  docketItem: SelectiveNonNullable<IAPIDocketItem, 'flagDescription'>
  setIsRefreshable?: StateSetter<boolean>
  tableRef?: any
}

const ModalErrorHandling: React.FC<IProps> = (props) => {
  const { step, setStep, setIsRefreshable, docketItem, tableRef } = props
  const { contract, product, flagDescription, flagInvalidationDescription } = docketItem

  const [error, setError] = React.useState<string>()

  const docketItemFlags = parseFlagDescription(flagDescription)
  const docketItemInvalidationFlags = parseInvalidationFlagDescription(flagInvalidationDescription)

  const edition = docketItemInvalidationFlags.length > 0

  const resetState = () => {
    setStep(1)
  }

  const onCloseHandler = () => {
    setStep(0)
  }

  return (
    <Modal
      open={step === 1 || step === 2}
      onClose={onCloseHandler}
      title="Désactiver / Réactiver des erreurs"
      modalId="ModalErrorHandling"
      hint={
        <Stack gap={1}>
          <Typography variant="subtitle1" fontWeight={500} color="primary.main">
            À propos des erreurs désactivées
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} color="text.secondary">
            Les erreurs seront désactivées uniquement sur cette ligne. La désactivation ne se
            propagera pas sur d’autres bordereaux.
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} color="text.secondary">
            Si la ligne est remplacée par un nouvel import de bordereau, les erreurs seront
            réactivées.
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} color="text.secondary">
            Vous pouvez réactiver les erreurs à tout moment.
          </Typography>
        </Stack>
      }
    >
      {step === 1 ? (
        <ErrorsStep
          onCloseHandler={onCloseHandler}
          contractFirstName={contract.firstName}
          contractLastName={contract.lastName}
          contractNumber={contract.number}
          productName={product?.name || ''}
          docketItemFlags={docketItemFlags}
          docketItemInvalidationFlags={docketItemInvalidationFlags}
          docketItem={docketItem}
          setStep={setStep}
          setError={setError}
          setIsRefreshable={setIsRefreshable}
          tableRef={tableRef}
        />
      ) : step === 2 && error ? (
        <FinalStep error={error} resetState={resetState} edition={edition} />
      ) : null}
    </Modal>
  )
}

export default ModalErrorHandling
