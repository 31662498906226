import { MouseEventHandler } from 'react'
import { downloadRawInsuranceFeeReport } from './ModalDownloadRawInsuranceFeeReport.service'
import { IFetchError } from 'utils'
import { ModalProps } from '@mui/material'

export const handleDownloadClick = ({
  insuranceFeeReportId,
  setLoading,
  setProgress,
  setError,
  onClose,
  setJobId,
}: {
  insuranceFeeReportId: number
  setProgress: StateSetter<number>
  setError: StateSetter<string | undefined>
  onClose: NonNullable<ModalProps['onClose']>
  setJobId: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
}): MouseEventHandler => {
  return async () => {
    setError(undefined)
    setProgress(0)
    setLoading(true)

    const res = await downloadRawInsuranceFeeReport(insuranceFeeReportId).catch((err: IFetchError) => {
      console.log(err.details)
      setError(
        `Erreur lors du téléchargement des données brutes, veuillez réessayer.`
      )
    })

    if (!res) {
      return
    }

    setJobId(res.jobId)
  }
}
