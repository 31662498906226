import { Box, ModalProps } from '@mui/material'
import * as React from 'react'
import { Modal } from 'component'
import { Stack } from '@mui/system'
import { displayArrayWithConjunction } from './core/ModalFrozenAssetEntity.helpers'
import { Information, InformationName } from './components'
import { IClientRowFae } from 'page/FrozenAssetEntities/Clients/components/ClientsListLayout'

export interface IProps {
  open: boolean
  onClose: NonNullable<ModalProps['onClose']>
  frozenAssetEntity: IClientRowFae | null | undefined
  suspect?: boolean
}

const ModalFrozenAssetEntity: React.FC<IProps> = (props) => {
  const { open, onClose, frozenAssetEntity, suspect } = props

  const {
    name,
    firstNames,
    aliases,
    genders,
    datesOfBirth,
    placesOfBirth,
    titles,
    reasons,
    legalGrounds,
    UEReferences,
  } = { ...frozenAssetEntity }

  const fullName =
    firstNames !== null && firstNames !== undefined ? [name, ...firstNames].join(' ') : name

  return (
    <Modal
      open={open}
      onClose={onClose}
      stepTitle={suspect ? 'Ressemblance identifiée' : `Détails de l'entité`}
      title={fullName || ''}
      modalId="ModalFrozenAssetEntity"
    >
      <Modal.Content>
        <Box py={2} px={3} borderRadius={0.5} bgcolor="background.default">
          <Stack flexDirection="row" mb={2}>
            <InformationName>Alias</InformationName>
            <Information lines={1}>
              {aliases != null && aliases !== undefined ? aliases.join(', ') : '-'}
            </Information>
          </Stack>
          <Stack flexDirection="row" mb={2}>
            <InformationName>Genre</InformationName>
            <Information lines={1}>
              {genders !== null && genders !== undefined
                ? displayArrayWithConjunction({
                    array: genders,
                    conjunction: 'ou',
                  })
                : '-'}
            </Information>
          </Stack>
          <Stack flexDirection="row" mb={2}>
            <InformationName>Naissance</InformationName>
            {datesOfBirth !== null || placesOfBirth !== null ? (
              <Stack flexDirection="column" alignItems="flex-start">
                {datesOfBirth !== null && datesOfBirth !== undefined ? (
                  <Information mb={0.25} lines={1}>
                    {displayArrayWithConjunction({
                      array: datesOfBirth,
                      conjunction: 'ou',
                    })}
                  </Information>
                ) : null}
                {placesOfBirth !== null && placesOfBirth !== undefined ? (
                  <Information mb={0.25} lines={1}>
                    {displayArrayWithConjunction({
                      array: placesOfBirth,
                      conjunction: 'ou',
                    })}
                  </Information>
                ) : null}
              </Stack>
            ) : (
              '-'
            )}
          </Stack>
          <Stack flexDirection="row" mb={2}>
            <InformationName>Titres</InformationName>
            <Information lines={1}>
              {titles !== null && titles !== undefined ? titles.join(', ') : '-'}
            </Information>
          </Stack>
          <Stack flexDirection="row" mb={2}>
            <InformationName>Raisons</InformationName>
            <Information lines={2}>
              {reasons !== null && reasons !== undefined
                ? displayArrayWithConjunction({
                    array: reasons,
                    conjunction: '/',
                  })
                : '-'}
            </Information>
          </Stack>
          <Stack flexDirection="row" mb={2}>
            <InformationName>Fondements</InformationName>
            <Information lines={2}>
              {legalGrounds !== null && legalGrounds !== undefined
                ? displayArrayWithConjunction({
                    array: legalGrounds,
                    conjunction: 'et',
                  })
                : '-'}
            </Information>
          </Stack>
          <Stack flexDirection="row" mb={2}>
            <InformationName>Références UE</InformationName>
            <Information lines={2}>
              {UEReferences !== null && UEReferences !== undefined ? UEReferences.join(', ') : '-'}
            </Information>
          </Stack>
        </Box>
      </Modal.Content>
    </Modal>
  )
}

export default ModalFrozenAssetEntity
