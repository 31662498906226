import React, { useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { GlobalStyles, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-premium'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Theme from 'theme'
import ScrollToTop from 'utils/hooks/scrollToTop'
import { ROUTES } from 'utils/router/constants'
import Middleware from 'api/middleware'
import { getCSRFToken } from 'api/get'
import { customFetch } from 'api/customFetch'
import { ModalProvider } from 'utils/hooks/modalBehavior'
import ModalRoot from 'utils/hooks/modalBehavior/modal-root'
import LoaderTopbar from 'component/LoaderTopbar'
import ModalUploadMultipleDockets from 'component/Modals/ModalUploadMultipleDockets'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!)

function App() {
  const [hasCSRFToken, setCSRFToken] = useState(false)
  const CSRFToken = useRef(null)

  useEffect(() => {
    if (!hasCSRFToken) {
      try {
        setCSRFToken(true)
        getCSRFToken()
          .then((response) => response.json())
          .then((token) => {
            customFetch.dispatchCSRFToken(token.crumb)
            CSRFToken.current = token.crumb
          })
          .catch((error) => console.log(`Get CRSF Token : ${error}`))
      } catch (error) {
        console.log(`Get CRSF Token : ${error}`)
      }
    }
  }, [hasCSRFToken])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GlobalStyles
        styles={{
          '::-webkit-scrollbar': {
            width: 16,
          },
          '::-webkit-scrollbar-thumb': {
            height: 50,
            background: 'grey',
            borderRadius: 9999,
            border: '4px solid transparent',
            backgroundClip: 'padding-box',
          },
        }}
      />
      <Router>
        <ThemeProvider theme={Theme}>
          <ScrollToTop />
          <ModalProvider>
            <LoaderTopbar />
            <ModalRoot />
            <Middleware CSRFToken={CSRFToken}>
              <Switch>{ROUTES}</Switch>
            </Middleware>
            <ModalUploadMultipleDockets />
          </ModalProvider>
        </ThemeProvider>
      </Router>
    </LocalizationProvider>
  )
}

export default App
