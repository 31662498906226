import { Box, Stack, Typography } from '@mui/material'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'
import { ReactNode } from 'react'

export const getTooltipContent = (
  commissionType: IAPIInsuranceFeeCommissionType,
  provider: string
): ReactNode | undefined => {
  switch (provider) {
    case 'axa':
      switch (commissionType) {
        case 'arbitration':
          return (
            <Stack gap={1}>
              <Typography variant="subtitle1" fontWeight={500}>
                À propos des pénalités d’AXA
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                AXA applique des pénalités lorsque vous enfreignez la politique de souscription. Ces
                règles sont disponibles dans votre convention de commission.
              </Typography>
              <Box>
                <Typography variant="subtitle1" color="text.secondary">
                  - Une pénalité sur arbitrage génère des frais de 0,80% du montant de l’opération.
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  - Ces frais sont partagés entre le client et le CGP.
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  - Le client assume la part des frais prévue sur cette opération (taux ou
                  incompressible que vous avez renseigné au moment de l’opération).
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  - Le CGP assume le reste.
                </Typography>
              </Box>
            </Stack>
          )
        case 'deposit':
          return (
            <Stack gap={1}>
              <Typography variant="subtitle1" fontWeight={500}>
                À propos des pénalités d’AXA
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                AXA applique des pénalités lorsque vous enfreignez la politique de souscription. Ces
                règles sont disponibles dans votre convention de commission.
              </Typography>
              <Box>
                <Typography variant="subtitle1" color="text.secondary">
                  - Une pénalité sur versement génère des frais de 0,15% ou 0,5% pour les opérations
                  avant le 1er janvier 2023 et génère des frais de 1,5% pour les opérations après le
                  1er janvier 2023 uniquement pour les versements de plus de 100 000€.
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  - Ces frais sont partagés entre le client et le CGP.
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  - Le client assume la part des frais prévue sur cette opération (taux ou
                  incompressible que vous avez renseigné au moment de l’opération).
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  - Le CGP assume le reste.
                </Typography>
              </Box>
            </Stack>
          )
        default:
          return undefined
      }
    default:
      return undefined
  }
}
