import { Stack, Typography, Divider, IconButton } from '@mui/material'
import { Trash2Icon } from 'icons'
import { Dispatch, SetStateAction } from 'react'

interface IFilesList {
  files: File[]
  setFiles: Dispatch<SetStateAction<File[]>>
  setIsAbeilleOutstandingContract: Dispatch<SetStateAction<boolean>>
}

export const FilesList = ({ files, setFiles, setIsAbeilleOutstandingContract }: IFilesList) => {
  const handleRemoveFile = (file: File) => {
    setFiles((prevState) => {
      return prevState.filter((f) => f.name !== file.name)
    })
    setIsAbeilleOutstandingContract(false)
  }
  return (
    <Stack mt={4}>
      <Typography mb={1} variant="subtitle1_m">
        {files.length > 1 ? 'Bordereaux' : 'Bordereau'}
      </Typography>
      {files.map((file) => (
        <Stack direction="row" alignItems="center" mb={1} key={file.name}>
          <Typography variant="subtitle1" color="text.secondary">
            {file.name}
          </Typography>
          <Divider component="div" sx={{ flex: 1, mx: '12px' }} />
          <Typography variant="subtitle1" mr={1.5} color="text.secondary">
            {Math.round(file.size / 1000)} ko
          </Typography>
          <IconButton color="errorLight" size="small" onClick={() => handleRemoveFile(file)}>
            <Trash2Icon />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  )
}
