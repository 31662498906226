import * as React from 'react'

import { ArrowRightIcon, Trash2Icon } from 'icons'
import { Modal } from 'component'
import { CardClickable } from 'component/Cards'
import { HiddenFileInput } from 'component/Inputs'

import {
  handleFileChange,
  handleFileDelete,
  handleImportClick,
  handleLoadFilesClick,
  handleModalCloseClick,
} from '../../core/modalUploadClients.helpers'

import {
  Box,
  Button,
  DialogProps,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material'
interface IProps {
  file: File | undefined
  setFile: StateSetter<File | undefined>
  setProgress: StateSetter<number>
  setError: StateSetter<string | undefined>
  setStep: StateSetter<number>
  setCrm: StateSetter<'o2s' | 'prisme' | undefined>
  crm: 'o2s' | 'prisme' | undefined
  onClose: NonNullable<DialogProps['onClose']>
}

const CrmStep: React.FC<IProps> = (props) => {
  const { file, setFile, setProgress, setError, setStep, crm, setCrm, onClose } =
    props

  const manyMoreInputRef = React.useRef<HTMLInputElement>(null)
  const o2sInputRef = React.useRef<HTMLInputElement>(null)

  return (
    <>
      <Modal.Content>
        <Typography variant="subtitle1" mb={1} sx={{ color: 'text.secondary' }}>
          Exportez les données clients de vos CRM Manymore et O2S et ajoutez les fichiers obtenus ci-dessous.
        </Typography>
        <Typography variant="subtitle1" mb={2} sx={{ color: 'text.secondary' }}>
          Les données importées seront comparées aux données existantes et les modifications seront effectuées automatiquement.
        </Typography>
        <Stack flexDirection="row" gap={2}>
          <CardClickable
            sx={{ textAlign: 'center' }}
            onClick={handleLoadFilesClick(manyMoreInputRef)}
          >
            <LogoWrapper justifyContent="center" alignItems="center">
              <img
                src={process.env.PUBLIC_URL + '/static/images/logo-manymore.png'}
                width={184}
                height={27}
                alt="logo morningstar"
              />
            </LogoWrapper>
            <Typography variant="body2" mb={0.5}>
              Cliquer ici pour ajouter une base de données clients Manymore
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              (csv uniquement, jusqu’à 10mb)
            </Typography>
          </CardClickable>
          <CardClickable sx={{ textAlign: 'center' }} onClick={handleLoadFilesClick(o2sInputRef)}>
            <LogoWrapper justifyContent="center" alignItems="center">
              <img
                src={process.env.PUBLIC_URL + '/static/images/logo-o2s.png'}
                width={168}
                height={31}
                alt="logo o2s"
                className="mr-3"
              />
            </LogoWrapper>
            <Typography variant="body2" mb={0.5}>
              Cliquer ici pour ajouter une base de données clients 02S
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              (xls uniquement, jusqu’à 10mb)
            </Typography>
          </CardClickable>
        </Stack>
        {file !== undefined ? (
          <Box mt={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              Données {crm === 'prisme' ? 'Manymore' : '02S Harvest'}
            </Typography>
            <Stack flexDirection="row" alignItems="center">
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {file.name}
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  color: 'text.secondary',
                  ml: 1.5,
                  mr: 1.5,
                }}
              />
              <Typography variant="subtitle1" mr={1.5} sx={{ color: 'text.secondary' }}>
                {Math.round(file.size / 1000)} ko
              </Typography>
              <IconButton
                color="errorLight"
                size="small"
                onClick={handleFileDelete(setFile, setCrm)}
              >
                <Trash2Icon />
              </IconButton>
            </Stack>
          </Box>
        ) : null}
        <HiddenFileInput
          onChange={handleFileChange(setFile, setCrm, 'prisme')}
          accept=".csv"
          reference={manyMoreInputRef}
        />
        <HiddenFileInput
          onChange={handleFileChange(setFile, setCrm, 'o2s')}
          accept=".xlsx, .xls"
          reference={o2sInputRef}
        />
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} flexDirection="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={handleModalCloseClick({ onClose })}>
            Annuler
          </Button>
          <Button
            disabled={file === undefined || crm === undefined}
            endIcon={<ArrowRightIcon />}
            onClick={handleImportClick({
              file,
              crm,
              setProgress,
              setError,
              setStep,
            })}
          >
            Lancer l'importation
          </Button>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default CrmStep

const LogoWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.25),
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  width: '100%',
  marginBottom: theme.spacing(2),
}))
