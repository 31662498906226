import { Box, Typography, useTheme } from '@mui/material'
import * as React from 'react'

interface IProps {
  filename: string
  periodStart: string
  periodEnd: string
}

const ImportNameCell: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { filename, periodStart, periodEnd } = props
  const theme = useTheme()

  return (
    <Box sx={{ width: '100%', paddingY: theme.spacing(1) }}>
      <Typography
        variant="subtitle1"
        color="text.primary"
        fontWeight={500}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        title={filename}
      >
        {filename}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {periodStart} › {periodEnd}
      </Typography>
    </Box>
  )
}

export default ImportNameCell
