import { CheckboxProps } from '@mui/material'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'
import { getInsuranceFeeReportItemsErrorsReport } from './ModalExportErrors.service'

export const handleCheckboxChange = ({
  setChecked,
  commissionsType,
  setSelectedCommissionsTypes,
}: {
  setChecked: StateSetter<boolean>
  commissionsType: IAPIInsuranceFeeCommissionType
  setSelectedCommissionsTypes: StateSetter<IAPIInsuranceFeeCommissionType[]>
}): CheckboxProps['onChange'] => {
  return (_, checked) => {
    setChecked(checked)

    setSelectedCommissionsTypes((prevState) => {
      if (!checked) {
        return prevState.filter((prevCommissionsType) => prevCommissionsType !== commissionsType)
      }
      return [...prevState, commissionsType]
    })
  }
}

export const generateErrorReports = async ({
  setErrorReportGenerationError,
  insuranceId,
  periodYear,
  periodMonth,
  selectedCommissionsTypes,
  setJobId,
  goToNextStep,
}: {
  setErrorReportGenerationError: StateSetter<string | undefined>
  insuranceId: number
  periodYear: number
  periodMonth: number
  selectedCommissionsTypes: IAPIInsuranceFeeCommissionType[]
  setJobId: StateSetter<string | undefined>
  goToNextStep: () => void
}) => {
  setErrorReportGenerationError(undefined)
  setJobId(undefined)

  const res = await getInsuranceFeeReportItemsErrorsReport({
    insuranceId,
    periodMonth,
    periodYear,
    selectedCommissionsTypes,
  }).catch((err) => {
    setErrorReportGenerationError(
      `Erreur lors de l'export de vos erreurs, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    goToNextStep()
  })

  if (!res) {
    return
  }

  setJobId(res.jobId)
}
