import { Components, Theme } from '@mui/material'

const MuiMenuItem: NonNullable<Components<Theme>['MuiMenuItem']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.MuiCustomSelectMenuItem': {
        ...theme.typography.body2,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        color: theme.palette.text.secondary,
        borderBottom: 'none',

        '&.MuiCustomSelectMenuItem-sizeSmall': {
          ...theme.typography.subtitle1,
          padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
        },

        '&:hover': {
          background: theme.palette.secondaryLight.main,
        },

        '&.Mui-selected': {
          background: theme.palette.primaryLight.main, // TOCHECK
          color: theme.palette.primary.main,
        },

        '&.Mui-disabled': {
          opacity: 0.5,
          background: theme.palette.neutral.main,
        },
      },
    }),
  },
}

export default MuiMenuItem
