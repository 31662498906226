import { API_DEPOSIT_FEE_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIInsuranceFeeDeposit } from 'api/interfaces'
import LayoutModalConfirm from 'component/Layouts/LayoutModalConfirm'
import EventEmitter, {
  UPDATE_EVENT_INSURANCE_FEE_DEPOSIT,
  UPDATE_EVENT_INSURANCE_PRODUCTS,
} from 'utils/events'

export const fetchInsuranceFeeDeposits = (selectedInsuranceProductId: number) => {
  const queryParams = new URLSearchParams()

  queryParams.append('insuranceProductId', `${selectedInsuranceProductId}`)

  return customFetch
    .get(`${API_DEPOSIT_FEE_URL}?${queryParams.toString()}`)
    .then((res) => res.json())
    .then((data: IAPIInsuranceFeeDeposit[]) => data)
    .catch((error) => {
      console.log(`Get ipefs error : ${error}`)
      return null
    })
}

const deleteIfd = (id: number) => {
  return customFetch.delete(`${API_DEPOSIT_FEE_URL}/${id}`).catch((error) => {
    console.log(`Delete Insurance Fee Deposit Report error : ${error}`)
    return null
  })
}

export const handleDeleteClick = (e: React.MouseEvent, modalContext: any, id: number) => {
  e.currentTarget.parentElement?.classList.remove('tooltipV2__content--show')

  modalContext.showModal(LayoutModalConfirm, {
    title: 'Supprimer une configuration',
    text: `Êtes-vous sur de vouloir supprimer cette configuration de frais de versement ?`,
    buttonText: 'Supprimer',
    onClickConfirm: async () => {
      await deleteIfd(id).then(() => {
        EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
        EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
        modalContext.hideModal()
      })
    },
  })
}
