import { Stack, Typography, TypographyProps, styled } from '@mui/material'
import * as React from 'react'
import { handleSeeMoreClick } from './core/ClampedTypography.helpers'

interface IProps extends TypographyProps {
  lines: number
  seeMoreProps?: TypographyProps
}

const ClampedTypography: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { seeMoreProps, ...contentProps } = props

  const [clamped, setClamped] = React.useState(true)

  const contentRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (!contentRef.current) {
      return
    }

    if (contentRef.current.clientHeight !== contentRef.current.scrollHeight) {
      return
    }

    setClamped(false)
  }, [contentRef])

  return (
    <Stack
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="flex-start"
    >
      <Content {...contentProps} clamped={clamped} ref={contentRef} />
      {clamped ? (
        <SeeMore {...seeMoreProps} onClick={handleSeeMoreClick(setClamped)}>
          [Voir plus]
        </SeeMore>
      ) : null}
    </Stack>
  )
}

export default ClampedTypography

const Content = styled(Typography, {
  shouldForwardProp: (propName) =>
    propName !== 'lines' && propName !== 'clamped',
})<{ lines: number; clamped: boolean }>(({ lines, clamped }) => ({
  display: clamped ? '-webkit-box' : 'unset',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lines,
  overflow: 'hidden',
}))

const SeeMore = styled(Typography)(() => ({
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}))
