import * as React from 'react'

import { Modal } from 'component'
import { AlertCircleIcon, RotateCcwIcon } from 'icons'

import { Button, Stack, Typography } from '@mui/material'

interface IProps {
  error: string | undefined
  resetState: () => void
  edition: boolean
}

const FinalStep: React.FC<IProps> = (props) => {
  const { error, resetState, edition } = props

  return (
    <Modal.Content>
      <Stack alignItems="center" width={450} margin="auto">
        <AlertCircleIcon color="error" sx={{ color: 'error.contrastText', mb: 2.75 }} />
        <Typography variant="h3b" mb={0.5} sx={{ color: 'text.primary' }}>
          {edition
            ? `Problème lors de l'édition d'une ou plusieurs erreurs désactivées`
            : `Problème lors de la désactivation d'une ou plusieurs erreurs`}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          mb={3}
          sx={{
            color: 'error.text',
          }}
        >
          {error}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Button startIcon={<RotateCcwIcon />} color="secondary" onClick={resetState}>
          Recommencer {edition ? `l'édition` : 'la création'} de la configuration
        </Button>
      </Stack>
    </Modal.Content>
  )
}

export default FinalStep
