import { useEffect, useRef, useState, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import LayoutPreferences from 'component/Layouts/LayoutPreferences'
import useButtonState from 'utils/hooks/useButtonState'
import { customFetch } from 'api/customFetch'
import { API_ME_URL } from 'api/constants'
import InputText from 'component/Inputs/InputText'
import { getErrorByName } from 'utils'
import { Button } from '@mui/material'
import { LayoutMenu } from 'component/Layouts'

interface IProps {
  user?: any
  dispatch: Function
}

function SettingsDetails(props: IProps) {
  const { id } = props.user
  const [email, setEmail] = useState('')
  const refMail = useRef<HTMLInputElement>(null)

  const [isChanged, bindChange] = useButtonState(false)
  const [isDisabled, bindDisable] = useButtonState(true)

  const [errors, setErrors] = useState()

  // Get email
  useEffect(() => {
    customFetch
      .get(API_ME_URL)
      .then((response) => response.json())
      .then((result) => {
        setEmail(result.email)
      })
  }, [id])

  function handleChangeMail(e: SyntheticEvent) {
    e.preventDefault()
    const email = refMail?.current?.value
    const data = { email: email }

    customFetch
      .patch(API_ME_URL, data)
      .then((response: any) => {
        if (response.ok) {
          bindChange(true)
        } else {
          response.json().then((errs: any) => {
            setErrors(errs)
          })
        }
      })
      .catch((error: any) => console.log('Failed to change mail: ' + error))
  }

  return (
    <LayoutMenu location="preferences" defaultMenuOpen={false} pageWhite={true}>
      <div className="page__content preferences">
        <LayoutPreferences>
          <div className="preferences-profile__main container mt-8">
            <form
              className="row f-jc-start mb-6 f f-ai-end f-wrap"
              onSubmit={(e: any) => {
                handleChangeMail(e)
              }}
            >
              <div className="col col-md-4 mb-3 mb-md-0">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="email">
                  Email
                </label>
                <InputText
                  register={refMail}
                  className="input ft-500 c-black"
                  id="email"
                  type="email"
                  defaultValue={email}
                  flex
                  required
                  error={getErrorByName('email', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                  placeholder="Ajouter un email"
                />
              </div>
              <Button
                type="submit"
                disabled={isDisabled}
                className={`${isChanged ? 'btn--success' : ''}`}
                sx={{ ml: 2 }}
              >
                {isChanged ? 'Modifié' : 'Modifier'}
              </Button>
            </form>
          </div>
        </LayoutPreferences>
      </div>
    </LayoutMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(SettingsDetails)
