import { IFlag, IInvalidationFlag } from 'utils/commissions'
import EventEmitter, { UPDATE_EVENT_DOCKETS_INVALIDATE } from 'utils/events'

import { IDocketItemPayloadFlag, patchDocketItem } from './ModalErrorHandling.service'

export const getDisabledErrorsCount = (processedFlags: IDocketItemProcessedFlag[]): number => {
  return processedFlags.reduce<number>((acc, curr) => {
    if (curr.status === 'disable') {
      acc++
    }

    return acc
  }, 0)
}

export const getCtaDisabled = ({
  disabledErrorsCount,
  invalidationFlagCount,
  processedFlags,
}: {
  disabledErrorsCount: number
  invalidationFlagCount: number
  processedFlags: IDocketItemProcessedFlag[]
}): boolean => {
  if (invalidationFlagCount === 0 && disabledErrorsCount === 0) {
    return true
  }

  const areAllDisabledErrorsCommented = processedFlags.reduce<boolean>((acc, curr) => {
    if (acc === false) {
      return acc
    }

    if (curr.status === 'disable' && curr.comment === undefined) {
      acc = false
    }

    return acc
  }, true)

  if (!areAllDisabledErrorsCommented) {
    return true
  }

  return false
}

export interface IDocketItemProcessedFlag extends IDocketItemPayloadFlag {
  type: string
  message: string
}

export const getProcessedFlags = (
  docketItemFlags: IFlag[],
  docketItemInvalidationFlags: IInvalidationFlag[]
): IDocketItemProcessedFlag[] => {
  return docketItemFlags.map<IDocketItemProcessedFlag>((flag) => {
    const invalidationFlag = docketItemInvalidationFlags.find(
      (invalidationFlag) => invalidationFlag.field === flag.field
    )

    return {
      status: invalidationFlag !== undefined ? 'disable' : 'enable',
      field: flag.field,
      comment: invalidationFlag !== undefined ? invalidationFlag.comment : undefined,
      type: flag.type,
      message: flag.message,
    }
  })
}

export const updateDocketItemErrors = async ({
  setError,
  setLoading,
  docketId,
  docketItemId,
  processedFlags,
  setStep,
  setIsRefreshable,
  tableRef,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  docketId: number
  docketItemId: number
  processedFlags: IDocketItemProcessedFlag[]
  setStep: StateSetter<number>
  setIsRefreshable?: StateSetter<boolean>
  tableRef?: any
}) => {
  setError(undefined)
  setLoading(true)

  const payload = processedFlags.map<IDocketItemPayloadFlag>((processedFlag) => {
    const { message, type, ...payload } = processedFlag

    return payload
  })

  setStep(0)

  const res = await patchDocketItem({ docketId, docketItemId, payload }).catch((err) => {
    setError(
      `Erreur lors de la désactivation des erreurs, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
    setStep(2)
  })

  if (!res) {
    return
  }

  if (tableRef) {
    tableRef.updateRows([res])
  }

  if (setIsRefreshable) {
    setIsRefreshable(false)
  }
  EventEmitter.dispatch(UPDATE_EVENT_DOCKETS_INVALIDATE, res)
}
