import * as React from 'react'

import { AccordionSummary, AccordionSummaryProps, styled } from '@mui/material'

const AccordionHeader: React.FC<AccordionSummaryProps> = (props) => {
  return <StyledAccordionSummary {...props} />
}

export default AccordionHeader

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2),
  minHeight: 'unset',

  '.MuiAccordionSummary-expandIconWrapper': {
    order: -1,
    color: theme.palette.text.tertiary,
    marginRight: theme.spacing(2),
  },

  '.MuiAccordionSummary-content': {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(1.75),

    '&.Mui-expanded': {
      marginTop: theme.spacing(1.75),
      marginBottom: theme.spacing(1.75),
    },
  },

  '&.Mui-expanded': {
    minHeight: 'unset',
    '.MuiAccordionSummary-expandIconWrapper': {
      'path:first-of-type': {
        transform: 'rotate(90deg)',
      },
    },
  },
}))
