import { useRef, SyntheticEvent, useState } from 'react'
import { connect } from 'react-redux'
import LayoutPreferences from 'component/Layouts/LayoutPreferences'
import { customFetch } from 'api/customFetch'
import { API_ME_URL } from 'api/constants'
import { updateOrganizationName } from 'store/actions/organization'
import useButtonState from 'utils/hooks/useButtonState'
import InputText from 'component/Inputs/InputText'
import { getErrorByName } from 'utils'
import { Button } from '@mui/material'
import { LayoutMenu } from 'component/Layouts'

interface IProps {
  user?: any
  dispatch: Function
}

function SettingsCabinet(props: IProps) {
  const { user, dispatch } = props
  const { name } = user.organization

  const refOrganizationName: any = useRef(null)

  const [isChanged, bindChange] = useButtonState(false)
  const [isDisabled, bindDisable] = useButtonState(true)

  const [errors, setErrors] = useState()

  function handleChangeOrganizationName(e: SyntheticEvent) {
    e.preventDefault()
    const organizationName = refOrganizationName?.current?.value
    const data = { organizationName }

    customFetch
      .patch(API_ME_URL, data)
      .then((response: any) => {
        if (response.ok) {
          bindChange(true)
          dispatch(updateOrganizationName({ name: data.organizationName }))
        } else {
          response.json().then((errs: any) => {
            setErrors(errs)
          })
        }
      })
      .catch((error: any) => console.log('Failed to change organization name: ' + error))
  }

  return (
    <LayoutMenu location="preferences" defaultMenuOpen={false} pageWhite={true}>
      <div className="page__content preferences">
        <LayoutPreferences>
          <div className="preferences-profile__main container mt-8">
            <form
              className="row mb-6 f f-ai-end f-wrap"
              onSubmit={(e: any) => {
                handleChangeOrganizationName(e)
              }}
            >
              <div className="col col-md-4 mb-3 mb-md-0">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="organization">
                  Nom du cabinet
                </label>
                <InputText
                  id="organization"
                  register={refOrganizationName}
                  className="input ft-500 c-black"
                  type="text"
                  defaultValue={name}
                  flex
                  required
                  error={getErrorByName('name', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                  placeholder="Ajouter un nom d'organisation"
                />
              </div>
              <Button
                type="submit"
                disabled={isDisabled}
                className={`${isChanged ? 'btn--success' : ''}`}
                sx={{ ml: 2 }}
              >
                {isChanged ? 'Modifié' : 'Modifier'}
              </Button>
            </form>
          </div>
        </LayoutPreferences>
      </div>
    </LayoutMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(SettingsCabinet)
