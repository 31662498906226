import * as React from 'react'

import { PlusCircleIcon, MinusCircleIcon } from 'icons'
import { InsuranceProductsSelector, Modal } from 'component'
import { roundDecimals } from 'utils'
import { BaseTable } from 'component/Tables'
import {
  IAPIInsuranceFeeArbitrage,
  IAPIInsuranceFeeDeposit,
  IAPIInsuranceFeeUpfront,
  IAPIInsuranceProduct,
} from 'api/interfaces'

import {
  getChipLabel,
  getInsuranceProductsPropagatedToDifference,
  updatePropagation,
} from '../../core/ModalEditFeesPropagation.helpers'

import { Button, Chip, CircularProgress, Stack, styled, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'

interface IProps {
  feesType: 'arbitration' | 'deposit' | 'upfront'
  providerLogo: string
  insuranceName: string
  insuranceProductName: string
  editedConfiguration: IAPIInsuranceFeeArbitrage | IAPIInsuranceFeeDeposit | IAPIInsuranceFeeUpfront
  insuranceProducts: IAPIInsuranceProduct[]
  onCloseHandler: () => void
  goToNextStep: () => void
  setError: StateSetter<string | undefined>
}

const EditStep: React.FC<IProps> = (props) => {
  const {
    feesType,
    providerLogo,
    insuranceName,
    insuranceProductName,
    editedConfiguration,
    insuranceProducts,
    onCloseHandler,
    goToNextStep,
    setError,
  } = props

  const defaultInsuranceProductsPropagatedToIds = editedConfiguration.insuranceProducts.map(
    (product) => product.id
  )

  const [loading, setLoading] = React.useState(false)
  const [insuranceProductsPropagatedToIds, setInsuranceProductsPropagatedToIds] = React.useState(
    defaultInsuranceProductsPropagatedToIds
  )

  const ARBITRATION_DEPOSIT_COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'feeRate',
        headerName: `Taux de frais`,
        sortable: false,
        width: 113,
        valueFormatter: (params) => `${roundDecimals(params.value, 4)} %`,
      },
      {
        field: 'organizationRate',
        headerName: 'Taux CGP',
        sortable: false,
        width: 95,
        valueFormatter: (params) => `${roundDecimals(params.value, 4)} %`,
      },
      {
        field: 'insuranceRate',
        headerName: 'Taux Assureur',
        sortable: false,
        width: 122,
        valueGetter: (params) =>
          `${roundDecimals(Number(params.row.feeRate) - Number(params.row.organizationRate), 4)} %`,
      },
      {
        field: 'organizationMinimumAmount',
        headerName: 'Incomp. CGP',
        sortable: false,
        width: 91,
        valueFormatter: (params) => `${params.value} €`,
      },
      {
        field: 'insuranceMinimumAmount',
        headerName: 'Incomp. assur.',
        sortable: false,
        width: 91,
        valueFormatter: (params) => `${params.value} €`,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        sortable: false,
        flex: 1,
      },
    ],
    []
  )

  const UPFRONT_COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'organizationRate',
        headerName: 'Taux CGP',
        sortable: false,
        width: 95,
        valueFormatter: (params) => `${roundDecimals(params.value, 4)} %`,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        sortable: false,
        flex: 1,
      },
    ],
    []
  )

  const handleInsuranceProductsPropagatedToChange = (newSelectedInsuranceProductsIds: number[]) => {
    setInsuranceProductsPropagatedToIds(newSelectedInsuranceProductsIds)
  }

  const insuranceProductsPropagatedToDifference = getInsuranceProductsPropagatedToDifference({
    defaultInsuranceProductsPropagatedToIds,
    insuranceProductsPropagatedToIds,
  })

  const nextStepDisabled =
    insuranceProductsPropagatedToDifference.additions === 0 &&
    insuranceProductsPropagatedToDifference.removals === 0

  const updatePropagationBtnClickHandler = () => {
    updatePropagation({
      setLoading,
      setError,
      insuranceProductsPropagatedToIds,
      feesType,
      editedConfigurationId: editedConfiguration.id,
      goToNextStep,
    })
  }

  return (
    <>
      <Modal.Content>
        <Stack direction="row" gap={1.5} mb={2}>
          <img height={32} width={32} src={providerLogo} alt={`${insuranceName} logo`} />
          <Typography variant="h2b" fontWeight={500}>
            {insuranceProductName}
          </Typography>
        </Stack>
        <ConfigTable
          sx={{ mb: 2 }}
          autoHeight
          columns={feesType !== 'upfront' ? ARBITRATION_DEPOSIT_COLUMNS : UPFRONT_COLUMNS}
          rows={[editedConfiguration]}
          noContentMessage="Aucune commission sur arbitrage n'a été configurée pour ce produit."
        />
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mt={3.5}>
          <Typography variant="subtitle1" fontWeight={500}>
            Sélectionner les produits à qui propager la règle
          </Typography>
          <Stack direction="row" gap={1} height={24}>
            {insuranceProductsPropagatedToDifference.removals > 0 ? (
              <DifferenceChip
                color="warning"
                icon={<MinusCircleIcon />}
                label={getChipLabel(insuranceProductsPropagatedToDifference.removals)}
                size="small"
                visible={insuranceProductsPropagatedToDifference.additions > 0}
              />
            ) : null}
            {insuranceProductsPropagatedToDifference.additions > 0 ? (
              <DifferenceChip
                color="success"
                icon={<PlusCircleIcon />}
                label={getChipLabel(insuranceProductsPropagatedToDifference.additions)}
                size="small"
                visible={insuranceProductsPropagatedToDifference.additions > 0}
              />
            ) : null}
          </Stack>
        </Stack>
        <InsuranceProductsSelector
          sx={{ mt: 2.5 }}
          insuranceProducts={insuranceProducts}
          defaultSelectedInsuranceProductsIds={defaultInsuranceProductsPropagatedToIds}
          onChange={handleInsuranceProductsPropagatedToChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <Button
            disabled={nextStepDisabled}
            onClick={updatePropagationBtnClickHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ height: `20px !important`, width: `20px !important`, color: 'white' }}
                />
              ) : undefined
            }
          >
            Editer la propagation
          </Button>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default EditStep

const ConfigTable = styled(BaseTable)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,

  '.MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  '.MuiDataGrid-row': {
    '&:last-of-type': {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },

  '.MuiDataGrid-columnHeaderTitle': {
    overflow: 'visible',
    whiteSpace: 'normal',
    textAlign: 'center',
  },

  '.MuiDataGrid-cell, .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'center',
  },

  '.MuiDataGrid-cell': {
    '&[data-field="comment"]': {
      color: theme.palette.text.secondary,
    },
  },
}))

const DifferenceChip = styled(Chip)<{ visible: boolean }>(({ theme, visible }) => ({
  '&.MuiChip-colorWarning': {
    color: '#B45B05',
    backgroundColor: '#F8EFE6',
  },

  '&.MuiChip-colorSuccess': {
    color: theme.palette.successLight.contrastText,
    backgroundColor: theme.palette.successLight.main,
  },
}))
