import { History } from 'history'
import { FileTextIcon, HistoryIcon, SearchIcon, SettingsIcon, UsersIcon } from 'icons'
import {
  FROZEN_ASSETS_ENTITIES_CLIENTS_URL,
  FROZEN_ASSETS_ENTITIES_SEARCH_URL,
  LOGIN_URL,
  REPORTING_ANALYSIS_URL,
  REPORTING_CLIENT_ANALYSIS_DATA_URL,
  REPORTING_CONFIGURATION_URL,
  REPORTING_HISTORY_URL,
} from 'utils/router/constants'

import { getLogout } from './Menu.service'

import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

type ELocation = 'reportings' | 'asset-freeze' | 'preferences'

export const logout = async (history: History) => {
  await getLogout()

  history.push(LOGIN_URL)
}

export const getLocationTitle = (location: ELocation) => {
  switch (location) {
    case 'reportings':
      return 'Reporting de commissions'
    case 'asset-freeze':
      return 'Gel des avoirs'
    case 'preferences':
      return null
  }
}

interface INav {
  icon: React.ElementType
  name: string
  link: string
}

export const getLocationNav = (location: ELocation): INav[] => {
  switch (location) {
    case 'reportings':
      return [
        { icon: FileTextIcon, name: 'Analyse des bordereaux', link: REPORTING_ANALYSIS_URL },
        { icon: SettingsIcon, name: 'Paramétrage des produits', link: REPORTING_CONFIGURATION_URL },
        { icon: HistoryIcon, name: 'Historique des imports', link: REPORTING_HISTORY_URL },
        { icon: UsersIcon, name: 'Analyse des clients', link: REPORTING_CLIENT_ANALYSIS_DATA_URL },
      ]
    case 'asset-freeze':
      return [
        { icon: UsersIcon, name: 'Liste des clients', link: FROZEN_ASSETS_ENTITIES_CLIENTS_URL },
        {
          icon: SearchIcon,
          name: 'Recherche de prospects',
          link: FROZEN_ASSETS_ENTITIES_SEARCH_URL,
        },
      ]

    case 'preferences':
      return []
  }
}

export const getHideableElementSx = ({ theme, open }: { theme: Theme; open: boolean }): SxProps => {
  return {
    whiteSpace: 'nowrap',
    opacity: open ? 1 : 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeOut,
    }),
  }
}
