// @TODO :
// rgba qui appelle des couleurs du theme ?
// cleaner l'objet : utiliser de la décomposition d'objets (cf typography)
const stateShadows: any = {
  default: {
    formElements: {
      primary:
        'inset 0px 0px 0px 1px rgba(66, 65, 59, 0.20), 0px 1px 3px rgba(66, 65, 59, 0.05), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
      error:
        'inset 0px 0px 0px 1px rgba(164, 13, 0, 0.2), 0px 1px 3px rgba(66, 65, 59, 0.05), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    },
    primary:
      '0px 1px 3px 1px rgba(66, 65, 59, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    secondary:
      '0px 1px 3px 1px rgba(66, 65, 59, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px -1px 0px rgba(0, 0, 0, 0.3);',
    tertiary:
      'inset 0px 0px 0px 1px rgba(66, 65, 59, 0.20), 0px 1px 3px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  },
  hover: {
    formElements: {
      primary:
        'inset 0px 0px 0px 1px rgba(66, 65, 59, 0.25), 0px 1px 3px rgba(66, 65, 59, 0.05), 0px 0px 0px 3px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
      error:
        'inset 0px 0px 0px 1px rgba(164, 13, 0, 0.25), 0px 1px 3px rgba(66, 65, 59, 0.05), 0px 0px 0px 3px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    },
    primary:
      '0px 2px 6px rgba(66, 65, 59, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    secondary:
      '0px 2px 6px rgba(66, 65, 59, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px -1px 0px rgba(0, 0, 0, 0.3);',
    tertiary:
      'inset 0px 0px 0px 1px rgba(66, 65, 59, 0.25), 0px 2px 6px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    quaternary: '0px 2px 6px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
  },
  pressed: {
    primary:
      '0px 0px 1px rgba(66, 65, 59, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    secondary:
      '0px 0px 1px rgba(66, 65, 59, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px -1px 0px rgba(0, 0, 0, 0.3);',
    tertiary:
      'inset 0px 0px 0px 1px rgba(66, 65, 59, 0.25), 0px 0px 1px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    quaternary: '0px 0px 1px rgba(66, 65, 59, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
  },
  focus: {
    formElements: {
      primary:
        'inset 0px 0px 0px 1px rgba(48, 129, 219, 0.5), 0px 1px 3px rgba(66, 65, 59, 0.05), 0px 0px 0px 4px rgba(48, 129, 219, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
      success:
        '0px 1px 3px rgba(66, 65, 59, 0.05), 0px 0px 0px 4px rgba(41, 124, 64, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
      warning:
        '0px 1px 3px rgba(66, 65, 59, 0.05), 0px 0px 0px 4px rgba(180, 91, 5, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
      error:
        'inset 0px 0px 0px 1px rgba(164, 13, 0, 0.5), 0px 1px 3px rgba(66, 65, 59, 0.05), 0px 0px 0px 4px rgba(164, 13, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);',
    },
  },
}

const elevationShadows: any = {
  elevation_01: "0px 0px 1px rgba(0, 0, 0, 0.02), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 0px 18px rgba(0, 0, 0, 0.06);",
  elevation_02: "0px 1px 5px rgba(28, 47, 63, 0.08), 0px 3px 18px rgba(28, 47, 63, 0.04), 0px 15px 80px rgba(28, 47, 63, 0.03);",
}

// @TODO trop overkill
// pratique pour le setup mais grosso modo c'est tout ?
// radius : pertinent ? déjà dispo dans le theme
// est-ce que les sizing devraient dépendre des spacings du theme ?
// utiliser la décomposition d'objets sur l'appel des variables
const sizing: any = {
  button: {
    large: {
      height: '48px',
      gap: '12px',
      padding: '16px',
    },
    medium: {
      height: '40px',
      gap: '8px',
      padding: '12px',
    },
    small: {
      height: '32px',
      gap: '6px',
      padding: '8px',
    },
  },
  formElements: {
    large: {
      height: '48px',
      gap: '12px',
      padding: '16px',
    },
    medium: {
      height: '40px',
      gap: '8px',
      padding: '12px',
    },
    small: {
      height: '32px',
      gap: '6px',
      padding: '8px',
    },
  },
  selectors: {
    small: {
      height: '16px',
      gap: '4px',
    },
    medium: {
      height: '20px',
      gap: '8px',
    },
  },
}

const helpers = {
  scrollableContainer: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
  },
  clampedText: {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  fullImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  stateShadows,
  elevationShadows,
  sizing,
  sidebar: {
    open: {
      width: '240px',
    },
    closed: {
      width: '64px',
    },
  },
} as const

declare module '@mui/material/styles' {
  interface Theme {
    helpers: typeof helpers
  }
  interface ThemeOptions {
    helpers: typeof helpers
  }
}

export default helpers
