import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const HelpCircleIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.967 9.25C11.5024 9.25 11.1095 9.56774 10.9984 9.99926C10.8608 10.5341 10.3156 10.8561 9.78074 10.7184C9.24589 10.5808 8.9239 10.0356 9.06156 9.50074C9.39454 8.20704 10.5677 7.25 11.967 7.25C13.6238 7.25 14.967 8.59315 14.967 10.25C14.967 11.1814 14.5125 11.7993 14.0365 12.2587C13.8436 12.4449 13.624 12.6269 13.4266 12.7905C13.3975 12.8146 13.3689 12.8383 13.3409 12.8616C13.1105 13.0533 12.89 13.2413 12.6741 13.4571C12.2836 13.8476 11.6504 13.8476 11.2599 13.4571C10.8694 13.0666 10.8694 12.4334 11.2599 12.0429C11.5441 11.7587 11.8236 11.5223 12.0619 11.324C12.0935 11.2978 12.1239 11.2725 12.1534 11.248C12.358 11.0782 12.5132 10.9494 12.6475 10.8197C12.9215 10.5553 12.967 10.4232 12.967 10.25C12.967 9.69772 12.5193 9.25 11.967 9.25ZM11.977 16.75H11.967C11.4147 16.75 10.967 16.3023 10.967 15.75C10.967 15.1977 11.4147 14.75 11.967 14.75H11.977C12.5293 14.75 12.977 15.1977 12.977 15.75C12.977 16.3023 12.5293 16.75 11.977 16.75Z"
      />
    </SvgIcon>
  )
}

export default HelpCircleIcon
