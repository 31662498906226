import * as React from 'react'

import moment from 'moment'
import { Modal } from 'component'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'

import { CommissionsTypeSelectionStep, GenerationStep, FinalStep } from './components'

import { DialogProps } from '@mui/material'

interface IProps {
  open: boolean
  onClose: NonNullable<DialogProps['onClose']>
  insuranceProviderLogo: string
  insuranceName: string
  periodMonth: number
  periodYear: number
  insuranceId: number
  selectedCommissionType: IAPIInsuranceFeeCommissionType
  activeCommissionTypes: IAPIInsuranceFeeCommissionType[]
}

const ModalExportErrors: React.FC<IProps> = (props) => {
  const {
    open,
    onClose,
    insuranceProviderLogo,
    insuranceName,
    periodMonth,
    periodYear,
    insuranceId,
    selectedCommissionType,
    activeCommissionTypes,
  } = props

  const [step, setStep] = React.useState(1)
  const [selectedCommissionsTypes, setSelectedCommissionsTypes] = React.useState<
    IAPIInsuranceFeeCommissionType[]
  >([])
  const [errorReportGenerationError, setErrorReportGenerationError] = React.useState<string>()
  const [errorReportFileUrl, setErrorReportFileUrl] = React.useState<string>()

  const resetState = () => {
    setStep(1)
    setSelectedCommissionsTypes([])
    setErrorReportGenerationError(undefined)
    setErrorReportFileUrl(undefined)
  }

  const onCloseHandler = () => {
    onClose({}, 'backdropClick')
    resetState()
  }

  const goToNextStep = () => {
    setStep((prevState) => prevState + 1)
  }

  const period = moment()
    .month(periodMonth - 1)
    .year(periodYear)
    .format('MMMM YYYY')

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title="Exporter les erreurs d'une période"
      modalId="ModalExportErrors"
    >
      {step === 1 ? (
        <CommissionsTypeSelectionStep
          insuranceProviderLogo={insuranceProviderLogo}
          insuranceName={insuranceName}
          period={period}
          onCloseHandler={onCloseHandler}
          setSelectedCommissionsTypes={setSelectedCommissionsTypes}
          goToNextStep={goToNextStep}
          selectedCommissionType={selectedCommissionType}
          activeCommissionTypes={activeCommissionTypes}
        />
      ) : step === 2 ? (
        <GenerationStep
          selectedCommissionsTypes={selectedCommissionsTypes}
          insuranceId={insuranceId}
          periodMonth={periodMonth}
          periodYear={periodYear}
          setErrorReportGenerationError={setErrorReportGenerationError}
          goToNextStep={goToNextStep}
          setErrorReportFileUrl={setErrorReportFileUrl}
        />
      ) : step === 3 ? (
        <FinalStep
          errorReportGenerationError={errorReportGenerationError}
          errorReportFileUrl={errorReportFileUrl}
          resetState={resetState}
        />
      ) : null}
    </Modal>
  )
}

export default ModalExportErrors
