import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const UsersIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5ZM4 7C4 4.79086 5.79086 3 8 3C10.2091 3 12 4.79086 12 7C12 9.20914 10.2091 11 8 11C5.79086 11 4 9.20914 4 7ZM16 8C15.4477 8 15 8.44772 15 9C15 9.55228 15.4477 10 16 10C16.5523 10 17 9.55228 17 9C17 8.44772 16.5523 8 16 8ZM13 9C13 7.34315 14.3431 6 16 6C17.6569 6 19 7.34315 19 9C19 10.6569 17.6569 12 16 12C14.3431 12 13 10.6569 13 9ZM8 14C5.79086 14 4 15.7909 4 18V19H12V18C12 17.339 11.8397 16.7154 11.5557 16.166C11.5306 16.1264 11.5086 16.0854 11.4895 16.0433C10.8043 14.8238 9.49836 14 8 14ZM12.5825 14.1268C11.4819 12.8259 9.83742 12 8 12C4.68629 12 2 14.6863 2 18V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V19C22 15.4781 19.2893 13 16 13C14.7243 13 13.5475 13.4202 12.5825 14.1268ZM13.6075 15.8609C13.8611 16.5254 14 17.2465 14 18V19H20C20 16.6309 18.2336 15 16 15C15.1098 15 14.2805 15.3153 13.6075 15.8609Z"
      />
    </SvgIcon>
  )
}

export default UsersIcon
