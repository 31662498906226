import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const LogoIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 3C7.875 2.58579 8.21079 2.25 8.625 2.25H15.5625C15.9767 2.25 16.3125 2.58579 16.3125 3C16.3125 3.41421 15.9767 3.75 15.5625 3.75H8.625C8.21079 3.75 7.875 3.41421 7.875 3ZM7.875 21C7.875 20.5858 8.21079 20.25 8.625 20.25H15.5625C15.9767 20.25 16.3125 20.5858 16.3125 21C16.3125 21.4142 15.9767 21.75 15.5625 21.75H8.625C8.21079 21.75 7.875 21.4142 7.875 21ZM9.375 8.25C8.96079 8.25 8.625 8.58579 8.625 9C8.625 9.41421 8.96079 9.75 9.375 9.75H14.8125C15.2267 9.75 15.5625 9.41421 15.5625 9C15.5625 8.58579 15.2267 8.25 14.8125 8.25H9.375ZM8.625 15C8.625 14.5858 8.96079 14.25 9.375 14.25H14.8125C15.2267 14.25 15.5625 14.5858 15.5625 15C15.5625 15.4142 15.2267 15.75 14.8125 15.75H9.375C8.96079 15.75 8.625 15.4142 8.625 15ZM11.0625 11.25C10.6483 11.25 10.3125 11.5858 10.3125 12C10.3125 12.4142 10.6483 12.75 11.0625 12.75H13.125C13.5392 12.75 13.875 12.4142 13.875 12C13.875 11.5858 13.5392 11.25 13.125 11.25H11.0625ZM6.75 6C6.75 5.58579 7.08579 5.25 7.5 5.25H16.6875C17.1017 5.25 17.4375 5.58579 17.4375 6C17.4375 6.41421 17.1017 6.75 16.6875 6.75H7.5C7.08579 6.75 6.75 6.41421 6.75 6ZM7.5 17.25C7.08579 17.25 6.75 17.5858 6.75 18C6.75 18.4142 7.08579 18.75 7.5 18.75H16.6875C17.1017 18.75 17.4375 18.4142 17.4375 18C17.4375 17.5858 17.1017 17.25 16.6875 17.25H7.5Z"
        fill="#AF9064"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 3C9.375 2.58579 9.71079 2.25 10.125 2.25H14.0625C14.4767 2.25 14.8125 2.58579 14.8125 3C14.8125 3.41421 14.4767 3.75 14.0625 3.75H10.125C9.71079 3.75 9.375 3.41421 9.375 3ZM9.375 21C9.375 20.5858 9.71079 20.25 10.125 20.25H14.0625C14.4767 20.25 14.8125 20.5858 14.8125 21C14.8125 21.4142 14.4767 21.75 14.0625 21.75H10.125C9.71079 21.75 9.375 21.4142 9.375 21ZM10.6875 8.25C10.2733 8.25 9.9375 8.58579 9.9375 9C9.9375 9.41421 10.2733 9.75 10.6875 9.75H13.5C13.9142 9.75 14.25 9.41421 14.25 9C14.25 8.58579 13.9142 8.25 13.5 8.25H10.6875ZM9.9375 15C9.9375 14.5858 10.2733 14.25 10.6875 14.25H13.5C13.9142 14.25 14.25 14.5858 14.25 15C14.25 15.4142 13.9142 15.75 13.5 15.75H10.6875C10.2733 15.75 9.9375 15.4142 9.9375 15ZM11.8125 11.25C11.3983 11.25 11.0625 11.5858 11.0625 12C11.0625 12.4142 11.3983 12.75 11.8125 12.75H12.375C12.7892 12.75 13.125 12.4142 13.125 12C13.125 11.5858 12.7892 11.25 12.375 11.25H11.8125ZM8.625 6C8.625 5.58579 8.96079 5.25 9.375 5.25H14.8125C15.2267 5.25 15.5625 5.58579 15.5625 6C15.5625 6.41421 15.2267 6.75 14.8125 6.75H9.375C8.96079 6.75 8.625 6.41421 8.625 6ZM9.375 17.25C8.96079 17.25 8.625 17.5858 8.625 18C8.625 18.4142 8.96079 18.75 9.375 18.75H14.8125C15.2267 18.75 15.5625 18.4142 15.5625 18C15.5625 17.5858 15.2267 17.25 14.8125 17.25H9.375Z"
        fill="#D9CDBC"
      />
    </SvgIcon>
  )
}

export default LogoIcon
