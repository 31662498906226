import {
  USER_UPDATE_NAMES,
  USER_UPDATE_PICTURE,
} from 'store/constants/user'
import { ORGANIZATION_UPDATE_LOGO } from '../constants/organization'

export const updateNames = ({
  firstName,
  lastName,
}: {
  firstName: string | undefined
  lastName: string | undefined
}) => {
  return {
    type: USER_UPDATE_NAMES,
    firstName: firstName,
    lastName: lastName,
  }
}

export const updatePicture = ({ picture }: { picture: string }) => {
  return {
    type: USER_UPDATE_PICTURE,
    picture: picture,
  }
}

export const deletePicture = () => {
  return {
    type: USER_UPDATE_PICTURE,
    picture: null,
  }
}

export const deleteLogo = () => {
  return {
    type: ORGANIZATION_UPDATE_LOGO,
    logo: null,
  }
}