import { Chip, Typography } from '@mui/material'
import { SnowIcon } from 'icons'
import { IClientRowStatus } from 'page/FrozenAssetEntities/Clients/components/ClientsListLayout'
import * as React from 'react'

interface IProps {
  status: IClientRowStatus
}

const ClientStatusCell: React.FC<IProps> = (props) => {
  const { status } = props

  return status === 'suspected' ? (
    <Chip size="small" label="Gel suspecté" color="warning" icon={<SnowIcon />} />
  ) : (
    <Typography variant="subtitle1" color="text.primary">
      Avoirs non gelés
    </Typography>
  )
}

export default ClientStatusCell
