import * as React from 'react'

import { Box, Divider, Stack, Typography, styled } from '@mui/material'

interface IProps {
  prismeId: number | null
  nexusId: number | null
  placeOfBirth: string | null
  nationality: string | null
  email: string | null
  phone: string | null
  mobilePhone: string | null
  address: string | null
}

const ClientDetail: React.FC<IProps> = (props) => {
  const {
    prismeId,
    nexusId,
    placeOfBirth,
    nationality,
    email,
    phone,
    mobilePhone,
    address,
  } = props

  return (
    <Wrapper>
      <Stack flexDirection="row">
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            id prisme
          </Typography>
          <Typography variant="subtitle1">{prismeId || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            id nexus
          </Typography>
          <Typography variant="subtitle1">{nexusId || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            lieu de naissance
          </Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {placeOfBirth || '-'}
          </Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            nationalité
          </Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {nationality || '-'}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <Stack flexDirection="row">
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            email
          </Typography>
          <Typography variant="subtitle1">{email || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            téléphone
          </Typography>
          <Typography variant="subtitle1">{phone || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            portable
          </Typography>
          <Typography variant="subtitle1">{mobilePhone || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            adresse
          </Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {address || '-'}
          </Typography>
        </Box>
      </Stack>
    </Wrapper>
  )
}

export default ClientDetail

const Wrapper = styled(Stack)(({ theme }) => ({
  borderRadius: 2,
  background: theme.palette.background.default,
  flexGrow: 1,
}))
