import { patch, post } from 'utils'
import { API_ARBITRAGE_FEE_URL, API_DEPOSIT_FEE_URL, API_UPFRONT_FEE_URL } from 'api/constants'
import { IAPIInsuranceFeeArbitrage } from 'api/interfaces'

export const postArbitrationDepositConfiguration = ({
  insuranceProductIds,
  feeRate,
  organizationRate,
  organizationMinimumAmount,
  insuranceMinimumAmount,
  comment,
  feesType,
}: {
  insuranceProductIds: number[]
  feeRate: number
  organizationRate: number
  organizationMinimumAmount: number
  insuranceMinimumAmount: number
  comment: string
  feesType: 'arbitration' | 'deposit'
}) => {
  const body = {
    insuranceProductIds,
    feeRate,
    organizationRate,
    organizationMinimumAmount,
    insuranceMinimumAmount,
    comment,
  }

  let url: string

  switch (feesType) {
    case 'arbitration':
      url = API_ARBITRAGE_FEE_URL
      break
    case 'deposit':
      url = API_DEPOSIT_FEE_URL
      break
  }

  return post<IAPIInsuranceFeeArbitrage>({
    url,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}

export const postUpfrontConfiguration = ({
  insuranceProductIds,
  organizationRate,
  comment,
  isin,
  intermediaryRate,
}: {
  insuranceProductIds: number[]
  organizationRate: number
  comment: string
  isin?: string
  intermediaryRate?: number
}) => {
  const body = {
    insuranceProductIds,
    organizationRate,
    comment,
    isin,
    intermediaryRate,
  }

  return post({
    url: API_UPFRONT_FEE_URL,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}

export const patchArbitrationDepositConfiguration = ({
  feeRate,
  organizationRate,
  organizationMinimumAmount,
  insuranceMinimumAmount,
  comment,
  editedConfigurationId,
  feesType,
}: {
  feeRate: number
  organizationRate: number
  organizationMinimumAmount: number
  insuranceMinimumAmount: number
  comment: string
  editedConfigurationId: number
  feesType: 'arbitration' | 'deposit'
}) => {
  const body = {
    feeRate,
    organizationRate,
    organizationMinimumAmount,
    insuranceMinimumAmount,
    comment,
  }

  let url: string

  switch (feesType) {
    case 'arbitration':
      url = `${API_ARBITRAGE_FEE_URL}/${editedConfigurationId}`
      break
    case 'deposit':
      url = `${API_DEPOSIT_FEE_URL}/${editedConfigurationId}`
      break
  }

  return patch({
    url,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}

export const patchUpfrontConfiguration = ({
  organizationRate,
  comment,
  editedConfigurationId,
  isin,
  intermediaryRate,
}: {
  organizationRate: number
  comment: string
  editedConfigurationId: number
  isin?: string
  intermediaryRate?: number
}) => {
  const body = {
    organizationRate,
    comment,
    isin,
    intermediaryRate,
  }

  return patch({
    url: `${API_UPFRONT_FEE_URL}/${editedConfigurationId}`,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}

export const patchConfigurationPropagation = ({
  insuranceProductIds,
  editedConfigurationId,
  feesType,
}: {
  insuranceProductIds: number[]
  editedConfigurationId: number
  feesType: 'arbitration' | 'deposit' | 'upfront'
}) => {
  const body = {
    insuranceProductIds,
  }

  let url: string

  switch (feesType) {
    case 'arbitration':
      url = `${API_ARBITRAGE_FEE_URL}/${editedConfigurationId}`
      break
    case 'deposit':
      url = `${API_DEPOSIT_FEE_URL}/${editedConfigurationId}`
      break
    case 'upfront':
      url = `${API_UPFRONT_FEE_URL}/${editedConfigurationId}`
  }

  return patch({
    url,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}
