import React from 'react'
import Icon from 'component/Icon'

function InputText(props: any) {
  const {
    className,
    onChange,
    name,
    title,
    type,
    iconLeft,
    iconRight,
    getValue,
    register,
    placeholder = '',
    removeTextField,
    error,
    defaultValue,
    disabled = false,
    required = false,
    readonly = false,
    minLength,
    flex,
    id,
    onKeyDown,
  } = props

  const inputChange = (value: string) => {
    if (getValue) getValue(name, value)
  }

  const classes = `${className || ''} ${error ? 'has-error' : ''}`

  return (
    <div className={`input ${classes}`}>
      <div className={flex ? 'f' : ''}>
        {iconLeft && <Icon className={iconLeft} />}
        {title && <label className="ft-secondary mb-1">{title}</label>}
        {iconRight && <Icon className={iconRight} onClick={removeTextField} />}
        <input
          type={type}
          name={name}
          id={id}
          defaultValue={defaultValue}
          readOnly={readonly}
          className="mb-0"
          disabled={disabled}
          placeholder={placeholder}
          onChange={getValue ? (e) => inputChange(e.target.value) : onChange}
          ref={register}
          required={required}
          minLength={minLength}
          onKeyDown={onKeyDown}
        />
      </div>
      {error && (
        <div className="input__error ft-tertiary mt-1 c-black-700">
          {error.message}
        </div>
      )}
    </div>
  )
}

export default InputText
