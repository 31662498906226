import * as React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ContractMissingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 88 88" {...props}>
      <path
        d="M51.3332 11.0007C51.3332 8.97561 49.6915 7.33398 47.6665 7.33398H25.6665C19.5914 7.33398 14.6665 12.2589 14.6665 18.334V69.6673C14.6665 75.7424 19.5914 80.6673 25.6665 80.6673H62.3332C68.4083 80.6673 73.3332 75.7424 73.3332 69.6673V51.334C73.3332 49.3089 71.6915 47.6673 69.6665 47.6673C67.6415 47.6673 65.9998 49.3089 65.9998 51.334V69.6673C65.9998 71.6924 64.3582 73.334 62.3332 73.334H25.6665C23.6415 73.334 21.9998 71.6924 21.9998 69.6673V18.334C21.9998 16.3089 23.6415 14.6673 25.6665 14.6673H47.6665C49.6915 14.6673 51.3332 13.0257 51.3332 11.0007Z"
        fill="#D7C6A3"
        fillOpacity="0.5"
      />
      <path
        d="M39.7174 49.3C38.8828 48.0482 37.3681 47.4332 35.897 47.7489C34.426 48.0647 33.2971 49.247 33.0497 50.7311L31.727 58.6673H29.3332C27.3081 58.6673 25.6665 60.3089 25.6665 62.3339C25.6665 64.359 27.3081 66.0006 29.3332 66.0006H34.8332C36.6256 66.0006 38.1553 64.7048 38.4499 62.9367L38.7589 61.0828L40.949 64.3678C41.629 65.3879 42.7739 66.0006 43.9998 66.0006C45.2258 66.0006 46.3706 65.3879 47.0507 64.3678L47.6665 63.4441L48.2823 64.3678C48.9624 65.3879 50.1072 66.0006 51.3332 66.0006H58.6665C60.6915 66.0006 62.3332 64.359 62.3332 62.3339C62.3332 60.3089 60.6915 58.6673 58.6665 58.6673H53.2955L50.7174 54.8C50.0373 53.78 48.8925 53.1673 47.6665 53.1673C46.4405 53.1673 45.2957 53.78 44.6157 54.8L43.9998 55.7238L39.7174 49.3Z"
        fill="#D7C6A3"
        fillOpacity="0.5"
      />
      <path
        d="M65.7688 14.6673C64.0653 14.6673 62.6247 15.8324 62.2174 17.4146C61.7127 19.3757 59.7137 20.5563 57.7525 20.0516C55.7914 19.5468 54.6108 17.5478 55.1156 15.5867C56.3365 10.8431 60.6382 7.33398 65.7688 7.33398C71.8439 7.33398 76.7688 12.2589 76.7688 18.334C76.7688 21.7491 75.1023 24.0147 73.3568 25.6994C72.6498 26.3818 71.8446 27.0492 71.1208 27.6491L70.8064 27.9099C69.9615 28.6127 69.153 29.3019 68.3616 30.0934C66.9297 31.5253 64.6081 31.5253 63.1761 30.0934C61.7442 28.6615 61.7442 26.3398 63.1761 24.9079C64.218 23.866 65.2429 22.999 66.1167 22.2721L66.4522 21.9935C67.2023 21.3708 67.7715 20.8983 68.2641 20.4228C69.2687 19.4533 69.4355 18.9689 69.4355 18.334C69.4355 16.3089 67.7938 14.6673 65.7688 14.6673Z"
        fill="#D7C6A3"
        fillOpacity="0.5"
      />
      <path
        d="M65.7707 40.334C63.7456 40.334 62.104 38.6924 62.104 36.6673C62.104 34.6423 63.7456 33.0007 65.7707 33.0007H65.8073C67.8324 33.0007 69.474 34.6423 69.474 36.6673C69.474 38.6924 67.8324 40.334 65.8073 40.334H65.7707Z"
        fill="#D7C6A3"
        fillOpacity="0.5"
      />
    </SvgIcon>
  )
}

export default ContractMissingIcon
