import { IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'
import { IAPIReportError } from 'api/interfaces/responses'
import { getRevalidateDockets } from './ModalRevalidateDockets.service'
import EventEmitter, { UPDATE_EVENT_DOCKETS_REVALIDATE } from 'utils/events'

export const revalidateDockets = async ({
  setError,
  selectedPeriod,
  selectedInsurance,
  commissionType,
  setJobId,
}: {
  setError: StateSetter<string | undefined>
  selectedPeriod: IAPIReportPeriod
  selectedInsurance: IAPIInsurance
  commissionType: string
  setJobId: StateSetter<string | undefined>
}) => {
  setError(undefined)

  const res = await getRevalidateDockets({
    selectedPeriod,
    selectedInsurance,
    commissionType,
  }).catch(() => {
    setError(
      'Une erreur est survenue durant la revalidation. Si le problème persiste, contactez le support Sendraise.'
    )
  })

  if (!res) {
    return
  }

  setJobId(res.jobId)
}

export const getOnComplete = ({
  selectedPeriod,
  setSelectedPeriod,
  itemValue,
  setRevalidatedErrors,
  setProgress,
  setStep,
}: {
  selectedPeriod: IAPIReportPeriod
  setSelectedPeriod: StateSetter<IAPIReportPeriod | undefined>
  itemValue: string
  setRevalidatedErrors: StateSetter<number | undefined>
  setProgress: StateSetter<number>
  setStep: StateSetter<number>
}) => {
  return (data: { reportsRevalidated: number; commissions: IAPIReportError[] }) => {
    const { commissions } = data

    const previousSelectedPeriodCommissions = selectedPeriod!.commissions
    delete previousSelectedPeriodCommissions[commissions[0].type]

    const errors =
      (Object.values(previousSelectedPeriodCommissions) as number[]).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      ) + commissions[0].errors

    const selectedPeriodUpdated: IAPIReportPeriod = {
      ...selectedPeriod!,
      errors,
      commissions: {
        ...selectedPeriod!.commissions,
        [commissions[0].type]: commissions[0].errors,
      },
    }

    const revalidatedErrors = commissions.find(
      (reportedError) => reportedError.type === itemValue
    )?.errors

    setRevalidatedErrors(revalidatedErrors)
    setSelectedPeriod(selectedPeriodUpdated)

    EventEmitter.dispatch(UPDATE_EVENT_DOCKETS_REVALIDATE, {
      selectedPeriodUpdated,
    })

    setProgress(0)
    setStep(2)
  }
}
