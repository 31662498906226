import { Components, Theme } from '@mui/material'

const MuiFormControlLabel: NonNullable<Components<Theme>['MuiFormControlLabel']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: 0,

      '.MuiFormControlLabel-label': {
        ...theme.typography.subtitle1_m,
        marginLeft: theme.helpers.sizing.selectors.medium.gap,
      },

      '&.MuiFormControlLabel-sizeSmall': {
        '.MuiFormControlLabel-label': {
          ...theme.typography.subtitle2_m,
          marginLeft: theme.helpers.sizing.selectors.small.gap,
        },
      },

      '&.MuiFormControlLabel-switch': {
        '.MuiFormControlLabel-label': {
          marginLeft: theme.spacing(1),
        },

        '&.MuiFormControlLabel-sizeSmall': {
          '.MuiFormControlLabel-label': {
            marginLeft: theme.spacing(0.5),
          },
        },
      },

      '&.MuiFormControlLabel-checkbox': {

        '.Mui-checked': {
          '.MuiCheckbox-icon': {
            backgroundColor: theme.palette.primary.main,
          }
        },
  
        '.Mui-disabled': {
          '.MuiCheckbox-icon': {
            opacity: 0.5,
            boxShadow: theme.helpers.stateShadows.default.formElements.primary,
            backgroundColor: theme.palette.neutralLight.main,
          }
        },

        '&:hover': {
          '.MuiCheckbox-icon': {
            backgroundColor: theme.palette.neutralLight.main,
            boxShadow: theme.helpers.stateShadows.hover.formElements.primary,
          },

          '.Mui-checked': {
            '.MuiCheckbox-icon': {
              backgroundColor: theme.palette.primary.dark,
            }
          },

          '.Mui-disabled': {
            '.MuiCheckbox-icon': {
              boxShadow: theme.helpers.stateShadows.default.formElements.primary,
            }
          }
        },
      }
    }),
    label: ({ theme }) => ({
      color: theme.palette.text.primary,

      '&.Mui-disabled': {
        opacity: .5
      }
    }),
  },
}

export default MuiFormControlLabel
