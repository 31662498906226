import { MouseEventHandler } from 'react'
import { getReport } from './ModalDownloadClientsDocuments.service'
import { IFetchError } from 'utils'
import { ModalProps } from '@mui/material'

//todo remove it when no more usefull
export const handleDownloadClick = ({
  target,
  minDate = '',
  maxDate = '',
  setLoading,
  setProgress,
  setError,
  onClose,
  setJobId,
}: {
  target: 'csv' | 'pdf'
  minDate?: string
  maxDate?: string
  setProgress: StateSetter<number>
  setError: StateSetter<string | undefined>
  onClose: NonNullable<ModalProps['onClose']>
  setJobId: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
}): MouseEventHandler => {
  return async () => {
    setError(undefined)
    setProgress(0)
    setLoading(true)

    const res = await getReport(minDate, maxDate).catch((err: IFetchError) => {
      console.log(err.details)
      setError(
        `Erreur lors de la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )
    })

    if (!res) {
      return
    }

    setJobId(res.jobId)
  }
}

export const handleDownload = async ({
  target,
  minDate = '',
  maxDate = '',
  setLoading,
  setProgress,
  setError,
  onClose,
  setJobId,
}: {
  target: 'csv' | 'pdf'
  minDate?: string
  maxDate?: string
  setProgress: StateSetter<number>
  setError: StateSetter<string | undefined>
  onClose: NonNullable<ModalProps['onClose']>
  setJobId: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
}) => {
  setError(undefined)
  setProgress(0)
  setLoading(true)

  const res = await getReport(minDate, maxDate).catch((err: IFetchError) => {
    setError(
      `Erreur lors de la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  if (!res) {
    setError(
      `Erreur lors de la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    return
  }

  setJobId(res.jobId)
}
