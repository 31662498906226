import * as React from 'react'

import { Moment } from 'moment'
import { Modal } from 'component'
import Loader from 'component/Loader'

import { importFeeUcits } from '../../core/ModalUploadRateGrid.helpers'

import { Box, Stack, styled, Typography } from '@mui/material'

interface IProps {
  selectedInsuranceProductsIds: number[]
  setError: StateSetter<string | undefined>
  file: File
  name: string
  periodStart: Moment
  category: string | undefined
  goToNextStep: () => void
}

const UploadingStep: React.FC<IProps> = (props) => {
  const {
    selectedInsuranceProductsIds,
    setError,
    file,
    name,
    periodStart,
    category,
    goToNextStep,
  } = props

  React.useEffect(() => {
    importFeeUcits({
      selectedInsuranceProductsIds,
      setError,
      file,
      name,
      periodStart,
      category,
      goToNextStep,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal.Content>
      <Wrapper justifyContent="center" alignItems="center">
        <Box mb={2}>
          <Loader />
        </Box>
        <Typography variant="body2" mb={0.5} sx={{ color: 'text.primary' }}>
          Fichier en cours d'importation
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {file.name}
        </Typography>
      </Wrapper>
    </Modal.Content>
  )
}

export default UploadingStep

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default, // TOCHECK
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(6),
}))
