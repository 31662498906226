import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const SendraiseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 32">
      <rect
        opacity="0.8"
        x="1.84619"
        width="13.8462"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect
        opacity="0.8"
        x="1.84619"
        y="29.5386"
        width="13.8462"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect
        opacity="0.8"
        x="3.07715"
        y="9.84619"
        width="11.3846"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect
        opacity="0.8"
        x="3.07715"
        y="19.6924"
        width="11.3846"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect
        opacity="0.8"
        x="5.84619"
        y="14.769"
        width="5.84615"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect
        opacity="0.8"
        y="4.92285"
        width="17.5385"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect
        opacity="0.8"
        y="24.6152"
        width="17.5385"
        height="2.46154"
        rx="1.23077"
        fill="#FBFBF9"
      />
      <rect x="4.30762" width="8.92308" height="2.46154" rx="1.23077" fill="white" />
      <rect x="4.30762" y="29.5386" width="8.92308" height="2.46154" rx="1.23077" fill="white" />
      <rect x="5.23047" y="9.84619" width="7.07692" height="2.46154" rx="1.23077" fill="white" />
      <rect x="5.23047" y="19.6924" width="7.07692" height="2.46154" rx="1.23077" fill="white" />
      <rect x="7.07666" y="14.769" width="3.38462" height="2.46154" rx="1.23077" fill="white" />
      <rect x="3.07666" y="4.92285" width="11.3846" height="2.46154" rx="1.23077" fill="white" />
      <rect x="3.07666" y="24.6152" width="11.3846" height="2.46154" rx="1.23077" fill="white" />
    </SvgIcon>
  )
}

export default SendraiseIcon
