import { MouseEventHandler } from 'react'
import { IProps } from './pagination.types'

export const handlePrevClick = (
  page: IProps['page'],
  setPage: IProps['setPage']
): MouseEventHandler | undefined => {
  if (page <= 1) {
    return
  }

  const handler = () => {
    setPage(page - 1)
  }

  return handler
}

export const handleNextClick = (
  page: IProps['page'],
  setPage: IProps['setPage'],
  total: IProps['total'],
  pageSize: IProps['pageSize']
): MouseEventHandler | undefined => {
  if (page >= Math.ceil(total / pageSize)) {
    return
  }

  const handler = () => {
    setPage(page + 1)
  }

  return handler
}
