import { getUpfrontConfigurations } from './UpfrontNavbarContent.service'

export const retrieveUpfrontConfigurations = async ({
  setError,
  setLoading,
  setUpfrontConfigurations,
  selectedInsuranceProductId,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  setUpfrontConfigurations: StateSetter<any[] | undefined>
  selectedInsuranceProductId: number
}) => {
  setError(undefined)
  setLoading(true)

  const res = await getUpfrontConfigurations(selectedInsuranceProductId).catch((err) => {
    setError(
      `Erreur lors de la récupération des configurations de frais d'upfront, veuillez rafraichir la page. Si le problème persiste, contactez le support Sendraise.`
    )
  })

  setLoading(false)

  if (!res) {
    return
  }

  setUpfrontConfigurations(res)
}
