import { Stack, Typography, styled } from '@mui/material'
import { IAPIUploadedReport } from 'api/interfaces'
import Loader from 'component/Loader'
import { AlertCircleIcon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import { CommissionDetail } from './CommissionDetail'

interface IImportLine {
  uploadedReport: IAPIUploadedReport
}

export const AbeilleImportLine = ({ uploadedReport }: IImportLine) => {
  const { loading, error, commissions, fileName, insurance } = uploadedReport

  return (
    <>
      {loading && (
        <StyledBox direction="column" justifyContent="center" alignItems="center">
          <Loader />
          <Typography variant="subtitle1_m" color="secondary" mt={2}>
            Fichier en cours d’analyse
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            {fileName}
          </Typography>
        </StyledBox>
      )}
      {error && !loading && (
        <Stack
          direction="row"
          alignItems="start"
          bgcolor="errorLight.main"
          p={1}
          borderRadius="4px"
          color="errorLight.contrastText"
          gap={1}
        >
          <AlertCircleIcon />
          <Typography variant="subtitle1" color="errorLight.contrastText" lineHeight="20px">
            {error}
          </Typography>
        </Stack>
      )}
      {!loading && !error && (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img
            height="32px"
            width="32px"
            src={getProviderLogo(uploadedReport.insurance!.provider)}
            alt={`${uploadedReport.insurance!.provider} logo`}
          />
          <Typography variant="body1_m" color="secondary" mt={1}>
            {uploadedReport.fileName}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            Le bordereau a bien été importé et analysé.
          </Typography>
          <Stack width="100%" mt={3} pb={4}>
            {commissions.map((commission, index) => (
              <CommissionDetail
                key={index}
                commission={commission}
                year={commissions[0].year}
                month={commissions[0].month}
                insurance={insurance}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  )
}

const StyledBox = styled(Stack)(({ theme }) => ({
  background: '#F4F4F1',
  border: `1px dashed ${theme.palette.divider}`,
  padding: 48,
  borderRadius: 4,
}))
