import {
  ChangeEventHandler,
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
} from 'react'

export const handleKeyDown = (
  onEnterPress: Function | undefined
): KeyboardEventHandler => {
  const handler: KeyboardEventHandler = (e) => {
    if (!onEnterPress || e.key !== 'Enter') {
      return
    }

    onEnterPress()
  }

  return handler
}

export const handleChange = (
  setValue: Dispatch<SetStateAction<string>>
): ChangeEventHandler => {
  const handler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }

  return handler
}
