import * as React from 'react'

import { ChevronRightIcon } from 'icons'
import { Link as RouterLink } from 'react-router-dom'

import { alpha, Chip, Link, Stack, Typography } from '@mui/material'

interface IProps {
  title: string
  description: string
  link: string
  disabled: boolean
  soon: boolean
}

const FeatureMenuItem: React.FC<IProps> = (props) => {
  const { title, description, link, disabled, soon } = props

  return (
    <Link
      sx={(theme) => ({
        py: 2,
        pl: 2,
        pr: 1.5,
        width: theme.spacing(26),
        bgcolor: 'background.inverted',
        border: disabled
          ? `1px dashed ${theme.palette.secondary.light}`
          : `1px solid ${theme.palette.secondary.light}`,
        borderRadius: 1,
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeOut,
        }),
        pointerEvents: disabled ? 'none' : 'initial',
        ':hover': {
          bgcolor: 'secondary.main',
        },
      })}
      underline="none"
      to={disabled ? '' : link}
      component={RouterLink}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={0.5}>
          <Typography variant="body2" fontWeight={500} color="primaryLight.main">
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={(theme) => ({ color: alpha(theme.palette.secondary.contrastText, 0.7) })}
          >
            {description}
          </Typography>
        </Stack>
        {!disabled && !soon && (
          <ChevronRightIcon
            sx={(theme) => ({
              mt: 0.5,
              fontSize: 16,
              color: alpha(theme.palette.secondary.contrastText, 0.7),
            })}
          />
        )}
        {disabled && soon && <Chip sx={{ mr: 1 }} variant="tag" label="Bientôt" size="small" />}
      </Stack>
    </Link>
  )
}

export default FeatureMenuItem
