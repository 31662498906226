import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const ChevronOpenIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4712 10.1951C12.7316 10.4554 12.7316 10.8776 12.4712 11.1379L8.47124 15.1379C8.21089 15.3983 7.78878 15.3983 7.52843 15.1379L3.52843 11.1379C3.26808 10.8776 3.26808 10.4554 3.52843 10.1951C3.78878 9.93475 4.21089 9.93475 4.47124 10.1951L7.99984 13.7237L11.5284 10.1951C11.7888 9.93475 12.2109 9.93475 12.4712 10.1951Z"
          fill="#262522"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4712 5.80457C12.2109 6.06492 11.7888 6.06492 11.5284 5.80457L7.99984 2.27598L4.47124 5.80457C4.21089 6.06492 3.78878 6.06492 3.52843 5.80457C3.26808 5.54422 3.26808 5.12211 3.52843 4.86177L7.52843 0.861766C7.78878 0.601416 8.21089 0.601416 8.47124 0.861766L12.4712 4.86177C12.7316 5.12212 12.7316 5.54423 12.4712 5.80457Z"
          fill="#262522"
        />
      </g>
    </SvgIcon>
  )
}

export default ChevronOpenIcon
