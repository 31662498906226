import React, { useEffect, useState } from 'react'
import LayoutCommissionsDocket from 'component/Layouts/LayoutCommissionsDocket'
import {
  IAPIArbitrageDocket,
  IAPIArbitrageDocketItem,
  IAPIDocket,
  IAPIDocketItem,
  IAPIInsurance,
  IAPIInsuranceFeeCommissionType,
  IAPIReportPeriod,
} from 'api/interfaces'
import { IFilter } from 'component/Tables/SearchFiltersDropdown'
import Loader from 'component/Loader'
import DocketsHeader from 'component/DocketsHeader'
import Navbar from 'component/Navbar'
import NavbarLink from 'component/Navbar/NavbarLink'
import NavbarContent from 'component/Navbar/NavbarContent'
import Tag from 'component/Tag'
import InfoPanel from 'component/InfoPanel'
import Icon from 'component/Icon'
import { RepeatIcon } from 'icons'
import { connect } from 'react-redux'
import { Button, Typography } from '@mui/material'
import ModalReavalidateDockets from 'component/Modals/ModalRevalidateDockets'
import { ModalErrorHandling } from 'component/Modals'
import { useHistory, useParams } from 'react-router-dom'
import { REPORTING_ANALYSIS_URL } from 'utils/router/constants'
import { COMMISSION_KINDS_MAP, getCommissionKindKeyByValue } from 'utils/mapping'
import DocketsTableAlt from 'component/Tables/DocketsTable'

export interface IDocket extends IAPIDocket {
  items?: IAPIDocketItem[]
}

export interface IArbitrageDocket extends IAPIArbitrageDocket {
  items?: IAPIArbitrageDocketItem[]
}

export const COMMISSION_KINDS: IFilter[] = [
  { label: 'Frais de gestion', value: 'outstanding_contract' },
  { label: 'Majorations', value: 'outstanding_override' },
  { label: 'Mandats', value: 'outstanding_delegate' },
  { label: 'Rétrocessions OPCVM', value: 'outstanding_ucits' },
  { label: 'Arbitrages', value: 'arbitration' },
  { label: 'Versements', value: 'deposit' },
  { label: 'Upfronts', value: 'upfront' },
  { label: 'Autres', value: 'unknown' },
]

interface IProps {
  user: any
}

const Dockets: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { user } = props

  const [step, setStep] = React.useState(0)

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const [selectedPeriod, setSelectedPeriod] = useState<IAPIReportPeriod>()
  const [selectedInsurance, setSelectedInsurance] = useState<IAPIInsurance>()

  const [isRevalidable, setIsRevalidable] = React.useState(true)
  const [isRefreshable, setIsRefreshable] = React.useState(true)
  const [modalRevalidateDocketsOpen, setModalRevalidateDocketsOpen] = useState(false)
  const [selectedDocketItem, setSelectedDocketItem] = useState<IAPIDocketItem>()
  const [tableRef, setTableRef] = useState<any>(undefined)
  const [refreshAnalysis, setRefreshAnalysis] = useState<any>(false)

  const history = useHistory()
  const { commission } = useParams<{ commission?: string }>()

  const [selectedCommissionType, setSelectedCommissionType] = useState<string>(
    commission || COMMISSION_KINDS[0].value
  )

  useEffect(() => {
    if (selectedInsurance === undefined) {
      return
    }

    if (selectedPeriod && !commission) {
      const commissionKey = COMMISSION_KINDS_MAP[COMMISSION_KINDS[0].value]
      history.push(
        `${REPORTING_ANALYSIS_URL}/${selectedInsurance.provider}/${selectedPeriod.year}/${selectedPeriod.month}/${commissionKey}`
      )
      return
    }

    const selectedCommissionType = getCommissionKindKeyByValue(commission)

    if (selectedPeriod && !selectedCommissionType) {
      history.push(
        `${REPORTING_ANALYSIS_URL}/${selectedInsurance.provider}/${selectedPeriod.year}/${selectedPeriod.month}`
      )
      return
    }

    if (selectedCommissionType) {
      setSelectedCommissionType(selectedCommissionType)
    }
  }, [commission, history, selectedInsurance, selectedPeriod])

  if (!user.organization.enableReports) {
    window.location.href = '/'
    return <></>
  }

  const closeModalRevalidateDockets = () => {
    setModalRevalidateDocketsOpen(false)
  }

  const openModalRevalidateDockets = () => {
    setRefreshAnalysis(true)
    setModalRevalidateDocketsOpen(true)
  }

  const isDocketItemWithError = (
    docketItem: IAPIDocketItem
  ): docketItem is SelectiveNonNullable<IAPIDocketItem, 'flagDescription'> => {
    return docketItem.flagDescription !== null
  }

  return (
    <LayoutCommissionsDocket
      selectedInsurance={selectedInsurance}
      setSelectedInsurance={setSelectedInsurance}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      selectedCommissionType={selectedCommissionType}
      setSelectedCommissionType={setSelectedCommissionType}
      isDataLoading={isDataLoading}
      setIsDataLoading={setIsDataLoading}
      setIsRefreshable={setIsRefreshable}
    >
      {!selectedPeriod ? (
        <div className="page__content page__empty-state">
          <img
            className="img-logo-xl"
            src={process.env.PUBLIC_URL + '/static/images/logo-sendraise-light-xl.svg'}
            alt="Sendraise"
          />
        </div>
      ) : (
        <div className="page__content">
          {isDataLoading || selectedInsurance === undefined ? (
            <div className="f f-jc-center mt-2">
              <Loader />
            </div>
          ) : (
            <div className="container commissions--container">
              <DocketsHeader
                period={selectedPeriod}
                insurance={selectedInsurance}
                selectedCommissionType={selectedCommissionType as IAPIInsuranceFeeCommissionType}
              />
              <Navbar
                id="navbar-reports"
                activeLink={selectedCommissionType}
                setActiveLink={setSelectedCommissionType}
                className="navbar--ucits"
              >
                <Navbar.Header variant="dark">
                  {COMMISSION_KINDS.map((item) => (
                    <NavbarLink
                      key={item.value}
                      id={item.value}
                      variant="dark"
                      onChange={(commissionType) => {
                        setIsRefreshable(true)
                        const commission = COMMISSION_KINDS_MAP[commissionType]

                        history.push(
                          `${REPORTING_ANALYSIS_URL}/${selectedInsurance.provider}/${selectedPeriod.year}/${selectedPeriod.month}/${commission}`
                        )
                      }}
                    >
                      <Typography variant="subtitle1" component="a">
                        {item.label}
                      </Typography>
                      {selectedPeriod &&
                        selectedPeriod.commissions &&
                        selectedPeriod.commissions.hasOwnProperty(item.value) &&
                        selectedPeriod.commissions[item.value] > 0 && (
                          <Tag type="danger" variant="default" className="ml-1">
                            {selectedPeriod.commissions[item.value]}
                          </Tag>
                        )}
                      {selectedPeriod &&
                        selectedPeriod.commissions &&
                        selectedPeriod.commissions.hasOwnProperty(item.value) &&
                        selectedPeriod.commissions[item.value] === 0 && (
                          <Tag type="success" variant="default" className="ml-1">
                            <Icon className="icon-check" />
                          </Tag>
                        )}
                    </NavbarLink>
                  ))}
                </Navbar.Header>

                {COMMISSION_KINDS.map((item) => {
                  const insuranceMessageInfos = selectedInsurance.insuranceMessageInfos?.filter(
                    (imi) => imi.commissionType === item.value
                  )

                  let showTable = true
                  if (insuranceMessageInfos) {
                    if (
                      selectedInsurance.provider === 'axa' &&
                      item.value === 'outstanding_ucits'
                    ) {
                      showTable = false
                    }
                  }

                  return (
                    <NavbarContent key={item.value} id={item.value}>
                      {insuranceMessageInfos &&
                        insuranceMessageInfos.map((insuranceMessageInfo) => (
                          <InfoPanel
                            title={insuranceMessageInfo.title}
                            key={insuranceMessageInfo.title}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: insuranceMessageInfo.message }}
                            ></div>
                          </InfoPanel>
                        ))}

                      {showTable && (
                        <DocketsTableAlt
                          setTableRef={setTableRef}
                          selectedInsurance={selectedInsurance}
                          selectedPeriod={selectedPeriod}
                          commissionType={item.value}
                          isRefreshable={isRefreshable}
                          isRevalidable={isRevalidable}
                          refreshAnalysis={refreshAnalysis}
                          setRefreshAnalysis={setRefreshAnalysis}
                          setIsRevalidable={setIsRevalidable}
                          openModaleDeleteDocketsErrors={() => setStep(1)}
                          setSelectedDocketItem={setSelectedDocketItem}
                          relaunchAnalysisButton={
                            <Button
                              startIcon={<RepeatIcon />}
                              onClick={openModalRevalidateDockets}
                              color="neutralLight"
                              disabled={!showTable || !isRevalidable}
                              size="small"
                            >
                              Relancer l'analyse
                            </Button>
                          }
                        />
                      )}
                      <ModalReavalidateDockets
                        open={modalRevalidateDocketsOpen}
                        selectedInsurance={selectedInsurance!}
                        selectedPeriod={selectedPeriod}
                        setSelectedPeriod={setSelectedPeriod}
                        itemLabel={item.label}
                        itemValue={item.value}
                        closeModal={closeModalRevalidateDockets}
                      />
                    </NavbarContent>
                  )
                })}
              </Navbar>
            </div>
          )}
        </div>
      )}
      {selectedDocketItem !== undefined && isDocketItemWithError(selectedDocketItem) ? (
        <ModalErrorHandling
          tableRef={tableRef}
          step={step}
          setStep={setStep}
          docketItem={selectedDocketItem}
          setIsRefreshable={setIsRefreshable}
        />
      ) : null}
    </LayoutCommissionsDocket>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Dockets)
