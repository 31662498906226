import * as React from 'react'

import { AlertCircleIcon, ArrowRightIcon, CheckCircleIcon } from 'icons'

import { handleSeeClientsClick } from '../../core/modalUploadClients.helpers'

import { Button, DialogProps, Stack, Typography } from '@mui/material'
import { RouteComponentProps } from 'react-router-dom'
import { Modal } from 'component'

interface IProps {
  history: RouteComponentProps['history']
  onClose: NonNullable<DialogProps['onClose']>
  error: string | undefined
  setError: StateSetter<string | undefined>
  setProgress: StateSetter<number>
  setFile: StateSetter<File | undefined>
  setStep: StateSetter<number>
  setCrm: StateSetter<'prisme' | 'o2s' | undefined>
  onRetry: React.MouseEventHandler
}

const FinalStep: React.FC<IProps> = (props) => {
  const { history, onClose, error, setError, setProgress, setFile, setStep, setCrm, onRetry } =
    props

  return (
    <Modal.Content>
      <Stack alignItems="center" justifyContent="center" mt={1} mb={4}>
        {error ? (
          <AlertCircleIcon sx={{ color: 'error.contrastText', mb: 1, fontSize: 24 }} />
        ) : (
          <CheckCircleIcon sx={{ color: 'success.main', mb: 1, fontSize: 24 }} />
        )}
        <Typography variant="body1" fontWeight={500} color="text.primary" mb={0.5}>
          {error ? "Echec de l'importation" : 'Importation réussie'}
        </Typography>
        {error ? (
          <Typography variant="subtitle1" color="error.text" align="center" mb={3}>
            {error}
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle1" color="text.secondary" align="center" mb={3}>
              Vos clients ont été importés et instantanément confrontés au Registre National des
              gels du Trésor Public.
            </Typography>
          </>
        )}
        {error ? (
          <Button onClick={onRetry} color="secondary">
            Recommencer l'import
          </Button>
        ) : (
          <Button
            endIcon={<ArrowRightIcon />}
            onClick={handleSeeClientsClick({
              history,
              onClose,
              setError,
              setProgress,
              setFile,
              setStep,
              setCrm,
            })}
          >
            Consulter la liste des clients
          </Button>
        )}
      </Stack>
    </Modal.Content>
  )
}

export default FinalStep
