import { Stack, useTheme } from '@mui/material'
import CommissionChip from 'component/CommissionChip'
import { ICommissionType } from 'page/Commissions/Imports/core/Imports.hooks'
import * as React from 'react'

interface IProps {
  rowCommissionTypes: ICommissionType[]
}

const ImportComissionTypeCell: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { rowCommissionTypes } = props

  const theme = useTheme()

  return (
    <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)} paddingY={theme.spacing(1)}>
      {rowCommissionTypes.map((rowCommissionType) => {
        return (
          <CommissionChip
            key={rowCommissionType.key}
            commissionType={rowCommissionType}
            size="small"
          />
        )
      })}
    </Stack>
  )
}

export default ImportComissionTypeCell
