import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const ContractIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4H7C6.44771 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V5C18 4.44772 17.5523 4 17 4ZM7 2C5.34315 2 4 3.34315 4 5V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V5C20 3.34315 18.6569 2 17 2H7Z"
      />
      <path d="M9.79014 13.0223C10.1913 12.9361 10.6044 13.1039 10.8321 13.4453L12 15.1972L12.1679 14.9453C12.3534 14.6671 12.6656 14.5 13 14.5C13.3344 14.5 13.6466 14.6671 13.8321 14.9453L14.5352 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H14C13.6656 18 13.3534 17.8329 13.1679 17.5547L13 17.3028L12.8321 17.5547C12.6466 17.8329 12.3344 18 12 18C11.6656 18 11.3534 17.8329 11.1679 17.5547L10.5707 16.6588L10.4864 17.1644C10.406 17.6466 9.98884 18 9.5 18H8C7.44772 18 7 17.5523 7 17C7 16.4477 7.44772 16 8 16H8.65287L9.01361 13.8356C9.08107 13.4308 9.38894 13.1084 9.79014 13.0223Z" />
      <path d="M7 6C7 5.44772 7.44772 5 8 5H12C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7H8C7.44772 7 7 6.55228 7 6Z" />
      <path d="M7 9C7 8.44772 7.44772 8 8 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10H8C7.44772 10 7 9.55228 7 9Z" />
    </SvgIcon>
  )
}

export default ContractIcon
