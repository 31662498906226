import { Components, Theme } from '@mui/material'

const MuiInputLabel: NonNullable<Components<Theme>['MuiInputLabel']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.subtitle1,
      color: `${theme.palette.text.primary} !important`,
      fontWeight: 500,
      position: 'static',
      transform: 'none',
      marginBottom: theme.spacing(1),

      '&.Mui-focused': {
        color: theme.palette.text.primary,
      },
    }),
    sizeSmall: ({ theme }) => ({
      ...theme.typography.subtitle2,
      fontWeight: 500,
    }),
  },
}

export default MuiInputLabel
