import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from 'react'
import { customFetch } from 'api/customFetch'
import { ADMIN_ACTION, API_URL } from 'api/constants'
import moment from 'moment'
import { IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'
import { ModalContext } from 'utils/hooks/modalBehavior'
import Loader from 'component/Loader'
import { capitalize, IFetchError } from 'utils'
import Icon from 'component/Icon'
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material'
import EventEmitter, {
  UPDATE_EVENT_DOCKETS,
  UPDATE_EVENT_DOCKETS_INVALIDATE,
  UPDATE_EVENT_DOCKETS_REVALIDATE,
} from 'utils/events'
import { CheckIcon } from 'icons'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { REPORTING_ANALYSIS_URL } from 'utils/router/constants'
import { useHistory, useParams } from 'react-router-dom'
import { COMMISSION_KINDS_MAP } from 'utils/mapping'
import LayoutMenu from '../LayoutMenu'
import { COMMISSION_KINDS } from 'page/Commissions/Dockets'
import InsuranceDropdown from 'component/InsuranceDropdown'
import { getAllInsrs } from 'page/Commissions/Contracts/core/Contracts.service'

const localization = require('moment/locale/fr')
moment.locale('fr', localization)

export const TYPE_UCITS = 'ucits'
export const TYPE_DOCKETS = 'docket'

interface ILayoutCommissionProps {
  children: ReactNode
  selectedPeriod: IAPIReportPeriod | undefined
  setSelectedPeriod: Dispatch<SetStateAction<IAPIReportPeriod | undefined>>
  selectedInsurance: IAPIInsurance | undefined
  setSelectedInsurance: Dispatch<SetStateAction<IAPIInsurance | undefined>>
  selectedCommissionType: string
  setSelectedCommissionType: Dispatch<SetStateAction<string>>
  isDataLoading: boolean
  setIsDataLoading: Dispatch<SetStateAction<boolean>>
  setIsRefreshable: Dispatch<SetStateAction<boolean>>
  user: any
}

interface IYearPeriod {
  year: number
  monthCount: number
}

interface IPayload {
  selectedPeriodUpdated: IAPIReportPeriod
}

interface ISidebarExpandableYearProps {
  currentYear: IYearPeriod
  periods: IAPIReportPeriod[] | undefined
  selectedInsuranceId: number
  selectedInsuranceProvider: string
  selectedPeriod: IAPIReportPeriod | undefined
  setSelectedPeriod: Dispatch<SetStateAction<IAPIReportPeriod | undefined>>
  initialOpen: boolean
  selectedCommissionType: string
  setIsRefreshable: Dispatch<SetStateAction<boolean>>
}

const SidebarExpandableYear: FC<React.PropsWithChildren<ISidebarExpandableYearProps>> = ({
  currentYear,
  periods,
  selectedInsuranceProvider,
  selectedPeriod,
  initialOpen = false,
  selectedCommissionType,
  setIsRefreshable,
  setSelectedPeriod,
}) => {
  const history = useHistory()

  const [open, setOpen] = useState<boolean>(initialOpen)

  useEffect(() => {
    setOpen(currentYear.year === selectedPeriod?.year)
  }, [selectedPeriod, currentYear.year])

  const periodChangeHandler = (current: IAPIReportPeriod) => {
    setIsRefreshable(true)
    const commission = COMMISSION_KINDS_MAP[selectedCommissionType]
    const currentPeriod = periods?.find(
      (period) => period.year === current.year && period.month === current.month
    )
    setSelectedPeriod(currentPeriod)
    history.push(
      `${REPORTING_ANALYSIS_URL}/${selectedInsuranceProvider}/${current.year}/${current.month}/${commission}`
    )
    window.scrollTo(0, 0)
  }

  const bodyClassNames =
    'sidebar-list-body ' + (open ? 'sidebar-list__expanded ' : 'sidebar-list__collapsed')

  return (
    <>
      <div className="sidebar-list-header" onClick={() => setOpen(!open)}>
        <div className="sidebar-list-header-caption body-l-medium">{currentYear.year}</div>
        <Chip
          label={currentYear.monthCount}
          size="small"
          color="default"
          sx={(_) => ({
            fontWeight: 700,
            minWidth: '24px',
          })}
        />

        <div className="sidebar-list-header-expand ml-3">
          <Icon className={open ? 'icon-chevron-up' : 'icon-chevron-down'} />
        </div>
      </div>

      <div className={bodyClassNames}>
        {periods &&
          periods.length &&
          periods
            .filter((p) => p.year === currentYear.year)
            .map((currentData, index: number) => {
              const itemClassNames =
                'sidebar-list-item ' +
                (currentData.year === selectedPeriod?.year &&
                currentData.month === selectedPeriod?.month
                  ? 'sidebar-list-item--active'
                  : '')

              const itemLabel = moment(currentData.year + '-' + currentData.month, 'YYYY-M').format(
                'MMMM YYYY'
              )

              return (
                <div
                  className={itemClassNames}
                  key={index}
                  onClick={() => periodChangeHandler(currentData)}
                >
                  <div className="sidebar-list-item-caption body-m-medium">
                    {capitalize(itemLabel)}
                  </div>
                  {currentData.warnings && currentData.warnings > 0 && (
                    <Chip
                      label={currentData.errors}
                      size="small"
                      color="warning"
                      variant="outlined"
                      sx={(_) => ({
                        fontWeight: 700,
                        minWidth: '24px',
                      })}
                    />
                  )}
                  {currentData.errors && currentData.errors > 0 ? (
                    <Chip
                      label={currentData.errors}
                      size="small"
                      color="error"
                      variant="outlined"
                      sx={(_) => ({
                        fontWeight: 700,

                        minWidth: '24px',
                      })}
                    />
                  ) : (
                    <IconChip
                      icon={<CheckIcon />}
                      size="small"
                      color="success"
                      variant="outlined"
                    />
                  )}
                </div>
              )
            })}
      </div>
    </>
  )
}

const LayoutCommissions: FC<React.PropsWithChildren<ILayoutCommissionProps>> = ({
  children,
  selectedPeriod,
  setSelectedPeriod,
  selectedInsurance,
  setSelectedInsurance,
  setSelectedCommissionType,
  selectedCommissionType,
  isDataLoading,
  setIsDataLoading,
  setIsRefreshable,
  user,
}) => {
  const [yearPeriods, setYearPeriods] = useState<IYearPeriod[]>()
  const [periods, setPeriods] = useState<IAPIReportPeriod[]>()
  const [insurances, setInsurances] = useState<IAPIInsurance[] | undefined>(undefined)

  const { provider, year, month } = useParams<{
    provider: string
    year: string
    month: string
  }>()
  const history = useHistory()

  const modal = useContext(ModalContext)

  const fetchInsurances = useCallback(async () => {
    const res = await getAllInsrs(true).catch((err: IFetchError) => {
      setIsDataLoading(false)
    })

    if (!res) {
      return
    }

    setInsurances(res.results)
    const currentInsurance =
      res.results.find((insurance: IAPIInsurance) => insurance.provider === provider) ||
      res.results[0]

    setSelectedInsurance(currentInsurance)

    if (!provider) {
      history.push(`${REPORTING_ANALYSIS_URL}/${currentInsurance.provider}`)
    }
    setIsDataLoading(false)
  }, [provider, history, setIsDataLoading, setSelectedInsurance])

  useEffect(() => {
    setIsDataLoading(true)
    fetchInsurances()
  }, [fetchInsurances, setIsDataLoading])

  const fetchPeriods = useCallback(() => {
    if (!selectedInsurance) {
      setIsDataLoading(false)
      return
    }

    customFetch
      .get(`${API_URL}insurances/${selectedInsurance.id}/insurance-fee-report-items?action=summary`)
      .then((res) => res.json())
      .then((data: IAPIReportPeriod[]) => {
        setIsDataLoading(false)
        setPeriods(data)

        const years = data.reduce<IYearPeriod[]>((acc, curr) => {
          const idx = acc.findIndex((yearPeriod) => yearPeriod.year === curr.year)

          if (idx === -1) {
            acc.push({ year: curr.year, monthCount: 1 })
          } else {
            acc[idx].monthCount++
          }

          return acc
        }, [])

        setYearPeriods(years)

        const currentPeriod = data.find(
          (period) => Number(period.year) === Number(year) && Number(period.month) === Number(month)
        )
        if (currentPeriod) {
          setSelectedPeriod(currentPeriod)
        }
      })
      .catch((error) => {
        setIsDataLoading(false)
        console.log(`Get available years error : ${error}`)
      })
  }, [selectedInsurance, month, setIsDataLoading, setSelectedPeriod, year])

  useEffect(() => {
    fetchPeriods()
  }, [selectedInsurance, fetchPeriods])

  const deleteAllReportingClientData = () => {
    setIsDataLoading(true)

    customFetch
      .delete(`${API_URL}insurance-fee-reports`)
      .then(() => {
        setYearPeriods([])
        setIsDataLoading(false)
        setSelectedPeriod(undefined)
      })
      .catch(() => {
        setYearPeriods([])
        setIsDataLoading(false)
        setSelectedPeriod(undefined)
      })
  }

  useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_DOCKETS, () => {
      fetchInsurances()
    })

    return () => EventEmitter.remove(UPDATE_EVENT_DOCKETS)
  }, [fetchInsurances])

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_DOCKETS_REVALIDATE, (payload: IPayload) => {
      const { selectedPeriodUpdated } = payload

      if (periods) {
        const periodIndex = periods.findIndex(
          (p) => p.month === selectedPeriodUpdated.month && p.year === selectedPeriodUpdated.year
        )
        if (periodIndex > -1) {
          let newPeriod = {
            ...periods[periodIndex],
            errors: selectedPeriodUpdated.errors,
            commissions: { ...selectedPeriodUpdated.commissions },
          }
          periods[periodIndex] = newPeriod
        }

        setPeriods(periods)
      }
    })

    return () => EventEmitter.remove(UPDATE_EVENT_DOCKETS_REVALIDATE)
  }, [periods, setPeriods])

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_DOCKETS_INVALIDATE, () => {
      fetchPeriods()
    })

    return () => EventEmitter.remove(UPDATE_EVENT_DOCKETS_INVALIDATE)
  }, [selectedInsurance, setPeriods, fetchPeriods])

  const getInsuranceChangeHandler = (insurance: IAPIInsurance) => {
    if (insurance !== selectedInsurance) {
      setSelectedInsurance(insurance)
      setPeriods(undefined)
      setSelectedPeriod(undefined)
      setSelectedCommissionType(COMMISSION_KINDS[0].value)
      history.push(`${REPORTING_ANALYSIS_URL}/${insurance.provider}`)
    }
  }

  return (
    <LayoutMenu
      location="reportings"
      defaultMenuOpen={true}
      canClose={selectedInsurance !== undefined && selectedPeriod !== undefined}
    >
      <Stack className="filters-commission">
        <Box px={3} pb={3} pt={4}>
          <Typography variant="h2" mb={2}>
            Analyse des bordereaux
          </Typography>

          <Button
            startIcon={<Icon className={`icon-x-square`} />}
            disabled={insurances && insurances.length === 0}
            onClick={(e: any) => {
              e.preventDefault()
              if (
                modal.currentModal?.name === 'ModalUploadMultipleDockets' &&
                !modal.currentModal?.success
              ) {
                modal.setCurrentModal({
                  ...modal.currentModal,
                  collapsed: false,
                })
              } else if (
                modal.currentModal?.name === 'ModalUploadMultipleDockets' &&
                modal.currentModal?.success
              ) {
                modal.setCurrentModal(null)
                setTimeout(() => {
                  modal.setCurrentModal({
                    name: 'ModalUploadMultipleDockets',
                    initialSelectedInsurance: selectedInsurance,
                    collapsed: false,
                  })
                }, 100)
              } else {
                modal.setCurrentModal({
                  name: 'ModalUploadMultipleDockets',
                  initialSelectedInsurance: selectedInsurance,
                  collapsed: false,
                })
              }
            }}
            sx={{
              mb: 3,
            }}
            fullWidth
          >
            Importer un bordereau
          </Button>

          {insurances !== undefined ? (
            insurances.length !== 0 ? (
              <InsuranceDropdown
                insurances={insurances}
                getInsuranceChangeHandler={getInsuranceChangeHandler}
                selectedInsurance={selectedInsurance || insurances[0]}
                reportCount={true}
              />
            ) : (
              <div className="mosaic-menu mt-3 mosaic-menu-small">
                <div className="body-label-m ft-truncat">Aucun assureur paramétré.</div>
              </div>
            )
          ) : null}
        </Box>
        <Divider />
        {isDataLoading ? (
          <div className="sidebar-list">
            <div className="f f-jc-center mt-2">
              <Loader />
            </div>
          </div>
        ) : (
          <div className="sidebar-list">
            {yearPeriods && yearPeriods.length && selectedInsurance !== undefined ? (
              yearPeriods.map((currentYear: any, index: number) => {
                return (
                  <div className="sidebar-list-block" key={index}>
                    <SidebarExpandableYear
                      currentYear={currentYear}
                      periods={periods}
                      selectedInsuranceId={selectedInsurance.id}
                      selectedInsuranceProvider={selectedInsurance.provider}
                      selectedPeriod={selectedPeriod}
                      setSelectedPeriod={setSelectedPeriod}
                      setIsRefreshable={setIsRefreshable}
                      initialOpen={
                        selectedPeriod ? selectedPeriod.year === currentYear.year : false
                      }
                      selectedCommissionType={selectedCommissionType}
                    />
                  </div>
                )
              })
            ) : (
              <div className="sidebar-empty-state">
                <img
                  className="img-logo-s"
                  src={process.env.PUBLIC_URL + '/static/images/logo-sendraise-light-s.svg'}
                  alt="Sendraise"
                />
                <div>Aucun bordereau importé pour le moment</div>
              </div>
            )}
          </div>
        )}

        {user.role === 'admin' && ADMIN_ACTION === 'true' && (
          <Button
            color="error"
            startIcon={<Icon className={`icon-trash`} />}
            sx={{
              margin: 3,
            }}
            onClick={(e: any) => {
              e.preventDefault()
              deleteAllReportingClientData()
            }}
          >
            Supprimer les données RCC
          </Button>
        )}
      </Stack>
      {children}
    </LayoutMenu>
  )
}

const IconChip = styled(Chip)(() => ({
  cursor: 'pointer',

  '.MuiChip-label': {
    display: 'none',
  },
}))

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(LayoutCommissions)
