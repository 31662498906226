import React, { FC } from 'react'
import moment from 'moment'

import { Box } from '@mui/material'

const localization = require('moment/locale/fr')
moment.updateLocale('fr', localization)

interface IDocketDateCellProps {
  item: any
  field: string
  value?: any
}

const DocketDateCell: FC<React.PropsWithChildren<IDocketDateCellProps>> = ({
  item,
  field,
  value,
}) => {
  if (!value) {
    value = item[field]
  }

  const dateValue = moment(value).format('DD/MM/YYYY')

  return (
    <td className="cell--docket-date" key={`docket-${field}-${item.id}`}>
      <Box color="text.primary">{dateValue}</Box>
    </td>
  )
}

export default DocketDateCell
