import { Typography } from '@mui/material'
import * as React from 'react'
import { addJSXSeparatorToArray } from 'utils'

export const displayArrayWithConjunction = ({
  array,
  conjunction,
}: {
  array: any[]
  conjunction: string
}) => {
  const separator = (
    <>
      {' '}
      <Typography
        variant="subtitle1"
        component="span"
        sx={{ color: 'text.secondary' }}
      >
        {conjunction}
      </Typography>{' '}
    </>
  )

  return addJSXSeparatorToArray({
    array,
    separator,
  })
}
