import {
  IAPIDocketItem,
  IAPIDocketItemDirection,
  IAPIDocketItemFlagDescriptionProperty,
} from 'api/interfaces'

export const getProviderLogo = (provider: string) => {
  return 'https://sendraise.s3.eu-west-3.amazonaws.com/insurances/' + provider + '.png'
}

export interface ITooltipError {
  hasErrors: boolean
  errorMessage: JSX.Element
}

export interface IFlag {
  field: IAPIDocketItemFlagDescriptionProperty
  type: string
  message: string
}

export interface IInvalidationFlag {
  field: IAPIDocketItemFlagDescriptionProperty
  comment: string
  userName: string
}

export const parseFlagDescription = (flagDescription: string | null): IFlag[] => {
  if (flagDescription === null) {
    return []
  }

  const splittedFlagDescription = flagDescription.split('\n')

  return splittedFlagDescription.map<IFlag>((flagStr) => {
    const flagInfos = flagStr.split('|')

    return {
      field: flagInfos[0] as IAPIDocketItemFlagDescriptionProperty,
      type: flagInfos[1],
      message: flagInfos[2],
    }
  })
}

export const parseInvalidationFlagDescription = (
  invalidationFlagDescription: string | null
): IInvalidationFlag[] => {
  if (invalidationFlagDescription === null) {
    return []
  }

  const splittedFlagDescription = invalidationFlagDescription.split('\n')

  return splittedFlagDescription.map<IInvalidationFlag>((flagStr) => {
    const flagInfos = flagStr.split('|')

    return {
      field: flagInfos[0] as IAPIDocketItemFlagDescriptionProperty,
      comment: flagInfos[1],
      userName: flagInfos[2],
    }
  })
}

export const getDirectionLabel = (
  direction: IAPIDocketItemDirection,
  isPenalty?: boolean,
  item?: IAPIDocketItem
): string => {
  if (item?.commissionType === 'unknown') {
    return item.subCommissionType
  }

  const arbitrationOrDepositType =
    item?.commissionType === 'arbitration' || item?.commissionType === 'deposit'

  switch (direction) {
    case 'issuing':
      return `Emission commission/${arbitrationOrDepositType ? item?.subCommissionType || '' : ''}`
    case 'takeback':
      return isPenalty
        ? `Pénalité/${arbitrationOrDepositType ? item?.subCommissionType || '' : ''}`
        : `Reprise commission/${arbitrationOrDepositType ? item?.subCommissionType || '' : ''}`
    case 'cancel_issuing':
      return `Annulation émission/${arbitrationOrDepositType ? item?.subCommissionType || '' : ''}`
    case 'cancel_takeback':
      return isPenalty
        ? `Annulation pénalité/${arbitrationOrDepositType ? item?.subCommissionType || '' : ''}`
        : `Annulation reprise/${arbitrationOrDepositType ? item?.subCommissionType || '' : ''}`
  }
}

enum ErrorType {
  MISSING_RULES = 'Absence des règles d’application des frais',
}

export const isManualErrorRaisingDisabled = (item: IAPIDocketItem, errorType?: string) => {
  if (item.flagDescription === null || item.flagDescription === '') {
    return true
  }

  const flagDescriptionSerialized = parseFlagDescription(item.flagDescription)

  if (item.commissionType === 'deposit' || item.commissionType === 'arbitration') {
    if (!errorType) {
      return (
        flagDescriptionSerialized.some((flag) => flag.type === ErrorType.MISSING_RULES) &&
        flagDescriptionSerialized.length === 1
      )
    } else {
      return errorType === ErrorType.MISSING_RULES
    }
  }

  return false
}
