import * as React from 'react'

import { Edit3Icon, InfoIcon, PlusCircleIcon, Share2Icon, Trash2Icon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import EventEmitter, { UPDATE_EVENT_INSURANCE_FEE_UPFRONT } from 'utils/events'
import { BaseTable } from 'component/Tables'
import { primaryPercentageCell, textSecondaryCell } from 'component/Tables/BaseTable'
import { ModalConfigureFees, ModalDeleteFees, ModalEditFeesPropagation } from 'component/Modals'
import Loader from 'component/Loader'
import { Context } from 'page/Commissions/Contracts'
import { IAPIInsuranceFeeUpfront } from 'api/interfaces'

import { retrieveUpfrontConfigurations } from './core/UpfrontNavbarContent.helpers'

import { GridActionsCellItem } from '@mui/x-data-grid-premium'
import {
  Box,
  Button,
  Chip,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'

const UpfrontNavbarContent: React.FC = () => {
  const context = React.useContext(Context)

  const { selectedInsuranceProduct, selectedInsurance, insuranceProducts } = { ...context }

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [upfrontConfigurations, setUpfrontConfigurations] =
    React.useState<IAPIInsuranceFeeUpfront[]>()
  const [modalConfigureFeesOpen, setModalConfigureFeesOpen] = React.useState(false)
  const [modalEditFeesPropagationOpen, setModalEditFeesPropagationOpen] = React.useState(false)
  const [modalDeleteFeesOpen, setModalDeleteFeesOpen] = React.useState(false)
  const [editedUpfrontConfiguration, setEditedUpfrontConfiguration] =
    React.useState<IAPIInsuranceFeeUpfront>()

  React.useEffect(() => {
    if (selectedInsuranceProduct === undefined) {
      return
    }

    retrieveUpfrontConfigurations({
      setError,
      setLoading,
      setUpfrontConfigurations,
      selectedInsuranceProductId: selectedInsuranceProduct.id,
    })
  }, [selectedInsuranceProduct])

  React.useEffect(() => {
    if (selectedInsuranceProduct === undefined) {
      return
    }

    EventEmitter.subscribe(UPDATE_EVENT_INSURANCE_FEE_UPFRONT, () => {
      retrieveUpfrontConfigurations({
        setError,
        setLoading,
        setUpfrontConfigurations,
        selectedInsuranceProductId: selectedInsuranceProduct.id,
      })
    })

    return () => {
      EventEmitter.remove(UPDATE_EVENT_INSURANCE_FEE_UPFRONT)
    }
  }, [selectedInsuranceProduct])

  const COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'isin',
        headerName: 'Fonds (ISIN)',
        ...textSecondaryCell,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return params.value ?? '-'
        },
      },
      {
        field: 'organizationRate',
        headerName: 'Taux de com. assureur',
        sortable: false,
        ...primaryPercentageCell,
        align: 'left',
        headerAlign: 'left',
        minWidth: 108,
        maxWidth: 190,
        flex: 1,
      },
      {
        field: 'intermediaryRate',
        headerName: 'Com. complémentaire courtier',
        sortable: false,
        ...primaryPercentageCell,
        align: 'left',
        headerAlign: 'left',
        minWidth: 108,
        maxWidth: 190,
        flex: 1,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        ...textSecondaryCell,
        sortable: false,
        flex: 1,
      },
      {
        field: 'insuranceProducts',
        headerName: '',
        sortable: false,
        width: 70,
        renderCell: (params) => {
          const insuranceProductsPropagatedToCount = params.value.length

          return insuranceProductsPropagatedToCount > 1 ? (
            <PropagationTooltip
              arrow
              placement="bottom-end"
              title={
                <Typography component="p" variant="subtitle2" fontWeight={500}>
                  Partagée avec {insuranceProductsPropagatedToCount - 1} produit
                  {insuranceProductsPropagatedToCount - 1 > 1 ? 's' : null}
                </Typography>
              }
            >
              <PropagationChip
                size="small"
                icon={<Share2Icon />}
                label={insuranceProductsPropagatedToCount - 1}
              />
            </PropagationTooltip>
          ) : (
            <></>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 48,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Edit3Icon />}
            label="Éditer la règle"
            showInMenu
            onClick={() => {
              setEditedUpfrontConfiguration(params.row)
              setModalConfigureFeesOpen(true)
            }}
          />,
          <GridActionsCellItem
            icon={<Share2Icon />}
            label="Éditer la propagation"
            showInMenu
            onClick={() => {
              setEditedUpfrontConfiguration(params.row)
              setModalEditFeesPropagationOpen(true)
            }}
          />,
          <GridActionsCellItem
            icon={<Trash2Icon />}
            label="Supprimer"
            sx={{ color: 'error.main' }}
            onClick={() => {
              setEditedUpfrontConfiguration(params.row)
              setModalDeleteFeesOpen(true)
            }}
            showInMenu
          />,
        ],
      },
    ],
    []
  )

  const closeModalConfigureFees = () => {
    setModalConfigureFeesOpen(false)
  }

  const openModalConfigureFees = () => {
    setModalConfigureFeesOpen(true)
  }

  const closeModalEditFeesPropagation = () => {
    setModalEditFeesPropagationOpen(false)
  }

  const closeModalDeleteFees = () => {
    setModalDeleteFeesOpen(false)
  }

  return upfrontConfigurations !== undefined &&
    insuranceProducts !== undefined &&
    selectedInsurance !== undefined &&
    selectedInsuranceProduct !== undefined ? (
    <>
      {loading ? (
        <Stack alignItems="center" justifyContent="center">
          <Loader />
        </Stack>
      ) : error ? (
        <Typography color="error.text">{error}</Typography>
      ) : (
        <div className="f f-direction-column">
          {upfrontConfigurations.length !== 0 ? (
            <>
              <div className="mb-2">
                {upfrontConfigurations.find(
                  (insuranceFeeUpfront) => insuranceFeeUpfront.isin === null
                ) ? (
                  <Stack
                    direction="row"
                    gap={1}
                    mb={2}
                    borderRadius={0.5}
                    sx={(theme) => ({
                      backgroundColor: '#F6E5D5',
                      color: '#BC5F06',
                      padding: theme.spacing(1, 1.5),
                    })}
                  >
                    <InfoIcon sx={{ fontSize: 20 }} />
                    <Box>
                      <Typography variant="subtitle1_m" sx={{ color: '#BC5F06' }}>
                        Modification du paramétrage des upfronts
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: '#BC5F06' }}>
                        La déclaration d'une règle de commission sur upfront doit désormais
                        s'accompagner d'un code ISIN spécifique. Les anciennes règles ont été
                        conservées mais ne seront pas appliquées si elles ne sont pas accompagnées
                        d'un code ISIN. Veuillez mettre à jour les règles ci-dessous.
                      </Typography>
                    </Box>
                  </Stack>
                ) : null}
                <div className="f f-jc-space-b f-ai-center">
                  <p className="ft-500 mb-0">
                    Règles de frais d'upfront ({upfrontConfigurations.length})
                  </p>
                  <div className="f f-ai-center">
                    <Button startIcon={<PlusCircleIcon />} onClick={openModalConfigureFees}>
                      Ajouter une configuration de frais
                    </Button>
                  </div>
                </div>
              </div>
              <BaseTable
                autoHeight
                columns={COLUMNS}
                rows={upfrontConfigurations}
                noContentMessage="Aucune commission sur upfront n'a été configurée pour ce produit."
              />
            </>
          ) : (
            <div className="f f-ai-center f-jc-space-b">
              <p className="mb-0">
                Aucune commission sur upfront n'a été configurée pour ce produit.
              </p>
              <Button startIcon={<PlusCircleIcon />} onClick={openModalConfigureFees}>
                Ajouter une configuration de frais
              </Button>
            </div>
          )}
        </div>
      )}
      <ModalConfigureFees
        open={modalConfigureFeesOpen}
        closeModal={closeModalConfigureFees}
        providerLogo={getProviderLogo(selectedInsurance.provider)}
        insuranceName={selectedInsurance.name}
        insuranceProductId={selectedInsuranceProduct.id}
        insuranceProductName={selectedInsuranceProduct.name}
        insuranceProducts={insuranceProducts}
        feesType="upfront"
        editedConfiguration={editedUpfrontConfiguration}
        setEditedConfiguration={setEditedUpfrontConfiguration}
      />
      {editedUpfrontConfiguration !== undefined ? (
        <>
          <ModalEditFeesPropagation
            open={modalEditFeesPropagationOpen}
            closeModal={closeModalEditFeesPropagation}
            providerLogo={getProviderLogo(selectedInsurance.provider)}
            insuranceName={selectedInsurance.name}
            insuranceProductName={selectedInsuranceProduct.name}
            insuranceProducts={insuranceProducts}
            feesType="upfront"
            editedConfiguration={editedUpfrontConfiguration}
            setEditedConfiguration={setEditedUpfrontConfiguration}
          />
          <ModalDeleteFees
            feesType="upfront"
            open={modalDeleteFeesOpen}
            closeModal={closeModalDeleteFees}
            providerLogo={getProviderLogo(selectedInsurance.provider)}
            insuranceName={selectedInsurance.name}
            insuranceProductName={selectedInsuranceProduct.name}
            insuranceProductId={selectedInsuranceProduct.id}
            editedConfiguration={editedUpfrontConfiguration}
            setEditedConfiguration={setEditedUpfrontConfiguration}
          />
        </>
      ) : null}
    </>
  ) : null
}

export default UpfrontNavbarContent

const PropagationChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  color: 'white',
  fontWeight: 700,
}))

const PropagationTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',

    '.MuiTooltip-arrow': {
      color: theme.palette.secondary.main,
    },
  },
}))
