import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const LogOutIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7067 11.2925C22.0973 11.6831 22.0973 12.3162 21.7067 12.7067L17.7067 16.7067C17.3162 17.0973 16.6831 17.0973 16.2925 16.7067C15.902 16.3162 15.902 15.6831 16.2925 15.2925L18.5854 12.9996H8.99963C8.44735 12.9996 7.99963 12.5519 7.99963 11.9996C7.99963 11.4474 8.44735 10.9996 8.99963 10.9996H18.5854L16.2925 8.70674C15.902 8.31622 15.902 7.68305 16.2925 7.29253C16.6831 6.902 17.3162 6.902 17.7067 7.29253L21.7067 11.2925ZM13.9996 2.99964C13.9996 3.55192 13.5519 3.99964 12.9996 3.99964L6.20041 3.99964C5.62386 3.99964 5.25159 4.00041 4.96825 4.02356C4.69659 4.04576 4.59587 4.08344 4.54642 4.10863C4.35826 4.2045 4.20528 4.35748 4.10941 4.54565C4.08422 4.59509 4.04654 4.69581 4.02434 4.96747C4.00119 5.25081 4.00042 5.62308 4.00042 6.19964V17.7996C4.00042 18.3762 4.00119 18.7485 4.02434 19.0318C4.04654 19.3035 4.08422 19.4042 4.10941 19.4536C4.20528 19.6418 4.35826 19.7948 4.54642 19.8906C4.59587 19.9158 4.69659 19.9535 4.96825 19.9757C5.25159 19.9989 5.62386 19.9996 6.20041 19.9996H12.9996C13.5519 19.9996 13.9996 20.4474 13.9996 20.9996C13.9996 21.5519 13.5519 21.9996 12.9996 21.9996H6.16185C5.63471 21.9997 5.17995 21.9997 4.80539 21.9691C4.41004 21.9368 4.01682 21.8654 3.63844 21.6727C3.07396 21.385 2.61502 20.9261 2.3274 20.3616C2.1346 19.9832 2.06329 19.59 2.03098 19.1947C2.00038 18.8201 2.0004 18.3653 2.00042 17.8382V6.16107C2.0004 5.63393 2.00038 5.17917 2.03098 4.80461C2.06329 4.40926 2.1346 4.01604 2.3274 3.63766C2.61502 3.07318 3.07396 2.61424 3.63844 2.32662C4.01682 2.13382 4.41004 2.06251 4.80539 2.03021C5.17996 1.9996 5.63472 1.99962 6.16187 1.99963L12.9996 1.99964C13.5519 1.99964 13.9996 2.44735 13.9996 2.99964Z"
      />
    </SvgIcon>
  )
}

export default LogOutIcon
