import { Components, Theme } from '@mui/material'

const MuiCheckbox: NonNullable<Components<Theme>['MuiCheckbox']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      borderRadius: 4,

      '.MuiCheckbox-icon': {
        borderRadius: 4,
        backgroundColor: theme.palette.neutralLight.main,
        boxShadow: theme.helpers.stateShadows.default.formElements.primary,
        color: theme.palette.primary.contrastText,
        transition: theme.transitions.create(
          ['background-color', 'box-shadow'],
          {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeOut,
          }
        ),
      },

      '.MuiTouchRipple-child': {
        borderRadius: '4px !important',
      },
    }),
  },
  variants: [
    {
      props: { size: 'small'},
      style: ({ theme }) => ({
        
        '.MuiCheckbox-icon': {
          height: theme.helpers.sizing.selectors.small.height,
          width: theme.helpers.sizing.selectors.small.height,
          
          'svg': {
            fontSize: 16,
          },
        },
      })
    },
    {
      props: { size: 'medium'},
      style: ({ theme }) => ({
        
        '.MuiCheckbox-icon': {
          height: theme.helpers.sizing.selectors.medium.height,
          width: theme.helpers.sizing.selectors.medium.height,
          
          'svg': {
            fontSize: 20,
          },
        },
      })
    },
  ]
}

export default MuiCheckbox
