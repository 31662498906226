import * as React from 'react'
import { Route, RouteComponentProps, Switch, useLocation, withRouter } from 'react-router-dom'
import { LayoutMenu } from 'component/Layouts'
import { Box, Stack, Tab, Tabs, Typography, styled } from '@mui/material'
import {
  FROZEN_ASSETS_ENTITIES_CLIENTS_URL,
  PROOFS_OF_VERIFICATION_CLIENTS_URL,
} from 'utils/router/constants'
import ProofLayout from './components/ProofsLayout'
import ClientsListLayout, { IClientRow } from './components/ClientsListLayout'
import { SnowIcon } from 'icons'
import HelpContainer from 'component/HelpContainer'
import { fetchFirstClientPage } from './core/Clients.helpers'

export interface IInitialClientsValue {
  rows: readonly IClientRow[]
  clientCount: number
  filteredClientCount: number
  clientDBUpdate: string
  faeDBUpdate: string
}

const Clients: React.FC<RouteComponentProps> = (props) => {
  const { history } = props
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [tab, setTab] = React.useState(FROZEN_ASSETS_ENTITIES_CLIENTS_URL)
  const [suspectCount, setSuspectCount] = React.useState<number | undefined>()
  const [loading, setLoading] = React.useState(true)
  const [initialValues, setInitialValues] = React.useState<IInitialClientsValue | undefined>()

  React.useEffect(() => {
    if (location.pathname !== tab) {
      setTab(location.pathname)
    }
  }, [location, tab])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
    history.push(newValue)
  }

  React.useEffect(() => {
    fetchFirstClientPage({
      setLoading,
      debouncedSearch: searchParams.get('r') || '',
      setSuspectCount,
      setInitialValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutMenu location="asset-freeze" defaultMenuOpen={true}>
      <Box bgcolor="background.inverted">
        <Wrapper direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="secondary.contrastText">
            Liste des clients
          </Typography>
          <HelpContainer
            message="Guide d’import depuis un CRM"
            helpURL="https://busy-stop-fcc.notion.site/Guide-utilisateur-du-Contr-le-de-Gel-des-Avoirs-803d7ecd4f65423ba9f9ae4b6469defa?pvs=4"
            size="small"
          />
        </Wrapper>
        <CustomTab
          value={tab}
          onChange={handleTabChange}
          aria-label="clients navigation"
          role="navigation"
        >
          <Tab
            disableRipple={true}
            label={
              <Stack direction="row" alignItems="center" spacing={2} color="secondary.contrastText">
                <span>Clients</span>{' '}
                {suspectCount && suspectCount > 0 ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    bgcolor="warning.main"
                    borderRadius={1}
                    sx={{ padding: '4px 8px' }}
                  >
                    <Typography variant="subtitle2" component="span" fontWeight={700}>
                      {suspectCount}
                    </Typography>
                    <SnowIcon sx={{ ml: '4px', width: '16px' }} />
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            }
            value={FROZEN_ASSETS_ENTITIES_CLIENTS_URL}
            sx={{ fontWeight: 500 }}
          />
          <Tab
            disableRipple={true}
            label="Preuves de vérification"
            value={PROOFS_OF_VERIFICATION_CLIENTS_URL}
            sx={{ fontWeight: 500 }}
          />
        </CustomTab>
      </Box>
      <Wrapper>
        <Switch>
          <Route
            path={FROZEN_ASSETS_ENTITIES_CLIENTS_URL}
            render={() => (
              <ClientsListLayout
                initialValues={initialValues}
                initalLoading={loading}
                handleTabChange={handleTabChange}
                setSuspectCount={setSuspectCount}
                suspectCount={suspectCount}
              />
            )}
            exact
          />
          <Route path={PROOFS_OF_VERIFICATION_CLIENTS_URL} component={ProofLayout} exact />
        </Switch>
      </Wrapper>
    </LayoutMenu>
  )
}

export default withRouter(Clients)

const Wrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
}))

const CustomTab = styled(Tabs)(({ theme }) => ({
  button: {
    color: theme.palette.secondary.contrastText,
    opacity: 0.7,
    '&.Mui-selected': {
      color: theme.palette.secondary.contrastText,
      opacity: 1,
    },
  },
  '.MuiTabs-indicator': {
    height: 4,
    borderRadius: `4px 4px 0px 0px`,
  },
}))
