import * as React from 'react'

import { UsersIcon } from 'icons'

import { ModalUploadClients } from 'component/Modals'

import { handleModalTriggerClick } from '../../core/Clients.helpers'

import { Box, Button, CircularProgress, Stack, Typography, styled } from '@mui/material'

interface IProps {
  dbUpdateDate: string
  dbUpdateDelta: string
  loading: boolean
  disabled: boolean
}

const ClientAction: React.FC<IProps> = (props) => {
  const { dbUpdateDate, dbUpdateDelta, loading, disabled } = props

  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <Wrapper>
      {loading ? (
        <LoaderWrapper alignItems="center" justifyContent="center">
          <CircularProgress sx={{ color: 'text.primary' }} />
        </LoaderWrapper>
      ) : (
        <>
          <Stack direction="row" alignItems="center" mb={0.5}>
            <UsersIcon />
            <Typography variant="subtitle1" color="text.primary" ml={1} fontWeight={500}>
              Votre base de données clients
            </Typography>
          </Stack>
          <Typography variant="subtitle2" color="text.secondary" mb={2}>
            Mis à jour {dbUpdateDelta} ({dbUpdateDate})
          </Typography>
          <Button onClick={handleModalTriggerClick(setModalOpen, true)} disabled={disabled}>
            Mettre à jour la base de données clients
          </Button>
        </>
      )}
      <ModalUploadClients open={modalOpen} onClose={handleModalTriggerClick(setModalOpen, false)} />
    </Wrapper>
  )
}

export default ClientAction

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  borderRadius: '0 4px 4px 0',
  background: theme.palette.background.paper,
  padding: theme.spacing(2),
}))

const LoaderWrapper = styled(Stack)(({ theme }) => ({
  height: 100,
}))
