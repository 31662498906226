import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, Link, Paper, Snackbar, Stack, Typography } from '@mui/material'
import { CheckCircleIcon, ArrowRightIcon } from 'icons'

import { useHistory, useLocation } from 'react-router-dom'
import { sendEmailValidation, validateEmail } from '../core/Subscription.service'
import { getCustomError } from '../core/Subscription.helpers'
import Loader from 'component/Loader'
import Alert from 'component/Alert'
import { SubscriptionContext } from '../SubscriptionManager'
import { SUBSCRIPTION_ADDRESS_URL } from 'utils/router/constants'

function SubscriptionEmail() {
  let history = useHistory()
  const location = useLocation()
  const { registration, setRegistration } = useContext(SubscriptionContext)
  const searchParams = location.search.split('&')
  const email = searchParams[0]?.replace('?email=', '') || null
  const validationKey = searchParams[1]?.replace('token=', '') || null

  const [error, setError] = useState<string | null>(null)
  const [emailSent, setEmailSent] = useState(false)
  const [emailValidated, setEmailValidated] = useState(false)

  const sendEmail = async () => {
    try {
      await sendEmailValidation()
    } catch (e: any) {
      setError(e.message)
    }
  }

  const checkEmailValidation = useCallback(async () => {
    if (!email || !validationKey || !registration || emailValidated) return
    try {
      await validateEmail({ email, validationKey })
      setRegistration({ ...registration, isEmailValidated: true })
      setEmailValidated(true)
    } catch (e: any) {
      setError(e.message)
    }
  }, [email, validationKey, registration, setRegistration, emailValidated])

  useEffect(() => {
    if (validationKey && email) {
      checkEmailValidation()
    } else {
      sendEmail()
    }
  }, [validationKey, email, checkEmailValidation])

  return (
    <>
      <Box sx={{ ml: 6 }}>
        <Typography variant="body2" color="text.secondary" mb={0.5}>
          Étape 2
        </Typography>
        <Typography variant="h2b" mb={4} component="h2">
          Vérification de l’email
        </Typography>
      </Box>

      {emailValidated ? (
        <>
          <Paper elevation={2} sx={{ p: '32px 48px', mb: 4 }}>
            <Typography variant="body2" fontWeight={500} color="success.main" mb={1}>
              Votre e-mail a été vérifié
              <CheckCircleIcon
                color="success"
                sx={{ fontSize: 24, ml: 1, verticalAlign: 'bottom' }}
              />
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Vous pouvez poursuivre votre inscription.
            </Typography>
          </Paper>

          <Box textAlign="right">
            <Button
              color="secondary"
              onClick={() => history.push(SUBSCRIPTION_ADDRESS_URL)}
              endIcon={<ArrowRightIcon />}
            >
              Continuer
            </Button>
          </Box>
        </>
      ) : (
        <Paper elevation={2} sx={{ p: '32px 48px', mb: 4 }}>
          {error && <Alert message={getCustomError(error)} />}
          {validationKey && email && !error && !emailValidated && (
            <Stack alignItems="center" justifyContent="center" mb={3}>
              <Loader />
            </Stack>
          )}
          {!validationKey && !email && (
            <>
              <Typography variant="subtitle1" fontWeight={500} mb={1.5}>
                Vous allez recevoir un e-mail à l’adresse {registration?.userEmail} pour confirmer
                votre compte.
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" mb={4} ml={2}>
                Cliquez sur le lien dans l’e-mail pour valider votre compte.
              </Typography>
            </>
          )}
          <Box
            textAlign="center"
            sx={{
              borderRadius: 1,
              p: 3,
              bgcolor: 'background.default',
            }}
          >
            <Typography variant="subtitle1" color="text.primary" mb={3}>
              Vous rencontrez un problème avec la confirmation ?
            </Typography>
            <Button
              color="neutralLight"
              size="small"
              sx={{ mb: 1.5 }}
              onClick={() => {
                setEmailSent(true)
                sendEmail()
              }}
            >
              Renvoyer l'e-mail
            </Button>
            <Box>
              <Link color="text.secondary" fontSize={12} sx={{ fontWeight: 400 }}>
                J’ai un autre problème
              </Link>
            </Box>
          </Box>
        </Paper>
      )}
      <Snackbar
        open={emailSent}
        autoHideDuration={3000}
        onClose={() => setEmailSent(false)}
        message="E-mail envoyé"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  )
}

export default SubscriptionEmail
