import moment from 'moment'
import React from 'react'

import { API_CLIENTS_URL, API_FROZEN_ASSET_CHECK_PROOFS_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIClient } from 'api/interfaces'
import { getSearchMethod } from 'utils'
import { get } from 'utils'
import { IAPIQueueResponse } from 'api/interfaces/responses'

export const getClients = async ({
  setLoading,
  setHeaderLoading,
  search,
  page,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setHeaderLoading?: React.Dispatch<React.SetStateAction<boolean>>
  search?: string
  page: number
}): Promise<
  | {
      clients: IAPIClient[]
      clientCount: number
      filteredClientCount: number
      suspectCount: number
      clientDBUpdate: string
      faeDBUpdate: string
    }
  | undefined
> => {
  setLoading(true)

  if (setHeaderLoading) {
    setHeaderLoading(true)
  }

  const params = new URLSearchParams()

  params.append('page', page.toString())

  if (search) {
    if (getSearchMethod(search) === 'name') {
      params.append('q', search)
    } else {
      params.append('birth', moment(search, ['DD/MM/YYYY', 'DD-MM-YYYY']).toISOString())
    }
  }

  const res = await customFetch
    .get(`${API_CLIENTS_URL}${params.getAll.length > 0 ? `?${params.toString()}` : null}`)
    .catch((err) => {
      console.log(err)
    })

  if (!res || !res.ok) {
    setLoading(false)

    if (setHeaderLoading) {
      setHeaderLoading(false)
    }

    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    console.log(err)
  })

  setLoading(false)

  if (setHeaderLoading) {
    setHeaderLoading(false)
  }

  return jsonRes
    ? {
        clients: jsonRes.results,
        clientCount: jsonRes.totalClientCount,
        filteredClientCount: jsonRes.filteredClientCount,
        suspectCount: jsonRes.totalFrozenAssetsCount,
        clientDBUpdate: jsonRes.customerLastUpdate,
        faeDBUpdate: jsonRes.tresorPublicLastUpdate,
      }
    : undefined
}

export const getVerificationProofs = async ({
  page,
  perPage,
  setLoading,
}: {
  page: number
  perPage: number
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  setLoading(true)

  const params = new URLSearchParams()
  params.append('offset', (page * perPage).toString())
  params.append('count', perPage.toString())

  const res = await customFetch
    .get(
      `${API_FROZEN_ASSET_CHECK_PROOFS_URL}${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      console.log(err)
    })

  if (!res || !res.ok) {
    setLoading(false)
    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    console.log(err)
  })
  setLoading(false)

  return jsonRes
}

export const getSingleProofReport = (id: number) => {
  const searchParams = new URLSearchParams()
  searchParams.append('getReport', 'true')

  return get<IAPIQueueResponse>({
    url: `${API_FROZEN_ASSET_CHECK_PROOFS_URL}/${id}?${searchParams.toString()}`,
  })
}
