import * as React from 'react'
import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  Divider,
  styled,
  Chip,
  ClickAwayListener,
} from '@mui/material'
import { Moment } from 'moment'
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro'
import { CalendarIcon } from 'icons'

export interface RangeShortcutType {
  label: string
  getValue: () => [Moment | null, Moment | null]
}

interface IProps {
  shortcuts?: RangeShortcutType[]
}

const DateRangeInput: React.FC<IProps> = ({ shortcuts = [] }) => {
  const [value, setValue] = React.useState<DateRange<Moment>>([null, null])
  const [currentShortcut, setCurrentShortcut] = React.useState<string>()
  const [open, setOpen] = React.useState(false)

  const RangeShortcutsPanel: React.FC<{
    setValue?: React.Dispatch<React.SetStateAction<DateRange<Moment>>>
    children: React.ReactNode
  }> = ({ setValue, children }) => {
    const handleRangeClick = (shortCut: RangeShortcutType) => {
      const range = shortCut.getValue()

      if (range && setValue) {
        setCurrentShortcut(range[0] ? shortCut.label : '')
        setValue(range)

        if (range[0]) {
          setOpen(false)
        }
      }
    }

    return (
      <React.Fragment>
        <Box sx={{ m: 2 }} display="flex" gap={2}>
          <div>
            <List>
              {shortcuts.map((shortcut) => (
                <ListItem key={shortcut.label} disablePadding>
                  <CustomShortCut
                    variant="filled"
                    size="small"
                    onClick={(e) => handleRangeClick(shortcut)}
                    label={shortcut.label}
                    clickable={true}
                    className={currentShortcut === shortcut.label ? 'shortcut--selected' : ''}
                  ></CustomShortCut>
                </ListItem>
              ))}
            </List>
          </div>
          <Divider orientation="vertical" />
        </Box>
        {children}
      </React.Fragment>
    )
  }

  const handleClickAway = (e: any) => {
    const pickerModal = document.querySelector('.MuiPickersPopper-root')
    if (
      open &&
      (currentShortcut || (value[0] && value[1])) &&
      pickerModal &&
      !pickerModal.contains(e.target)
    ) {
      setOpen(false)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway} disableReactTree={true}>
      <DateRangePicker
        endText=""
        startText=""
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
          setCurrentShortcut('')
        }}
        onAccept={() => {
          setOpen(false)
        }}
        open={open}
        components={shortcuts.length ? { PaperContent: RangeShortcutsPanel } : undefined}
        PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
        componentsProps={shortcuts.length ? { paperContent: { setValue } } : undefined}
        renderInput={(startProps, endProps) => (
          <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ mr: 2 }}>
              Du
            </Typography>
            <TextField
              {...startProps}
              fullWidth
              name="minDate"
              inputProps={{
                ...startProps.inputProps,
                placeholder: 'JJ/MM/AAAA',
              }}
              InputProps={{
                ...startProps.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
              onClick={() => setOpen(true)}
            />
            <Typography variant="subtitle1" sx={{ mx: 2 }}>
              au
            </Typography>
            <TextField
              {...endProps}
              fullWidth
              name="maxDate"
              inputProps={{
                ...endProps.inputProps,
                placeholder: 'JJ/MM/AAAA',
              }}
              InputProps={{
                ...endProps.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
              onClick={() => setOpen(true)}
            />
          </Stack>
        )}
      />
    </ClickAwayListener>
  )
}

export default DateRangeInput

const CustomShortCut = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  fontSize: 12,
  marginBottom: 8,
  backgroundColor: `${theme.palette.secondaryLight.main} !important`,
  color: `${theme.palette.secondaryLight.contrastText} !important`,

  '&:hover, &:active, &.shortcut--selected': {
    backgroundColor: `${theme.palette.secondaryLight.dark} !important`,
  },
}))
