import { Card, CardActionArea, Stack, SxProps, styled } from '@mui/material'
import * as React from 'react'

interface IProps {
  sx?: SxProps
  onClick?: React.MouseEventHandler
}

const CardClickable: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { children, onClick, sx } = props

  return (
    <Wrapper onClick={onClick} sx={sx}>
      <Actions>
        <Stack alignItems="center" justifyContent="center">
          {children}
        </Stack>
      </Actions>
    </Wrapper>
  )
}

export default CardClickable

const Wrapper = styled(Card)(({ theme }) => ({
  background: 'transparent',
  borderRadius: 2,
  border: `1px dashed ${theme.palette.divider}`,
  boxShadow: 'none',
  width: '100%',
}))

const Actions = styled(CardActionArea)(({ theme }) => ({
  height: '100%',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  background: theme.palette.background.default,
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeOut,
  }),

  '.MuiCardActionArea-focusHighlight': {
    display: 'none',
  },

  '&:hover': {
    background: theme.palette.background.background02,
    textDecoration: 'underline',
  },
}))
