import { Components, Stack, Theme } from '@mui/material'

const MuiDialogTitle: NonNullable<Components<Theme>['MuiDialogTitle']> = {
  defaultProps: {
    component: Stack,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(3)}`,
      backgroundColor: theme.palette.background.inverted,
    }),
  },
}

export default MuiDialogTitle
