import * as React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckDoubleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 88 88" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.2451 12.0558C62.6849 13.4797 62.6977 15.8013 61.2737 17.2411L35.8891 42.9078C35.2003 43.6042 34.2616 43.9961 33.2821 43.9961C32.3026 43.9961 31.3638 43.6042 30.6751 42.9078L19.393 31.5004C17.969 30.0606 17.9819 27.739 19.4217 26.315C20.8615 24.891 23.1831 24.9038 24.607 26.3437L33.2821 35.1151L56.0597 12.0844C57.4837 10.6446 59.8052 10.6318 61.2451 12.0558ZM75.9261 23.07C77.358 24.502 77.358 26.8236 75.9261 28.2555L35.5928 68.5888C34.1608 70.0207 31.8392 70.0207 30.4073 68.5888L12.074 50.2555C10.642 48.8236 10.642 46.502 12.074 45.07C13.5059 43.6381 15.8275 43.6381 17.2594 45.07L33 60.8106L70.7406 23.07C72.1726 21.6381 74.4942 21.6381 75.9261 23.07Z"
          fill="#BFD9C6"
        />
      </svg>
    </SvgIcon>
  )
}

export default CheckDoubleIcon
