import { IAPIDocketItemFlagDescriptionProperty } from 'api/interfaces'
import { formatCurrencySymbol, formatPercent } from 'utils'
import { getDirectionLabel } from 'utils/commissions'

export const getErrorTitle = (field: IAPIDocketItemFlagDescriptionProperty): string => {
  switch (field) {
    case 'ucits':
      return 'Support'
    case 'direction':
      return 'Opération'
    case 'insuranceRate':
      return 'Taux assureur'
    case 'baseAmount':
      return 'Assiette'
    case 'customerAmount':
      return 'Frais client'
    case 'organizationAmount':
      return 'Commission CGP'
    case 'organizationRate':
      return 'Taux CGP'
  }
}

export const getFormattedValue = (field: IAPIDocketItemFlagDescriptionProperty, value: any) => {
  switch (field) {
    case 'ucits':
      return value
    case 'direction':
      return getDirectionLabel(value)
    case 'insuranceRate':
      return `${value} %`
    case 'baseAmount':
      return `${formatCurrencySymbol(value)}`
    case 'customerAmount':
      return `${formatCurrencySymbol(value)}`
    case 'organizationAmount':
      return `${formatCurrencySymbol(value)}`
    case 'organizationRate':
      return `${formatPercent(value)}`
  }
}
