import React from 'react'
import { LayoutMenu } from 'component/Layouts'

function Home() {
  return (
    <LayoutMenu location="home" defaultMenuOpen={true} canClose={false}>
      <div className="home page--black">
        <div className="page__content">
          <div className="container f f-direction-column f-center">
            <div className="heading">
              <img
                src={process.env.PUBLIC_URL + '/static/images/logo-sendraise-beta.svg'}
                alt="SendRaise logo"
                loading="lazy"
              />
              <h1 className="ft-center mt-5 c-blue-200 h5 col col-md-8 col--center">
                Redéfinir les standards de l'agrégation de données financières et personnelles en
                gestion de patrimoine
              </h1>
            </div>
          </div>
          <div className="page__background page__background--animated">
            <div id="rect1" className="shape__animated"></div>
            <div id="rect2" className="shape__animated"></div>
            <div id="bubble" className="shape__animated"></div>
          </div>
        </div>
      </div>
    </LayoutMenu>
  )
}

export default Home
