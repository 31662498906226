import * as React from 'react'
import { TableCell, TableRow, Typography, styled } from '@mui/material'
import { formatCurrencySymbol, formatMonth } from 'utils'

interface IProps {
  row: { [key: string]: any }
  yearRow: boolean
}

const CommissionTableRow = ({ row, yearRow }: IProps) => {
  const rowHasData = React.useMemo(() => {
    return row.fees
      .filter((fee: any) => fee)
      .reduce((acc: number, curr: { [key: string]: number }) => (acc += curr.cumulated), 0)
  }, [row])

  return (
    <>
      <CustomTableRow
        key={row.month}
        hover={true}
        className={`${yearRow ? 'row--year' : ''} ${!rowHasData ? 'row--disabled' : ''}`}
      >
        {yearRow ? (
          <StyledBodyCell
            sx={{ fontSize: 14, fontWeight: 500 }}
            className="body-cell--first body-cell--cumulated"
          >
            {row.year}
          </StyledBodyCell>
        ) : (
          <StyledBodyCell sx={{ fontSize: 14 }} className="body-cell--first">
            {formatMonth(row.month)}
          </StyledBodyCell>
        )}
        <StyledBodyCell className="body-cell--cumulated">
          {row.fees
            .filter((fee: any, index: number) => fee.cumulated || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number1" fontWeight={500} key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.cumulated !== 0.0 ? formatCurrencySymbol(fee.cumulated, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.outstandingContract || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.outstandingContract !== 0.0 ? formatCurrencySymbol(fee.outstandingContract, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.outstandingDelegate || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.outstandingDelegate !== 0.0 ? formatCurrencySymbol(fee.outstandingDelegate, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.outstandingOverride || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.outstandingOverride !== 0.0 ? formatCurrencySymbol(fee.outstandingOverride, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.outstandingUcits || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.outstandingUcits !== 0.0 ? formatCurrencySymbol(fee.outstandingUcits, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.arbitration || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.arbitration !== 0.0 ? formatCurrencySymbol(fee.arbitration, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.deposit || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {formatCurrencySymbol(fee.deposit, fee.currency)}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.upfront || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}
                {fee.upfront !== 0.0 ? formatCurrencySymbol(fee.upfront, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
        <StyledBodyCell>
          {row.fees
            .filter((fee: any, index: number) => fee.unknown || index === 0)
            .map((fee: any, index: number) => (
              <Typography component="p" variant="number2" key={index}>
                {index > 0 && '+'}&nbsp;
                {fee.unknown !== 0.0 ? formatCurrencySymbol(fee.unknown, fee.currency) : '-'}
              </Typography>
            ))}
        </StyledBodyCell>
      </CustomTableRow>
    </>
  )
}

export default CommissionTableRow

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  '&.row--year': {
    '.MuiTableCell-root': {
      opacity: 1,
      background: theme.palette.secondary.main,
      color: `${theme.palette.secondary.contrastText}B3`,
      paddingTop: 16,
      paddingBottom: 16,

      '&.body-cell--cumulated': {
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  '&.row--disabled': {
    '.MuiTableCell-root': {
      opacity: 0.4,
    },
  },
}))

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  opacity: 0.7,
  paddingTop: 6,
  paddingBottom: 6,
  border: 'none',
  textAlign: 'right',
  '&.body-cell--cumulated': {
    fontSize: theme.typography.subtitle1.fontSize,
    opacity: 1,
    fontWeight: 500,
  },

  '&.body-cell--first': {
    textAlign: 'left',
  },
}))
