import { CheckIcon, XIcon } from 'icons'
import React, { useState, useEffect } from 'react'
import Theme from 'theme'

interface CustomIconComponents {
  ValidIcon: React.ReactNode
  InvalidIcon: React.ReactNode
}
interface PasswordProps {
  value: string
  valueAgain?: string
  minLength?: number
  maxLength?: number
  iconSize?: number
  validColor?: string
  invalidColor?: string
  validTextColor?: string
  invalidTextColor?: string
  onChange?: (isValid: boolean, failedRules: RuleNames[]) => any
  messages?: {
    [key in RuleNames]?: string
  }
  iconComponents?: CustomIconComponents
}
export type RuleNames =
  | 'minLength'
  | 'maxLength'
  | 'specialChar'
  | 'number'
  | 'capital'
  | 'match'
  | 'lowercase'
  | 'letter'
  | 'notEmpty'

export interface PasswordChecklistProps extends PasswordProps {
  className?: string
  style?: React.CSSProperties
  rules: Array<RuleNames>
  rtl?: boolean
  hideIcon?: boolean
  specialCharsRegex?: RegExp
}
const PasswordChecklist: React.FC<PasswordChecklistProps> = ({
  className,
  style,
  rules,
  value,
  valueAgain,
  minLength,
  maxLength,
  rtl,
  onChange,
  // eslint-disable-next-line
  specialCharsRegex = /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g,
  messages = {},
  ...remainingProps
}) => {
  const [isValid, setIsValid] = useState(false)
  const ruleDefinitions: {
    [key in RuleNames]: { valid: boolean; message: string }
  } = {
    minLength: {
      valid: value.length >= (minLength || 10),
      message: messages.minLength || `Le mot de passe doit faire ${minLength} caractères minimum`,
    },
    specialChar: {
      valid: specialCharsRegex.test(value),
      message: messages.specialChar || '1 caractère spécial minimum (%@/-+&#)',
    },
    number: {
      valid: /\d/g.test(value),
      message: messages.number || 'Votre mot de passe doit contenir au moins 1 chiffre',
    },
    capital: {
      valid: (() => {
        var i = 0
        if (value.length === 0) {
          return false
        }
        while (i < value.length) {
          const character = value.charAt(i)
          if (character === character.toLowerCase()) {
            // Character is lowercase, numeric, or a symbol
          } else if (character === character.toUpperCase()) {
            return true
          }
          i++
        }
        return false
      })(),
      message: messages.capital || 'Votre mot de passe doit contenir une majuscule',
    },
    match: {
      valid: value.length > 0 && value === valueAgain,
      message: messages.match || 'Veuillez confirmer votre mot de passe',
    },
    lowercase: {
      valid: (() => {
        var i = 0
        if (value.length === 0) {
          return false
        }
        while (i < value.length) {
          const character = value.charAt(i)
          if (character === character.toUpperCase()) {
            // Character is lowercase, numeric, or a symbol
          } else if (character === character.toLowerCase()) {
            return true
          }
          i++
        }
        return false
      })(),
      message: messages.lowercase || 'Votre mot de passe doit contenir une minuscule',
    },
    letter: {
      valid: /[a-zA-Z]/g.test(value),
      message: messages.letter || 'Votre mot de passe doit contenir une lettre',
    },
    maxLength: {
      valid: value.length <= (maxLength || 256),
      message: messages.maxLength || `Votre mot de passe ne doit pas faire plus de ${maxLength} caractères.`,
    },
    notEmpty: {
      valid: Boolean(value.length > 0 && valueAgain && valueAgain.length > 0),
      message: messages.notEmpty || 'Veuillez renseigner un mot de passe',
    },
  }
  const enabledRules = rules.filter((rule) => Boolean(ruleDefinitions[rule]))
  useEffect(() => {
    if (enabledRules.every((rule) => ruleDefinitions[rule].valid)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    } // eslint-disable-next-line
  }, [value, valueAgain, enabledRules])
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(
        isValid,
        enabledRules.filter((rule) => !ruleDefinitions[rule].valid)
      )
    } // eslint-disable-next-line
  }, [isValid, enabledRules, onChange])

  if (rtl) {
    className = className ? className + ' rtl' : 'rtl'
  }

  return (
    <ul
      className={className}
      style={{
        margin: 0,
        padding: 0,
        ...style,
      }}
    >
      {enabledRules.map((rule) => {
        const { message, valid } = ruleDefinitions[rule]
        return (
          <Rule
            key={rule}
            valid={valid}
            iconSize={18}
            validColor={Theme.palette.success.main}
            invalidColor={Theme.palette.text.primary}
            rtl={rtl}
            {...remainingProps}
          >
            {message}
          </Rule>
        )
      })}
    </ul>
  )
}

interface RuleProps {
  valid: boolean
  iconSize?: number
  iconComponents?: CustomIconComponents
  validColor?: string
  invalidColor?: string
  validTextColor?: string
  invalidTextColor?: string
  rtl?: boolean
  hideIcon?: boolean
  children?: React.ReactNode
}
const Rule: React.FC<RuleProps> = ({
  valid,
  iconSize,
  validColor,
  invalidColor,
  iconComponents,
  hideIcon,
  rtl,
  children,
}) => {
  return (
    <li
      className={valid ? 'valid' : 'invalid'}
      style={{
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'flex-start',
        margin: '4px 0',
        fontSize: '12px',
      }}
    >
      {!hideIcon ? (
        iconComponents ? (
          valid ? (
            iconComponents.ValidIcon
          ) : (
            iconComponents.InvalidIcon
          )
        ) : valid ? (
          <CheckIcon color="success" fontSize="small" />
        ) : (
          <XIcon color="secondary" fontSize="small" />
        )
      ) : null}
      <span
        style={{
          paddingLeft: 8,
          flex: 1,
          color: valid ? validColor : invalidColor,
          opacity: valid ? 1 : 0.7,
          fontWeight: valid ? 500 : 400,
        }}
      >
        {children}
      </span>
    </li>
  )
}

export default PasswordChecklist
