import * as React from 'react'

import { AlertTriangleIcon, ArrowRightIcon } from 'icons'

import { Box, Stack, Typography, styled } from '@mui/material'

interface IProps {
  name: string
  firstNames: string[] | null
  titles: string[] | null
  onSeeDetailsClick: React.MouseEventHandler
}

const SuspectDetail: React.FC<IProps> = (props) => {
  const { name, firstNames, titles, onSeeDetailsClick } = props

  const fullName = firstNames !== null ? [name, ...firstNames].join(' ') : name

  return (
    <Wrapper flexDirection="column" justifyContent="space-between">
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          variant="sectionHeading"
          sx={{ color: 'warning.main' }}
          mb={2}
        >
          Ressemblance suspectée
        </Typography>
        <AlertTriangleIcon
          sx={{ fontSize: 16, color: 'warning.main' }}
        />
      </Stack>
      <Box>
        <ElipsedName
          variant="subtitle1"
          fontWeight={500}
          mb={0.5}
          sx={{ color: 'text.primary' }}
          title={fullName}
        >
          {fullName}
        </ElipsedName>
        {titles ? (
          <ClampedTitles
            variant="subtitle2"
            mb={1}
            sx={{ color: 'text.secondary' }}
            title={titles.join(', ')}
          >
            {titles.join(', ')}
          </ClampedTitles>
        ) : null}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={onSeeDetailsClick}
          >
            Voir le détail
          </Typography>
          <ArrowRightIcon
            onClick={onSeeDetailsClick}
            sx={{ color: 'text.secondary' }}
          />
        </Stack>
      </Box>
    </Wrapper>
  )
}

export default SuspectDetail

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 280,
  background: theme.palette.warningLight.main,
  padding: theme.spacing(2),
  borderRadius: 2,
}))

const ElipsedName = styled(Typography)(() => ({
  textOverflow: 'elipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}))

const ClampedTitles = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
}))
