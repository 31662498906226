import { styled } from '@mui/material'
import * as React from 'react'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  reference: React.RefObject<HTMLInputElement>
}

const HiddenFileInput: React.FC<IProps> = (props) => {
  const { reference, ...inputProps } = props

  return <Input {...inputProps} ref={reference} type="file" />
}

export default HiddenFileInput

const Input = styled('input')(() => ({
  position: 'fixed',
  visibility: 'hidden',
  height: 0,
  width: 0,
  top: -10,
  left: -10,
}))
