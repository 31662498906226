import { createTheme } from '@mui/material/styles'
import MuiButton from './components/MuiButton'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import helpers from './helpers'
import MuiCheckbox from './components/MuiCheckbox'
import MuiLink from './components/MuiLink'
import MuiChip from './components/MuiChip'
import MuiSelect from './components/MuiSelect'
import MuiOutlinedInput from './components/MuiOutlinedInput'
import MuiPopover from './components/MuiPopover'
import MuiToggleButton from './components/MuiToggleButton'
import MuiSvgIcon from './components/MuiSvgIcon'
import MuiInputLabel from './components/MuiInputLabel'
import MuiAlert from './components/MuiAlert'
import MuiSwitch from './components/MuiSwitch'
import MuiTabs from './components/MuiTabs'
import MuiTab from './components/MuiTab'
import MuiTooltip from './components/MuiTooltip'
import MuiAvatarGroup from './components/MuiAvatarGroup'
import MuiPaper from './components/MuiPaper'
import MuiMenuItem from './components/MuiMenuItem'
import MuiInputBase from './components/MuiInputBase'
import MuiInputAdornment from './components/MuiInputAdornment'
import MuiDrawer from './components/MuiDrawer'
import MuiFormControlLabel from './components/MuiFormControlLabel'
import MuiFormControl from './components/MuiFormControl'
import MuiDialog from './components/MuiDialog'
import MuiDialogActions from './components/MuiDialogActions'
import MuiDialogTitle from './components/MuiDialogTitle'
import MuiDialogContent from './components/MuiDialogContent'
import MuiIconButton from './components/MuiIconButton'
import MuiToggleButtonGroup from './components/MuiToggleButtonGroup'

import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'

const Theme = createTheme({
  palette,
  typography,
  shadows,
  components: {
    MuiButton,
    MuiCheckbox,
    MuiLink,
    MuiChip,
    MuiSelect,
    MuiInputLabel,
    MuiOutlinedInput,
    MuiInputBase,
    MuiInputAdornment,
    MuiPopover,
    MuiToggleButton,
    MuiToggleButtonGroup,
    MuiSvgIcon,
    MuiPaper,
    MuiMenuItem,
    MuiAlert,
    MuiSwitch,
    MuiTabs,
    MuiTab,
    MuiTooltip,
    MuiAvatarGroup,
    MuiDrawer,
    MuiFormControlLabel,
    MuiFormControl,
    MuiDialog,
    MuiDialogTitle,
    MuiDialogContent,
    MuiDialogActions,
    MuiIconButton,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
  shape: {
    borderRadius: 4,
  },
  helpers,
})

export default Theme
