import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const Edit3Icon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5321 3.2968C19.1415 2.90628 18.5084 2.90627 18.1179 3.2968L16.914 4.50067L18.3282 5.91489L19.5321 4.71101C19.9226 4.32049 19.9226 3.68732 19.5321 3.2968ZM20.9463 6.12523C22.1179 4.95365 22.1179 3.05416 20.9463 1.88259C19.7747 0.711011 17.8752 0.711015 16.7036 1.88259L14.7929 3.79335L5.05565 13.5306C5.031 13.5552 5.00664 13.5796 4.98256 13.6037C4.21258 14.3735 3.72847 14.8575 3.34944 15.4207C3.01365 15.9197 2.74357 16.4598 2.54587 17.0278C2.32271 17.669 2.22598 18.3467 2.07212 19.4245C2.06731 19.4582 2.06244 19.4923 2.05751 19.5268L2.01006 19.859C1.96412 20.1806 2.07724 20.5044 2.31341 20.7275C2.54958 20.9505 2.87937 21.0449 3.19779 20.9807L3.77732 20.8638C4.7459 20.6684 5.35499 20.5456 5.93025 20.3192C6.44003 20.1185 6.92448 19.8587 7.37367 19.5451C7.88056 19.1912 8.31984 18.7518 9.01843 18.0531C9.04022 18.0313 9.06226 18.0092 9.08456 17.9869L20.9463 6.12523ZM16.914 7.3291L15.4998 5.91489L6.46986 14.9448C5.60344 15.8112 5.26653 16.1542 5.0087 16.5373C4.76886 16.8937 4.57594 17.2795 4.43473 17.6853C4.33882 17.9608 4.27468 18.252 4.19675 18.7349C4.66592 18.6334 4.93953 18.5598 5.19773 18.4581C5.56185 18.3148 5.90789 18.1292 6.22875 17.9052C6.5736 17.6645 6.88444 17.3586 7.67034 16.5727L16.914 7.3291ZM12 20C12 19.4477 12.4477 19 13 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H13C12.4477 21 12 20.5523 12 20Z"
      />
    </SvgIcon>
  )
}

export default Edit3Icon
