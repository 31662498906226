import { Grid, styled } from '@mui/material'
import * as React from 'react'

interface IProps {
  leftPanel: React.ReactNode
  rightPanel: React.ReactNode
}

const CardDoublePanel: React.FC<IProps> = (props) => {
  const { leftPanel, rightPanel } = props

  return (
    <CardContainer container>
      <Grid item xs={6}>
        {leftPanel}
      </Grid>
      <Grid item xs={6}>
        {rightPanel}
      </Grid>
    </CardContainer>
  )
}

export default CardDoublePanel

const CardContainer = styled(Grid)
(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius
}))
