import * as React from 'react'

import { FileTextIcon, Trash2Icon } from 'icons'
import { BaseInput, HiddenFileInput } from 'component/Inputs'

import {
  getFormatsString,
  handleChange,
  handleDrop,
  handleWrapperClick,
} from './core/FileUploader.helpers'

import { Card, CardActionArea, Stack, styled, SxProps, Typography } from '@mui/material'
import HelpContainer from 'component/HelpContainer'

interface IProps {
  sx?: SxProps
  formats: ISupportedFileExtension[]
  sizeLimit: string
  file: File | undefined
  onChange: (file: File) => void
  onReset: React.MouseEventHandler
  helpURL?: string
}

const FileUploader: React.FC<IProps> = (props) => {
  const { sx, formats, sizeLimit, helpURL, onChange, file, onReset } = props

  const [dragOver, setDragOver] = React.useState(false)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const formatsString = getFormatsString(formats)

  return (
    <Wrapper sx={sx} file={file} dragOver={dragOver}>
      <CardActionArea>
        <UploadZone
          flexDirection={file !== undefined ? 'row' : 'column'}
          justifyContent="center"
          alignItems="center"
          onDragOver={(e) => {
            e.preventDefault()
          }}
          onDrop={handleDrop({ onChange, formats })}
          onDragEnter={() => {
            setDragOver(true)
          }}
          onDragLeave={() => {
            setDragOver(false)
          }}
          onClick={handleWrapperClick(inputRef)}
        >
          {file !== undefined ? (
            <BaseInput
              id="file-readonly"
              value={file.name}
              readOnly
              startIcon={<FileTextIcon />}
              endIcon={<Trash2Icon onClick={onReset} sx={{ cursor: 'pointer' }} />}
              sx={{ flexBasis: '65%', cursor: 'default' }}
            />
          ) : (
            <>
              <FileTextIcon sx={{ color: 'text.secondary', mb: 2, fontSize: 24 }} />
              <Typography variant="body2" color="text.primary">
                Glisser-déposer un fichier au format {formatsString}
              </Typography>
              <Typography variant="body2" mb={0.5} color="text.primary">
                ou cliquer ici pour l'ajouter
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                ({formatsString} uniquement, jusqu'a {sizeLimit})
              </Typography>
              <HiddenFileInput
                reference={inputRef}
                onChange={handleChange(onChange)}
                accept={formats.map((format) => `.${format}`).join(', ')}
              />
            </>
          )}
        </UploadZone>
      </CardActionArea>
      {helpURL && (
        <HelpContainer
          message="Quel fichier choisir ? Quel format est attendu ?"
          helpURL={helpURL}
          standalone={false}
        />
      )}
    </Wrapper>
  )
}

export default FileUploader

const Wrapper = styled(Card, {
  shouldForwardProp: (propName) => propName !== 'file' && propName !== 'dragOver',
})<{ file: File | undefined; dragOver: boolean }>(({ file, dragOver }) => ({
  boxShadow: 'none',
  '.MuiCardActionArea-focusHighlight, .MuiTouchRipple-root': {
    display: file ? 'none' : undefined,
  },

  '.MuiCardActionArea-focusHighlight': {
    opacity: dragOver ? 0.08 : undefined,
  },
}))

const UploadZone = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  background: theme.palette.background.default,
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: 4,
  cursor: 'default',

  '.MuiOutlinedInput-root, .MuiInputBase-input' : {
    cursor: 'default',
  },
  '.MuiOutlinedInput-root:hover': {
    boxShadow: theme.helpers.stateShadows.default.formElements.primary,
    '.MuiInputAdornment-root': {
      opacity: 0.7
    },
  }
}))
