import React, { FC } from 'react'

const Loader: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default Loader
