import { styled, SxProps, TextField } from '@mui/material'
import * as React from 'react'
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
  CalendarPickerView,
} from '@mui/x-date-pickers-pro'
import { Moment } from 'moment'
import { CalendarIcon } from 'icons'
import { DateInputActionBar } from './components'

interface IProps {
  id: string
  value: Moment | null
  onChange: DatePickerProps<any, Moment>['onChange']
  size?: 'small' | 'medium'
  sx?: SxProps
  label?: string
  disabled?: boolean
  placeholder?: string
  disableFuture?: boolean
  openTo?: CalendarPickerView
  views?: CalendarPickerView[]
}

const DateInput: React.FC<IProps> = (props) => {
  const { value, onChange, size, label, id, placeholder, ...rest } = props

  return (
    <DatePicker
      label={label}
      size={size}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          size={size}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            placeholder,
          }}
        />
      )}
      components={{
        OpenPickerIcon: CalendarIcon,
        // @ts-ignore
        ActionBar: DateInputActionBar,
      }}
      onChange={onChange as any}
      closeOnSelect={false}
      PaperProps={{
        className: `MuiCustomDatePickerPaper ${
          size === 'small' ? 'MuiCustomDatePickerPaper-sizeSmall' : null
        }`,
      }}
      dayOfWeekFormatter={(day) => day.slice(0, 2)}
      showDaysOutsideCurrentMonth
      componentsProps={{
        actionBar: {
          actions: ['accept', 'clear'],
          // @ts-ignore
          value,
          size,
        },
      }}
      {...rest}
    />
  )
}

export default DateInput

const DatePicker = styled(MUIDatePicker)<{
  size: 'small' | 'medium' | undefined
}>(({ size, theme }) => ({
  '.MuiIconButton-root': {
    color: theme.palette.text.secondary,
    padding: 0,
    marginRight: 0,
  },
}))
