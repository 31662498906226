import * as React from 'react'

import { BaseTextFieldProps, InputAdornment, TextField } from '@mui/material'

interface IProps extends BaseTextFieldProps {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  readOnly?: boolean
  type?: string
}

const BaseInput: React.FC<IProps> = (props) => {
  const { startIcon, endIcon, onChange, readOnly, type = 'text', ...otherProps } = props

  return (
    <TextField
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ) : undefined,
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : undefined,
        readOnly,
      }}
      type={type}
      onChange={onChange}
      variant="outlined"
      {...otherProps}
    />
  )
}

export default BaseInput
