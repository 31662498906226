import * as React from 'react'

import { API_DOCKETS_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIDocket } from 'api/interfaces'

export const getImports = async ({
  setLoading,
  debouncedSearch,
  filter,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  debouncedSearch: string
  filter: string
}): Promise<IAPIDocket[] | undefined> => {
  setLoading(true)

  const params = new URLSearchParams()

  if (debouncedSearch) {
    params.append('q', debouncedSearch)
  }

  if (filter) {
    params.append('type', filter)
  }

  const res = await customFetch
    .get(
      `${API_DOCKETS_URL}${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      console.log(err)
    })

  if (!res || !res.ok) {
    setLoading(false)
    return
  }

  const jsonRes: void | IAPIDocket[] = await res.json().catch((err) => {
    console.log(err)
  })

  setLoading(false)
  return jsonRes || undefined
}
