import { Box, Button, Stack, styled, Typography, useTheme } from '@mui/material'
import CommissionChip from 'component/CommissionChip'
import InputText from 'component/Inputs/InputText'
import Loader from 'component/Loader'
import * as React from 'react'
import EventEmitter, { UPDATE_EVENT_DOCKETS_IMPORTS } from 'utils/events'
import useQueueProgress from 'utils/hooks/queueProgress'
import { deleteDocket } from './core/ModalDeleteDocket.service'
import { ICommissionType } from 'page/Commissions/Imports/core/Imports.hooks'
import Modal from 'component/Modal'
import { IImportRow } from 'page/Commissions/Imports'

interface IProps {
  commissionTypes: ICommissionType[]
  deletingRow: IImportRow | null
  onClose: () => void
}

const ModalDeleteDocket: React.FC<IProps> = (props) => {
  const { onClose, deletingRow, commissionTypes } = props

  const [step, setStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [error, setError] = React.useState('')
  const [jobId, setJobId] = React.useState<string>()

  React.useEffect(() => {
    if (!deletingRow) {
      setLoading(false)
      setStep(0)
      setError('')
      setProgress(0)
      setJobId(undefined)
    }
  }, [deletingRow])

  const theme = useTheme()

  const onComplete = () => {
    setStep(2)
    EventEmitter.dispatch(UPDATE_EVENT_DOCKETS_IMPORTS)
  }

  const onError = (error?: string) => {
    setError(
      error ||
        'Une erreur est survenue pendant la suppression du bordereau, veuillez réessayer. Si le problème persiste contactez le service client Sendraise.'
    )
    setLoading(false)
  }

  useQueueProgress({
    interval: 1000,
    queueId: 'process-insurance-delete',
    jobId,
    setJobId,
    setProgress,
    onComplete,
    onError,
  })

  const handleDeleteClick: React.MouseEventHandler = async () => {
    if (!deletingRow?.id) return
    setStep(1)

    const processing = await deleteDocket({ id: deletingRow.id, setError })
    setLoading(true)
    setJobId(processing?.jobId)
  }

  const handleClose = () => {
    EventEmitter.dispatch(UPDATE_EVENT_DOCKETS_IMPORTS)
    onClose()
  }

  return (
    <Modal
      open={!!deletingRow}
      onClose={handleClose}
      title="Suppression du bordereau"
      modalId="ModalDeleteDocket"
    >
      <Modal.Content>
        {step === 0 && (
          <>
            <Typography
              sx={{ mb: 2 }}
              variant="h2b"
              color="text.primary"
              fontWeight={500}
              component="h2"
            >
              Vous allez supprimer le bordereau suivant
            </Typography>
            <StyledBox sx={{ pt: 3, pb: 3, pl: 4, pr: 4, mb: 2 }}>
              <Typography sx={{ mb: 0.5 }} fontWeight={500} color="text.primary" variant="body2">
                {deletingRow?.name.filename || ''}
              </Typography>
              <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle1">
                Uploadé par {deletingRow?.upload.uploader || ''}, le{' '}
                {deletingRow?.upload.uploadDate || ''}
              </Typography>
              <Typography sx={{ mb: 2 }} color="text.secondary" variant="subtitle1">
                Période du {deletingRow?.name.startDate || ''} au {deletingRow?.name.endDate || ''}
              </Typography>
              <Stack direction="row" gap={theme.spacing(1)}>
                {commissionTypes.map((commissionType) => (
                  <CommissionChip
                    key={commissionType.key}
                    commissionType={commissionType}
                    size="small"
                  />
                ))}
              </Stack>
            </StyledBox>
            <Typography sx={{ mb: 4 }} variant="subtitle1" color="text.secondary">
              Les lignes de ce bordereau seront effacées de la base de données, qu’il s’agisse
              d’émissions ou de corrections de commissions.
              <br />
              En fonction des informations contenus dans ce bordereau, il est possible que de
              nouvelles erreurs apparaissent sur les lignes d'autres bordereaux.
            </Typography>
          </>
        )}
        {step === 1 && (
          <>
            <Typography
              sx={{ mb: 2 }}
              variant="h2b"
              color="text.primary"
              fontWeight={500}
              component="h2"
            >
              Suppression du bordereau
            </Typography>
            <Box sx={{ mb: 4 }} className="dropzone dropzone__loading">
              {loading ? (
                <>
                  <Loader />
                  <div className="dropzone__loading-title">
                    Traitement en cours : <strong>{progress} %</strong>
                  </div>
                  <div className="dropzone__loading-file">{deletingRow?.name.filename || ''}</div>
                </>
              ) : (
                <div className="dropzone__file-recap">
                  <InputText
                    name="file"
                    className="input--icons mb-2"
                    iconLeft="icon-16 icon--left icon-alert-circle"
                    defaultValue={deletingRow?.name.filename || ''}
                    readonly
                    required
                  />

                  {error && <p className="dropzone__error-message mb-0">{error}</p>}
                </div>
              )}
            </Box>
          </>
        )}
        {step === 2 && (
          <>
            <Typography
              sx={{ mb: 2 }}
              variant="h2b"
              color="text.primary"
              fontWeight={500}
              component="h2"
            >
              Le bordereau a été supprimé
            </Typography>
            <Typography sx={{ mb: 4 }} variant="subtitle1" color="text.secondary">
              Les données liées au bordereau sélectionné ont bien été supprimées.
              <br />
              Si ces données étaient des corrections ou faisaient l'objet de corrections, celles-ci
              ont été annulées.
            </Typography>
          </>
        )}
      </Modal.Content>
      {(error || step === 0 || step === 2) && (
        <Modal.Actions>
          <Stack flexGrow={1} direction="row" justifyContent="space-between">
            {(step === 0 || (error && step === 1)) && (
              <Button variant="text" color="neutral" onClick={onClose}>
                Annuler
              </Button>
            )}
            {step === 0 && (
              <Button variant="text" color="error" onClick={handleDeleteClick}>
                Confirmer la suppression
              </Button>
            )}
            {error && step === 1 && (
              <Button variant="text" color="error" onClick={handleDeleteClick}>
                Relancer la suppression
              </Button>
            )}
            {step === 2 && (
              <Button variant="text" color="neutral" onClick={onClose} sx={{ ml: 'auto' }}>
                Terminer
              </Button>
            )}
          </Stack>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default ModalDeleteDocket

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
}))
