import { useTheme } from '@mui/material'
import { IImportRowCommissionType } from '..'

export type ICommissionType = {
  key: IImportRowCommissionType
  value: string
  display: string
  color: { main: string; contrastText: string }
}

export function useCommissionTypes(): ICommissionType[] {
  const theme = useTheme()
  return [
    {
      key: 'outstandingContract',
      value: 'outstanding_contract',
      display: 'Encours',
      color: theme.palette.dataviz.crimsonDark,
    },
    {
      key: 'outstandingOverride',
      value: 'outstanding_override',
      display: 'Majorations',
      color: theme.palette.dataviz.crimsonMid,
    },
    {
      key: 'outstandingDelegate',
      value: 'outstanding_delegate',
      display: 'Mandats',
      color: theme.palette.dataviz.crimsonLight,
    },
    {
      key: 'outstandingUcits',
      value: 'outstanding_ucits',
      display: 'Rétrocessions OPCVM',
      color: theme.palette.dataviz.turquoise,
    },
    {
      key: 'arbitration',
      value: 'arbitration',
      display: 'Arbitrages',
      color: theme.palette.dataviz.greenishDark,
    },
    {
      key: 'deposit',
      value: 'deposit',
      display: 'Versements',
      color: theme.palette.dataviz.greenishMid,
    },
    {
      key: 'upfront',
      value: 'upfront',
      display: 'Upfronts',
      color: theme.palette.dataviz.greenishLight,
    },
    {
      key: 'unknown',
      value: 'unknown',
      display: 'Autres',
      color: theme.palette.neutral,
    },
  ]
}
