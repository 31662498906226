import { Components, Theme } from '@mui/material'

const MuiInputAdornment: NonNullable<Components<Theme>['MuiInputAdornment']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      svg: {
        zIndex: 2,
        // fontSize: 20,
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }),
      },

      '&.MuiInputAdornment-sizeSmall': {
        '&.MuiInputAdornment-positionStart': {
          marginRight: theme.helpers.sizing.formElements.small.gap,
        },

        '&.MuiInputAdornment-positionEnd': {
          marginLeft: theme.helpers.sizing.formElements.small.gap,
        },

        svg: {
          fontSize: 16,
        },
      },
      '&.MuiInputAdornment-sizeMedium': {
        '&.MuiInputAdornment-positionStart': {
          marginRight: theme.helpers.sizing.formElements.medium.gap,
        },

        '&.MuiInputAdornment-positionEnd': {
          marginLeft: theme.helpers.sizing.formElements.medium.gap,
        },

        svg: {
          fontSize: 20
        },
      },
    }),
  },
}

export default MuiInputAdornment
