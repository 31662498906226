import { IStandardProps } from 'api/interfaces'
import React, { useRef, useEffect, FC, CSSProperties, RefObject } from 'react'

function useDetectOutside(ref: RefObject<HTMLDivElement>, callback: Function) {
  const handleMouseDown = (e: MouseEvent) => {
    if (!ref.current) {
      return
    }

    if (!ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  })
}

interface IProps extends IStandardProps {
  callback: Function
  style?: CSSProperties
  reference?: RefObject<any>
  children?: React.ReactNode
}

// Component that alerts if you click outside of it
const DetectOutside: FC<React.PropsWithChildren<IProps>> = ({
  callback,
  className,
  children,
  reference,
  style,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useDetectOutside(reference || wrapperRef, callback)

  return (
    <div
      style={style}
      className={className || ''}
      ref={reference || wrapperRef}
    >
      {children}
    </div>
  )
}

export default DetectOutside
