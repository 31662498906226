export const getMIMEType = (extension: ISupportedFileExtension): string => {
  switch (extension) {
    case 'csv':
      return 'text/csv'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'pdf':
      return 'application/pdf'
  }
}

export const getCapitalizeName = (name: string) => {
  return `${name.charAt(0).toUpperCase()}${name.substring(1)}`
}
