import { Components, Theme } from '@mui/material'

const MuiDrawer: NonNullable<Components<Theme>['MuiDrawer']> = {
  styleOverrides: {
    paper: ({ theme }) => ({
      background: theme.palette.background.inverted,
    }),
  },
}

export default MuiDrawer
