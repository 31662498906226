import * as React from 'react'
import { Box, Button, ModalProps, Stack, styled, Typography } from '@mui/material'

import { Modal } from 'component'

import Loader from 'component/Loader'

import useQueueProgress from 'utils/hooks/queueProgress'
import { downloadRemoteFile } from 'utils'
import { handleDownloadClick } from '../ModalDownloadRawInsuranceFeeReport/core/ModalDownloadRawInsuranceFeeReport.helpers'

interface IProps {
  open: boolean
  onClose: NonNullable<ModalProps['onClose']>
  insuranceFeeReportId: number
}

const ModalDownloadRawInsuranceFeeReport: React.FC<IProps> = (props) => {
  const { open, onClose, insuranceFeeReportId } = props

  const [loading, setLoading] = React.useState<boolean>(false)
  const [progress, setProgress] = React.useState<number>(0)
  const [error, setError] = React.useState<string>()
  const [jobId, setJobId] = React.useState<string>()

  useQueueProgress({
    interval: 1000,
    queueId: 'download-raw-insurance-fee-report',
    jobId,
    setJobId,
    setProgress,
    onError: (error) => {
      setError(error || 'Erreur durant le téléchargement des données brutes, veuillez réessayer.')
      setLoading(false)
    },
    onComplete: (data) => {
      setLoading(false)
      downloadRemoteFile(data)
      onClose({}, 'backdropClick')
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Téléchargement des données brutes"
      className="modal--proofs"
      modalId="ModalDownloadRawInsuranceFeeReport"
    >
      <Modal.Content>
        {loading ? (
          <>
            <Wrapper justifyContent="center" alignItems="center">
              <Box mb={2}>
                <Loader />
              </Box>
              <Typography variant="body2" mb={0.5} sx={{ color: 'text.primary' }}>
                Progression : {progress}%
              </Typography>
            </Wrapper>
          </>
        ) : (
          <>
            {error ? (
              <Typography variant="subtitle1" color="error.text" mt={2}>
                {error}
              </Typography>
            ) : null}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="flex-end">
          <Button
            onClick={handleDownloadClick({
              insuranceFeeReportId,
              setLoading,
              setProgress,
              setError,
              onClose,
              setJobId,
            })}
          >
            Télécharger le fichier de données brutes
          </Button>
        </Stack>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalDownloadRawInsuranceFeeReport

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(6),
}))
