import { ORGANIZATION_UPDATE_NAME, ORGANIZATION_UPDATE_LOGO } from 'store/constants/organization'

export const updateOrganizationName = ({name}: {name: string}) => {
  return (
    {
      type: ORGANIZATION_UPDATE_NAME,
      name: name,
    }
  )
}

export const updateLogo = ({logo}: {logo: string}) => {
  return (
    {
      type: ORGANIZATION_UPDATE_LOGO,
      logo: logo,
    }
  )
}
