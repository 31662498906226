import { API_QUEUE_URL, API_USER_INSURANCE_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { useEffect } from 'react'
import EventEmitter, { UPDATE_EVENT_DOCKETS } from 'utils/events'
import { IHook } from './ModalUploadMultipleDockets.types'

export const useModalUploadMultipleDockets: IHook = ({
  jobId,
  setJobId,
  setInsurances,
  setSelectedInsurance,
  updateUploadedReports,
  currentFetchingReportIndex,
  uploadedReports,
  handleNextUpload,
  modal,
}) => {
  useEffect(() => {
    if (!modal) return
    customFetch
      .get(API_USER_INSURANCE_URL)
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        setInsurances(data.results)
      })
      .catch((err) => {
        console.log(`Get insurances error : ${err}`)
      })
  }, [setSelectedInsurance, setInsurances, modal])

  useEffect(() => {
    if (!jobId || currentFetchingReportIndex === null || !modal) {
      return
    }

    let queueId = 'process-insurance-import'
    const interval = setInterval(() => {
      customFetch
        .get(`${API_QUEUE_URL}/${queueId}/${jobId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.state === 'completed') {
            clearInterval(interval)
            if (data.data.error) {
              let msg = data.data.error
              if (data.data.error.message) {
                msg = data.data.error.message
              }
              updateUploadedReports({
                id: currentFetchingReportIndex,
                progress: 0,
                error: msg,
                loading: false,
              })
              setJobId(undefined)
              if (currentFetchingReportIndex !== uploadedReports.length) {
                handleNextUpload(msg)
              }
              return
            }
            updateUploadedReports({
              id: currentFetchingReportIndex,
              progress: 100,
              loading: false,
              commissions: data.data.commissions,
            })

            EventEmitter.dispatch(UPDATE_EVENT_DOCKETS, data.data.insurance)
            setJobId(undefined)
            if (currentFetchingReportIndex !== uploadedReports.length) {
              handleNextUpload()
            }
            return
          }
          if (data.state === 'failed') {
            clearInterval(interval)
            const errorMessage = "Erreur lors de l'envoi du fichier, réessayez plus tard"
            updateUploadedReports({
              id: currentFetchingReportIndex,
              progress: 0,
              error: errorMessage,
              loading: false,
            })
            setJobId(undefined)
            if (currentFetchingReportIndex !== uploadedReports.length) {
              handleNextUpload(errorMessage)
            }
            return
          }
          updateUploadedReports({
            id: currentFetchingReportIndex,
            progress: Math.round(data.progress * 100),
            loading: true,
          })
        })
        .catch((err) => {
          console.log(err)
          const errorMessage = "Erreur lors de l'envoi du fichier, réessayez plus tard"

          setJobId(undefined)
          updateUploadedReports({
            id: currentFetchingReportIndex,
            progress: 0,
            error: errorMessage,
            loading: false,
          })
          if (currentFetchingReportIndex !== uploadedReports.length) {
            handleNextUpload(errorMessage)
          }
          return
        })
    }, 500)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobId,
    setJobId,
    updateUploadedReports,
    currentFetchingReportIndex,
    handleNextUpload,
    uploadedReports,
    modal,
  ])
}
