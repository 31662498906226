import { API_USER_INSURANCE_URL } from 'api/constants'
import { IAPIInsurancesResponse } from 'api/interfaces'
import { IAPIInsuranceProductResponse } from 'api/interfaces/responses'
import { get } from 'utils'

export const getAllInsrs = (insuranceFeeReports?: boolean) => {
  let url = API_USER_INSURANCE_URL
  if (insuranceFeeReports) {
    url += '?include=insuranceFeeReports'
  }
  return get<IAPIInsurancesResponse>({
    url,
  })
}

export const getInsrPrdsByInsr = (insrId: number) => {
  return get<IAPIInsuranceProductResponse>({
    url: `${API_USER_INSURANCE_URL}/${insrId}/products`,
  })
}
