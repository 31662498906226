import React, { FC } from 'react'
import NavbarContent from './NavbarContent'
import NavbarHeader from './NavbarHeader'
import { Context } from './typescript/navbar.context'
import { IComposition, IProps } from './typescript/navbar.interfaces'

export { Context as NavbarContext } from './typescript/navbar.context'
export type { IContext as INavbarContext } from './typescript/navbar.interfaces'

const Navbar: FC<React.PropsWithChildren<IProps>> & IComposition = ({
  children,
  className,
  activeLink,
  setActiveLink,
  id,
}) => {
  const contextValue = {
    activeLink,
    setActiveLink,
  }

  return (
    <Context.Provider value={contextValue}>
      <div className={`navbar ${className || ''}`}>{children}</div>
    </Context.Provider>
  )
}

Navbar.Header = NavbarHeader
Navbar.Content = NavbarContent

export default Navbar
