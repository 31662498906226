import { Components, Theme } from "@mui/material";

const MuiSvgIcon: NonNullable<Components<Theme>["MuiSvgIcon"]> = {
  styleOverrides: {
    root: (props) => ({
      fontSize: 16,
    }),
    fontSizeSmall: () => ({
      fontSize: 16,
    }),
    fontSizeMedium: () => ({
      fontSize: 20,
    }),
    fontSizeLarge: () => ({
      fontSize: 24,
    }),
  },
};

export default MuiSvgIcon;
