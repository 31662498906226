import { Components, Theme } from '@mui/material'
import { ChevronDownIcon } from 'icons'

const MuiSelect: NonNullable<Components<Theme>['MuiSelect']> = {
  defaultProps: {
    IconComponent: ChevronDownIcon,
  },
  styleOverrides: {
    select: ({ theme }) => ({
      paddingRight: `${theme.spacing(4.75)} !important`,
      minHeight: 'unset',

      '&.Mui-disabled': {
        cursor: 'not-allowed',
      },

      '&.MuiSelect-inputSizeSmall': {
        paddingRight: `${theme.spacing(3.5)} !important`,

        '& ~ .MuiSelect-icon': {
          fontSize: 16,
        },
      },
    }),

    icon: ({ theme }) => ({
      color: `${theme.palette.text.secondary} !important`,
      zIndex: 2,
      right: theme.spacing(1),
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
    }),
  },
}

export default MuiSelect
