import { isIsinCode, roundToDigits } from 'utils'
import {
  IAPIInsuranceFeeArbitrage,
  IAPIInsuranceFeeDeposit,
  IAPIInsuranceFeeUpfront,
} from 'api/interfaces'
import EventEmitter, {
  UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE,
  UPDATE_EVENT_INSURANCE_FEE_DEPOSIT,
  UPDATE_EVENT_INSURANCE_PRODUCTS,
  UPDATE_EVENT_INSURANCE_FEE_UPFRONT,
} from 'utils/events'

import {
  patchArbitrationDepositConfiguration,
  patchUpfrontConfiguration,
  patchConfigurationPropagation,
  postArbitrationDepositConfiguration,
  postUpfrontConfiguration,
} from './ModalConfigureFees.service'

export const getModalTitle = (
  edition: boolean,
  feesType: 'arbitration' | 'deposit' | 'upfront'
): string => {
  switch (feesType) {
    case 'arbitration':
      return edition
        ? `Éditer la règle de frais d'arbitrage`
        : `Configurer une règle de frais d'arbitrage`
    case 'deposit':
      return edition
        ? `Éditer la règle de frais de versement`
        : `Configurer une règle de frais de versement`
    case 'upfront':
      return edition
        ? `Éditer la règle de frais d'upfront`
        : `Configurer une règle de frais d'upfront`
  }
}

export const getModalCTA = (edition: boolean) => {
  if (edition) {
    return 'Éditer la règle pour ce produit'
  }

  return 'Ajouter la configuration'
}

export const getEditionChipLabel = (insuranceProductsPropagatedToCount: number) => {
  return insuranceProductsPropagatedToCount > 1
    ? `Règle propagée à ${insuranceProductsPropagatedToCount} produits`
    : `Règle propagée à ${insuranceProductsPropagatedToCount} produit`
}

export const getChipLabel = (selectedInsuranceProductsCount: number) => {
  return selectedInsuranceProductsCount > 1
    ? `${selectedInsuranceProductsCount} produits sélectionnés`
    : `${selectedInsuranceProductsCount} produit sélectionné`
}

interface IDefaults {
  feeRate: number | undefined
  insuranceRate: number | undefined
  comment: string
  organizationRate: number | undefined
  organizationMinimumAmount: number | undefined
  insuranceMinimumAmount: number | undefined
  propagate: boolean
  isin: string | undefined
  intermediaryRate: number | undefined
  isStructuredProduct: boolean
}

export const getDefaults = (
  editedConfiguration:
    | IAPIInsuranceFeeArbitrage
    | IAPIInsuranceFeeDeposit
    | IAPIInsuranceFeeUpfront
    | undefined,
  propagate: boolean,
  isStructuredProduct: boolean
): IDefaults => {
  const defaults: IDefaults = {
    feeRate: undefined,
    organizationRate: undefined,
    insuranceRate: undefined,
    organizationMinimumAmount: undefined,
    insuranceMinimumAmount: undefined,
    comment: '',
    propagate,
    isin: undefined,
    intermediaryRate: undefined,
    isStructuredProduct,
  }

  if (editedConfiguration !== undefined) {
    defaults.organizationRate = editedConfiguration.organizationRate
    defaults.comment = editedConfiguration.comment || ''

    if ('feeRate' in editedConfiguration) {
      defaults.feeRate = editedConfiguration.feeRate
      defaults.insuranceRate = defaults.feeRate - defaults.organizationRate
    }

    if ('organizationMinimumAmount' in editedConfiguration) {
      defaults.organizationMinimumAmount = editedConfiguration.organizationMinimumAmount
    }

    if ('insuranceMinimumAmount' in editedConfiguration) {
      defaults.insuranceMinimumAmount = editedConfiguration.insuranceMinimumAmount
    }

    if ('isin' in editedConfiguration) {
      defaults.isin = editedConfiguration.isin || ''
      defaults.intermediaryRate = editedConfiguration.intermediaryRate
      defaults.isStructuredProduct = (editedConfiguration.intermediaryRate !== undefined && editedConfiguration.intermediaryRate !== null)
    }
  }

  return defaults
}

export const getFeeRateChangeHandler = ({
  setFeeRate,
  setOrganizationRate,
  setInsuranceRate,
}: {
  setFeeRate: StateSetter<number | undefined>
  setOrganizationRate: StateSetter<number | undefined>
  setInsuranceRate: StateSetter<number | undefined>
}): ((value: number | undefined) => void) => {
  return (value) => {
    const newFeeRate = value

    setFeeRate(newFeeRate)

    if (newFeeRate === undefined) {
      setOrganizationRate(undefined)
      setInsuranceRate(undefined)
      return
    }

    const newOrganizationRate = roundToDigits(newFeeRate / 2, 2)
    setOrganizationRate(newOrganizationRate)
    setInsuranceRate(roundToDigits(newFeeRate - newOrganizationRate))
  }
}

export const getArbitrationDepositOrganizationRateChangeHandler = ({
  feeRate,
  setOrganizationRate,
  setInsuranceRate,
}: {
  feeRate: number
  setOrganizationRate: StateSetter<number | undefined>
  setInsuranceRate: StateSetter<number | undefined>
}): ((value: number | undefined) => void) => {
  return (value) => {
    setOrganizationRate(value)

    if (value === undefined || value > feeRate) {
      setInsuranceRate(undefined)
      return
    }

    setInsuranceRate(roundToDigits(feeRate - value, 2))
  }
}

export const addArbitrationDepositConfiguration = async ({
  setError,
  setLoading,
  insuranceProductId,
  selectedInsuranceProductsIds,
  feeRate,
  organizationRate,
  organizationMinimumAmount,
  insuranceMinimumAmount,
  comment,
  goToNextStep,
  feesType,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  insuranceProductId: number
  selectedInsuranceProductsIds: number[]
  feeRate: number
  organizationRate: number
  organizationMinimumAmount: number
  insuranceMinimumAmount: number
  comment: string
  goToNextStep: () => void
  feesType: 'arbitration' | 'deposit'
}) => {
  setError(undefined)
  setLoading(true)

  await postArbitrationDepositConfiguration({
    insuranceProductIds: [insuranceProductId, ...selectedInsuranceProductsIds],
    feeRate,
    organizationRate,
    organizationMinimumAmount,
    insuranceMinimumAmount,
    comment,
    feesType,
  }).catch((err) => {
    setError(
      `Erreur lors de l'ajout de la nouvelle configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  switch (feesType) {
    case 'arbitration':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE)
      break
    case 'deposit':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
      break
  }

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
}

export const addUpfrontConfiguration = async ({
  setError,
  setLoading,
  insuranceProductId,
  selectedInsuranceProductsIds,
  organizationRate,
  comment,
  goToNextStep,
  isin,
  intermediaryRate,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  insuranceProductId: number
  selectedInsuranceProductsIds: number[]
  organizationRate: number
  comment: string
  goToNextStep: () => void
  isin?: string
  intermediaryRate?: number
}) => {
  setError(undefined)
  setLoading(true)

  await postUpfrontConfiguration({
    insuranceProductIds: [insuranceProductId, ...selectedInsuranceProductsIds],
    organizationRate,
    comment,
    isin,
    intermediaryRate,
  }).catch((err) => {
    setError(
      `Erreur lors de l'ajout de la nouvelle configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_UPFRONT)
  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
}

export const updateAndPropagateArbitrationDepositConfiguration = async ({
  setError,
  setLoading,
  feeRate,
  organizationRate,
  organizationMinimumAmount,
  insuranceMinimumAmount,
  comment,
  editedConfigurationId,
  goToNextStep,
  feesType,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  feeRate: number
  organizationRate: number
  organizationMinimumAmount: number
  insuranceMinimumAmount: number
  comment: string
  editedConfigurationId: number
  goToNextStep: () => void
  feesType: 'arbitration' | 'deposit'
}) => {
  setError(undefined)
  setLoading(true)

  await patchArbitrationDepositConfiguration({
    feeRate,
    organizationRate,
    organizationMinimumAmount,
    insuranceMinimumAmount,
    comment,
    editedConfigurationId,
    feesType,
  }).catch((err) => {
    setError(
      `Erreur lors de l'édition de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  switch (feesType) {
    case 'arbitration':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE)
      break
    case 'deposit':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
      break
  }
}

export const updateAndPropagateUpfrontConfiguration = async ({
  setError,
  setLoading,
  organizationRate,
  comment,
  editedConfigurationId,
  goToNextStep,
  isin,
  intermediaryRate,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  organizationRate: number
  comment: string
  editedConfigurationId: number
  goToNextStep: () => void
  isin?: string
  intermediaryRate?: number
}) => {
  setError(undefined)
  setLoading(true)

  await patchUpfrontConfiguration({
    organizationRate,
    comment,
    editedConfigurationId,
    isin,
    intermediaryRate,
  }).catch((err) => {
    setError(
      `Erreur lors de l'édition de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_UPFRONT)
}

export const updateArbitrationDepositConfiguration = async ({
  setError,
  setLoading,
  insuranceProductId,
  insuranceProductsPropagatedToIds,
  feeRate,
  organizationRate,
  organizationMinimumAmount,
  insuranceMinimumAmount,
  comment,
  editedConfigurationId,
  goToNextStep,
  feesType,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  insuranceProductId: number
  insuranceProductsPropagatedToIds: number[]
  feeRate: number
  organizationRate: number
  organizationMinimumAmount: number
  insuranceMinimumAmount: number
  comment: string
  editedConfigurationId: number
  goToNextStep: () => void
  feesType: 'arbitration' | 'deposit'
}) => {
  setError(undefined)
  setLoading(true)

  await patchConfigurationPropagation({
    insuranceProductIds: insuranceProductsPropagatedToIds,
    editedConfigurationId,
    feesType,
  }).catch((err) => {
    setError(
      `Erreur lors de l'édition de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
    goToNextStep()
  })

  await postArbitrationDepositConfiguration({
    insuranceProductIds: [insuranceProductId],
    feeRate,
    organizationRate,
    organizationMinimumAmount,
    insuranceMinimumAmount,
    comment,
    feesType,
  }).catch((err) => {
    setError(
      `Erreur lors de l'ajout de la nouvelle configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  switch (feesType) {
    case 'arbitration':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE)
      break
    case 'deposit':
      EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
      break
  }
}

export const updateUpfrontConfiguration = async ({
  setError,
  setLoading,
  insuranceProductId,
  insuranceProductsPropagatedToIds,
  organizationRate,
  comment,
  editedConfigurationId,
  goToNextStep,
  isin,
  intermediaryRate,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  insuranceProductId: number
  insuranceProductsPropagatedToIds: number[]
  organizationRate: number
  comment: string
  editedConfigurationId: number
  goToNextStep: () => void
  isin?: string
  intermediaryRate?: number
}) => {
  setError(undefined)
  setLoading(true)

  await patchConfigurationPropagation({
    insuranceProductIds: insuranceProductsPropagatedToIds,
    editedConfigurationId,
    feesType: 'upfront',
  }).catch((err) => {
    setError(
      `Erreur lors de l'édition de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
    goToNextStep()
  })

  await postUpfrontConfiguration({
    insuranceProductIds: [insuranceProductId],
    organizationRate,
    comment,
    isin,
    intermediaryRate,
  }).catch((err) => {
    setError(
      `Erreur lors de l'ajout de la nouvelle configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
    )
    setLoading(false)
  })

  goToNextStep()

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_UPFRONT)
}

export const getArbitrationDepositNextStepDisabled = ({
  feeRate,
  organizationRate,
  insuranceRate,
  organizationMinimumAmount,
  insuranceMinimumAmount,
  comment,
}: {
  feeRate: number | undefined
  organizationRate: number | undefined
  insuranceRate: number | undefined
  organizationMinimumAmount: number | undefined
  insuranceMinimumAmount: number | undefined
  comment: string
}): boolean => {
  return (
    feeRate === undefined ||
    organizationRate === undefined ||
    insuranceRate === undefined ||
    organizationMinimumAmount === undefined ||
    insuranceMinimumAmount === undefined ||
    comment === ''
  )
}

export const getUpfrontNextStepDisabled = ({
  organizationRate,
  comment,
  isin,
  isStructuredProduct,
  intermediaryRate
}: {
  organizationRate: number | undefined
  comment: string
  isin: string | undefined
  isStructuredProduct: boolean
  intermediaryRate: number | undefined
}): boolean => {
  return organizationRate === undefined || comment === '' || !isIsinCode(isin) || (isStructuredProduct && (intermediaryRate === undefined || intermediaryRate === null))
}
