import { LOGIN_URL } from 'utils/router/constants'
import { API_CRUMB_URL, API_LOGOUT_URL } from 'api/constants'
import store from 'store'
import { USER_LOGOUT } from 'store/constants/user'

export async function getCSRFToken() {
  let request = await fetch(API_CRUMB_URL, {
    method: 'GET',
    credentials: 'include',
  })
  return request
}

export async function getLogout() {
  let request = await fetch(API_LOGOUT_URL, {
    method: 'GET',
    credentials: 'include',
  })

  store.dispatch({
    type: USER_LOGOUT
  })
  
  window.location.replace(LOGIN_URL)

  return request
}