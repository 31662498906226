import * as React from 'react'

import { Typography } from '@mui/material'

const InformationName: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Typography
      {...props}
      variant="subtitle1"
      fontWeight={500}
      flexBasis={150}
      flexShrink={0}
      mr={0.5}
      sx={{ color: 'text.secondary' }}
      component="p"
    />
  )
}

export default InformationName
