import { API_REGISTRATION_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { getJsonResponse, getResumeKey, manageApiError } from './Subscription.helpers'
import { getCSRFToken } from 'api/get'

export interface IOrganizationPayload {
  organizationName?: string
  organizationSiret?: string
  organizationAddress?: string
  organizationZipCode?: string
  organizationCity?: string
  organizationCountry?: string
  userFirstName?: string
  userLastName?: string
  userPhone?: string
}

interface IUserPayload {
  email?: string
  password?: string
  billingEmail?: string
  optInNews?: boolean
  optInCompliance?: boolean
  acceptTerms?: boolean
}

export const checkCSRFToken = async () => {
  const res = await getCSRFToken()
  const json = await res.json()  
  customFetch.dispatchCSRFToken(json.crumb)
};


export const getCurrentOnboardUser = async () => {
  const resumeKey = getResumeKey()
  const res = await customFetch.get(`${API_REGISTRATION_URL}/${resumeKey}`).catch((e) => {
    console.log(e)
  })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return await getJsonResponse(res)
}

export const createRegistration = async (body: {
  email: string
  password: string
  recaptcha: string
}) => {
  const res = await customFetch.post(`${API_REGISTRATION_URL}`, body).catch((e) => {
    console.log(e)
  })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  const jsonRes = await getJsonResponse(res)

  localStorage.setItem('resumeKey', jsonRes.resumeKey)
  return
}

export const sendEmailValidation = async () => {
  const resumeKey = getResumeKey()
  const res = await customFetch
    .post(`${API_REGISTRATION_URL}/${resumeKey}/email`, {})
    .catch((e) => {
      console.log(e)
    })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return
}

export const validateEmail = async (body: { email: string; validationKey: string }) => {
  const resumeKey = getResumeKey()
  const res = await customFetch
    .put(`${API_REGISTRATION_URL}/${resumeKey}/email`, body)
    .catch((e) => {
      console.log(e)
    })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return
}

export const putOrganization = async (body: IOrganizationPayload) => {
  const resumeKey = getResumeKey()
  const res = await customFetch
    .put(`${API_REGISTRATION_URL}/${resumeKey}/organization`, body)
    .catch((e) => {
      console.log(e)
    })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return
}

export const putRegisteredUser = async (body: IUserPayload) => {
  const resumeKey = getResumeKey()
  const res = await customFetch
    .put(`${API_REGISTRATION_URL}/${resumeKey}/user`, body)
    .catch((e) => {
      console.log(e)
    })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return
}

export const getStripePlans = async () => {
  const resumeKey = getResumeKey()
  const res = await customFetch.get(`${API_REGISTRATION_URL}/${resumeKey}/plans`).catch((e) => {
    console.log(e)
  })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return await getJsonResponse(res)
}


export const createStripeSubscription = async (body: { priceId: string }) => {
  const resumeKey = getResumeKey()
  const res = await customFetch
    .post(`${API_REGISTRATION_URL}/${resumeKey}/subscriptions`, body)
    .catch((e) => {
      console.log(e)
    })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return res
}


export const createStripeCheckout = async (body: { priceId: string }) => {
  const resumeKey = getResumeKey()
  const res = await customFetch
    .post(`${API_REGISTRATION_URL}/${resumeKey}/checkout`, body)
    .catch((e) => {
      console.log(e)
    })

  if (!res || !res.ok) {
    await manageApiError(res)
    return
  }

  return await getJsonResponse(res)
}
