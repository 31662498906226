import React, { ReactNode } from 'react'
import Sidebar from 'component/Sidebar'

interface IProps {
  children: ReactNode
  className?: string
  sidebarContent: JSX.Element
  isSmall?: boolean
  isExtraSmall?: boolean
  picture?: string
}

function LayoutSidebar(props: IProps) {
  const { children, className, sidebarContent, isSmall, isExtraSmall } = props

  return (
    <div className={`page ${className || ''}`}>
      <Sidebar
        sidebarContent={sidebarContent}
        isSmall={isSmall}
        isExtraSmall={isExtraSmall}
      />
      {children}
    </div>
  )
}

export default LayoutSidebar
