import * as React from 'react'

import { getRootClassname } from './core/Switch.helpers'

import { FormControlLabel, SxProps, Switch as MUISwitch, SwitchProps } from '@mui/material'

interface IProps {
  sx?: SxProps
  label?: string
  checked: boolean
  onChange?: SwitchProps['onChange']
  size?: 'medium' | 'small'
  disabled?: boolean
}

const Switch: React.FC<IProps> = (props) => {
  const { sx, label, checked, onChange, size, disabled } = props

  return (
    <FormControlLabel
      sx={sx}
      label={label}
      className={getRootClassname(size)}
      control={<MUISwitch size={size} checked={checked} onChange={onChange} disabled={disabled} />}
    />
  )
}

export default Switch
