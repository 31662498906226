import { Components, Theme } from '@mui/material'

const MuiTooltip: NonNullable<Components<Theme>['MuiTooltip']> = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      maxWidth: 'unset',
      background: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    }),
  },
}

export default MuiTooltip
