import React, { FC } from 'react'

import { FlagTooltip } from 'component'
import { formatCurrencySymbol } from 'utils'
import { parseFlagDescription, parseInvalidationFlagDescription } from 'utils/commissions'

import { Box, Stack } from '@mui/material'

interface IDocketCurrencyCellProps {
  item: any
  field: string
  value?: any
  showErrors?: boolean
  tag?: string
}

const DocketCurrencyCell: FC<React.PropsWithChildren<IDocketCurrencyCellProps>> = ({
  item,
  field,
  value,
  showErrors = true,
  tag = 'td',
}) => {
  if (value === undefined) {
    value = item[field]
  }

  const Element = tag as keyof JSX.IntrinsicElements
  const classNames = ['cell--docket-currency cell-number']
  // const formattedValue =
  //   field === ''
  //     ? ''
  //     : formatCurrencySymbol(
  //         value,
  //         item.currency === 'OTHER' ? item.currencyOther : item.currency
  //       ) || '-'
  const provider = item.insuranceFeeReport.insurance.provider

  let formattedValue = '-'
  if (!(provider === 'intencial' && field === 'baseAmount' && value === 0.0)) {
    if (item.currency === 'OTHER') {
      formattedValue = formatCurrencySymbol(value, item.currencyOther)
    } else {
      formattedValue = formatCurrencySymbol(value, item.currency)
    }
  }

  const fieldFlag = parseFlagDescription(item.flagDescription).find(
    (itemFlag) => itemFlag.field === field
  )
  const fieldInvalidationFlag = parseInvalidationFlagDescription(
    item.flagInvalidationDescription
  ).find((itemInvalidationFlag) => itemInvalidationFlag.field === field)

  const hasErrors = fieldFlag !== undefined
  const isInvalidated = fieldInvalidationFlag !== undefined

  if (formattedValue === '-') {
    classNames.push('cell--docket-no-value')
  }

  return (
    <Element className={classNames.join(' ')} key={`docket-${field}-${item.id}`}>
      <Stack direction="row">
        {showErrors && hasErrors ? (
          <FlagTooltip
            fieldFlag={fieldFlag!}
            fieldInvalidationFlag={fieldInvalidationFlag}
            sx={{ mr: 1, svg: { display: 'block' } }}
          />
        ) : null}
        <Box
          fontWeight={hasErrors && showErrors ? (isInvalidated ? 400 : 500) : 400}
          color={
            hasErrors && showErrors
              ? isInvalidated
                ? 'text.primary'
                : 'warning.main'
              : 'text.primary'
          }
        >
          {formattedValue}
        </Box>
      </Stack>
    </Element>
  )
}

export default DocketCurrencyCell
