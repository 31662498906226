import React, { ChangeEvent, FormEvent, useState } from 'react'
import { withRouter } from 'react-router-dom'
import InputText from 'component/Inputs/InputText'
import { Button } from '@mui/material'
import Loader from 'component/Loader'
import { API_FILE_TRANSFER_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { roundDecimals } from 'utils'

function FileTransfer(props: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const [file, setFile] = useState<File | undefined>()
  const [fileName, setFileName] = useState<string | undefined>()
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [progress, setProgress] = useState<number>(0)

  const resetForm = () => {
    setIsFileUploaded(false)
    setError(undefined)
    setIsLoading(false)
    setFile(undefined)
    setFileName(undefined)
  }

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    setFile(e.target.files[0])
    setFileName(e.target.files[0].name)
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setProgress(0)
    setError(undefined)
    setIsLoading(true)

    try {
      if (!name || name.trim().length === 0) {
        setError('Veuillez saisir votre nom')
        return
      }

      if (!email || email.trim().length === 0) {
        setError('Veuillez saisir votre email')
        return
      }

      if (!file) {
        setError('Veuillez sélectionner un fichier')
        return
      }

      const form = new FormData()
      form.append('file', file!)
      form.append('fileName', fileName!)
      form.append('name', name!)
      form.append('email', email!)

      const res = await customFetch.postUpload(API_FILE_TRANSFER_URL, form, (progress: number) => {
        setProgress(roundDecimals(progress * 100, 0))
      })

      const json = await res.json()

      if (!res.ok) {
        setProgress(0)
        setIsLoading(false)
        setIsFileUploaded(false)
        setError(json.message)
        return
      }

      setProgress(0)
      setError(undefined)
      setIsLoading(false)
      setIsFileUploaded(true)
    } catch (err: any) {
      setProgress(0)
      setIsLoading(false)
      setIsFileUploaded(false)
      setError('Une erreur est survenue durant l’envoi')
      console.error(err)
      return
    }
  }

  return (
    <div className="page page--black page--v-center">
      <section className="container">
        <div className="row">
          <div className="col col--center col-md-6 col-lg-4">
            <form className="box box--padding ft-center" onSubmit={onSubmit}>
              <h2>Transfert sécurisé</h2>

              {!isFileUploaded && error && error.length > 0 && (
                <div className="mt-2 mb-2">
                  <p className="mb-0 c-red">{error}</p>
                </div>
              )}

              {isLoading && (
                <div className="mt-2 mb-2">
                  Envoi du fichier en cours : {progress} %
                  <Loader />
                </div>
              )}

              {isFileUploaded && (
                <div className="mt-3 mb-3">
                  <div className="mb-2">
                    Merci ! Le fichier <strong>{fileName}</strong> a bien été transmis à l'équipe
                    SendRaise.
                  </div>

                  <Button className="btn--full f-jc-center mt-2" type="button" onClick={resetForm}>
                    Envoyer un autre fichier
                  </Button>
                </div>
              )}

              {!isLoading && !isFileUploaded && (
                <>
                  <div className="mt-2 mb-2">
                    Sélectionnez un fichier à envoyer à l'équipe SendRaise (max. 1 Go).
                  </div>

                  <InputText
                    className="mb-2"
                    name="name"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    placeholder="Votre nom"
                    value={name}
                    required
                  />

                  <InputText
                    className="mb-3"
                    name="email"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    placeholder="Votre email"
                    value={email}
                    required
                  />

                  <InputText
                    name="file"
                    type="file"
                    onChange={onFileChange}
                    placeholder="Sélectionner un fichier à envoyer"
                    required
                    className="input-file-transfer mb-3"
                  />

                  <Button className="btn--full f-jc-center" type="submit">
                    Envoyer le fichier
                  </Button>
                </>
              )}
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(FileTransfer)
