import { Fragment, ReactNode, cloneElement, useEffect, useState } from 'react'

// remove all accents from string
export const unaccent = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const getErrorByName = (name: string, errors: any) => {
  if (errors && errors.statusCode === 409) {
    return null
  }

  if (errors) {
    for (const error of errors) {
      if (error.context && error.context.key === name) return error
      if (error.context && error.context.present && error.context.present[0] === name) {
        return error
      }
    }
  }
  return null
}

export const formatDateLocale = (dateString: string, config?: Intl.DateTimeFormatOptions) => {
  let date = new Date(dateString)

  if (date) return date.toLocaleDateString('fr-FR', config)

  return `Err parsing ${dateString}`
}

export function getNextWeek() {
  const today = new Date()
  const nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  ).toLocaleDateString()
  return nextweek
}

export const roundToDigits = (nb: any, digits: number = 2) => {
  if (nb) {
    return Math.round(nb * 10 ** digits) / 10 ** digits
  } else {
    return 0
  }
}

export const formatTimestamp = (timestamp: number) => {
  var date = new Date(timestamp)

  // return formattedTime
  return `${date.toLocaleDateString([], {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  })} à ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`
}

/**
 * Rounds a number to the specified decimals
 * @param num The number to round
 * @param places The requested number of digits
 * @returns The rounded number
 */
export const roundDecimals = (num: number, places: number) => {
  const factor = 10 ** places
  return Math.round(num * factor) / factor
}

/**
 * Formats a number with the specified currency
 * @param value The amount as number or string
 * @param currency The currency code (EUR, USD...)
 * @returns A formatted string with amount and currency
 */
export const formatCurrencySymbol = (value: number | string, currency: string = 'EUR') => {
  if (value === undefined || value === null || value === '-' || value === '' || isNaN(Number(value))) {
    return '-'
  }

  if (typeof value === 'string') {
    value = Number(value).toFixed(2)
  }
  // @ts-ignore
  if (!Intl.supportedValuesOf('currency').includes(currency)) {
    if (typeof value === 'number') {
      value = value.toFixed(2)
    }
    return value.toString().replace('.', ',') + ' ' + currency
  }

  const formattedValue = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency,
    signDisplay: 'exceptZero',
  }).format(+value)

  // Workaround: 'negative' is not supported for signDisplay property, so we remove positive sign manually
  return formattedValue.replace('+', '')
}

export const formatPercent = (value: number | string) => {
  if (typeof value === 'string') {
    value = Number(value)
  }

  return new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  }).format(value / 100)
}

export function formatCountry(country: string, withEmoji?: boolean) {
  let currentCountry = '-'

  switch (country) {
    case 'FRA':
      currentCountry = `France ${withEmoji ? '🇫🇷' : ''}`
      break
    case 'LUX':
      currentCountry = `Luxembourg`
      break
    default:
      throw new Error('Unknown country format')
  }
  return currentCountry
}

export function getBaseUrl() {
  let baseUrl = ''

  if (typeof window !== 'undefined') {
    baseUrl = window.location.origin
  }

  return baseUrl
}

export function downloadPDFbase64(pdf: string, fileName = 'pdf') {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const downloadRemoteFile = (href: string) => {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = href
  link.target = '_blank'
  link.download = ''
  document.body.appendChild(link)
  link.click()

  setTimeout(() => {
    link.parentNode?.removeChild(link)
  }, 0)
}

/**
 * Checks if the string matches ISIN format
 * @param str The string to test
 * @return True if it matches ISIN format
 */
export const isIsinCode = (str: string | undefined): boolean => {
  return str !== undefined && str.match(/^[A-Z]{2}[A-Z0-9]{9}[0-9]{1}$/) !== null;
};

export function textWithNBSpaceReplaced(originalText: string) {
  return originalText && originalText.replace(/&nbsp;/g, ' ')
}

export function textWithAMPReplaced(originalText: string) {
  return originalText && originalText.replace(/&amp;/g, '&')
}

export function slugify(string: string, seperator?: string) {
  seperator = seperator || '-'
  return string.toLowerCase().replace(/\s/g, seperator)
}

export function CountDecimalDigits(value: number) {
  const char_array = value.toString().split('') // split every single char
  const not_decimal = char_array.lastIndexOf('.')
  return not_decimal < 0 ? 0 : char_array.length - not_decimal
}

// @todo remove if we don't use MorningStar
export function getDocNameDocType(documentType: string) {
  let name = '-'

  // Language (FR) : 454
  // DICI : documentType = 74
  // Prospectus : documentType = 1
  // Rapport mensuel : documentType = 52
  switch (documentType) {
    case '74':
      name = 'DICI'
      break
    case '1':
      name = 'Prospectus'
      break
    case '52':
      name = 'Rapport mensuel'
      break
    default:
      throw new Error('Unknown currency format')
  }
  return name
}

export const debounce = (func: (e: Event) => any, wait: number, immediate: boolean): (() => {}) => {
  let timeout: any, args: any, context: any, timestamp: number, result: any

  const later = () => {
    const now = new Date().getTime(),
      last = now - timestamp

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function () {
    args = arguments
    timestamp = new Date().getTime()
    let callNow = immediate && !timeout
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      // @ts-ignore
      result = func.apply(this.context, args)
      // @ts-ignore
      this.context = args = null
    }

    return result
  }
}

export const useDebounce = <T,>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function fileConvertSize(aSize: any) {
  aSize = Math.abs(parseInt(aSize, 10))
  const def: any = [
    [1, 'octets'],
    [1024, 'ko'],
    [1024 * 1024, 'Mo'],
    [1024 * 1024 * 1024, 'Go'],
    [1024 * 1024 * 1024 * 1024, 'To'],
  ]
  for (var i = 0; i < def.length; i++) {
    if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1]
  }
}

export function getStringExtension(text: string) {
  return text.split('.').pop()
}

export function removeStringExtension(text: string) {
  return text.substring(0, text.indexOf('.'))
}

export const CROP_PROFILE_LIMIT = 254
export const CROP_BLOG_LIMIT = 127
export const cropString = (text = '', limit: number) => {
  return text.length > limit ? text.substring(0, limit) + '...' : text
}

export const parseFileData = (data: any) => {
  if (data === null) {
    return []
  }

  let result: any = []

  data.forEach((doc: any) => {
    let existing = result.filter((v: any) => v.isin === doc.isin)

    // convert file id number to string and remove dupplicates
    doc.files = doc.files
      .map((file: any) => ({
        id: file.id.toString(),
        name: file.name,
        url: file.url,
        date: file.date,
      }))
      // eslint-disable-next-line
      .filter((v: any, i: number, a: any) => {
        if (v.id !== null) {
          return a.findIndex((t: any) => t.name === v.name && t.url === v.url) === i
        }
      })

    if (existing.length > 0) {
      let existingIndex = result.indexOf(existing[0])
      result[existingIndex].files = result[existingIndex].files.concat(doc.files)
    } else {
      if (typeof doc.files === 'string') doc.url = [doc.files]
      result.push(doc)
    }
  })

  return result
}

export const returnAttachmentData = (data: any) => {
  let result = data
    ? data.map((doc: any) => doc && doc.name.split('---')[3] === undefined && doc).filter(Boolean)
    : []

  return result
}

export const capitalize = (str: string) => {
  const separator = str.includes('-') ? '-' : ' '

  return str
    .split(separator)
    .map((word) => `${word.charAt(0).toUpperCase()}${word.toLowerCase().slice(1)}`)
    .join(separator)
}

export const getInitials = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`
}

export const getSearchMethod = (search: string): 'birth' | 'name' => {
  const numbers = search.match(/[0-9]/g)

  if (numbers === null || numbers.length === 0) {
    return 'name'
  }

  return 'birth'
}

export const addJSXSeparatorToArray = ({
  array,
  separator,
}: {
  array: any[]
  separator: ReactNode
}) => {
  return array.reduce<any[]>((acc, curr, index, array) => {
    acc.push(curr)

    if (index === array.length - 1) {
      return acc
    }

    acc.push(separator)

    return acc
  }, [])
}

export const keyifyMixedArray = (array: any[]) => {
  return array.map((el, index) =>
    typeof el === 'string' ? (
      <Fragment key={index}>{el}</Fragment>
    ) : (
      cloneElement(el, { key: index })
    )
  )
}

export const urlFormat = (str: string) => {
  return str.toLowerCase().replaceAll(' ', '-')
}

export const formatMonth = (monthEN: string) => {
  switch (monthEN.toLowerCase()) {
    case 'january':
      return 'Janvier'
    case 'february':
      return 'Février'
    case 'march':
      return 'Mars'
    case 'april':
      return 'Avril'
    case 'may':
      return 'Mai'
    case 'june':
      return 'Juin'
    case 'july':
      return 'Juillet'
    case 'august':
      return 'Août'
    case 'september':
      return 'Septembre'
    case 'october':
      return 'Octobre'
    case 'november':
      return 'Novembre'
    default:
      return 'Décembre'
  }
}

export const loaderAnimated = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    display="block"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
  >
    <rect width="6" height="12" x="47" y="24" fill="#0a0a0a" rx="3" ry="6">
      <animate
        attributeName="opacity"
        begin="-0.9166666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.8333333333333334s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.75s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.6666666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.5833333333333334s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.5s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.4166666666666667s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.3333333333333333s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.25s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.16666666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.08333333333333333s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill="#0a0a0a"
      rx="3"
      ry="6"
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="0s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
  </svg>
)

export { get, post, patch, fetchDelete } from './fetch'
export type { IFetchError } from './fetch'
export { getInsrPrdsFamGroupings, getInsuranceProductsGroupedByFamily } from './insurances'
export type { IInsrPrdsGroupedByFam, IInsuranceProductsGroupedByFamily } from './insurances'
export { getMIMEType } from './miscellaneous'
