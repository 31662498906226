import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const ListIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.5C2 4.94772 2.44772 4.5 3 4.5H4C4.55228 4.5 5 4.94772 5 5.5V6.5C5 7.05228 4.55228 7.5 4 7.5H3C2.44772 7.5 2 7.05228 2 6.5V5.5ZM7 5.99994C7.00003 5.44766 7.44778 4.99997 8.00006 5L21.0001 5.00078C21.5523 5.00082 22 5.44856 22 6.00084C22 6.55313 21.5522 7.00082 20.9999 7.00078L7.99994 7C7.44766 6.99997 6.99997 6.55222 7 5.99994ZM2 11.5C2 10.9477 2.44772 10.5 3 10.5H4C4.55228 10.5 5 10.9477 5 11.5V12.5C5 13.0523 4.55228 13.5 4 13.5H3C2.44772 13.5 2 13.0523 2 12.5V11.5ZM7 11.9999C7.00003 11.4477 7.44778 11 8.00006 11L21.0001 11.0008C21.5523 11.0008 22 11.4486 22 12.0008C22 12.5531 21.5522 13.0008 20.9999 13.0008L7.99994 13C7.44766 13 6.99997 12.5522 7 11.9999ZM2 17.5C2 16.9477 2.44772 16.5 3 16.5H4C4.55228 16.5 5 16.9477 5 17.5V18.5C5 19.0523 4.55228 19.5 4 19.5H3C2.44772 19.5 2 19.0523 2 18.5V17.5ZM7 17.9999C7.00003 17.4477 7.44777 17 8.00006 17L21.0001 17.0007C21.5523 17.0007 22 17.4485 22 18.0008C22 18.5531 21.5522 19.0007 20.9999 19.0007L7.99994 19C7.44766 19 6.99997 18.5522 7 17.9999Z"
      />
    </SvgIcon>
  )
}

export default ListIcon
