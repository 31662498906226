import React, { FC } from 'react'

import { FlagTooltip } from 'component'
import { formatPercent } from 'utils'
import { parseFlagDescription, parseInvalidationFlagDescription } from 'utils/commissions'

import { Box, Stack } from '@mui/material'

interface IDocketPercentCellProps {
  item: any
  field: string
  value?: any
  showErrors?: boolean
  tag?: string
}

const DocketPercentCell: FC<React.PropsWithChildren<IDocketPercentCellProps>> = ({
  item,
  field,
  value = undefined,
  showErrors = true,
  tag = 'td',
}) => {
  if (value === undefined) {
    value = item[field]
  }
  const Element = tag as keyof JSX.IntrinsicElements
  const classNames = ['cell--docket-percent cell-number']

  const provider = item.insuranceFeeReport.insurance.provider
  let formattedValue = '-'
  if (!(provider === 'intencial' && field === 'organizationRate' && value === 0.0)) {
    formattedValue = formatPercent(value) || '-'
  }

  const fieldFlag = parseFlagDescription(item.flagDescription).find(
    (itemFlag) => itemFlag.field === field
  )
  const fieldInvalidationFlag = parseInvalidationFlagDescription(
    item.flagInvalidationDescription
  ).find((itemInvalidationFlag) => itemInvalidationFlag.field === field)

  const hasErrors = fieldFlag !== undefined
  const isInvalidated = fieldInvalidationFlag !== undefined

  return (
    <Element className={classNames.join(' ')} key={`docket-${field}-${item.id}`}>
      <Stack direction="row">
        {showErrors && hasErrors ? (
          <FlagTooltip
            fieldFlag={fieldFlag!}
            fieldInvalidationFlag={fieldInvalidationFlag}
            sx={{ mr: 1, svg: { display: 'block' } }}
          />
        ) : null}
        <Box
          fontWeight={hasErrors && showErrors ? (isInvalidated ? 400 : 500) : 400}
          color={
            hasErrors && showErrors
              ? isInvalidated
                ? 'text.primary'
                : 'warning.main'
              : 'text.primary'
          }
        >
          {formattedValue}
        </Box>
      </Stack>
    </Element>
  )
}

export default DocketPercentCell
