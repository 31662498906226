import { ContractIcon, FileTextIcon, HistoryIcon } from 'icons'
import LayoutMiniSidebar from 'component/Layouts/LayoutMiniSidebar'
import { FC } from 'react'
import LayoutCommissionsContent from './LayoutCommissionsContent'
import LayoutCommissionsHeader from './LayoutCommissionsHeader'
import { IComposition, IProps } from './typescript/layoutCommissions.interfaces'
import {
  REPORTING_ANALYSIS_URL,
  REPORTING_CONFIGURATION_URL,
  REPORTING_HISTORY_URL,
} from 'utils/router/constants'

export type { IProps as ILayoutCommissionProps } from './typescript/layoutCommissions.interfaces'

const LayoutCommission: FC<React.PropsWithChildren<IProps>> & IComposition = ({
  asideContent,
  children,
  className,
}) => {
  return (
    <LayoutMiniSidebar
      links={[
        {
          icon: FileTextIcon,
          to: REPORTING_ANALYSIS_URL,
        },
        {
          icon: ContractIcon,
          to: REPORTING_CONFIGURATION_URL,
        },
        {
          icon: HistoryIcon,
          to: REPORTING_HISTORY_URL,
        },
      ]}
      className={`page commissions ${className || ''} f f-jc-space-b`}
    >
      <div className="commissions__aside">{asideContent}</div>
      <div className="commissions__main f f-direction-column">{children}</div>
    </LayoutMiniSidebar>
  )
}

LayoutCommission.Header = LayoutCommissionsHeader
LayoutCommission.Content = LayoutCommissionsContent

export default LayoutCommission
