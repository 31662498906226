import * as React from 'react'

import { Modal } from 'component'
import { IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'

import { Alert, Box, Stack, styled, Typography } from '@mui/material'
import { AlertCircleIcon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import Loader from 'component/Loader'

import moment from 'moment'
import { capitalize } from 'utils'
import { getOnComplete, revalidateDockets } from '../../core/ModalRevalidateDockets.helpers'
import useQueueProgress from 'utils/hooks/queueProgress'

interface IProps {
  setError: StateSetter<undefined | string>
  selectedInsurance: IAPIInsurance
  selectedPeriod: IAPIReportPeriod
  setSelectedPeriod: StateSetter<IAPIReportPeriod | undefined>
  itemLabel: string
  itemValue: string
  setRevalidatedErrors: StateSetter<number | undefined>
  setStep: StateSetter<number>
  progress: number
  setProgress: StateSetter<number>
}

const StartStep: React.FC<IProps> = (props) => {
  const {
    setError,
    selectedInsurance,
    selectedPeriod,
    setSelectedPeriod,
    itemLabel,
    itemValue,
    setRevalidatedErrors,
    setStep,
    progress,
    setProgress,
  } = props

  const [jobId, setJobId] = React.useState<string>()

  React.useEffect(() => {
    revalidateDockets({
      setError,
      selectedPeriod,
      selectedInsurance,
      commissionType: itemValue,
      setJobId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onComplete = getOnComplete({
    selectedPeriod,
    setSelectedPeriod,
    itemValue,
    setRevalidatedErrors,
    setProgress,
    setStep,
  })

  const onError = (error?: string) => {
    setError(
      error ||
        'Une erreur est survenue pendant la revalidation, veuillez réessayer. Si le problème persiste contactez le service client Sendraise.'
    )
    setStep(2)
    setProgress(0)
  }

  useQueueProgress({
    interval: 1000,
    queueId: 'process-insurance-revalidate',
    jobId,
    setJobId,
    setProgress,
    onComplete,
    onError,
  })

  return (
    <Modal.Content>
      <Stack flexDirection="row" alignItems="center" mb={2}>
        <img
          src={getProviderLogo(selectedInsurance.provider)}
          alt={`${selectedInsurance.name} logo`}
          width={32}
        />
        <Typography variant="h2" color="text.primary" ml={1.5}>
          {capitalize(
            moment(selectedPeriod.year + '-' + selectedPeriod.month + '-1').format('MMMM YYYY')
          )}
        </Typography>
      </Stack>
      <StyledBox
        sx={{
          py: 3,
          px: 4,
          mb: 2,
          textAlign: 'center',
        }}
      >
        <Loader />
        <Typography sx={{ mb: 0.5 }} fontWeight={500} color="text.primary" variant="body2">
          {`Recalcul des erreurs (${progress}%)`}
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle1">
          {itemLabel}
        </Typography>
      </StyledBox>
      <Stack alignItems="center" justifyContent="center" mt={1} mb={4}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert
            icon={<AlertCircleIcon sx={{ color: '#B45B05' }} />}
            sx={{
              backgroundColor: '#F8EFE6',
            }}
          >
            <Typography variant="subtitle1" color="text.secondary" sx={{ color: '#B45B05' }}>
              Le recalcul des erreurs nécessite le blocage de l'application. Vous devez attendre la
              fin du processus avant de pouvoir utiliser Sendraise.
            </Typography>
          </Alert>
        </Stack>
      </Stack>
    </Modal.Content>
  )
}

export default StartStep

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
}))
