import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Box, Button, Paper, Snackbar, Stack, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { BaseInput } from 'component/Inputs'
import HelpContainer from 'component/HelpContainer'
import { ArrowRightIcon, SearchIcon } from 'icons'
import { useDebounce } from 'utils'
import { useFormik } from 'formik'
import { schemaAddress, schemaOrgnizationAddress } from 'utils/schema'
import { IOrganizationPayload, putOrganization } from '../core/Subscription.service'
import SubscriptionError from '../SubscriptionError'
import { SubscriptionContext } from '../SubscriptionManager'
import { SUBSCRIPTION_OPTIN_URL } from 'utils/router/constants'
import Loader from 'component/Loader'

function SubscriptionAddress() {
  let history = useHistory()
  const { registration, setRegistration } = useContext(SubscriptionContext)
  const [step, setStep] = useState(0)
  const [isAddressValid, setAddressIsValid] = useState(false)
  const [search, setSearch] = React.useState<string>('')
  const [options, setOptions] = React.useState([])
  const [error, setError] = useState<string | null>(null)
  const [addressError, setAddressError] = useState<string | null>(null)
  const [searching, setSearching] = useState(false)
  const [addressValidating, setAddressValidating] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [prefilledAddress, setPrefilledAddress] = useState(false)
  const [selectedAddress, setSelectedAddress] = React.useState({
    organizationAddress: '',
    organizationZipCode: '',
    organizationCity: '',
    organizationCountry: '',
  })

  // Organization FORM
  const formik = useFormik({
    initialValues: {
      organizationName: '',
      organizationSiret: '',
      userFirstName: '',
      userLastName: '',
      userPhone: '',
    },
    validationSchema: schemaOrgnizationAddress,
    onSubmit: async (values: IOrganizationPayload) => {
      updateOrganization(values)
    },
    validateOnBlur: true,
  })

  const hasRegistrationAddress = useMemo(() => {
    return (
      registration?.organizationAddress &&
      registration?.organizationZipCode &&
      registration?.organizationCity &&
      registration?.organizationCountry
    )
  }, [registration])

  useEffect(() => {
    if (registration) {
      formik.setValues({
        organizationName: registration?.organizationName || '',
        organizationSiret: registration?.organizationSiret || '',
        userFirstName: registration?.userFirstName || '',
        userLastName: registration?.userLastName || '',
        userPhone: registration?.userPhone || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration])

  useEffect(() => {
    if (hasRegistrationAddress) {
      const fullAddress = `${registration?.organizationAddress || ''} ${
        registration?.organizationCity
      } ${registration?.organizationZipCode || ''} ${registration?.organizationCountry || ''}`

      setSearch(fullAddress)
      validateAddress({
        organizationAddress: registration?.organizationAddress || '',
        organizationZipCode: registration?.organizationZipCode || '',
        organizationCity: registration?.organizationCity || '',
        organizationCountry: registration?.organizationCountry || '',
      })
    }
  }, [registration, hasRegistrationAddress])

  const updateOrganization = async (values: IOrganizationPayload) => {
    if (!registration) return
    setError(null)
    try {
      await putOrganization(values)
      setRegistration({ ...registration, ...values })
      if (step === 1) {
        history.push(SUBSCRIPTION_OPTIN_URL)
      } else {
        setStep(1)
      }
    } catch (e: any) {
      setError(e.message)
    }
  }

  const prefillAddressInput = useCallback(
    (options: any) => {
      if (hasRegistrationAddress && options.length && !value && !prefilledAddress) {
        setValue(options[0])
        setPrefilledAddress(true)
      }
    },
    [hasRegistrationAddress, value, prefilledAddress]
  )

  // Search FORM
  const debouncedSearch = useDebounce(search, 800)

  const formatedSelectedAddress = useMemo(() => {
    return `${selectedAddress?.organizationAddress || ''} ${
      selectedAddress?.organizationZipCode || ''
    } ${selectedAddress?.organizationCity}`
  }, [selectedAddress])

  useEffect(() => {
    if (search.length && !addressValidating && search !== formatedSelectedAddress) {
      setSearching(true)
      setAddressIsValid(false)
    } else if (!search.length) {
      setOptions([])
      setSearching(false)
      setAddressIsValid(false)
      setValue(null)
    }
  }, [search, addressValidating, formatedSelectedAddress])

  React.useEffect(() => {
    if (debouncedSearch !== '' && debouncedSearch !== formatedSelectedAddress) {
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${debouncedSearch}`)
        .then((response) => response.json())
        .then((data: any) => {
          setSearching(false)
          setOptions(data.features)
          prefillAddressInput(data.features)
        })
        .catch(() => {
          setOptions([])
          setSearching(false)
        })
    }
  }, [debouncedSearch, formatedSelectedAddress, prefillAddressInput])

  const validateAddress = async (address: {
    organizationAddress: string
    organizationZipCode: string
    organizationCity: string
    organizationCountry: string
  }) => {
    try {
      setAddressValidating(true)

      setAddressError(null)
      await schemaAddress.validate(address)
      setAddressIsValid(true)
      setSelectedAddress(address)
    } catch (err: any) {
      setAddressIsValid(false)
      setAddressError(err.message)
    } finally {
      setAddressValidating(false)
    }
  }

  const onAddressSelected = async (_event: any, value: any) => {
    setSearching(false)

    if (value?.properties) {
      const address = {
        organizationAddress: value.properties.name,
        organizationZipCode: value.properties.postcode,
        organizationCity: value.properties.city,
        organizationCountry: 'FR',
      }
      setValue(value)
      validateAddress(address)
    }
  }

  const handleSiretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    value = value.replace(/[^0-9]+/g, '')
    e.target.value = value
    return formik.handleChange(e)
  }

  const next = () => {
    updateOrganization(selectedAddress)
  }

  return (
    <>
      <Box sx={{ ml: 6 }}>
        <Typography variant="body2" color="text.secondary" mb={0.5}>
          Étape 3
        </Typography>
        <Typography variant="h2b" mb={4} component="h2">
          Coordonnées du cabinet
        </Typography>
      </Box>

      {step === 0 && (
        <Paper elevation={2} sx={{ p: '32px 48px', mb: 4 }}>
          {error ? (
            <SubscriptionError error={error} clearError={() => setError(null)} />
          ) : (
            <form onSubmit={formik.handleSubmit} id="form-info">
              <BaseInput
                id="organizationName"
                label="Nom du cabinet"
                placeholder="John Doe Consulting"
                fullWidth
                sx={{ mb: 3 }}
                value={formik.values.organizationName}
                onChange={formik.handleChange}
                error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                helperText={formik.touched.organizationName && formik.errors.organizationName}
              />
              <BaseInput
                id="organizationSiret"
                label="N° de SIRET"
                placeholder="12345678900000"
                sx={{ mb: 3 }}
                value={formik.values.organizationSiret}
                onChange={handleSiretChange}
                onBlur={formik.handleBlur}
                error={formik.touched.organizationSiret && Boolean(formik.errors.organizationSiret)}
                helperText={formik.touched.organizationSiret && formik.errors.organizationSiret}
              />

              <Typography variant="subtitle1" fontWeight={500} mb={1.5}>
                Contact principal
              </Typography>

              <Stack direction="row" gap={2}>
                <BaseInput
                  id="userFirstName"
                  label="Prénom"
                  placeholder="John"
                  fullWidth
                  sx={{ mb: 3 }}
                  value={formik.values.userFirstName}
                  onChange={formik.handleChange}
                  error={formik.touched.userFirstName && Boolean(formik.errors.userFirstName)}
                  helperText={formik.touched.userFirstName && formik.errors.userFirstName}
                />
                <BaseInput
                  id="userLastName"
                  label="Nom"
                  placeholder="Doe"
                  fullWidth
                  sx={{ mb: 3 }}
                  value={formik.values.userLastName}
                  onChange={formik.handleChange}
                  error={formik.touched.userLastName && Boolean(formik.errors.userLastName)}
                  helperText={formik.touched.userLastName && formik.errors.userLastName}
                />
              </Stack>

              <BaseInput
                id="userPhone"
                label="Numéro de téléphone"
                placeholder="0144556677"
                value={formik.values.userPhone}
                onChange={formik.handleChange}
                error={formik.touched.userPhone && Boolean(formik.errors.userPhone)}
                helperText={formik.touched.userPhone && formik.errors.userPhone}
                type="tel"
              />
            </form>
          )}
        </Paper>
      )}

      {step === 1 && (
        <Paper elevation={2} sx={{ p: '32px 48px', mb: 4 }}>
          {error ? (
            <SubscriptionError error={error} clearError={() => setError(null)} />
          ) : (
            <>
              <HelpContainer
                message={
                  <>
                    <b>Saisissez les premières informations</b> de votre adresse et <br />
                    <b>sélectionnez le résultat correspondant</b> dans les suggestions.
                    <br />
                    <p className="mt-1 mb-0">
                      Nous acceptons uniquement des cabinets domiciliés en France.
                    </p>
                  </>
                }
                size="small"
                light={true}
                alignItems="flex-start"
                sx={{ mb: 1.5 }}
              />
              <Autocomplete
                autoComplete
                options={options}
                value={value}
                filterOptions={(address) => address}
                noOptionsText={
                  search.length && !searching ? 'Aucune adresse ne correspond à votre saisie' : null
                }
                onChange={onAddressSelected}
                onInputChange={(_event, newInputValue) => {
                  if (_event?.type === 'blur' && search !== formatedSelectedAddress) {
                    setValue(null)
                    setSearch('')
                    setOptions([])
                    setSelectedAddress({
                      organizationAddress: '',
                      organizationZipCode: '',
                      organizationCity: '',
                      organizationCountry: '',
                    })
                  } else {
                    setSearch(newInputValue)
                  }
                }}
                forcePopupIcon={false}
                renderInput={(params) => (
                  <BaseInput
                    {...params}
                    label={
                      <Stack direction="row" alignItems="center">
                        <p style={{ margin: '0 8px 0 0 ' }}>Rechercher une adresse </p>
                        {searching && <Loader />}
                      </Stack>
                    }
                    placeholder="12, rue de Parme"
                    startIcon={<SearchIcon />}
                  />
                )}
                getOptionLabel={(option: any) => option.properties.label}
              />
            </>
          )}
        </Paper>
      )}

      {!error && (
        <Box textAlign="right">
          {step === 0 && (
            <Button
              color="secondary"
              type="submit"
              endIcon={<ArrowRightIcon />}
              form="form-info"
              disabled={!formik.isValid || !formik.dirty}
            >
              Continuer
            </Button>
          )}
          {step === 1 && (
            <Button
              color="secondary"
              onClick={() => next()}
              endIcon={<ArrowRightIcon />}
              disabled={!isAddressValid}
            >
              Continuer
            </Button>
          )}
        </Box>
      )}
      <Snackbar
        open={!!addressError}
        autoHideDuration={6000}
        onClose={() => setAddressError(null)}
        message={addressError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  )
}

export default SubscriptionAddress
