import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const AlertTriangleIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7571 2.41967C11.5467 2.06022 12.4533 2.06022 13.2429 2.41967C13.8037 2.67495 14.1973 3.14368 14.5285 3.63377C14.8583 4.12188 15.2195 4.77852 15.6563 5.57279L21.2914 15.8185C21.7008 16.5628 22.0412 17.1817 22.2648 17.694C22.491 18.2126 22.6666 18.7685 22.5961 19.3569C22.496 20.1925 22.0496 20.9476 21.3657 21.438C20.884 21.7834 20.3123 21.8973 19.7489 21.949C19.1923 22 18.486 22 17.6365 22H6.36352C5.51401 22 4.80772 22 4.25106 21.949C3.68765 21.8973 3.11595 21.7834 2.63432 21.438C1.95043 20.9476 1.50395 20.1925 1.40384 19.3569C1.33333 18.7685 1.50898 18.2126 1.73522 17.694C1.95876 17.1817 2.29916 16.5628 2.70858 15.8184L8.34368 5.57281C8.78051 4.77854 9.14165 4.12189 9.47152 3.63377C9.80272 3.14368 10.1963 2.67495 10.7571 2.41967ZM12.4143 4.23995C12.1511 4.12013 11.8489 4.12013 11.5857 4.23995C11.5425 4.25962 11.4055 4.3439 11.1286 4.75363C10.8563 5.15649 10.539 5.73146 10.0723 6.57991L4.48438 16.7398C4.04561 17.5376 3.75075 18.0757 3.56835 18.4938C3.38441 18.9154 3.38365 19.069 3.38963 19.119C3.42301 19.3975 3.57183 19.6492 3.7998 19.8127C3.84068 19.842 3.97565 19.9153 4.4337 19.9574C4.88793 19.999 5.50158 20 6.41206 20H17.5879C18.4984 20 19.1121 19.999 19.5663 19.9574C20.0243 19.9153 20.1593 19.842 20.2002 19.8127C20.4282 19.6492 20.577 19.3975 20.6103 19.119C20.6163 19.069 20.6156 18.9154 20.4316 18.4938C20.2492 18.0757 19.9544 17.5376 19.5156 16.7398L13.9277 6.57991C13.461 5.73147 13.1436 5.15649 12.8714 4.75363C12.5945 4.3439 12.4575 4.25962 12.4143 4.23995ZM12 9.00001C12.5523 9.00001 13 9.44772 13 10V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V10C11 9.44772 11.4477 9.00001 12 9.00001ZM11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
      />
    </SvgIcon>
  )
}

export default AlertTriangleIcon
