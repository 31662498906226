import store from 'store'

export interface IFetchError {
  perimeter: IFetchErrorPerimeter
  details: any
}

export type IFetchErrorPerimeter = 'fetch' | 'parse' | 'api'

export const get = <T>({
  url,
  headers,
  errorPattern,
}: {
  url: string
  headers?: HeadersInit
  errorPattern?: RegExp
}): Promise<T> => {
  return customFetch<T>({ url, headers, errorPattern, method: 'GET' })
}

export const post = <T>({
  url,
  headers,
  errorPattern,
  body,
}: {
  url: string
  headers?: HeadersInit
  errorPattern?: RegExp
  body: BodyInit
}): Promise<T> => {
  return customFetch<T>({ url, headers, errorPattern, method: 'POST', body })
}

export const patch = <T>({
  url,
  headers,
  errorPattern,
  body,
}: {
  url: string
  headers?: HeadersInit
  errorPattern?: RegExp
  body: BodyInit
}) => {
  return customFetch<T>({ url, headers, errorPattern, method: 'PATCH', body })
}

export const fetchDelete = <T>({
  url,
  headers,
  errorPattern,
}: {
  url: string
  headers?: HeadersInit
  errorPattern?: RegExp
}) => {
  return customFetch<T>({ url, headers, errorPattern, method: 'DELETE' })
}

const customFetch = async <T>({
  url,
  headers: customHeaders,
  errorPattern = /^(4|5)/,
  method,
  body,
}: {
  url: string
  headers?: HeadersInit
  errorPattern?: RegExp
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  body?: BodyInit
}): Promise<T> => {
  const headers = {
    'X-CSRF-Token': store.getState().user.CSRFToken,
    ...customHeaders,
  }

  const errorRegExp = new RegExp(errorPattern)

  const rawRes = await fetch(url, {
    method,
    credentials: 'include',
    headers,
    body,
  }).catch((err) => {
    const error: IFetchError = {
      perimeter: 'fetch',
      details: err,
    }

    throw error
  })

  if (errorRegExp.test(rawRes.status.toString())) {
    const jsonErr = await rawRes.json().catch()

    const error: IFetchError = {
      perimeter: 'api',
      details: jsonErr || rawRes,
    }

    throw error
  }

  const jsonRes =
    (rawRes.status !== 204 && url.indexOf('/v1/logout') === -1)
      ? await rawRes.json().catch((err) => {
          const error: IFetchError = {
            perimeter: 'parse',
            details: err,
          }

          throw error
        })
      : null

  return jsonRes
}
