import store from 'store'
import { USER_UPDATE_CSRF_TOKEN } from 'store/constants/user'

class API {
  dispatchCSRFToken(token: string) {
    store.dispatch({
      type: USER_UPDATE_CSRF_TOKEN,
      CSRFToken: token,
    })
  }

  getCSRFToken = () => store.getState().user.CSRFToken

  get = (url: string) =>
    fetch(url, { credentials: 'include', headers: { 'X-CSRF-Token': this.getCSRFToken() } })

  fetchByMethod = (url: string, method: string, params: any, paramStringify = true) => {
    let headers: HeadersInit = {
      'X-CSRF-Token': this.getCSRFToken(),
    }

    if (paramStringify) {
      headers['Content-Type'] = 'application/json'
    }

    return fetch(url, {
      method: method,
      credentials: 'include',
      headers: headers,
      body: paramStringify ? JSON.stringify(params) : params,
    })
  }

  post = (url: string, params: any, paramStringify?: boolean) =>
    this.fetchByMethod(url, 'POST', params, paramStringify)

  postUpload = async (
    url: string,
    params: FormData,
    onProgress: (progress: number) => void
  ): Promise<Response> => {
    // send POST request to server
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest()
      request.open('POST', url)
      request.setRequestHeader('X-CSRF-Token', this.getCSRFToken())
      request.withCredentials = true

      request.upload.addEventListener('progress', function (e) {
        let completed = e.loaded / e.total
        onProgress(completed)
      })

      request.addEventListener('error', function (err) {
        reject(err)
      })

      request.addEventListener('load', function (e) {
        const res = new Response(request.response, { status: request.status })

        if (request.status >= 100 && request.status < 299) {
          resolve(res)
        } else {
          reject(res)
        }
      })

      request.send(params)
    })
  }

  patch = (url: string, params: any) => this.fetchByMethod(url, 'PATCH', params)

  put = (url: string, params: any) => this.fetchByMethod(url, 'PUT', params)

  delete = (url: string) => this.fetchByMethod(url, 'DELETE', {})
}

export const customFetch = new API()
