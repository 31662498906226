import * as React from 'react'

import { Modal } from 'component'
import Loader from 'component/Loader'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'
import useQueueProgress from 'utils/hooks/queueProgress'

import { generateErrorReports } from '../../core/ModalExportErrors.helpers'

import { Box, Stack, styled, Typography } from '@mui/material'

interface IProps {
  setErrorReportGenerationError: StateSetter<string | undefined>
  insuranceId: number
  periodYear: number
  periodMonth: number
  selectedCommissionsTypes: IAPIInsuranceFeeCommissionType[]
  goToNextStep: () => void
  setErrorReportFileUrl: StateSetter<string | undefined>
}

const GenerationStep: React.FC<IProps> = (props) => {
  const {
    setErrorReportGenerationError,
    insuranceId,
    periodMonth,
    periodYear,
    selectedCommissionsTypes,
    goToNextStep,
    setErrorReportFileUrl,
  } = props

  const [jobId, setJobId] = React.useState<string>()
  const [progress, setProgress] = React.useState<number>(0)

  React.useEffect(() => {
    generateErrorReports({
      setErrorReportGenerationError,
      insuranceId,
      periodMonth,
      periodYear,
      selectedCommissionsTypes,
      setJobId,
      goToNextStep,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useQueueProgress({
    interval: 1000,
    queueId: 'process-insurance-export',
    jobId,
    setJobId,
    setProgress,
    onComplete: (data) => {
      setErrorReportFileUrl(data.fileUrl)
      goToNextStep()
    },
    onError: () => {
      setErrorReportGenerationError(
        `Erreur lors de l'export de vos erreurs, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )
    },
  })

  return (
    <>
      <Modal.Content>
        <Wrapper justifyContent="center" alignItems="center">
          <Box mb={2}>
            <Loader />
          </Box>
          <Typography variant="body2" mb={0.5} sx={{ color: 'text.primary' }}>
            Génération des fichiers en cours : {progress}%
          </Typography>
        </Wrapper>
      </Modal.Content>
    </>
  )
}

export default GenerationStep

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(6),
}))
