import { FC } from 'react'
import { IProps } from './typescript/layoutCommissionsHeader.interfaces'

export type { IProps as ILayoutCommissionsHeaderProps } from './typescript/layoutCommissionsHeader.interfaces'

const LayoutCommissionsHeader: FC<React.PropsWithChildren<IProps>> = ({ children, className }) => {
  return <div className={`commissions__header ${className || ''}`}>{children}</div>
}

export default LayoutCommissionsHeader
