import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const SearchIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 4C6.91015 4 4 6.91015 4 10.5C4 14.0899 6.91015 17 10.5 17C12.2921 17 13.9148 16.2748 15.0906 15.1018C16.2702 13.9251 17 12.2978 17 10.5C17 6.91015 14.0899 4 10.5 4ZM17.1701 15.7694C18.3159 14.3209 19 12.4903 19 10.5C19 5.80558 15.1944 2 10.5 2C5.80558 2 2 5.80558 2 10.5C2 15.1944 5.80558 19 10.5 19C12.4834 19 14.3081 18.3207 15.7542 17.182L20.294 21.7082C20.6851 22.0981 21.3182 22.0972 21.7082 21.706C22.0981 21.3149 22.0972 20.6818 21.706 20.2918L17.1701 15.7694Z"
      />
    </SvgIcon>
  )
}

export default SearchIcon
