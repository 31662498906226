import { Components, Theme } from '@mui/material'

const MuiTab: NonNullable<Components<Theme>['MuiTab']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.subtitle1,
      fontWeight: 400,
      color: theme.palette.text.secondary,
      padding: `${theme.spacing(1)} 0`,
      minWidth: 'unset',
      maxWidth: 'unset',

      '&.Mui-selected': {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
    }),
  },
}

export default MuiTab
