import * as React from 'react'

import { Icon, Modal } from 'component'
import { IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'

import { Alert, Stack, Typography } from '@mui/material'

import { getProviderLogo } from 'utils/commissions'

import moment from 'moment'
import { capitalize } from 'utils'

interface IProps {
  selectedInsurance: IAPIInsurance
  selectedPeriod: IAPIReportPeriod
  itemLabel: string
  revalidatedErrors: number | undefined
  error: string | undefined
  onClose: () => void
}

const FinalStep: React.FC<IProps> = (props) => {
  const { selectedInsurance, selectedPeriod, itemLabel, revalidatedErrors, error, onClose } = props

  const iconType =
    revalidatedErrors && revalidatedErrors > 0 ? 'icon-alert-circle' : 'icon-check-circle'
  const counterClassName =
    'commission-count commission-count-' +
    (revalidatedErrors && revalidatedErrors > 0 ? 'error' : 'success')

  return (
    <Modal.Content>
      <Stack alignItems="center" mb={2}>
        <img
          src={getProviderLogo(selectedInsurance.provider)}
          alt={`${selectedInsurance.name} logo`}
          width={32}
        />
        <Typography variant="h2" color="text.primary">
          {capitalize(
            moment(selectedPeriod.year + '-' + selectedPeriod.month + '-1').format('MMMM YYYY')
          )}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Traitement terminé.
        </Typography>
        {revalidatedErrors !== undefined && error === undefined ? (
          <div className="commission-item" onClick={onClose}>
            <div className="commission-label">{itemLabel}</div>
            <div className="commission-separator">
              <div></div>
            </div>
            <div className={counterClassName}>
              <div>
                {revalidatedErrors} erreur{revalidatedErrors > 1 && 's'}
              </div>
              <Icon className={iconType} />
            </div>
            <div className="commission-arrow">
              <Icon className="icon-arrow-right" />
            </div>
          </div>
        ) : (
          <Stack flexDirection="row" sx={{ alignItems: 'center', mt: 4 }}>
            <Alert variant="outlined" severity="error">
              <Typography variant="subtitle1" color="text.secondary" sx={{ color: '#B45B05' }}>
                La re-vérification a échoué. Si le problème persiste, contactez le support
                Sendraise.
              </Typography>
            </Alert>
          </Stack>
        )}
      </Stack>
    </Modal.Content>
  )
}

export default FinalStep
