import * as React from 'react'

import { CircularProgress, Stack, Typography, styled } from '@mui/material'

interface IProps {
  stat: number | undefined
  loading: boolean
}

const ClientStat: React.FC<IProps> = (props) => {
  const { stat, loading } = props

  return (
    <Wrapper alignItems="center" justifyContent="center">
      {loading ? (
        <LoaderWrapper alignItems="center" justifyContent="center">
          <CircularProgress sx={{ color: 'text.primary' }} />
        </LoaderWrapper>
      ) : stat !== undefined ? (
        <>
          <Typography mb={0.5} variant="h1" component="p">
            {stat}
          </Typography>
          <Typography variant="body2">client{stat > 1 ? 's' : null}</Typography>
        </>
      ) : null}
    </Wrapper>
  )
}

export default ClientStat

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '4px 0 0 4px',
  background: theme.palette.background.background02,
  color: theme.palette.text.primary,
  height: '100%',
}))

const LoaderWrapper = styled(Stack)(({ theme }) => ({
  height: 100,
}))
