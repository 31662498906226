import { Stack, Typography } from '@mui/material'
import { AlertTriangleIcon } from 'icons'

function Alert({ message }: { message: string }) {
  return (
    <Stack
      direction="row"
      bgcolor="warningLight.main"
      color="warningLight.contrastText"
      px="12px"
      py={1}
      borderRadius={1}
      mb={4}
    >
      <AlertTriangleIcon sx={{ mr: 1 }} />
      <Typography variant="subtitle1" component="p" color="warningLight.contrastText">
        {message}
      </Typography>
    </Stack>
  )
}

export default Alert
