import * as React from 'react'
import moment from 'moment'

import { RouteComponentProps } from 'react-router-dom'
import { IAPIFrozenAssetEntity } from 'api/interfaces'
import { addJSXSeparatorToArray, keyifyMixedArray } from 'utils'
import { FROZEN_ASSETS_ENTITIES_SEARCH_URL } from 'utils/router/constants'

import { IFaeRow } from '..'
import { getFae } from './Search.service'

import { Typography } from '@mui/material'

export const handleSearchClick = ({
  search,
  history,
}: {
  search: string
  history: RouteComponentProps['history']
}): React.MouseEventHandler => {
  return () => {
    if (search === '') {
      return
    }

    const searchParams = new URLSearchParams()

    searchParams.append('r', search)

    history.push({
      pathname: FROZEN_ASSETS_ENTITIES_SEARCH_URL,
      search: `?${searchParams.toString()}`,
    })
  }
}

export const handleSearchChange = (
  setSearch: React.Dispatch<React.SetStateAction<string>>
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    setSearch(e.target.value)
  }
}
export const handleSearchReset = (
  setSearch: React.Dispatch<React.SetStateAction<string>>
): React.MouseEventHandler => {
  return () => {
    setSearch('')
  }
}

export const handleSeeDetailsClick = ({
  setFaeModalOpen,
  setSelectedFae,
  fae,
}: {
  setFaeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedFae: React.Dispatch<React.SetStateAction<IFaeRow | undefined>>
  fae: IFaeRow
}): React.MouseEventHandler => {
  return () => {
    setFaeModalOpen(true)
    setSelectedFae(fae)
  }
}

export const getNoContentMessage = (debouncedSearch: string): string => {
  return debouncedSearch === ''
    ? `Aucune entité n'a été importée pour le moment`
    : `Aucun entité ne correspond à vos critères de recherche`
}

export const getRows = (faes: IAPIFrozenAssetEntity[]): IFaeRow[] =>
  faes.map<IFaeRow>((fae, index) => {
    const {
      idRegistre,
      firstNames,
      name,
      nature,
      titles,
      aliases,
      genders,
      datesOfBirth,
      placesOfBirth,
      reasons,
      legalGrounds,
      UEReferences,
    } = fae

    return {
      id: idRegistre,
      fullName: firstNames ? `${name} ${firstNames.join(' ')}` : name,
      name,
      nature,
      firstNames,
      titles,
      aliases,
      genders,
      datesOfBirth: datesOfBirth
        ? datesOfBirth.map((date) => moment(date).format('DD/MM/YYYY'))
        : null,
      placesOfBirth,
      reasons,
      legalGrounds,
      UEReferences,
    }
  })

export const handleModalClose = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
): React.MouseEventHandler => {
  return () => {
    setModalOpen(false)
  }
}

export const displayArrayWithConjunction = ({
  array,
  conjunction,
}: {
  array: any[]
  conjunction: string
}) => {
  const separator = (
    <>
      {' '}
      <Typography
        variant="subtitle1"
        component="span"
        textTransform="none"
        sx={{ color: 'text.secondary' }}
      >
        {conjunction}
      </Typography>{' '}
    </>
  )

  return keyifyMixedArray(
    addJSXSeparatorToArray({
      array,
      separator,
    })
  )
}

export const updateAllData = async ({
  debouncedSearch,
  setLoading,
  page,
  setFaeCount,
  setRows,
}: {
  debouncedSearch: string
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  page: number
  setFaeCount: React.Dispatch<React.SetStateAction<number | undefined>>
  setRows: React.Dispatch<React.SetStateAction<readonly IFaeRow[] | undefined>>
}) => {
  if (debouncedSearch === '') {
    return
  }

  const res = await getFae({ setLoading, debouncedSearch, page })

  if (!res) {
    return
  }

  setFaeCount(res.faeCount)
  setRows(getRows(res.faes))
}

export const updateUrl = ({
  history,
  debouncedSearch,
}: {
  debouncedSearch: string
  history: RouteComponentProps['history']
}) => {
  if (debouncedSearch === '') {
    return
  }

  const searchParams = new URLSearchParams()

  searchParams.append('r', debouncedSearch)

  history.push({
    pathname: FROZEN_ASSETS_ENTITIES_SEARCH_URL,
    search: `?${searchParams.toString()}`,
  })
}
