import React from 'react'
import { Button, Link, Stack } from '@mui/material'

import { getCustomError } from '../core/Subscription.helpers'
import { LOGIN_URL } from 'utils/router/constants'
import { ArrowLeftIcon } from 'icons'
import Alert from 'component/Alert'

function SubscriptionError({ error, clearError }: { error: string; clearError: () => void }) {
  const { status } = JSON.parse(error)

  return (
    <>
      <Alert message={getCustomError(error)} />
      <Stack alignItems="center">
        {status === 409 || status === 410 ? (
          <Button component={Link} href={LOGIN_URL}>
            Me connecter
          </Button>
        ) : (
          <Button
            onClick={clearError}
            color="neutralLight"
            variant="contained"
            startIcon={<ArrowLeftIcon />}
          >
            Précédent
          </Button>
        )}
      </Stack>
    </>
  )
}

export default SubscriptionError
