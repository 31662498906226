import { patch } from 'utils'
import { API_DOCKETS_URL } from 'api/constants'
import { IAPIDocketItem, IAPIDocketItemFlagDescriptionProperty } from 'api/interfaces'

export interface IDocketItemPayloadFlag {
  status: 'enable' | 'disable'
  field: IAPIDocketItemFlagDescriptionProperty
  comment: string | undefined
}

export const patchDocketItem = ({
  docketId,
  docketItemId,
  payload,
}: {
  docketId: number
  docketItemId: number
  payload: IDocketItemPayloadFlag[]
}) => {
  return patch<IAPIDocketItem>({
    url: `${API_DOCKETS_URL}/${docketId}/items/${docketItemId}`,
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  })
}
