import * as React from 'react'
import { Modal } from 'component'
import { Button, CircularProgress, Stack, styled, Typography } from '@mui/material'
import { removeUcitsGridItem } from '../../core/ModalDeleteRateGridItem.helpers'

interface IProps {
  onCloseHandler: () => void
  ucitsGridId: number
  ucitsGridItemId: number
  ucitsGridItemName: string
  ucitsGridItemIsin: string
  setError: StateSetter<string | undefined>
  goToNextStep: () => void
}

const DeletionStep: React.FC<IProps> = (props) => {
  const {
    onCloseHandler,
    ucitsGridId,
    ucitsGridItemId,
    ucitsGridItemName,
    ucitsGridItemIsin,
    setError,
    goToNextStep,
  } = props

  const [loading, setLoading] = React.useState(false)

  const deleteUcitsGridItemClickHandler = () => {
    removeUcitsGridItem({ setError, setLoading, ucitsGridId, ucitsGridItemId, goToNextStep })
  }

  return (
    <>
      <Modal.Content>
        <Typography>
          Êtes-vous sûr de vouloir supprimer l'Unité de Compte{' '}
          <Typography component="span" fontWeight={500}>
            {ucitsGridItemName} ({ucitsGridItemIsin})
          </Typography>
          ?
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <DeleteButton
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ height: `20px !important`, width: `20px !important`, color: 'white' }}
                />
              ) : undefined
            }
            onClick={deleteUcitsGridItemClickHandler}
          >
            Supprimer l'Unité de Compte
          </DeleteButton>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default DeletionStep

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: 'white',

  '&:hover': {
    backgroundColor: '#C6564D',
  },
}))
