import {
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  styled,
  Link,
  Button,
  Tooltip,
  Box,
} from '@mui/material'
import * as React from 'react'
import { getProviderLogo } from 'utils/commissions'
import theme from 'theme'
import {
  AlertCircleIcon,
  AlertOffIcon,
  CheckDoubleIcon,
  FileMissingIcon,
  PlusCircleIcon,
} from 'icons'
import { COMMISSION_KINDS_MAP, ERROR_DIRECTION_MAP } from 'utils/mapping'
import { IAPIClientError, IAPICommissionClient } from 'api/interfaces/entities'
import { REPORTING_ANALYSIS_URL } from 'utils/router/constants'
import moment from 'moment'
import { getCommissionsYears, getDocket } from '../../core/Clients.services'
import { IAPIDocketItem } from 'api/interfaces'
import { ModalErrorHandling } from 'component/Modals'
import EventEmitter, { UPDATE_EVENT_DOCKETS_INVALIDATE } from 'utils/events'
import EmptyBlock from '../EmptyBlock'
import { ModalContext } from 'utils/hooks/modalBehavior'

interface IProps {
  activeErrors: IAPIClientError[]
  deactivatedErrors: IAPIClientError[]
  setActiveErrors: StateSetter<IAPIClientError[]>
  setDeactivatedErrors: StateSetter<IAPIClientError[]>
  client: IAPICommissionClient
}

const COMMISSION_KINDS_ADJUSTMENT_MAP = new Map<string, string>([
  ['encours-contrat', 'Encours contrat'],
  ['opcvm', 'Rétrocessions OPCVM'],
])

const ErrorsTab = ({
  activeErrors,
  deactivatedErrors,
  setActiveErrors,
  setDeactivatedErrors,
  client,
}: IProps) => {
  const [docketItem, setDocketItem] = React.useState<IAPIDocketItem>()
  const [step, setStep] = React.useState(1)
  const [hasCommissions, setHasCommissions] = React.useState(false)
  const modal = React.useContext(ModalContext)

  const getPeriodLink = (error: IAPIClientError) => {
    const year = moment(error.period).format('YYYY')
    const month = moment(error.period).format('M')
    const type = COMMISSION_KINDS_MAP[error.commissionType]

    return `${REPORTING_ANALYSIS_URL}/${error.insurance}/${year}/${month}/${type}?q=${error.contractNumber}`
  }

  const handleErrorDeactivation = async (error: IAPIClientError) => {
    const res = await getDocket({
      reportId: error.insuranceFeeReportId,
      reportItemId: error.insuranceFeeReportItemId,
    })
    if (!res || !res.docket) {
      return
    }
    setDocketItem({
      ...res.docket,
      insuranceFeeReport: {
        id: error.insuranceFeeReportId,
        insurance: {
          id: 0,
          name: error.insurance,
          provider: error.insurance,
          insuranceMessageInfos: [],
          reportCount: 0,
        },
      },
    })
  }

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_DOCKETS_INVALIDATE, (docketItemPayload: IAPIDocketItem) => {
      if (docketItemPayload) {
        const currentError = activeErrors.find(
          (error) => error.insuranceFeeReportItemId === docketItemPayload.id
        )
        setActiveErrors((prevState) =>
          prevState.filter((error) => error.insuranceFeeReportItemId !== docketItemPayload.id)
        )
        setDeactivatedErrors((prevState) =>
          currentError ? [...prevState, currentError] : prevState
        )
        setDocketItem(undefined)
      }
    })
    setDocketItem(undefined)

    return () => EventEmitter.remove(UPDATE_EVENT_DOCKETS_INVALIDATE)
  }, [activeErrors, setActiveErrors, setDeactivatedErrors])

  const isDocketItemWithError = (
    docketItem: IAPIDocketItem
  ): docketItem is SelectiveNonNullable<IAPIDocketItem, 'flagDescription'> => {
    return docketItem.flagDescription !== null
  }

  const fetchCommissionsYear = React.useCallback(async () => {
    if (!client) return
    const res = await getCommissionsYears({ clientId: client.id, filterParams: {} })

    if (!res) {
      return
    }
    setHasCommissions(!!res.years.length)
  }, [client])

  React.useEffect(() => {
    fetchCommissionsYear()
  }, [fetchCommissionsYear])

  return (
    <>
      <List
        sx={{
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          px: 6,
          py: 0,
        }}
      >
        <ListItem sx={{ p: 0, height: 32 }}>
          <ListItemText
            primary="Type de commission"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 1 }}
          />
          <ListItemText
            primary="Opération"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 1 }}
          />
          <ListItemText
            primary="Erreur"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 2 }}
          />
          <ListItemText
            primary="Produit et bordereau"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 2, textAlign: 'right' }}
          />
        </ListItem>
      </List>

      <Wrapper>
        <Typography variant="sectionHeading" component="h3" sx={{ opacity: 0.7 }} mb={2} pl={2}>
          Erreurs actives
        </Typography>

        {!activeErrors.length ? (
          <EmptyBlock
            title={
              hasCommissions
                ? `Bravo, les commissions d’${client.firstName} ${client.lastName} sont en règle !`
                : 'Pas de commission : pas d’erreur !'
            }
            description={
              hasCommissions
                ? 'Le cumul des commissions reflète parfaitement les commissions importées.'
                : 'Importez des bordereaux concernant les contrats de ce client pour voir d’éventuelles erreurs s’afficher ici.'
            }
            icon={
              hasCommissions ? (
                <CheckDoubleIcon sx={{ fontSize: 88, mb: 2 }} />
              ) : (
                <FileMissingIcon sx={{ fontSize: 88, mb: 3 }} />
              )
            }
            cta={
              hasCommissions
                ? undefined
                : {
                    label: 'Ajouter un bordereau',
                    onClick: () => {
                      modal.setCurrentModal({
                        name: 'ModalUploadMultipleDockets',
                        initialSelectedInsurance: undefined,
                        collapsed: false,
                      })
                    },
                    icon: <PlusCircleIcon sx={{ mr: '4px' }} />,
                  }
            }
          />
        ) : (
          activeErrors.map((error, index) => (
            <ListItem
              sx={{
                background: theme.palette.background.default,
                borderRadius: 1,
                mb: 2,
                height: 44,
              }}
              key={index}
            >
              <ListItemText
                primary={
                  COMMISSION_KINDS_ADJUSTMENT_MAP.get(COMMISSION_KINDS_MAP[error.commissionType]) ??
                  COMMISSION_KINDS_MAP[error.commissionType]
                }
                primaryTypographyProps={{ variant: 'subtitle1_m', textTransform: 'capitalize' }}
                sx={{ flex: 1 }}
              />
              <ListItemText
                primary={ERROR_DIRECTION_MAP[error.direction]}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ opacity: 0.7, flex: 1 }}
              />
              <ListItemText
                primary={
                  <Tooltip
                    placement="bottom-start"
                    title={
                      <Box sx={{ width: 300 }}>
                        <Typography component="p" variant="subtitle1_m" color="warning.main" mb={1}>
                          {error.title}
                        </Typography>
                        <Typography
                          component="p"
                          variant="subtitle1"
                          color="neutralLight.contrastText"
                          sx={{ opacity: 0.7 }}
                          mb={2}
                        >
                          {error.body}
                        </Typography>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => handleErrorDeactivation(error)}
                        >
                          Désactiver l'erreur
                        </Button>
                      </Box>
                    }
                  >
                    <Stack direction="row">
                      <AlertCircleIcon color="warning" />
                      <Typography variant="subtitle1" color="warning.main" ml={1}>
                        {error.title}
                      </Typography>
                    </Stack>
                  </Tooltip>
                }
                sx={{ flex: 2 }}
              />
              <ListItemText
                primary={
                  <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    <Typography variant="subtitle1_m" mr={1}>
                      {error.product}
                    </Typography>
                    <img
                      width="24px"
                      height="24px"
                      src={getProviderLogo(error.insurance)}
                      alt={error.insurance}
                    />
                    <Link
                      href={getPeriodLink(error)}
                      color="inherit"
                      variant="subtitle1"
                      ml={1}
                      sx={{ opacity: 0.7 }}
                      textTransform="capitalize"
                      target="_blank"
                    >
                      {moment(error.period).format('MMM YYYY')}
                    </Link>
                  </Stack>
                }
                sx={{ flex: 2 }}
              />
            </ListItem>
          ))
        )}
        <Typography
          variant="sectionHeading"
          component="h3"
          sx={{ opacity: 0.7 }}
          mb={2}
          mt={activeErrors.length > 0 ? 3 : 0}
          pl={2}
        >
          Erreurs désactivées
        </Typography>

        {!deactivatedErrors.length ? (
          <EmptyBlock
            title={`Vous n’avez désactivé aucune erreur sur commissions pour ${client.firstName} ${client.lastName}.`}
            description="C’est une bonne nouvelle : moins d’erreurs, c’est moins de soucis et de traitements. 😉"
            icon={<AlertOffIcon sx={{ fontSize: 88, mb: 2, color: '#E9E1CE' }} />}
          />
        ) : (
          deactivatedErrors.map((error, index) => (
            <ListItem
              sx={{
                border: '1px dashed rgba(66, 65, 59, 0.20)',
                borderRadius: 1,
                height: 44,
                mb: 2,
              }}
              key={index}
            >
              <ListItemText
                primary={
                  COMMISSION_KINDS_ADJUSTMENT_MAP.get(COMMISSION_KINDS_MAP[error.commissionType]) ??
                  COMMISSION_KINDS_MAP[error.commissionType]
                }
                primaryTypographyProps={{ variant: 'subtitle1_m', textTransform: 'capitalize' }}
                sx={{ flex: 1 }}
              />
              <ListItemText
                primary={ERROR_DIRECTION_MAP[error.direction]}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ opacity: 0.7, flex: 1 }}
              />
              <ListItemText
                primary={
                  <Tooltip
                    placement="bottom-start"
                    title={
                      <Box sx={{ width: 300 }}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{ fontWeight: 500 }}
                        >
                          {error.title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          sx={{ fontStyle: 'italic' }}
                        >
                          "{error.body}"
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Erreur désactivée
                        </Typography>
                      </Box>
                    }
                  >
                    <Stack direction="row">
                      <AlertOffIcon sx={{ opacity: 0.5 }} />
                      <Typography variant="subtitle1" sx={{ opacity: 0.7 }} ml={1}>
                        {error.title}
                      </Typography>
                    </Stack>
                  </Tooltip>
                }
                sx={{ flex: 2 }}
              />
              <ListItemText
                primary={
                  <Stack direction="row" justifyContent="flex-end">
                    <Typography variant="subtitle1_m" mr={1}>
                      {error.product}
                    </Typography>
                    <img
                      width="24px"
                      height="24px"
                      src={getProviderLogo(error.insurance)}
                      alt={error.insurance}
                    />
                    <Link
                      href={getPeriodLink(error)}
                      color="inherit"
                      variant="subtitle1"
                      ml={1}
                      sx={{ opacity: 0.7 }}
                      textTransform="capitalize"
                      target="_blank"
                    >
                      {moment(error.period).format('MMM YYYY')}
                    </Link>
                  </Stack>
                }
                sx={{ flex: 2 }}
              />
            </ListItem>
          ))
        )}
      </Wrapper>
      {docketItem !== undefined && isDocketItemWithError(docketItem) && (
        <ModalErrorHandling step={step} setStep={setStep} docketItem={docketItem} />
      )}
    </>
  )
}

export default ErrorsTab

const Wrapper = styled(List)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
}))
