import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { API_LOGIN_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { HOMEPAGE_URL } from 'utils/router/constants'
import InputText from 'component/Inputs/InputText'
import { Button } from '@mui/material'
import { LOADING_UPDATE } from 'store/constants/loader'

function Login(props: any) {
  const { history, dispatch } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const getValue = (name: string, value: any) => {
    if (name === 'email') {
      setEmail(value)
    }
    if (name === 'password') {
      setPassword(value)
    }
  }

  const onSubmit = (event: any) => {
    event.preventDefault()

    const formData = {
      email: email.replace('+', '%20'),
      password: password,
    }

    dispatch({
      type: LOADING_UPDATE,
      loading: true,
    })

    customFetch
      .post(API_LOGIN_URL, formData)
      .then((response) => {
        if (response.ok) {
          dispatch({
            type: LOADING_UPDATE,
            loading: false,
          })
          history.push(HOMEPAGE_URL)
        } else {
          if (response.status === 403 || response.status === 404) {
            dispatch({
              type: LOADING_UPDATE,
              loading: false,
            })

            setMessage('Email et/ou mot de passe invalide.')
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: LOADING_UPDATE,
          loading: false,
        })
        console.log('Failed login : ' + error)
      })
  }

  return (
    <div className="page page--black page--v-center">
      <section className="container">
        <div className="row">
          <div className="col col--center col-md-6 col-lg-4">
            <form className="box box--padding ft-center" onSubmit={onSubmit}>
              <img
                className="img-event-none mb-3 mt-2"
                src={process.env.PUBLIC_URL + '/static/images/logo-black.svg'}
                alt="SendRaise logo"
                loading="lazy"
              />
              {message !== '' && (
                <div className="mt-2 mb-2">
                  <p className="mb-0 c-red">{message}</p>
                </div>
              )}
              <InputText
                name="email"
                type="text"
                placeholder="Email"
                required
                getValue={getValue}
                className="mb-2"
              />
              <InputText
                name="password"
                type="password"
                placeholder="Password"
                required
                getValue={getValue}
                className="mb-4"
              />
              <Button className="btn--full f-jc-center" type="submit">
                Connect
              </Button>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.connected,
  }
}

export default connect(mapStateToProps)(withRouter(Login))
