import React, { BaseSyntheticEvent } from 'react'
import { Table, TableBody, TablePagination, TableRow, styled } from '@mui/material'

interface IProps {
  count: number
  rowsPerPage: number
  page: number
  handleChangePage: (event: any, page: number) => void
  handleChangeRowsPerPage: (event: BaseSyntheticEvent) => void
  labelRowsPerPage: string
  rowsPerPageOptions?: number[]
  sx?: { [key: string]: any }
}

function MuiTablePagination({
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  labelRowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
  sx = {},
}: IProps) {
  return (
    <Table sx={{ ...sx, mb: 1 }}>
      <TableBody>
        <TableRow>
          <CustomTablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} sur ${count}`
            }}
            labelRowsPerPage={labelRowsPerPage}
          />
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default MuiTablePagination

const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
  border: 'none',
  padding: 0,
  color: theme.palette.neutralLight.contrastText,

  p: {
    fontSize: 12,
    opacity: 0.7,
    marginBottom: 0,
  },

  '.MuiTablePagination-toolbar': {
    minHeight: 'unset !important',
  },

  '.MuiInputBase-root, .MuiButtonBase-root': {
    fontSize: 12,
    background: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.helpers.stateShadows.default.formElements.primary,
    height: 32,
  },

  '.MuiButtonBase-root': {
    height: 32,
    width: 32,
    padding: 8,
  },

  '.MuiButtonBase-root:first-of-type': {
    marginRight: theme.spacing(1),
  },
}))
