import { API_USER_INSURANCE_URL } from 'api/constants'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'
import { IAPIQueueResponse } from 'api/interfaces/responses'
import { get } from 'utils'

export const getInsuranceFeeReportItemsErrorsReport = ({
  insuranceId,
  periodMonth,
  periodYear,
  selectedCommissionsTypes,
}: {
  insuranceId: number
  periodMonth: number
  periodYear: number
  selectedCommissionsTypes: IAPIInsuranceFeeCommissionType[]
}) => {
  const params = new URLSearchParams()

  params.append('action', 'export')
  params.append('month', periodMonth.toString())
  params.append('year', periodYear.toString())
  params.append('types', selectedCommissionsTypes.join(','))

  return get<IAPIQueueResponse>({
    url: `${API_USER_INSURANCE_URL}/${insuranceId}/insurance-fee-report-items?${params.toString()}`,
  })
}
