import { API_DOCKETS_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIProcessing } from 'api/interfaces'
import { Dispatch, SetStateAction } from 'react'

export const deleteDocket = async ({
  id,
  setError,
}: {
  id: number
  setError: Dispatch<SetStateAction<string>>
}): Promise<IAPIProcessing | undefined> => {
  const res = await customFetch.delete(`${API_DOCKETS_URL}/${id}`).catch((err) => {
    console.log(err)
  })

  if (!res) {
    setError(
      `Une erreur est survenue durant la supression du bordereau. Si le problème persiste, contactez le support Sendraise.`
    )
    return
  }

  const jsonRes = await res.json().catch((err) => {
    console.log(err)
  })

  if (!res.ok) {
    if (jsonRes.message) {
      setError(jsonRes.message)
      return
    }

    setError(
      `Une erreur est survenue durant la supression du bordereau. Si le problème persiste, contactez le support Sendraise.`
    )
    return
  }

  return jsonRes as IAPIProcessing
}
