import React, { FC } from 'react'

import { FlagTooltip } from 'component'
import { IAPIDocketItem, IAPIDocketItemDirection } from 'api/interfaces'
import {
  getDirectionLabel,
  parseFlagDescription,
  parseInvalidationFlagDescription,
} from 'utils/commissions'

import { Box, Stack, Tooltip, Typography } from '@mui/material'
import HelpCircleIcon from 'icons/HelpCircleIcon'
import { getTooltipContent } from './core/DocketDirectionCell.helpers'

interface IDocketDirectionCellProps {
  item: IAPIDocketItem
  field: string
  value: IAPIDocketItemDirection
  showErrors?: boolean
  tag?: string
}

const DocketDirectionCell: FC<React.PropsWithChildren<IDocketDirectionCellProps>> = ({
  item,
  field,
  value,
  showErrors = true,
  tag = 'div',
}) => {
  const isPenalty = item.isPenalty
  const label = getDirectionLabel(value, isPenalty, item)

  const classNames = ['cell--docket-direction']
  const fieldFlag = parseFlagDescription(item.flagDescription).find(
    (itemFlag) => itemFlag.field === field
  )
  const Element = tag as keyof JSX.IntrinsicElements
  const fieldInvalidationFlag = parseInvalidationFlagDescription(
    item.flagInvalidationDescription
  ).find((itemInvalidationFlag) => itemInvalidationFlag.field === field)

  const hasErrors = !isPenalty && fieldFlag !== undefined
  const isInvalidated = fieldInvalidationFlag !== undefined
  const provider = item.insuranceFeeReport.insurance.provider

  const tooltipContent = getTooltipContent(item.commissionType, provider)

  return (
    <Element className={classNames.join(' ')} key={`docket-${field}-${item.id}`}>
      <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
        {showErrors && hasErrors ? (
          <FlagTooltip
            fieldFlag={fieldFlag!}
            fieldInvalidationFlag={fieldInvalidationFlag}
            sx={{ mr: 1, svg: { display: 'block' } }}
          />
        ) : null}
        {!label.includes('/') && (
          <Box
            fontWeight={hasErrors && showErrors ? (isInvalidated ? 400 : 500) : 400}
            color={
              hasErrors && showErrors
                ? isInvalidated
                  ? 'text.primary'
                  : 'warning.main'
                : 'text.primary'
            }
          >
            {label}
          </Box>
        )}
        {label.includes('/') && (
          <Stack>
            <Typography
              component="p"
              variant="subtitle1"
              fontWeight={hasErrors && showErrors ? (isInvalidated ? 400 : 500) : 400}
              color={
                hasErrors && showErrors
                  ? isInvalidated
                    ? 'text.primary'
                    : 'warning.main'
                  : 'text.primary'
              }
            >
              {label.split('/')[0]}
            </Typography>
            <Typography component="p" color="text.secondary" variant="subtitle2">
              {label.split('/')[1]}
            </Typography>
          </Stack>
        )}
        {isPenalty && tooltipContent !== undefined ? (
          <>
            <Tooltip
              placement="bottom-start"
              slotProps={{ popper: { sx: { maxWidth: 450 } } }}
              title={tooltipContent}
              sx={{ ml: 1, svg: { display: 'block' } }}
            >
              <Stack direction="row" alignItems="center">
                <HelpCircleIcon sx={{ fontSize: 16, color: 'text.secondary', cursor: 'pointer' }} />
              </Stack>
            </Tooltip>
            {item.insuranceRate !== null ? (
              <Typography variant="subtitle1" color="text.secondary" sx={{ width: '100%' }}>
                {parseFloat(item.insuranceRate).toFixed(2)}%
              </Typography>
            ) : null}
          </>
        ) : null}
      </Stack>
    </Element>
  )
}

export default DocketDirectionCell
