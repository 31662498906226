import {
  InputLabel,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButtonGroupProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { InfoIcon } from 'icons'
import * as React from 'react'

interface IProps {
  sx?: SxProps
  size?: 'small' | 'medium'
  label?: string
  buttons: IToggleButtonGroupButton[]
  exclusive?: boolean
  value: string | number | undefined
  onChange: ToggleButtonGroupProps['onChange']
  hint?: React.ReactNode
}

export interface IToggleButtonGroupButton {
  value: string | number
  display: React.ReactNode
}

const ToggleButtonGroup: React.FC<IProps> = (props) => {
  const { sx, size, label, buttons, exclusive, value, onChange, hint } = props
  return (
    <Box sx={sx}>
      {label ? (
        <Stack direction="row" gap={1} alignItems="center" mb={1}>
          <InputLabel sx={{ mb: 0 }} size={size === 'small' ? 'small' : 'normal'}>
            {label}
          </InputLabel>
          {hint ? (
            <Tooltip
              slotProps={{ popper: { sx: { maxWidth: 300 } } }}
              title={<Typography variant="subtitle1">{hint}</Typography>}
              placement="top"
            >
              <Stack>
                <InfoIcon sx={{ fontSize: 16, color: 'primary.main', cursor: 'pointer' }} />
              </Stack>
            </Tooltip>
          ) : null}
        </Stack>
      ) : null}
      <MUIToggleButtonGroup size={size} exclusive={exclusive} value={value} onChange={onChange}>
        {buttons.map((button, idx) => (
          <ToggleButton key={idx} value={button.value}>
            {button.display}
          </ToggleButton>
        ))}
      </MUIToggleButtonGroup>
    </Box>
  )
}

export default ToggleButtonGroup
