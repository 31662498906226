import { styled, SxProps } from '@mui/material'
import * as React from 'react'
import { SearchIcon, XIcon } from 'icons'
import { BaseInput } from 'component/Inputs'

interface IProps {
  id: string
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onReset: React.MouseEventHandler<SVGSVGElement>
  size?: 'small' | 'medium'
  sx?: SxProps
  label?: string
  disabled?: boolean
  placeholder?: string
}

const SearchInput: React.FC<IProps> = (props) => {
  const {
    onChange,
    onReset,
    value,
    size,
    sx,
    label,
    disabled,
    id,
    placeholder = 'Rechercher',
  } = props

  return (
    <BaseInput
      sx={sx}
      startIcon={<SearchIcon sx={{ color: 'text.secondary' }} />}
      endIcon={<StyledXIcon onClick={onReset} value={value} />}
      id={id}
      value={value}
      onChange={onChange}
      size={size}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default SearchInput

const StyledXIcon = styled(XIcon)<{ value: string }>(({ value }) => ({
  cursor: 'pointer',

  ...(value === '' && {
    opacity: 0,
    pointerEvents: 'none',
  }),
}))
