import * as React from 'react'

import { Edit3Icon, Share2Icon, Trash2Icon, PlusCircleIcon } from 'icons'
import { ModalConfigureFees, ModalDeleteFees, ModalEditFeesPropagation } from 'component/Modals'
import { BaseTable } from 'component/Tables'
import {
  percentageCell,
  currencyCell,
  textSecondaryCell,
  primaryPercentageCell,
} from 'component/Tables/BaseTable'
import Loader from 'component/Loader'
import { IAPIInsuranceFeeDeposit } from 'api/interfaces'
import { getProviderLogo } from 'utils/commissions'
import { Context } from 'page/Commissions/Contracts'

import { useDepositNavbarContent } from './core/depositNavbarContent.hooks'

import {
  Button,
  Chip,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-premium'
import { GridColDef } from '@mui/x-data-grid-premium'

const DepositNavbarContent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const context = React.useContext(Context)

  const { selectedInsuranceProduct, selectedInsurance, insuranceProducts } = { ...context }

  const [insuranceFeeDeposits, setInsuranceFeeDeposits] = React.useState<
    IAPIInsuranceFeeDeposit[] | null | undefined
  >(null)
  const [modalConfigureFeesOpen, setModalConfigureFeesOpen] = React.useState(false)
  const [modalEditFeesPropagationOpen, setModalEditFeesPropagationOpen] = React.useState(false)
  const [modalDeleteFeesOpen, setModalDeleteFeesOpen] = React.useState(false)
  const [editedInsuranceFeeDeposit, setEditedInsuranceFeeDeposit] = React.useState<
    IAPIInsuranceFeeDeposit | undefined
  >()

  useDepositNavbarContent({ selectedInsuranceProduct, setInsuranceFeeDeposits })

  const COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'feeRate',
        headerName: `Taux de frais`,
        ...primaryPercentageCell,
        align: 'left',
        headerAlign: 'left',
        sortable: false,
        minWidth: 108,
        maxWidth: 190,
        flex: 0.5,
      },
      {
        field: 'organizationRate',
        headerName: 'Taux CGP',
        ...percentageCell,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 86,
        maxWidth: 190,
        flex: 0.5,
      },
      {
        field: 'insuranceRate',
        headerName: 'Taux Assureur',
        ...percentageCell,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 109,
        maxWidth: 190,
        flex: 0.5,
        valueGetter: (params) => Number(params.row.feeRate) - Number(params.row.organizationRate),
      },
      {
        field: 'organizationMinimumAmount',
        headerName: 'Incompressible CGP',
        ...currencyCell,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 118,
        maxWidth: 190,
        flex: 0.5,
      },
      {
        field: 'insuranceMinimumAmount',
        headerName: 'Incompressible Assureur',
        ...currencyCell,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 118,
        maxWidth: 190,
        flex: 0.5,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        ...textSecondaryCell,
        sortable: false,
        flex: 2,
      },
      {
        field: 'insuranceProducts',
        headerName: '',
        sortable: false,
        width: 70,
        renderCell: (params) => {
          const insuranceProductsPropagatedToCount = params.value.length

          return insuranceProductsPropagatedToCount > 1 ? (
            <PropagationTooltip
              arrow
              placement="bottom-end"
              title={
                <Typography component="p" variant="subtitle2" fontWeight={500}>
                  Partagée avec {insuranceProductsPropagatedToCount - 1} produit
                  {insuranceProductsPropagatedToCount - 1 > 1 ? 's' : null}
                </Typography>
              }
            >
              <PropagationChip
                size="small"
                icon={<Share2Icon />}
                label={insuranceProductsPropagatedToCount - 1}
              />
            </PropagationTooltip>
          ) : (
            <></>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 48,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Edit3Icon />}
            label="Éditer la règle"
            showInMenu
            onClick={() => {
              setEditedInsuranceFeeDeposit(params.row)
              setModalConfigureFeesOpen(true)
            }}
          />,
          <GridActionsCellItem
            icon={<Share2Icon />}
            label="Éditer la propagation"
            showInMenu
            onClick={() => {
              setEditedInsuranceFeeDeposit(params.row)
              setModalEditFeesPropagationOpen(true)
            }}
          />,
          <GridActionsCellItem
            icon={<Trash2Icon />}
            label="Supprimer"
            sx={{ color: 'error.main' }}
            onClick={() => {
              setEditedInsuranceFeeDeposit(params.row)
              setModalDeleteFeesOpen(true)
            }}
            showInMenu
          />,
        ],
      },
    ],
    []
  )

  const closeModalConfigureFees = () => {
    setModalConfigureFeesOpen(false)
  }

  const openModalConfigureFees = () => {
    setModalConfigureFeesOpen(true)
  }

  const closeModalEditFeesPropagation = () => {
    setModalEditFeesPropagationOpen(false)
  }

  const closeModalDeleteFees = () => {
    setModalDeleteFeesOpen(false)
  }

  return insuranceFeeDeposits !== null ? (
    insuranceFeeDeposits !== undefined &&
    selectedInsurance !== undefined &&
    selectedInsuranceProduct !== undefined &&
    insuranceProducts !== undefined ? (
      <div className="f f-direction-column">
        {insuranceFeeDeposits.length ? (
          <>
            <div className="mb-2">
              <div className="f f-jc-space-b f-ai-center">
                <p className="ft-500 mb-0">
                  Règles de frais de versement ({insuranceFeeDeposits.length})
                </p>
                <div className="f f-ai-center">
                  <Button startIcon={<PlusCircleIcon />} onClick={openModalConfigureFees}>
                    Ajouter une configuration de frais
                  </Button>
                </div>
              </div>
            </div>

            <BaseTable
              autoHeight
              columns={COLUMNS}
              rows={insuranceFeeDeposits}
              noContentMessage="Aucune commission sur versement n'a été configurée pour ce produit."
            />
          </>
        ) : (
          <div className="f f-ai-center f-jc-space-b">
            <p className="mb-0">
              Aucune commission sur versement n'a été configurée pour ce produit.
            </p>
            <Button startIcon={<PlusCircleIcon />} onClick={openModalConfigureFees}>
              Ajouter une configuration de frais
            </Button>
          </div>
        )}
        <ModalConfigureFees
          open={modalConfigureFeesOpen}
          closeModal={closeModalConfigureFees}
          providerLogo={getProviderLogo(selectedInsurance.provider)}
          insuranceName={selectedInsurance.name}
          insuranceProductId={selectedInsuranceProduct.id}
          insuranceProductName={selectedInsuranceProduct.name}
          insuranceProducts={insuranceProducts}
          feesType="deposit"
          editedConfiguration={editedInsuranceFeeDeposit}
          setEditedConfiguration={setEditedInsuranceFeeDeposit}
        />
        {editedInsuranceFeeDeposit !== undefined ? (
          <>
            <ModalEditFeesPropagation
              open={modalEditFeesPropagationOpen}
              closeModal={closeModalEditFeesPropagation}
              providerLogo={getProviderLogo(selectedInsurance.provider)}
              insuranceName={selectedInsurance.name}
              insuranceProductName={selectedInsuranceProduct.name}
              insuranceProducts={insuranceProducts}
              feesType="deposit"
              editedConfiguration={editedInsuranceFeeDeposit}
              setEditedConfiguration={setEditedInsuranceFeeDeposit}
            />
            <ModalDeleteFees
              feesType="deposit"
              open={modalDeleteFeesOpen}
              closeModal={closeModalDeleteFees}
              providerLogo={getProviderLogo(selectedInsurance.provider)}
              insuranceName={selectedInsurance.name}
              insuranceProductName={selectedInsuranceProduct.name}
              insuranceProductId={selectedInsuranceProduct.id}
              editedConfiguration={editedInsuranceFeeDeposit}
              setEditedConfiguration={setEditedInsuranceFeeDeposit}
            />
          </>
        ) : null}
      </div>
    ) : (
      <Loader />
    )
  ) : (
    <></>
  )
}

export default DepositNavbarContent

const PropagationChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  color: 'white',
  fontWeight: 700,
}))

const PropagationTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',

    '.MuiTooltip-arrow': {
      color: theme.palette.secondary.main,
    },
  },
}))
