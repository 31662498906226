import React from 'react'
import { Button, Divider, Stack, Typography, styled } from '@mui/material'
import { createStripeSubscription } from '../core/Subscription.service'
import { SUBSCRIPTION_WELCOME_URL } from 'utils/router/constants'
import { useHistory } from 'react-router-dom'

interface IPricingCard {
  title: string
  desc: string
  price: number
  trialDuration: string
  priceId: string
  bestOffer?: boolean
  setError: (error: string) => void
}


function PricingCard({
  title,
  desc,
  price,
  trialDuration,
  priceId,
  bestOffer,
  setError,
}: IPricingCard) {
  let history = useHistory()
  const [loading, setLoading] = React.useState(false)

  const onPlanClick = async () => {
    try {
      setLoading(true)
      const res = await createStripeSubscription({ priceId })

      if (!res || !res.ok) {
        await setError('Une erreur est survenue')
        return
      }

      setLoading(false)
      history.push(SUBSCRIPTION_WELCOME_URL)
    } catch (e: any) {
      setLoading(false)
      setError(e.message)
    }
  }

  return (
    <CustomCard className={bestOffer ? 'card--bordered' : ''}>
      {bestOffer && (
        <BestOfferTag alignItems="center">
          <Typography variant="sectionHeading" color="primaryLight.light">
            Meilleure offre
          </Typography>
        </BestOfferTag>
      )}
      <Typography variant="subtitle2" fontWeight={500} color="text.primary">
        Sendraise - Gel des Avoirs
      </Typography>
      <Typography variant="body2_m" color="text.primary" mb={2} component="p">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" mb={2} sx={{ flex: 1 }}>
        {desc}
      </Typography>
      <Divider orientation="horizontal" sx={{ mb: 2 }} />
      <Typography variant="subtitle1_m" color="primary" sx={{ mb: '4px' }}>
        Essai de {trialDuration} jours inclus
      </Typography>
      <Stack direction="row" alignItems="baseline" spacing={1} mb={2}>
        <Typography variant="h2b" fontWeight={700} color="text.primary">
          {(price / 100).toLocaleString('fr-FR')} €
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          /an
        </Typography>
      </Stack>
      <Button
        size="medium"
        disabled={loading}
        color={bestOffer ? 'primary' : 'neutralLight'}
        variant="contained"
        fullWidth
        onClick={onPlanClick}
      >
        Démarrer l'essai
      </Button>
    </CustomCard>
  )
}


const CustomCard = styled(Stack)(({ theme }) => ({
  position: 'relative',
  flex: 1,
  borderRadius: 8,
  padding: 24,
  backgroundColor: theme.palette.background.default,

  '&.card--bordered': {
    border: `1px solid ${theme.palette.grey[400]}`,
    boxShadow: theme.helpers.stateShadows.hover.primary,
  },
}))

const BestOfferTag = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '-9px',

  span: {
    background: theme.palette.background.inverted,
    with: 'fit-content',
    padding: '0 8px',
    borderRadius: 4,
  },
}))

export default PricingCard
