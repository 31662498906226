import {
  USER_UPDATE_CONNECTED,
  USER_UPDATE_INFOS,
  USER_UPDATE_CSRF_TOKEN,
  USER_UPDATE_NAMES,
  USER_UPDATE_PICTURE,
  USER_LOGOUT
} from 'store/constants/user'

import { ORGANIZATION_UPDATE_NAME, ORGANIZATION_UPDATE_LOGO } from 'store/constants/organization'

export const userState = {
  id: 0,
  firstName: '',
  lastName: '',
  picture: null,
  email: '',
  role: 'member',
  organization: {
    id: 0,
    name: '',
    logo: '',
    enableFrozenAssets: false,
    enableReports: false,
    contractsLastUpdatedAt: null,
  },
  connected: false,
  CSRFToken: null,
  shortlists: [],
}

export function user(state = userState, action: any) {
  switch (action.type) {
    case USER_UPDATE_CONNECTED:
      return {
        ...state,
        connected: action.connected,
      }
    case USER_UPDATE_CSRF_TOKEN:
      return {
        ...state,
        CSRFToken: action.CSRFToken,
      }
    case USER_UPDATE_INFOS:
      return {
        ...state,
        id: action.user.id,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        signature: action.user.signature,
        picture: action.user.picture,
        role: action.user.role,
        shortlists: action.user.shortlists,
        email: action.user.email,
        organization: {
          id: action.user.organization.id,
          name: action.user.organization.name,
          logo: action.user.organization.logo,
          enableFrozenAssets: action.user.organization.enableFrozenAssets,
          enableReports: action.user.organization.enableReports,
          contractsLastUpdatedAt: action.user.organization.contractsLastUpdatedAt,
        },
        connected: true,
      }
    case USER_UPDATE_NAMES:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
      }
    case USER_LOGOUT:
      return {
        ...userState,
      }
    case USER_UPDATE_PICTURE:
      return {
        ...state,
        picture: action.picture,
      }
    case ORGANIZATION_UPDATE_LOGO:
      return {
        ...state,
        organization: {
          ...state.organization,
          logo: action.logo,
        },
      }
    case ORGANIZATION_UPDATE_NAME:
      return {
        ...state,
        organization: {
          ...state.organization,
          name: action.name,
        },
      }
    default:
      return state
  }
}
