import * as React from 'react'

import {
  BaseTextFieldProps,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SxProps,
} from '@mui/material'
import { VisibilityOffIcon, VisibilityIcon } from 'icons'

interface IProps extends BaseTextFieldProps {
  sx?: SxProps
  id: string
  label: string
  value?: unknown
  placeholder: string
  helperText?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  onMouseDown: any
  onClickShow: () => void
  showPassword?: boolean
  dataFormType?: string
  canCopyPaste?: boolean
}

const PasswordInput: React.FC<IProps> = (props) => {
  const {
    sx,
    id,
    label,
    value,
    placeholder,
    helperText,
    onClickShow,
    onMouseDown,
    onChange,
    showPassword,
    dataFormType = 'password',
    canCopyPaste = false
  } = props

  const preventCopyPaste = (e: any) => {
    if (!canCopyPaste) {
      e.preventDefault()
      return
    }
  }

  return (
    <FormControl variant="outlined" fullWidth sx={sx}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        onCopy={preventCopyPaste}
        onPaste={preventCopyPaste}
        onCut={preventCopyPaste}
        autoComplete="new-password"
        data-form-type={dataFormType}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClickShow}
              onMouseDown={onMouseDown}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
    </FormControl>
  )
}

export default PasswordInput
