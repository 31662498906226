import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'
import Theme from 'theme'

const BuildingIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C4.55228 2 5 2.44772 5 3V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3C21 3.55228 20.5523 4 20 4H4C3.44772 4 3 3.55228 3 3Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 21C3 20.4477 3.44772 20 4 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H4C3.44772 22 3 21.5523 3 21Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 21C13 20.4477 13.4477 20 14 20H20C20.5523 20 21 20.4477 21 21C21 21.5523 20.5523 22 20 22H14C13.4477 22 13 21.5523 13 21Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C9 17.4477 9.44772 17 10 17H14C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19H10C9.44772 19 9 18.5523 9 18Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C10.5523 17 11 17.4477 11 18V21C11 21.5523 10.5523 22 10 22C9.44772 22 9 21.5523 9 21V18C9 17.4477 9.44772 17 10 17Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 17C14.5523 17 15 17.4477 15 18V21C15 21.5523 14.5523 22 14 22C13.4477 22 13 21.5523 13 21V18C13 17.4477 13.4477 17 14 17Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V3C19 2.44772 19.4477 2 20 2Z"
        fill={Theme.palette.neutralLight.contrastText}
      />
      <path d="M7 6H11V10H7V6Z" fill={Theme.palette.neutralLight.contrastText} />
      <path d="M7 12H11V16H7V12Z" fill={Theme.palette.neutralLight.contrastText} />
      <path d="M13 6H17V10H13V6Z" fill={Theme.palette.neutralLight.contrastText} />
      <path d="M13 12H17V16H13V12Z" fill={Theme.palette.neutralLight.contrastText} />
    </SvgIcon>
  )
}

export default BuildingIcon
