import * as React from 'react'

import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom'
import { useDebounce } from 'utils'

import TextInput from 'component/Inputs/LargeSearchInput'

import { Pagination } from 'component'
import { SearchInput } from 'component/Inputs'
import { BaseTable } from 'component/Tables'
import { TypographyCell } from 'component/Layouts/TableCells'
import { ModalFrozenAssetEntity } from 'component/Modals'
import { LayoutMenu } from 'component/Layouts'

import {
  displayArrayWithConjunction,
  getNoContentMessage,
  handleModalClose,
  handleSearchChange,
  handleSearchClick,
  handleSearchReset,
  handleSeeDetailsClick,
  updateAllData,
  updateUrl,
} from './core/Search.helpers'

import { Button, Stack, Typography, styled } from '@mui/material'
import { GridRowParams, GridRowsProp } from '@mui/x-data-grid-premium'
import { GridColDef } from '@mui/x-data-grid-premium'

export interface IFaeRow {
  id: number
  fullName: string
  name: string
  nature: 'Personne morale' | 'Personne physique' | 'Navire'
  firstNames: string[] | null
  titles: string[] | null
  aliases: string[] | null
  genders: string[] | null
  datesOfBirth: string[] | null
  placesOfBirth: string[] | null
  reasons: string[] | null
  legalGrounds: string[] | null
  UEReferences: string[] | null
}

const Search: React.FC<RouteComponentProps> = (props) => {
  const { history } = props

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const [rows, setRows] = React.useState<GridRowsProp<IFaeRow>>()
  const [page, setPage] = React.useState<number>(1)
  const [loading, setLoading] = React.useState(false)
  const [faeCount, setFaeCount] = React.useState<number>()
  const [search, setSearch] = React.useState(searchParams.get('r') || '')
  const [faeModalOpen, setFaeModalOpen] = React.useState(false)
  const [selectedFae, setSelectedFae] = React.useState<IFaeRow>()

  const debouncedSearch = useDebounce(search, 800)

  React.useEffect(() => {
    updateUrl({ history, debouncedSearch })
  }, [debouncedSearch, history])

  React.useEffect(() => {
    updateAllData({ debouncedSearch, setLoading, page, setFaeCount, setRows })
  }, [debouncedSearch, page])

  const COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'fullName',
        headerName: `Nom de l'entité`,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <TypographyCell variant="subtitle1" color="text.primary" textTransform="capitalize">
            {params.value || '-'}
          </TypographyCell>
        ),
      },
      {
        field: 'datesOfBirth',
        headerName: 'Date de naissance',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <TypographyCell
            variant="subtitle1"
            color="text.primary"
            title={params.value !== null ? params.value.join(' ou ') : undefined}
            textTransform="capitalize"
          >
            {params.value
              ? displayArrayWithConjunction({
                  array: params.value,
                  conjunction: 'ou',
                })
              : '-'}
          </TypographyCell>
        ),
      },
      {
        field: 'nature',
        headerName: 'Nature',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <TypographyCell variant="subtitle1" color="text.primary" textTransform="capitalize">
            {params.value || '-'}
          </TypographyCell>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 110,
        getActions: (params: GridRowParams<IFaeRow>) => [
          <Typography
            variant="subtitle1"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleSeeDetailsClick({
              setFaeModalOpen,
              setSelectedFae,
              fae: params.row,
            })}
          >
            Voir le détail
          </Typography>,
        ],
      },
    ],
    []
  )

  return debouncedSearch ? (
    <LayoutMenu location="asset-freeze" defaultMenuOpen={true}>
      <Wrapper>
        <Typography sx={{ mb: 4 }} variant="h2">
          Registre du gel des avoirs
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={1.5}>
          <SearchInput
            id="search"
            value={search}
            onChange={handleSearchChange(setSearch)}
            onReset={handleSearchReset(setSearch)}
            size="small"
          />
          {faeCount ? (
            <Stack direction="row" alignItems="flex-end">
              <Typography
                variant="h3"
                fontWeight={500}
                sx={{ mr: 0.5 }}
                color="text.primary"
                lineHeight="23px"
              >
                {faeCount}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                ligne{faeCount > 1 ? 's' : null}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        <BaseTable
          autoHeight
          columns={COLUMNS}
          rows={rows || []}
          loading={loading}
          noContentMessage={getNoContentMessage(debouncedSearch)}
        />
        {faeCount ? (
          <Stack justifyContent="center" alignItems="center" mt={2}>
            <Pagination page={page} setPage={setPage} pageSize={50} total={faeCount} />
          </Stack>
        ) : null}
      </Wrapper>
      <ModalFrozenAssetEntity
        open={faeModalOpen}
        onClose={handleModalClose(setFaeModalOpen)}
        frozenAssetEntity={selectedFae}
      />
    </LayoutMenu>
  ) : (
    <LayoutMenu location="asset-freeze" defaultMenuOpen={true}>
      <Stack sx={{ mt: 10 }}>
        <div className="frozen-asset-entities__landing f f-direction-column f-center">
          <img
            src={process.env.PUBLIC_URL + '/static/images/logo-sendraise-beta-black.svg'}
            alt="SendRaise logo"
            loading="lazy"
          />
          <div className="frozen-asset-entities__searchbar f mt-5 mb-5">
            <TextInput
              type="text"
              value={search}
              setValue={setSearch}
              name="search"
              placeholder="Rechercher par nom/prénom/alias ou date de naissance"
              className="mr-3"
              icon="icon-search"
              iconPosition="left"
              onEnterPress={handleSearchClick({ search, history })}
            />
            <Button color="secondary" onClick={handleSearchClick({ search, history })}>
              Rechercher
            </Button>
          </div>
          <p className="ft-center c-blue-400 h6">
            Recherchez des prospects dans le registre national des gels avant d'entrer en relation
            commerciale avec eux.
          </p>
        </div>
      </Stack>
    </LayoutMenu>
  )
}

export default withRouter(Search)

const Wrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
}))
