import { Chip, ChipProps, styled } from '@mui/material'
import { ICommissionType } from 'page/Commissions/Imports/core/Imports.hooks'
import * as React from 'react'

interface IProps extends ChipProps {
  commissionType: ICommissionType
}

const CommissionChip: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { commissionType } = props

  return <StyledChip {...props} label={commissionType.display} />
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'commissionType',
})<{
  commissionType: ICommissionType
}>(({ commissionType }) => ({
  backgroundColor: `${commissionType.color.main} !important`,
  color: `${commissionType.color.contrastText} !important`,
}))

export default CommissionChip
