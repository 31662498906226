import { Components, Theme } from '@mui/material'

const MuiToggleButtonGroup: NonNullable<Components<Theme>['MuiToggleButtonGroup']> = {
  styleOverrides: {
    grouped: ({ theme }) => ({
      '&:not(:first-of-type)': {
        marginLeft: 0,
        borderLeft: 0,
      },
    }),
  },
}

export default MuiToggleButtonGroup
