import React, { FC } from 'react'

import { AlertOffIcon, MoreHorizontalIcon } from 'icons'
import Tooltip from 'component/Tooltip'
import { IAPIDocketItem } from 'api/interfaces'

import { GridActionsCellItem } from '@mui/x-data-grid-premium'
import { Box, IconButton, styled } from '@mui/material'
import { isManualErrorRaisingDisabled } from 'utils/commissions'

interface IDocketTooltipCellProps {
  onDisableErrorsClick: React.MouseEventHandler<HTMLLIElement>
  docketItem: IAPIDocketItem
}

const DocketTooltipCell: FC<React.PropsWithChildren<IDocketTooltipCellProps>> = (props) => {
  const { onDisableErrorsClick, docketItem } = props

  return (
    <td className="cell--tooltip">
      <Tooltip
        className="relative"
        closeOnLeave={false}
        header={
          <IconButtonStyled size="small">
            <MoreHorizontalIcon />
          </IconButtonStyled>
        }
      >
        <Box className="MuiDataGrid-menuList">
          <GridActionsCellItem
            icon={<AlertOffIcon />}
            label="Désactiver / Réactiver les erreurs"
            showInMenu
            onClick={onDisableErrorsClick}
            disabled={isManualErrorRaisingDisabled(docketItem)}
          />
        </Box>
      </Tooltip>
    </td>
  )
}

export default DocketTooltipCell

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 0.5px 0 0 #5d6a7b',
  },
  '&:focus': {
    backgroundColor: theme.palette.neutralLight.main,
    boxShadow: '0 0.5px 0 0 #5d6a7b',
  },
}))
