import * as React from 'react'
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import { Button, Stack, styled } from '@mui/material'
import { Moment } from 'moment'

interface IProps extends PickersActionBarProps {
  value: Moment | null
  size: 'small' | 'medium'
}

const DateInputActionBar: React.FC<IProps> = (props) => {
  const { onClear, onAccept, value, size } = props

  return (
    <Wrapper flexDirection="row" alignItems="center" gap={1.5}>
      <Button
        onClick={onClear}
        fullWidth
        variant="text"
        color="neutral"
        size={size}
      >
        Annuler
      </Button>
      <Button
        disabled={value === null}
        onClick={onAccept}
        fullWidth
        color="primary"
        size={size}
      >
        Confirmer
      </Button>
    </Wrapper>
  )
}

export default DateInputActionBar

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5),
}))
