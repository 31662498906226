import * as React from 'react'
import { Menu } from 'component'
import { Box } from '@mui/material'

interface IProps {
  location: 'home' | 'reportings' | 'asset-freeze' | 'preferences'
  defaultMenuOpen: boolean
  pageWhite?: boolean
  canClose?: boolean
}

interface IMenuContext {
  isOpen?: boolean
  children?: React.ReactNode
  openMenu?: () => void
  closeMenu?: () => void
}

export const MenuContext = React.createContext<IMenuContext>({})

const LayoutMenu: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { location, defaultMenuOpen, children, canClose = true, pageWhite } = props

  const [isOpen, setIsOpen] = React.useState(defaultMenuOpen)

  React.useEffect(() => {
    if (canClose) {
      setIsOpen(false)
    }
  }, [canClose])

  const openMenu = () => {
    setIsOpen(true)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const sxWhite = pageWhite ? { backgroundColor: 'white', height: '100vh' } : {}

  return (
    <Box display="flex">
      <Menu
        open={isOpen}
        openMenu={openMenu}
        closeMenu={canClose ? closeMenu : undefined}
        location={location}
      />
      <Box
        component="main"
        sx={(theme) => ({
          flex:
            location === 'home'
              ? `0 0 calc(100vw - ${theme.helpers.sidebar.open.width})`
              : `0 0 calc(100vw - ${theme.helpers.sidebar.closed.width})`,
          width: 'calc(100% - 68px)',
          ...sxWhite,
        })}
      >
        {children}
      </Box>
    </Box>
  )
}

export default LayoutMenu
