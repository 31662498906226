import React from 'react'
import { connect } from 'react-redux'

const LoaderTopbar = ({ loading }: { loading?: boolean }) => (
  <div className={`loader-topbar ${loading ? 'is-loading' : 'is-loaded'}`}>
    <span></span>
  </div>
)

const mapStateToProps = (state: any) => {
  return {
    loading: state.loader.loading,
  }
}

export default connect(mapStateToProps)(LoaderTopbar)
