import * as React from 'react'

import { LogoIcon } from 'icons'

import { DefaultMenu, HomeMenu, MenuProfile } from './components'
import { getHideableElementSx } from './core/Menu.helpers'

import { Box, Drawer, Stack, Typography, useTheme } from '@mui/material'

interface IProps {
  open: boolean
  location: 'home' | 'reportings' | 'asset-freeze' | 'preferences'
  openMenu: () => void
  closeMenu?: () => void
}

const Menu: React.FC<IProps> = (props) => {
  const { open, openMenu, closeMenu, location } = props
  const theme = useTheme()
  const hideableElementSx = getHideableElementSx({ theme, open })

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={(theme) => ({
        flexShrink: 0,
        width: theme.spacing(open ? 32 : 8.5),
        transition: theme.transitions.create('width', {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeOut,
        }),
      })}
      PaperProps={{
        sx: (theme) => ({
          borderRight: `1px solid ${theme.palette.secondary.main}`,
          overflowX: 'hidden',
          width: theme.spacing(open ? 32 : 8.5),
          transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeOut,
          }),
        }),
      }}
    >
      <Stack
        flexGrow={1}
        onMouseEnter={openMenu}
        onMouseLeave={location !== 'home' ? closeMenu : undefined}
      >
        <Stack flexGrow={1} gap={4} pt={3}>
          <Stack direction="row" gap={1} pl={3} pr={1} ml={-1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <LogoIcon sx={{ fontSize: 32 }} />
              <Typography variant="h3b" color="white" sx={hideableElementSx}>
                Sendraise
              </Typography>
            </Stack>
          </Stack>
          {location === 'home' ? (
            <HomeMenu open={open} />
          ) : (
            <DefaultMenu open={open} location={location} />
          )}
        </Stack>
        <Box px={2.25} py={2}>
          <MenuProfile open={open} />
        </Box>
      </Stack>
    </Drawer>
  )
}

export default Menu
