import * as React from 'react'
import { Box, Button, Card, ModalProps, Stack, styled, Typography } from '@mui/material'

import { Modal } from 'component'

import Loader from 'component/Loader'

import moment from 'moment'
import DateRangeInput, { RangeShortcutType } from 'component/Inputs/DateRangeInput'
import useQueueProgress from 'utils/hooks/queueProgress'
import { downloadRemoteFile } from 'utils'
import { handleDownload } from '../core/ModalDownloadClientsDocuments.helpers'
import { getAvailableReportYears } from '../core/ModalDownloadClientsDocuments.service'

interface IProps {
  open: boolean
  onClose: NonNullable<ModalProps['onClose']>
}

const ModalVerificationProofs: React.FC<IProps> = (props) => {
  const { open, onClose } = props

  const [loading, setLoading] = React.useState<boolean>(false)
  const [progress, setProgress] = React.useState<number>(0)
  const [error, setError] = React.useState<string>()
  const [jobId, setJobId] = React.useState<string>()
  const [shortcuts, setShortcuts] = React.useState<RangeShortcutType[]>([
    { label: 'Reset', getValue: () => [null, null] },
    {
      label: 'Le mois écoulé',
      getValue: () => {
        return [moment().startOf('month'), moment().endOf('month')]
      },
    },
  ])

  const getShorcuts = async () => {
    const res = await getAvailableReportYears()

    if (!res || !res.length || !res[0]) {
      return
    }

    const formatedShorcut: RangeShortcutType[] = res.map((year: number) => ({
      label: year.toString(),
      getValue: () => {
        return [
          moment().set({ year, month: 0, date: 1 }),
          moment().set({ year, month: 11, date: 31 }),
        ]
      },
    }))

    const sortByDate = res.sort((year1: number, year2: number) => year1 - year2)

    const allYears: RangeShortcutType = {
      label: 'Tout l’historique du cabinet',
      getValue: () => {
        return [
          moment().set({ year: sortByDate[0], month: 0, date: 1 }),
          moment().set({ year: sortByDate[res.length - 1], month: 11, date: 31 }),
        ]
      },
    }

    setShortcuts((prevState) => [...prevState, allYears, ...formatedShorcut])
  }

  React.useEffect(() => {
    getShorcuts()
  }, [])

  useQueueProgress({
    interval: 1000,
    queueId: 'generate-xlsx',
    jobId,
    setJobId,
    setProgress,
    onError: (error) => {
      setError(
        error ||
          'Erreur durant la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le service client Sendraise'
      )
      setLoading(false)
    },
    onComplete: (data) => {
      downloadRemoteFile(data.url)
      onClose({}, 'backdropClick')
      setLoading(false)
    },
  })

  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    const data = new FormData(e.target)
    const minDate = data.get('minDate') as string
    const maxDate = data.get('maxDate') as string

    handleDownload({
      target: 'csv',
      minDate,
      maxDate,
      setLoading: setLoading,
      setProgress,
      setError,
      onClose,
      setJobId,
    })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Télécharger les preuves de vérification en masse"
      className="modal--proofs"
      modalId="ModalVerificationProofs"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Content>
          {loading ? (
            <>
              <Wrapper justifyContent="center" alignItems="center">
                <Box mb={2}>
                  <Loader />
                </Box>
                <Typography variant="body2" mb={0.5} sx={{ color: 'text.primary' }}>
                  Génération du rapport : {progress}%
                </Typography>
              </Wrapper>
            </>
          ) : (
            <>
              <Card sx={{ boxShadow: 'none' }}>
                <Typography variant="body2" color="text.primary" mb={2} sx={{ opacity: 0.7 }}>
                  Sélectionnez la période pour laquelle vous souhaitez récupérer les preuves de
                  vérification :
                </Typography>
                <DateRangeInput shortcuts={shortcuts} />
              </Card>
            </>
          )}
          {error ? (
            <Typography variant="subtitle1" color="error.text" mt={2}>
              {error}
            </Typography>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Stack flexGrow={1} direction="row" justifyContent="flex-end">
            <Button type="submit">Générer et télécharger le fichier</Button>
          </Stack>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default ModalVerificationProofs

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default, // TOCHECK
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(6),
}))
