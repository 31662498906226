import * as React from 'react'

import { connect } from 'react-redux'
import { LogOutIcon, SettingsIcon } from 'icons'
import { getInitials } from 'utils'

import {
  handleTooltipClose,
  handleTooltipOpen,
  onLogoutClick,
  onSettingsClick,
} from '../../core/LayoutMiniSidebar.helpers'

import {
  Avatar,
  ClickAwayListener,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'

interface IProps {
  user: any
}

const MiniSidebarProfile: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { user } = props

  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  return (
    <ClickAwayListener onClickAway={handleTooltipClose(setTooltipOpen)}>
      <StyledTooltip
        title={
          <>
            <StyledTooltipOption
              p={1}
              direction="row"
              alignItems="center"
              onClick={onSettingsClick}
            >
              <SettingsIcon sx={{ mr: 1, fontSize: 14 }} />
              <Typography variant="subtitle1">Préférences</Typography>
            </StyledTooltipOption>
            <StyledTooltipOption
              p={1}
              direction="row"
              alignItems="center"
              onClick={onLogoutClick}
            >
              <LogOutIcon sx={{ mr: 1, fontSize: 14 }} />
              <Typography variant="subtitle1">Deconnexion</Typography>
            </StyledTooltipOption>
          </>
        }
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose(setTooltipOpen)}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <StyledAvatar
          alt={user.picture ? 'profile picture' : undefined}
          src={user.picture ? user.picture : undefined}
          onClick={handleTooltipOpen(setTooltipOpen)}
        >
          {!user.picture
            ? getInitials({
                firstName: user.firstName,
                lastName: user.lastName,
              })
            : null}
        </StyledAvatar>
      </StyledTooltip>
    </ClickAwayListener>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(MiniSidebarProfile)

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& + .MuiTooltip-popper': {
    '.MuiTooltip-tooltip': {
      overflow: 'hidden',
      padding: 0,
      background: 'white',
      boxShadow: theme.shadows[2],
    },
  },
}))

const StyledTooltipOption = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeOut,
  }),

  '&:hover': {
    background: theme.palette.secondaryLight.main,
  },
}))

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: 32,
  width: 32,
  background: theme.palette.primary.main,
  cursor: 'pointer',
  color: theme.palette.primary.contrastText,
  ...theme.typography.subtitle1,
}))
