import { Components, Theme } from '@mui/material'

const MuiTabs: NonNullable<Components<Theme>['MuiTabs']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `1px solid ${theme.palette.divider}`,
    }),
    flexContainer: ({ theme }) => ({
      gap: theme.spacing(5),
      padding: `0 ${theme.spacing(4)}`,
    }),
  },
}

export default MuiTabs
