import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  styled,
  SxProps,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { getCapitalizeName } from 'utils/miscellaneous'

interface IProps {
  id: string
  value?: string
  onChange: NonNullable<SelectProps['onChange']>
  label?: string
  disabled?: boolean
  placeholder?: string
  sx?: SxProps
  options: IDropDownOption[]
  size?: 'small' | 'medium'
}

export interface IDropDownOption {
  value?: string
  display: string
  icon?: React.ReactNode
  logo?: string
}

const DropDown: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { value, onChange, disabled, placeholder, sx, options, label, id, size } = props

  const handleRenderValue = (value: string) => {
    if (value === '' && placeholder) {
      return (
        <Typography
          sx={{ opacity: disabled ? 0.5 : null }}
          component="span"
          color="text.secondary"
          variant={size === 'small' ? 'subtitle2' : 'subtitle1'}
        >
          {placeholder}
        </Typography>
      )
    }

    const selectedIndex = options.findIndex((option) => option.value === value)
    const { display, icon, logo } = options[selectedIndex !== -1 ? selectedIndex : 0]

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {icon}
        {logo ? (
          <LogoWrapper justifyContent="center" alignItems="center">
            <img width={16} height={16} src={logo} alt={logo} key={logo} />
          </LogoWrapper>
        ) : null}
        {getCapitalizeName(display)}
      </Stack>
    )
  }

  return (
    <FormControl sx={sx}>
      {label ? (
        <InputLabel htmlFor={id} id={`${id}-label`} size={size === 'small' ? 'small' : 'normal'}>
          {label}
        </InputLabel>
      ) : null}
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
        variant="outlined"
        displayEmpty
        renderValue={handleRenderValue}
        size={size}
        inputProps={{
          className: size === 'small' ? 'MuiSelect-inputSizeSmall' : undefined,
        }}
        MenuProps={{
          classes: {
            paper: 'MuiCustomSelectMenuPaper',
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            className={`MuiCustomSelectMenuItem ${
              size === 'small' ? 'MuiCustomSelectMenuItem-sizeSmall' : null
            }`}
            key={`${option.value}_${index}`}
            value={option.value}
          >
            <Stack direction="row" gap={1} alignItems="center">
              {option.icon}
              {option.logo ? (
                <Stack direction="row" alignItems="right">
                  <LogoWrapper justifyContent="center" alignItems="center">
                    <img
                      width={16}
                      height={16}
                      src={option.logo}
                      alt={option.logo}
                      key={option.logo}
                    />
                  </LogoWrapper>
                </Stack>
              ) : null}
              {getCapitalizeName(option.display)}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropDown

const LogoWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
}))
