import { get } from 'utils'
import { API_LOGOUT_URL } from 'api/constants'
import store from 'store'
import { USER_LOGOUT } from 'store/constants/user'
import { LOGIN_URL } from 'utils/router/constants'

export const getLogout =  async () => {
  await get({ url: API_LOGOUT_URL })

  store.dispatch({
    type: USER_LOGOUT
  })

  window.location.replace(LOGIN_URL)
}
