import * as React from 'react'

import { ClampedTypography } from 'component'

interface IProps {
  lines: number
  mb?: number
}

const Information: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <ClampedTypography
      {...props}
      variant="subtitle1"
      // @ts-ignore
      component="p"
      sx={{ color: 'text.primary' }}
      seeMoreProps={{
        variant: 'subtitle1',
        sx: { color: 'primary.main' },
      }}
    />
  )
}

export default Information
