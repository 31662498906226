import {
  FormControlLabel,
  Checkbox as MUICheckbox,
  SxProps,
  CheckboxProps,
  Box,
  Stack,
} from '@mui/material'
import { CheckIcon, MinusIcon } from 'icons'
import * as React from 'react'
import { getCheckboxClassName, getRootClassName } from './core/Checkbox.helpers'

interface IProps {
  sx?: SxProps
  name?: string
  checked: boolean
  indeterminate?: boolean
  onChange?: CheckboxProps['onChange']
  onClick?: React.MouseEventHandler
  label?: string
  size?: 'medium' | 'small'
  disabled?: boolean
}

const Checkbox: React.FC<IProps> = (props) => {
  const { sx, checked, label, name, onChange, onClick, indeterminate, size, disabled } = props

  return (
    <FormControlLabel
      sx={sx}
      label={label}
      className={getRootClassName(size)}
      control={
        <MUICheckbox
          name={name}
          className={getCheckboxClassName(size)}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          onClick={onClick}
          size={size}
          disabled={disabled}
          icon={<Box className="MuiCheckbox-icon"></Box>}
          checkedIcon={
            <Stack justifyContent="center" alignItems="center" className="MuiCheckbox-icon">
              <CheckIcon />
            </Stack>
          }
          indeterminateIcon={
            <Stack justifyContent="center" alignItems="center" className="MuiCheckbox-icon">
              <MinusIcon />
            </Stack>
          }
        />
      }
    />
  )
}

export default Checkbox
