import { GridColDef } from '@mui/x-data-grid-premium'
import DocketDirectionCell from 'component/Layouts/TableCells/DocketDirectionCell'
import DocketProductCell from 'component/Layouts/TableCells/DocketProductCell'
import DocketPercentCell from 'component/Layouts/TableCells/DocketPercentCell'
import DocketCurrencyCell from 'component/Layouts/TableCells/DocketCurrencyCell'
import { IAPIDocketItem } from 'api/interfaces'
import { Box, Button, Chip, Link, Stack, Tooltip, Typography, styled } from '@mui/material'
import { BuildingIcon, FileTextIcon, LinkIcon, UserIcon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import LifeBuoyIcon from 'icons/LifeBuoyIcon'
import { IAPICommissionContractToAffiliate } from 'api/interfaces/entities'
import moment from 'moment'
import { REPORTING_ANALYSIS_URL } from 'utils/router/constants'
import Theme from 'theme'
import { COMMISSION_KINDS_MAP } from 'utils/mapping'

const groupDivider = ' • '

const getPeriodLink = (contract: IAPICommissionContractToAffiliate, period: string) => {
  const dt = moment(period)

  const year = dt.format('YYYY')
  const month = dt.format('M')
  const type = COMMISSION_KINDS_MAP.outstanding_contract

  if (!contract.insurance) {
    return
  }

  return `${REPORTING_ANALYSIS_URL}/${contract.insurance.provider}/${year}/${month}/${type}?q=${contract.number}`
}

const showHelpTooltip = (periods: string[], user: any) => {
  const allMomentPeriods = periods.map((period) => moment(period, 'M-YYYY'))
  const mostRecentPeriod = moment.max(allMomentPeriods)

  return user.organization.contractsLastUpdatedAt
    ? mostRecentPeriod > moment(user.organization.contractsLastUpdatedAt)
    : false
}

const GROUPED_COLUMNS: GridColDef[] = [
  {
    field: 'contract',
    headerName: 'Contract',
    sortable: false,
    hideable: false,
    flex: 1,
    groupingValueGetter: (params) =>
      params.value.firstName +
      ' ' +
      params.value.lastName.toUpperCase() +
      groupDivider +
      params.value.number +
      (params.row.product ? groupDivider + params.row.product.name : ''),
    renderCell: (params) => {
      const spanValue = params.value.split(groupDivider)
      return (
        <>
          <Typography component="span" variant="subtitle1_m" mr={'4px'}>
            {spanValue[0]}{' '}
          </Typography>
          <Typography component="span" variant="subtitle1" sx={{ opacity: 0.7 }}>
            {groupDivider}
            {spanValue.slice(1).join(groupDivider)}
          </Typography>
        </>
      )
    },
  },
]

const COMMON_COLUMNS: GridColDef[] = [
  {
    field: 'ucits',
    headerName: `Support`,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    width: 300,
    renderCell: (params) => {
      if (!params.row.groupedItems) {
        return ''
      }
      return params.rowNode.type === 'group' ? null : (
        <DocketProductCell item={params.row} field="ucits" tag="div" />
      )
    },
  },
  {
    field: 'direction',
    headerName: 'Opération',
    sortable: false,
    flex: 2,
    minWidth: 185,
    renderCell: (params) => {
      if (params.row.groupedItems && params.row.groupedItems.length > 0) {
        return '-'
      }
      return params.rowNode.type === 'group' ? null : (
        <DocketDirectionCell item={params.row} field="direction" value={params.value} tag="div" />
      )
    },
  },
  {
    field: 'effectiveDate',
    headerName: "Date d'effet",
    sortable: false,
    flex: 1,
    minWidth: 100,
    valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),
  },
  {
    field: 'baseAmount',
    headerName: 'Assiette',
    sortable: false,
    flex: 1,
    minWidth: 100,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) =>
      params.rowNode.type === 'group' ? null : (
        <DocketCurrencyCell item={params.row} field="baseAmount" value={params.value} tag="div" />
      ),
  },
]

const EXPANDABLE_DEFAULT_COLUMNS: GridColDef[] = [
  ...COMMON_COLUMNS,
  {
    field: 'organizationRate',
    headerName: 'Part apporteur',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) =>
      params.rowNode.type === 'group' ? null : (
        <DocketPercentCell
          item={params.row}
          field="organizationRate"
          value={params.value}
          tag="div"
        />
      ),
  },
  {
    field: 'organizationAmount',
    headerName: 'Commission CGP',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      let total = params.value
      if (params.row.groupedItems) {
        params.row.groupedItems.forEach((groupItem: IAPIDocketItem) => {
          total += groupItem.organizationAmount
        })
      }

      return params.rowNode.type === 'group' ? null : (
        <DocketCurrencyCell item={params.row} field="organizationAmount" value={total} tag="div" />
      )
    },
  },
]

const DEFAULT_COLUMNS: GridColDef[] = [...GROUPED_COLUMNS, ...EXPANDABLE_DEFAULT_COLUMNS]

const EXPANDABLE_ARBITRAGE_COLUMNS: GridColDef[] = [
  ...COMMON_COLUMNS,
  {
    field: 'organizationRate',
    headerName: 'Taux client',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) =>
      params.rowNode.type === 'group' ? null : (
        <DocketPercentCell
          item={params.row}
          field="organizationRate"
          value={params.row.insuranceRate + params.row.organizationRate}
          tag="div"
        />
      ),
  },
  {
    field: 'customerAmount',
    headerName: 'Frais client',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      let total = params.value
      if (params.row.groupedItems) {
        params.row.groupedItems.forEach((groupItem: IAPIDocketItem) => {
          total += groupItem.customerAmount
        })
      }

      return params.rowNode.type === 'group' ? null : (
        <DocketCurrencyCell item={params.row} field="customerAmount" value={total} tag="div" />
      )
    },
  },
  {
    field: 'insuranceAmount',
    headerName: 'Commission assureur',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      let total = params.value
      if (params.row.groupedItems) {
        params.row.groupedItems.forEach((groupItem: IAPIDocketItem) => {
          if (total && groupItem.insuranceAmount) {
            total += groupItem.insuranceAmount
          }
        })
      }

      return params.rowNode.type === 'group' ? null : (
        <DocketCurrencyCell item={params.row} field="insuranceAmount" value={total} tag="div" />
      )
    },
  },
  {
    field: 'organizationAmount',
    headerName: 'Commission CGP',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      let total = params.value
      if (params.row.groupedItems) {
        params.row.groupedItems.forEach((groupItem: IAPIDocketItem) => {
          total += groupItem.organizationAmount
        })
      }

      return params.rowNode.type === 'group' ? null : (
        <DocketCurrencyCell item={params.row} field="organizationAmount" value={total} tag="div" />
      )
    },
  },
]

const ARBITRAGE_COLUMNS: GridColDef[] = [...GROUPED_COLUMNS, ...EXPANDABLE_ARBITRAGE_COLUMNS]

const CLIENTS_COLUMNS: GridColDef[] = [
  {
    field: 'lastName',
    headerName: `Identité`,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    width: 300,
    renderCell: (params) => {
      return (
        <>
          {params.row.clientLegalForm === 'legal_entity' ? (
            <BuildingIcon sx={{ opacity: 0.7, mr: 1 }} />
          ) : (
            <UserIcon sx={{ opacity: 0.7, mr: 1 }} />
          )}
          <Typography component="span" variant="subtitle1_m">
            {params.row.lastName.toUpperCase()} {params.row.firstName}
          </Typography>
          {params.row.isNew && (
            <Chip sx={{ ml: 2 }} label="Nouveau" size="small" variant="outlined" color="info" />
          )}
        </>
      )
    },
  },
  {
    field: 'totalContractCount',
    headerName: 'Contrats',
    sortable: false,
    flex: 2,
    minWidth: 185,
    renderCell: (params) => {
      return (
        <>
          <Typography component="span" variant="subtitle1" sx={{ opacity: 0.7 }}>
            {params.value} {params.value > 1 ? 'contrats' : 'contrat'}
          </Typography>
          {params.row.manuallyAttachedContractCount > 0 && (
            <CustomNeutralChip
              sx={{ ml: 2 }}
              label={`${params.row.manuallyAttachedContractCount} ${
                params.row.manuallyAttachedContractCount > 1 ? 'rattachés' : 'rattaché'
              }`}
              variant="outlined"
              size="small"
              icon={<LinkIcon sx={{ width: '16px' }} />}
            />
          )}
        </>
      )
    },
  },
  {
    field: 'insurances',
    headerName: 'Assureurs',
    sortable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Stack gap={1} direction="row" alignItems="center">
          {params.value.map((insurer: string) => (
            <img
              width="24px"
              height="24px"
              src={getProviderLogo(insurer)}
              alt={insurer}
              key={insurer}
            />
          ))}
        </Stack>
      )
    },
  },
]

const CONTRACTS_AFFILIATION_COLUMNS = (
  setOpenContract: StateSetter<IAPICommissionContractToAffiliate | null>,
  user: any
) =>
  [
    {
      field: 'number',
      headerName: `Numéro de contrat`,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      flex: 1,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Typography component="span" variant="subtitle1_m">
              {params.value}
            </Typography>
          </>
        )
      },
    },
    {
      field: 'reportingClientName',
      headerName: 'Client',
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <Typography component="i" variant="subtitle1_m" sx={{ opacity: 0.7 }}>
              {params.value}
            </Typography>
          </>
        )
      },
      valueGetter: (params) =>
        params.row.lastName + (params.row.firstName ? ' ' + params.row.firstName : ''),
    },
    {
      field: 'productName',
      headerName: 'Produit',
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Stack gap={1} direction="row" alignItems="center">
            <img
              width="24px"
              height="24px"
              src={getProviderLogo(params.row.insurance.provider)}
              alt={params.row.insurance.provider}
            />
            <Typography component="span" variant="subtitle1_m">
              {params.value}
            </Typography>
          </Stack>
        )
      },
      valueGetter: (params) => {
        if (params.row.insuranceProduct) {
          return params.row.insuranceProduct.name
        }
      },
    },
    {
      field: 'docketPeriods',
      headerName: 'Bordereaux concernés',
      sortable: false,
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" flex={1} alignItems="center" justifyContent="space-between">
            <Stack gap={1} direction="row" alignItems="center" flexWrap="wrap">
              {params.value.slice(0, 3).map((period: string, index: number) => (
                <Typography component="span" variant="subtitle1" key={period}>
                  <Link
                    href={getPeriodLink(params.row, period)}
                    color="neutralLight.contrastText"
                    sx={{ opacity: 0.7 }}
                    variant="subtitle1"
                    textTransform="capitalize"
                    target="_blank"
                  >
                    {moment(period).format('MMM YYYY')}
                  </Link>
                  {index !== params.value.length - 1 && (
                    <span className="ml-1" style={{ opacity: 0.3 }}>
                      {groupDivider}
                    </span>
                  )}
                </Typography>
              ))}
              {params.value.length > 3 && (
                <Tooltip
                  title={
                    <Box sx={{ width: 300 }}>
                      <Typography
                        component="p"
                        variant="subtitle1_m"
                        color="neutralLight.contrastText"
                        mb={1}
                      >
                        {params.value.length - 3} autres bordereaux identifiés
                      </Typography>
                      {params.value.slice(3).map((period: string, index: number) => (
                        <Typography component="span" variant="subtitle1" key={`${period}_${index}`}>
                          <Link
                            href={getPeriodLink(params.row, period)}
                            color="neutralLight.contrastText"
                            sx={{ opacity: 0.7 }}
                            variant="subtitle1"
                            textTransform="capitalize"
                            target="_blank"
                          >
                            {moment(period).format('MMM YYYY')}
                          </Link>
                          {index !== params.value.slice(3).length - 1 && (
                            <span
                              className="ml-1"
                              style={{
                                opacity: 0.3,
                                color: Theme.palette.neutralLight.contrastText,
                                margin: '0 4px',
                              }}
                            >
                              {groupDivider}
                            </span>
                          )}
                        </Typography>
                      ))}
                    </Box>
                  }
                >
                  <Stack
                    bgcolor="background.background03"
                    borderRadius="14px"
                    direction="row"
                    alignItems="center"
                    p="5px"
                    fontSize={12}
                    fontWeight={700}
                    height="28px"
                  >
                    +{params.value.length - 3}
                    <FileTextIcon sx={{ width: 12 }} />
                  </Stack>
                </Tooltip>
              )}
              {showHelpTooltip(params.value, user) && (
                <Tooltip
                  title={
                    <Box sx={{ width: 300 }}>
                      <Typography
                        component="p"
                        variant="subtitle1_m"
                        color="neutralLight.contrastText"
                        mb={1}
                      >
                        Votre dernier import client est antérieur à ces bordereaux
                      </Typography>
                      <Typography
                        component="p"
                        variant="subtitle1"
                        color="neutralLight.contrastText"
                        sx={{ opacity: 0.7 }}
                        mb={2}
                      >
                        Vérifiez la présence de ce client dans votre CRM, puis mettez à jour votre
                        base de données client.
                      </Typography>
                      <Button color="secondary" size="small">
                        Mettre à jour ma base
                      </Button>
                    </Box>
                  }
                >
                  <CustomHelpChip icon={<LifeBuoyIcon />} size="small" />
                </Tooltip>
              )}
            </Stack>
            <CustomAffiliationButton
              startIcon={<LinkIcon />}
              size="small"
              sx={{ opacity: 0.7, flexShrink: 0 }}
              className="client-affiliation-btn"
              color="neutral"
              onClick={() => setOpenContract(params.row)}
            >
              <span>Rattacher à un client de votre base</span>
            </CustomAffiliationButton>
          </Stack>
        )
      },
      valueGetter: (params) => {
        if (params.row.userInsuranceContractPeriods) {
          return params.row.userInsuranceContractPeriods.docketPeriods
        }
      },
    },
  ] as GridColDef[]

export {
  EXPANDABLE_DEFAULT_COLUMNS,
  EXPANDABLE_ARBITRAGE_COLUMNS,
  DEFAULT_COLUMNS,
  ARBITRAGE_COLUMNS,
  CLIENTS_COLUMNS,
  CONTRACTS_AFFILIATION_COLUMNS,
}

const CustomNeutralChip = styled(Chip)(({ theme }) => ({
  background: `${theme.palette.neutralLight.dark} !important`,
  color: theme.palette.neutralLight.contrastText,
}))

const CustomHelpChip = styled(Chip)(({ theme }) => ({
  background: `${theme.palette.accentHelpLight.main} !important`,
  color: `${theme.palette.accentHelp.main} !important`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  width: 24,

  svg: {
    marginLeft: '8px !important',
  },
}))

const CustomAffiliationButton = styled(Button)(({ theme }) => ({
  background: `${theme.palette.neutralLight.light} !important`,
  color: theme.palette.neutralLight.contrastText,

  'span:has(svg)': {
    marginRight: '0 !important',
  },

  'span:has(svg) + span': {
    width: 0,
    overflow: 'hidden',
    marginLeft: 0,
    opacity: 0,
    transition: `all 0.2s ${theme.transitions.easing.easeIn}`,
  },

  '&:hover span:has(svg) + span': {
    width: 200,
    marginLeft: 4,
    opacity: 1,
  },
}))
