interface ICommissionKind {
  [key: string]: string
}

export const COMMISSION_KINDS_MAP: ICommissionKind = {
  outstanding_contract: 'encours-contrat',
  outstanding_delegate: 'mandats',
  outstanding_ucits: 'opcvm',
  arbitration: 'arbitrages',
  deposit: 'versements',
  upfront: 'upfronts',
  outstanding_override: 'majorations',
  unknown: 'autres',
}

export const ERROR_DIRECTION_MAP: ICommissionKind = {
  issuing: 'Émission',
  takeback: 'Reprise de commission',
}

export const getCommissionKindKeyByValue = (value?: string) => {
  if (!value) return undefined
  return Object.keys(COMMISSION_KINDS_MAP).find((k) => COMMISSION_KINDS_MAP[k] === value)
}
