import { CheckboxProps } from '@mui/material'
import { IInsuranceProductsGroupedByFamily } from 'utils'

export const getFamilyGroupChecked = ({
  selectedInsuranceProductsIdxs,
  familyGroupIdx,
}: {
  selectedInsuranceProductsIdxs: number[][]
  familyGroupIdx: number
}): boolean => {
  return selectedInsuranceProductsIdxs[familyGroupIdx] !== undefined &&
    selectedInsuranceProductsIdxs[familyGroupIdx].length !== 0
    ? true
    : false
}

export const getFamilyGroupIndeterminate = ({
  selectedInsuranceProductsIdxs,
  familyGroupIdx,
  familyGroupProductCount,
}: {
  selectedInsuranceProductsIdxs: number[][]
  familyGroupIdx: number
  familyGroupProductCount: number
}): boolean => {
  return selectedInsuranceProductsIdxs[familyGroupIdx] !== undefined &&
    selectedInsuranceProductsIdxs[familyGroupIdx].length !== 0 &&
    selectedInsuranceProductsIdxs[familyGroupIdx].length !== familyGroupProductCount
    ? true
    : false
}

export const getFamilyGroupCheckedChangeHandler = ({
  selectedInsuranceProductsIdxs,
  setSelectedInsuranceProductsIdxs,
  familyGroupIdx,
  familyGroupProductCount,
}: {
  selectedInsuranceProductsIdxs: number[][]
  setSelectedInsuranceProductsIdxs: StateSetter<number[][]>
  familyGroupIdx: number
  familyGroupProductCount: number
}): CheckboxProps['onChange'] => {
  return (_, checked) => {
    const newState =
      selectedInsuranceProductsIdxs === undefined ? [] : [...selectedInsuranceProductsIdxs]

    if (checked) {
      newState[familyGroupIdx] = Array.from({ length: familyGroupProductCount }).map(
        (_, idx) => idx
      )
    } else {
      if (
        newState[familyGroupIdx] !== undefined &&
        newState[familyGroupIdx].length !== 0 &&
        newState[familyGroupIdx].length !== familyGroupProductCount
      ) {
        newState[familyGroupIdx] = Array.from({ length: familyGroupProductCount }).map(
          (_, idx) => idx
        )
      } else {
        newState[familyGroupIdx] = []
      }
    }

    setSelectedInsuranceProductsIdxs(newState)
  }
}

export const getProductChecked = ({
  selectedInsuranceProductsIdxs,
  familyGroupIdx,
  productIdx,
}: {
  selectedInsuranceProductsIdxs: number[][]
  familyGroupIdx: number
  productIdx: number
}): boolean => {
  return selectedInsuranceProductsIdxs[familyGroupIdx] !== undefined &&
    selectedInsuranceProductsIdxs[familyGroupIdx].includes(productIdx)
    ? true
    : false
}

export const getProductCheckedChangeHandler = ({
  selectedInsuranceProductsIdxs,
  setSelectedInsuranceProductsIdxs,
  familyGroupIdx,
  productIdx,
}: {
  selectedInsuranceProductsIdxs: number[][]
  setSelectedInsuranceProductsIdxs: StateSetter<number[][]>
  familyGroupIdx: number
  productIdx: number
}): CheckboxProps['onChange'] => {
  return (_, checked) => {
    let newState =
      selectedInsuranceProductsIdxs === undefined ? [] : [...selectedInsuranceProductsIdxs]

    if (checked) {
      if (newState[familyGroupIdx] === undefined) {
        newState[familyGroupIdx] = []
      }

      newState[familyGroupIdx].push(productIdx)
    } else {
      const currIdx = newState[familyGroupIdx].indexOf(productIdx)

      newState[familyGroupIdx].splice(currIdx, 1)
    }

    setSelectedInsuranceProductsIdxs(newState)
  }
}

export const getSelectedInsuranceProductsIds = ({
  selectedInsuranceProductsIdxs,
  insuranceProductsGroupedByFamily,
}: {
  selectedInsuranceProductsIdxs: number[][]
  insuranceProductsGroupedByFamily: IInsuranceProductsGroupedByFamily[]
}): number[] => {
  return selectedInsuranceProductsIdxs.reduce<number[]>((acc, curr, famIdx) => {
    if (!curr) {
      return acc
    }

    curr.forEach((selectedInsrPrdsIdx) => {
      acc.push(insuranceProductsGroupedByFamily[famIdx].products[selectedInsrPrdsIdx].id)
    })

    return acc
  }, [])
}

export const getDefaultSelectedInsuranceProductsIdxs = ({
  defaultSelectedInsuranceProductsIds,
  insuranceProductsGroupedByFamily,
}: {
  defaultSelectedInsuranceProductsIds?: number[]
  insuranceProductsGroupedByFamily: IInsuranceProductsGroupedByFamily[]
}): number[][] => {
  if (defaultSelectedInsuranceProductsIds === undefined) {
    return []
  }

  return insuranceProductsGroupedByFamily.reduce<number[][]>(
    (defaultSelectedInsuranceProductsIdxs, familyGroup, familyGroupIdx) => {
      const familyGroupProductsIds = familyGroup.products.map((product) => product.id)

      const selectedInsuranceProductsIdxs = familyGroupProductsIds.reduce<number[]>(
        (selectedInsuranceProductsIdxs, productId, productIdIdx) => {
          if (defaultSelectedInsuranceProductsIds.includes(productId)) {
            selectedInsuranceProductsIdxs.push(productIdIdx)
          }

          return selectedInsuranceProductsIdxs
        },
        []
      )

      if (selectedInsuranceProductsIdxs.length !== 0) {
        defaultSelectedInsuranceProductsIdxs[familyGroupIdx] = selectedInsuranceProductsIdxs
      }

      return defaultSelectedInsuranceProductsIdxs
    },
    []
  )
}
