import * as yup from 'yup'

export const schemaAddress = yup.object().shape({
  organizationAddress: yup.string().required().min(1),
  organizationZipCode: yup.string().required().min(1),
  organizationCity: yup.string().required().min(1),
  organizationCountry: yup.string().required().min(1),
})

export const schemaOrgnizationAddress = yup.object().shape({
  organizationName: yup.string().required('Champ obligatoire'),
  organizationSiret: yup
    .string()
    .required('Champ obligatoire')
    .length(14, 'Le SIRET se compose de 14 chiffres'),
  userFirstName: yup.string().required('Champ obligatoire'),
  userLastName: yup.string().required('Champ obligatoire'),
  userPhone: yup.string().required('Champ obligatoire'),
})

export const schemaAccount = yup.object().shape({
  email: yup.string().email('Email obligatoire').required('Champ obligatoire'),
  password: yup.string().required('Champ obligatoire').min(8, '8 caractères minimum'),
  passwordRepeat: yup.string().required('Champ obligatoire').min(8, '8 caractères minimum'),
})

export const schemaOptin = yup.object().shape({
  billingEmail: yup.string().when('isSameEmail', {
    is: false,
    then: (schema) => schema.email('Email obligatoire').required('Champ obligatoire'),
  }),
  acceptTerms: yup.boolean().oneOf([true], 'Champ obligatoire'),
  optInNews: yup.boolean(),
  optInCompliance: yup.boolean(),
  isSameEmail: yup.boolean(),
})
