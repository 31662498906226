import { patch } from 'utils'
import { API_ARBITRAGE_FEE_URL, API_DEPOSIT_FEE_URL, API_UPFRONT_FEE_URL } from 'api/constants'

export const patchConfigurationPropagation = ({
  feesType,
  editedConfigurationId,
  insuranceProductIds,
}: {
  feesType: 'arbitration' | 'deposit' | 'upfront'
  editedConfigurationId: number
  insuranceProductIds: number[]
}) => {
  const body = {
    insuranceProductIds,
  }

  let url: string

  switch (feesType) {
    case 'arbitration':
      url = `${API_ARBITRAGE_FEE_URL}/${editedConfigurationId}`
      break
    case 'deposit':
      url = `${API_DEPOSIT_FEE_URL}/${editedConfigurationId}`
      break
    case 'upfront':
      url = `${API_UPFRONT_FEE_URL}/${editedConfigurationId}`
  }

  return patch({
    url,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
}
