import React from 'react'

function ProfileImagePlaceholder(props: any) {
  const { firstName, lastName, picture, classNameImage, className } = props

  const getFirstLetter = (text: string) => text && text.charAt(0).toUpperCase()

  return (
    picture ? (
      <img src={picture} alt="" className={`img-circle ${classNameImage || ''}`}/>
    )
      :
      <span className={`img-circle img-placeholder ${className || ''}`}>
        {getFirstLetter(firstName)}
        {getFirstLetter(lastName)}
      </span>
  )
}
export default ProfileImagePlaceholder
