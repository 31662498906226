import * as React from 'react'

import { Modal } from 'component'
import {
  IAPIInsuranceFeeArbitrage,
  IAPIInsuranceFeeDeposit,
  IAPIInsuranceFeeUpfront,
} from 'api/interfaces'

import { DeletionStep, FinalStep } from './components'

interface IProps {
  feesType: 'arbitration' | 'deposit' | 'upfront'
  open: boolean
  closeModal: () => void
  providerLogo: string
  insuranceName: string
  insuranceProductName: string
  insuranceProductId: number
  editedConfiguration: IAPIInsuranceFeeArbitrage | IAPIInsuranceFeeDeposit | IAPIInsuranceFeeUpfront
  setEditedConfiguration:
    | StateSetter<IAPIInsuranceFeeArbitrage | undefined>
    | StateSetter<IAPIInsuranceFeeDeposit | undefined>
    | StateSetter<IAPIInsuranceFeeUpfront | undefined>
}

const ModalDeleteFees: React.FC<IProps> = (props) => {
  const {
    feesType,
    open,
    closeModal,
    setEditedConfiguration,
    providerLogo,
    insuranceName,
    insuranceProductName,
    insuranceProductId,
    editedConfiguration,
  } = props

  const [step, setStep] = React.useState(1)
  const [error, setError] = React.useState<string>()

  const resetState = () => {
    setStep(1)
    setError(undefined)
    setEditedConfiguration(undefined)
  }

  const onCloseHandler = () => {
    closeModal()
    resetState()
  }

  const goToNextStep = () => {
    setStep((prevState) => prevState + 1)
  }

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title="Suppression de la règle"
      modalId="ModalDeleteFees"
    >
      {step === 1 ? (
        <DeletionStep
          providerLogo={providerLogo}
          insuranceName={insuranceName}
          insuranceProductName={insuranceProductName}
          insuranceProductId={insuranceProductId}
          editedConfiguration={editedConfiguration}
          feesType={feesType}
          setError={setError}
          onCloseHandler={onCloseHandler}
          goToNextStep={goToNextStep}
        />
      ) : step === 2 ? (
        <FinalStep
          insuranceProductName={insuranceProductName}
          error={error}
          resetState={resetState}
        />
      ) : null}
    </Modal>
  )
}

export default ModalDeleteFees
