import * as React from 'react'
import {
  Home,
  Login,
  SettingsCabinet,
  SettingsDetails,
  SettingsProfile,
  SettingsSecurity,
  Search,
  Clients,
  FileTransfer,
  UI,
  Contracts,
  Dockets,
  Imports,
} from 'page'

import { Redirect, Route } from 'react-router-dom'
import SubscriptionManager from 'page/Subscription/SubscriptionManager'
import { ClientsAnalysis } from 'page/Commissions'

export const DEFAULT_LANG = 'fr'

export const FROZEN_ASSETS_ENTITIES_URL = '/gel-avoirs'
const PREFERENCES_URL = '/preferences'
export const REPORTING_URL = '/reporting'
export const SUBSCRIPTION_URL = '/souscription'

export const HOMEPAGE_URL = '/'
export const LOGIN_URL = '/login'
export const FILE_TRANSFER_URL = '/filetransfer'
export const UI_URL = '/UI'

export const FROZEN_ASSETS_ENTITIES_SEARCH_URL = `${FROZEN_ASSETS_ENTITIES_URL}/recherche`
export const FROZEN_ASSETS_ENTITIES_CLIENTS_URL = `${FROZEN_ASSETS_ENTITIES_URL}/base-clients`
export const PROOFS_OF_VERIFICATION_CLIENTS_URL = `${FROZEN_ASSETS_ENTITIES_URL}/preuves-de-verification`

export const PREFERENCES_PROFILE_URL = `${PREFERENCES_URL}/profile`
export const PREFERENCES_CABINET_URL = `${PREFERENCES_URL}/cabinet`
export const PREFERENCES_DETAILS_URL = `${PREFERENCES_URL}/details`
export const PREFERENCES_SECURITY_URL = `${PREFERENCES_URL}/security`

export const SUBSCRIPTION_ACCOUNT_URL = `${SUBSCRIPTION_URL}/compte`
export const SUBSCRIPTION_EMAIL_URL = `${SUBSCRIPTION_URL}/email`
export const SUBSCRIPTION_ADDRESS_URL = `${SUBSCRIPTION_URL}/coordonnees`
export const SUBSCRIPTION_OPTIN_URL = `${SUBSCRIPTION_URL}/cgu`
export const SUBSCRIPTION_PAYMENT_URL = `${SUBSCRIPTION_URL}/paiement`
export const SUBSCRIPTION_WELCOME_URL = `${SUBSCRIPTION_URL}/bienvenue`

export const REPORTING_ANALYSIS_URL = `${REPORTING_URL}/analyse`
export const REPORTING_CONFIGURATION_URL = `${REPORTING_URL}/parametrage`
export const REPORTING_HISTORY_URL = `${REPORTING_URL}/historique`

export const REPORTING_CLIENT_ANALYSIS_URL = `${REPORTING_URL}/analyse-clients`
export const REPORTING_CLIENT_ANALYSIS_DATA_URL = `${REPORTING_CLIENT_ANALYSIS_URL}/base-clients`
export const REPORTING_CLIENT_ANALYSIS_AFFILIATION_URL = `${REPORTING_CLIENT_ANALYSIS_URL}/rattachement-manuel`

export const ROUTES: React.ReactElement[] = [
  <Route key="home" path={HOMEPAGE_URL} component={Home} exact />,
  <Route key="file_transfer" path={FILE_TRANSFER_URL} component={FileTransfer} exact />,
  <Route key="login" path={LOGIN_URL} component={Login} exact />,
  <Route
    key="frozen_assets_search"
    path={FROZEN_ASSETS_ENTITIES_SEARCH_URL}
    component={Search}
    exact
  />,
  <Route key="frozen_assets" path={FROZEN_ASSETS_ENTITIES_URL} component={Clients} />,
  <Redirect
    key="clients"
    from={`${FROZEN_ASSETS_ENTITIES_URL}*`}
    to={FROZEN_ASSETS_ENTITIES_CLIENTS_URL}
  />,
  <Route
    key="reporting_analysis"
    path={`${REPORTING_ANALYSIS_URL}/:provider?/:year?/:month?/:commission?`}
    component={Dockets}
    exact
  />,
  <Route
    key="reporting_config"
    path={`${REPORTING_CONFIGURATION_URL}/:insuranceProvider?/:insuranceFamily?/:insuranceProductId?/:commissionType?`}
    component={Contracts}
    exact
  />,
  <Route key="reporting_history" path={REPORTING_HISTORY_URL} component={Imports} />,
  <Route key="reporting_client" path={REPORTING_CLIENT_ANALYSIS_URL} component={ClientsAnalysis} />,
  <Redirect
    key="redirect_client"
    from={`${REPORTING_CLIENT_ANALYSIS_URL}*`}
    to={REPORTING_CLIENT_ANALYSIS_DATA_URL}
  />,
  <Redirect key="redirect_reporting" from={`${REPORTING_URL}*`} to={REPORTING_ANALYSIS_URL} />,
  <Route
    key="preferences_profile"
    path={PREFERENCES_PROFILE_URL}
    component={SettingsProfile}
    exact
  />,
  <Route
    key="preferences_cabinet"
    path={PREFERENCES_CABINET_URL}
    component={SettingsCabinet}
    exact
  />,
  <Route
    key="preferences_details"
    path={PREFERENCES_DETAILS_URL}
    component={SettingsDetails}
    exact
  />,
  <Route
    key="preferences_security"
    path={PREFERENCES_SECURITY_URL}
    component={SettingsSecurity}
    exact
  />,
  <Route key="subscription" path={SUBSCRIPTION_URL} component={SubscriptionManager} />,
  <Route key="ui" path={UI_URL} component={UI} exact />,
]
