import { API_QUEUE_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIQueueId } from 'api/interfaces'
import * as React from 'react'

const useQueueProgress = ({
  interval,
  queueId,
  jobId,
  setJobId,
  setProgress,
  onComplete,
  onError,
}: {
  interval: number
  queueId: IAPIQueueId
  jobId: string | undefined
  setJobId: StateSetter<string | undefined>
  setProgress?: React.Dispatch<React.SetStateAction<number>>
  onComplete: (data: any) => void
  onError: (error?: string) => void
}) => {
  React.useEffect(() => {
    if (!jobId) {
      return
    }

    const intervalId = setInterval(async () => {
      const res = await customFetch
        .get(`${API_QUEUE_URL}/${queueId}/${jobId}`)
        .catch((err) => console.log(err))

      if (!res) {
        onError()
        clearInterval(intervalId)
        setJobId(undefined)

        return
      }

      const jsonRes = await res.json().catch((err) => {
        console.log(err)
      })

      if (!res.ok) {
        onError(jsonRes.message || undefined)
        clearInterval(intervalId)
        setJobId(undefined)

        return
      }

      if (jsonRes.state === 'completed') {
        clearInterval(intervalId)

        if (jsonRes.data?.error || !jsonRes.data) {
          onError(
            jsonRes.data?.error
              ? jsonRes.data?.error.message
                ? jsonRes.data?.error.message
                : jsonRes.data?.error
              : undefined
          )
          setJobId(undefined)

          return
        }

        if (setProgress) {
          setProgress(100)
        }
        onComplete(jsonRes.data)
        setJobId(undefined)

        return jsonRes.data
      }

      if (jsonRes.state === 'failed') {
        clearInterval(interval)
        onError()
        setJobId(undefined)

        return
      }

      if (setProgress) {
        setProgress(Math.round(jsonRes.progress * 100))
      }
    }, interval)

    return () => clearInterval(intervalId)
  }, [interval, jobId, queueId, setProgress, onComplete, onError, setJobId])
}

export default useQueueProgress
