import { Typography, TypographyProps, styled } from '@mui/material'
import * as React from 'react'

interface IProps extends TypographyProps {}

const TypographyCell: React.FC<IProps> = (props) => {
  const { children, ...otherProps } = props

  return (
    <StyledTypography title={typeof children === 'string' ? children : undefined} {...otherProps}>
      {children}
    </StyledTypography>
  )
}

export default TypographyCell

const StyledTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  transition: theme.transitions.create('color', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeOut,
  }),
}))
