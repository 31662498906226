import * as React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const SettingsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.5C20 4.67157 19.3284 4 18.5 4C17.6716 4 17 4.67157 17 5.5C17 6.32843 17.6716 7 18.5 7C19.3284 7 20 6.32843 20 5.5ZM18.5 2C20.433 2 22 3.567 22 5.5C22 7.433 20.433 9 18.5 9C16.9145 9 15.5752 7.94575 15.1449 6.5C14.5996 6.50007 14.2421 6.50152 13.9678 6.52393C13.6962 6.54612 13.5955 6.5838 13.546 6.60899C13.3578 6.70487 13.2049 6.85785 13.109 7.04601C13.0838 7.09545 13.0461 7.19617 13.0239 7.46784C13.0008 7.75117 13 8.12345 13 8.7L13 15.3C13 15.8766 13.0008 16.2488 13.0239 16.5322C13.0461 16.8038 13.0838 16.9045 13.109 16.954C13.2049 17.1422 13.3578 17.2951 13.546 17.391C13.5955 17.4162 13.6962 17.4539 13.9678 17.4761C14.2421 17.4985 14.5996 17.4999 15.1449 17.5C15.5752 16.0543 16.9145 15 18.5 15C20.433 15 22 16.567 22 18.5C22 20.433 20.433 22 18.5 22C16.9145 22 15.5752 20.9457 15.1449 19.5C14.6248 19.5 14.1756 19.4997 13.805 19.4694C13.4096 19.4371 13.0164 19.3658 12.638 19.173C12.0735 18.8854 11.6146 18.4265 11.327 17.862C11.1342 17.4836 11.0629 17.0904 11.0306 16.695C11 16.3205 11 15.8657 11 15.3385L11 13L8.85506 13C8.42479 14.4457 7.08551 15.5 5.5 15.5C3.567 15.5 2 13.933 2 12C2 10.067 3.567 8.5 5.5 8.5C7.08551 8.5 8.42479 9.55426 8.85506 11L11 11L11 8.66148C11 8.13432 11 7.67955 11.0306 7.30497C11.0629 6.90963 11.1342 6.51641 11.327 6.13803C11.6146 5.57354 12.0735 5.1146 12.638 4.82698C13.0164 4.63419 13.4096 4.56287 13.805 4.53057C14.1756 4.50029 14.6248 4.49998 15.1449 4.5C15.5752 3.05425 16.9145 2 18.5 2ZM20 18.5C20 17.6716 19.3284 17 18.5 17C17.6716 17 17 17.6716 17 18.5C17 19.3284 17.6716 20 18.5 20C19.3284 20 20 19.3284 20 18.5ZM7 12C7 11.1716 6.32843 10.5 5.5 10.5C4.67157 10.5 4 11.1716 4 12C4 12.8284 4.67157 13.5 5.5 13.5C6.32843 13.5 7 12.8284 7 12Z"
      />
    </SvgIcon>
  )
}

export default SettingsIcon
