import { Components, Theme } from "@mui/material";

const MuiPopover: NonNullable<Components<Theme>["MuiPopover"]> = {
  styleOverrides: {
    paper: ({ theme }) => ({
      background: theme.palette.background.default, // TOCHECK
      border: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(1.5),
      ".MuiMenu-list": {
        padding: 0,
      },
      ".MuiMenuItem-root": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      ".MuiMenuItem-root:last-child": {
        borderBottom: "none",
      },
    }),
  },
};

export default MuiPopover;
