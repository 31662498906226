import * as React from 'react'

import { Modal } from 'component'
import { AlertCircleIcon, CheckCircleIcon, DownloadIcon, RotateCcwIcon } from 'icons'

import { Button, Link, Stack, styled, Typography } from '@mui/material'

interface IProps {
  errorReportGenerationError: string | undefined
  errorReportFileUrl: string | undefined
  resetState: () => void
}

const FinalStep: React.FC<IProps> = (props) => {
  const { errorReportGenerationError, errorReportFileUrl, resetState } = props

  return (
    <Modal.Content>
      <Wrapper alignItems="center">
        {errorReportGenerationError !== undefined ? (
          <AlertCircleIcon color="error" sx={{ color: 'error.contrastText', mb: 2.75 }} />
        ) : (
          <CheckCircleIcon color="success" sx={{ color: 'success.main', mb: 2.75 }} />
        )}
        <Typography
          variant="h3b"
          mb={errorReportGenerationError ? 0.5 : 3}
          sx={{ color: 'text.primary' }}
        >
          {errorReportGenerationError
            ? `Problème lors de l'export des erreurs`
            : 'Vos fichiers sont prêts'}
        </Typography>
        {errorReportGenerationError ? (
          <Typography
            variant="subtitle1"
            textAlign="center"
            mb={3}
            sx={{
              color: 'error.text',
            }}
          >
            {errorReportGenerationError}
          </Typography>
        ) : null}
        {errorReportGenerationError ? (
          <Button startIcon={<RotateCcwIcon />} color="secondary" onClick={resetState}>
            Recommencer l'export
          </Button>
        ) : (
          <Button component={Link} href={errorReportFileUrl} download startIcon={<DownloadIcon />}>
            Télécharger mes fichiers
          </Button>
        )}
      </Wrapper>
    </Modal.Content>
  )
}

export default FinalStep

const Wrapper = styled(Stack)(() => ({
  width: 450,
  margin: 'auto',
}))
