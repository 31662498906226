import { Button } from '@mui/material'
import React, { FC } from 'react'
import { handleNextClick, handlePrevClick } from './core/pagination.helpers'
import { IProps } from './core/pagination.types'

const Pagination: FC<IProps> = ({
  page,
  setPage,
  pageSize,
  total,
  className,
}) => {
  const isLastPage = page === Math.ceil(total / pageSize)
  const isFirstPage = page === 1

  return (
    <div className={`pagination ${className || ''}`}>
      <Button
        disabled={isFirstPage}
        variant="outlined"
        color="secondary"
        onClick={handlePrevClick(page, setPage)}
        sx={{ width: 42 }}
      >
        <i className="icon-arrow-left"></i>
      </Button>
      <div className="pagination__number">
        {total < pageSize
          ? `${total} sur ${total}`
          : `${(page - 1) * pageSize}-${
              isLastPage ? total : page * pageSize
            } sur ${total}`}
      </div>
      <Button
        disabled={isLastPage}
        variant="outlined"
        color="secondary"
        onClick={handleNextClick(page, setPage, total, pageSize)}
        sx={{ width: 42 }}
      >
        <i className="icon-arrow-right"></i>
      </Button>
    </div>
  )
}

export default Pagination
