const custom400Translations: { [key: string]: string }[] = [
  {
    en: 'Invalid recaptcha token',
    fr: 'Le token recaptcha est incorrect.',
  },
  {
    en: 'Invalid validation key',
    fr: 'La clé de validation est invalide.',
  },
  {
    en: 'Email does not match',
    fr: 'Cet e-mail ne correspond à aucune demande de souscription.',
  },
  {
    en: 'Please validate your email before continuing registration',
    fr: 'Merci de valider votre e-mail avant de continuer la souscription.',
  },
  {
    en: 'This SIRET is not registered in the official governmental database',
    fr: "Ce SIRET n'est pas enregistré dans la base de données du gouvernement.",
  },
  {
    en: 'Email address should not be from a disposable email service',
    fr: 'L\'adresse e-mail utilisée ne doit pas être une adresse temporaire ou à usage unique.'
  },
  {
    en: 'userPhone',
    fr: 'Le numéro de téléphone est incorrect.',
  },
]

export const getCustomError = (error: string) => {
  const { status, message } = JSON.parse(error)
  switch (status) {
    case 400:
      return (
        custom400Translations.find((tr) => message.includes(tr.en))?.fr ||
        'La requête est incorrecte.'
      )
    case 403:
      return "L'adresse e-mail n'est associée à aucune demande de souscription."
    case 409:
      return "L'adresse e-mail est déjà utilisée sur un compte Sendraise."
    case 410:
      return 'Votre compte est déjà créé.'
    default:
      return 'Une erreur est survenue. Si le problème persiste, contactez le support Sendraise.'
  }
}

export const getResumeKey = () => {
  return localStorage.getItem('resumeKey')
}

export const getJsonResponse = async (res: any) => {
  const jsonRes: void | any = await res.json().catch((err: any) => {
    throw new Error(err.message)
  })

  return jsonRes
}

export const manageApiError = async (res: any) => {
  if (res) {
    const json = await getJsonResponse(res)

    const message = json?.message || json[0]?.message || ''

    throw new Error(JSON.stringify({ status: res?.status || 500, message }))
  } else {
    throw new Error(JSON.stringify({ status: 500, message: '' }))
  }
}
