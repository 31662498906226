import * as React from 'react'

import { PlusIcon, Share2Icon } from 'icons'
import { getInsuranceProductsGroupedByFamily, IInsuranceProductsGroupedByFamily } from 'utils'
import { AccordionBase, AccordionContent, AccordionHeader } from 'component/Accordion'

import { Box, Stack, styled, SxProps, Typography } from '@mui/material'

interface IProps {
  sx?: SxProps
  insuranceProducts: IInsuranceProductsGroupedByFamily['products']
}

const FeePropagationViewer: React.FC<IProps> = (props) => {
  const { sx, insuranceProducts } = props

  const insuranceProductsGroupedByFamily = getInsuranceProductsGroupedByFamily(insuranceProducts)

  return (
    <Box sx={sx}>
      {insuranceProductsGroupedByFamily.map((familyGroup) => (
        <AccordionBase defaultExpanded key={familyGroup.familyName}>
          <AccordionHeader expandIcon={<PlusIcon fontSize="small" />}>
            <Typography variant="subtitle1" fontWeight={500} sx={{ color: 'text.primary' }}>
              {familyGroup.familyName}
            </Typography>
          </AccordionHeader>
          <AccordionContent>
            {familyGroup.products.map((product) => (
              <InsuranceProduct
                key={product.id}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                  {product.name}
                </Typography>
                <Share2Icon sx={{ fontSize: 16, color: 'primary.main' }} />
              </InsuranceProduct>
            ))}
          </AccordionContent>
        </AccordionBase>
      ))}
    </Box>
  )
}

export default FeePropagationViewer

const InsuranceProduct = styled(Stack)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(1.25),
  },
}))
