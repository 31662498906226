import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const MinusIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 11.4477 5.44772 11 6 11L18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13L6 13C5.44772 13 5 12.5523 5 12Z"
      />
    </SvgIcon>
  )
}

export default MinusIcon
