import { useState, useRef, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import LayoutPreferences from 'component/Layouts/LayoutPreferences'
import useButtonState from 'utils/hooks/useButtonState'
import InputText from 'component/Inputs/InputText'
import { updateNames } from 'store/actions/user'
import { customFetch } from 'api/customFetch'
import { API_ME_URL } from 'api/constants'
import { getErrorByName } from 'utils'
import { Button } from '@mui/material'
import { LayoutMenu } from 'component/Layouts'

interface IProps {
  user?: any
  dispatch: Function
}

function SettingsProfile(props: IProps) {
  const { user, dispatch } = props
  const { firstName, lastName } = user

  const refLastName = useRef<HTMLInputElement>(null)
  const refFirstName = useRef<HTMLInputElement>(null)

  const [isChanged, bindChange] = useButtonState(false)
  const [isDisabled, bindDisable] = useButtonState(true)

  const [errors, setErrors] = useState()

  function handleChangeNames(e: SyntheticEvent) {
    e.preventDefault()
    const lastName = refLastName?.current?.value
    const firstName = refFirstName?.current?.value
    const data = { firstName, lastName }

    customFetch
      .patch(API_ME_URL, data)
      .then((response: any) => {
        if (response.ok) {
          bindChange(true)
          dispatch(updateNames(data))
        } else {
          response.json().then((errs: any) => {
            setErrors(errs)
          })
        }
      })

      .catch((error: any) => {
        console.log('Failed to change names: ' + error)
      })
  }

  return (
    <LayoutMenu location="preferences" defaultMenuOpen={false} pageWhite={true}>
      <div className="page__content preferences">
        <LayoutPreferences>
          <div className="preferences-profile__main container mt-8">
            <form
              className="row f-jc-start mb-6 f f-ai-end f-wrap"
              onSubmit={(e: any) => handleChangeNames(e)}
            >
              <div className="col col-md-4 mb-3 mb-md-0">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="lastName">
                  Nom
                </label>
                <InputText
                  id="lastName"
                  className="ft-500 c-black"
                  type="text"
                  defaultValue={lastName}
                  register={refLastName}
                  flex
                  required
                  error={getErrorByName('lastName', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                  placeholder="Ajouter un nom"
                />
              </div>
              <div className="col col-md-4">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="firstName">
                  Prénom
                </label>
                <InputText
                  id="firstName"
                  className="ft-500 c-black"
                  type="text"
                  defaultValue={firstName}
                  register={refFirstName}
                  flex
                  required
                  error={getErrorByName('firstName', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                  placeholder="Ajouter un prénom"
                />
              </div>
              <Button
                type="submit"
                disabled={isDisabled}
                className={`${isChanged ? 'btn--success' : ''}`}
                sx={{ ml: 2 }}
              >
                {isChanged ? 'Modifié' : 'Modifier'}
              </Button>
            </form>
          </div>
        </LayoutPreferences>
      </div>
    </LayoutMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(SettingsProfile)
