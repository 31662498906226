import * as React from 'react'
import { getMIMEType } from 'utils'

export const getFormatsString = (formats: string[]): string => {
  return formats.reduce<string>((acc, curr, idx) => {
    if (idx === 0) {
      return curr
    }

    if (idx === formats.length - 1) {
      return `${acc} ou ${curr}`
    }

    return `${acc}, ${curr}`
  }, '')
}

export const handleDrop = ({
  onChange,
  formats,
}: {
  onChange: (file: File) => void
  formats: ISupportedFileExtension[]
}): React.DragEventHandler => {
  return (e) => {
    e.preventDefault()
    const supportedMIMETypes = formats.map((format) => getMIMEType(format))

    const file = e.dataTransfer.items
      ? e.dataTransfer.items[0].getAsFile()
      : e.dataTransfer.files[0]

    if (file === null || !supportedMIMETypes.includes(file.type)) {
      return
    }

    onChange(file)
  }
}

export const handleChange = (
  onChange: (file: File) => void
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    onChange(e.target.files[0])
  }
}

export const handleWrapperClick = (
  inputRef: React.RefObject<HTMLInputElement>
): React.MouseEventHandler => {
  return () => {
    if (inputRef.current === null) {
      return
    }

    inputRef.current.click()
  }
}
