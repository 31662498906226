import { useRef, SyntheticEvent, useState } from 'react'
import { connect } from 'react-redux'
import LayoutPreferences from 'component/Layouts/LayoutPreferences'
import InputText from 'component/Inputs/InputText'
import { customFetch } from 'api/customFetch'
import { API_ME_URL } from 'api/constants'
import useButtonState from 'utils/hooks/useButtonState'
import { getErrorByName } from 'utils'
import { Button } from '@mui/material'
import { LayoutMenu } from 'component/Layouts'

function SettingsSecurity() {
  const refOldPassword = useRef<HTMLInputElement>(null)
  const refNewPassword = useRef<HTMLInputElement>(null)
  const refConfirmPassword = useRef<HTMLInputElement>(null)

  const [isChanged, bindChange] = useButtonState(false)
  const [isDisabled, bindDisable] = useButtonState(true)

  const [errors, setErrors] = useState<any>()

  function handleChangePassword(e: SyntheticEvent) {
    e.preventDefault()
    if (refNewPassword?.current?.value === refConfirmPassword?.current?.value) {
      const newPassword = refNewPassword?.current?.value
      const oldPassword = refOldPassword?.current?.value

      customFetch
        .patch(API_ME_URL, { newPassword, oldPassword })
        .then((response) => {
          if (response.ok) {
            bindChange(true)
          } else {
            response.json().then((errs: any) => {
              if (Array.isArray(errs)) {
                setErrors(errs)
              }
            })
          }
        })
        .catch((error: any) => console.log('Failed to change password: ' + error))
    } else {
      setErrors([
        {
          key: 'newPassword',
          message: 'Les mots de passe ne correspondent pas',
        },
      ])
    }
  }

  return (
    <LayoutMenu location="preferences" defaultMenuOpen={false} pageWhite={true}>
      <div className="page__content">
        <LayoutPreferences>
          <form
            className="preferences-profile__main container mt-8"
            onSubmit={(e: any) => handleChangePassword(e)}
          >
            <div className="row f-jc-start mb-6 f f-ai-end f-wrap">
              <div className="col col-md-4 mb-3 mb-md-0">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="current-password">
                  Mot de passe actuel
                </label>
                <InputText
                  id="current-password"
                  className="ft-500 c-black"
                  register={refOldPassword}
                  placeholder="Ajouter votre mot de passe actuel"
                  type="password"
                  autoComplete="current-password"
                  required
                  error={getErrorByName('oldPassword', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                />
              </div>
            </div>
            <div className="row f-jc-start f f-wrap mb-6">
              <div className="col col-md-4 mb-3 mb-md-0">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="new-password">
                  Nouveau mot de passe
                </label>
                <InputText
                  id="new-password"
                  className="ft-500 c-black"
                  register={refNewPassword}
                  placeholder="Ajouter un nouveau mot de passe"
                  type="password"
                  flex
                  required
                  minLength={8}
                  error={getErrorByName('newPassword', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                />
              </div>
              <div className="col col-md-4 mb-3 mb-md-0">
                <label className="f mb-1 ft-secondary ft-500 c-blue-400" htmlFor="confirmation">
                  Confirmation
                </label>
                <InputText
                  className="ft-500 c-black"
                  register={refConfirmPassword}
                  placeholder="Ajouter à nouveau le mot de passe"
                  id="confirmation"
                  type="password"
                  autoComplete="new-password"
                  flex
                  required
                  minLength={8}
                  error={getErrorByName('newPassword', errors)}
                  onChange={() => {
                    bindChange(false)
                    bindDisable(false)
                  }}
                />
              </div>
            </div>
            <Button
              type="submit"
              disabled={isDisabled}
              className={`${isChanged ? 'btn--success' : ''}`}
              sx={{ mt: 2 }}
            >
              {isChanged ? 'Modifié' : 'Changer mon mot de passe'}
            </Button>
          </form>
        </LayoutPreferences>
      </div>
    </LayoutMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(SettingsSecurity)
