import * as React from 'react'

import { Box, Button, CircularProgress, Stack, Typography, styled } from '@mui/material'
// import { PROOFS_OF_VERIFICATION_CLIENTS_URL } from 'utils/router/constants'
import { handleModalTriggerClick } from '../../core/Clients.helpers'
import { ModalDownloadClientsDocuments } from 'component/Modals'
import { PROOFS_OF_VERIFICATION_CLIENTS_URL } from 'utils/router/constants'

interface IProps {
  dbUpdateDate: string
  dbUpdateDelta: string
  loading: boolean
  disabled: boolean
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void
}

const SuspectAction: React.FC<IProps> = (props) => {
  const { dbUpdateDate, dbUpdateDelta, handleTabChange, loading } = props
  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <Wrapper>
      {loading ? (
        <LoaderWrapper alignItems="center" justifyContent="center">
          <CircularProgress sx={{ color: 'text.primary' }} />
        </LoaderWrapper>
      ) : (
        <>
          <Stack direction="row" alignItems="center" mb={0.5}>
            <img
              height={13}
              width={36}
              src="/static/images/government-logo.svg"
              alt="Logo du gouvernement français"
            />
            <Typography variant="subtitle1" color="text.primary" ml={1} fontWeight={500}>
              Registre du gel des avoirs
            </Typography>
          </Stack>
          <Typography variant="subtitle2" color="text.secondary" mb={2}>
            Mis à jour {dbUpdateDelta} ({dbUpdateDate})
          </Typography>
          <Button
            color="secondary"
            onClick={(e) => handleTabChange(e, PROOFS_OF_VERIFICATION_CLIENTS_URL)}
          >
            Voir les preuves de vérification
          </Button>
          <ModalDownloadClientsDocuments
            open={modalOpen}
            onClose={handleModalTriggerClick(setModalOpen, false)}
          />
        </>
      )}
    </Wrapper>
  )
}

export default SuspectAction

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  borderRadius: '0 4px 4px 0',
  background: theme.palette.background.paper,
  padding: theme.spacing(2),
}))

const LoaderWrapper = styled(Stack)(({ theme }) => ({
  height: 100,
}))
