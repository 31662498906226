import * as React from 'react'

import { getProviderLogo } from 'utils/commissions'

import { Box, Button, Grid, Popover, Stack, Typography, styled } from '@mui/material'
import { IAPIInsurance } from 'api/interfaces'
import { ChevronOpenIcon } from 'icons'

interface IProps {
  insurances: IAPIInsurance[]
  getInsuranceChangeHandler: (insurance: IAPIInsurance) => void
  selectedInsurance: IAPIInsurance
  reportCount: boolean
}

const InsuranceDropdown: React.FC<IProps> = (props) => {
  const { insurances, getInsuranceChangeHandler, selectedInsurance, reportCount } = props
  const [open, setOpen] = React.useState(false)
  const anchorEl = React.useRef(null)

  const handleClose = () => {
    setOpen(false)
  }

  const handleInsuranceSelection = (insurance: IAPIInsurance) => {
    getInsuranceChangeHandler(insurance)
    handleClose()
  }

  return (
    <Box>
      <CustomButton
        aria-describedby="insurances-dropdown"
        onClick={() => setOpen(true)}
        color="neutralLight"
        ref={anchorEl}
        fullWidth
      >
        <img
          src={getProviderLogo(selectedInsurance.provider)}
          width={40}
          height={40}
          alt={`${selectedInsurance.name} logo`}
        />
        <Stack alignItems="start" width="100%">
          <Typography component="p" variant="subtitle1_m">
            {selectedInsurance.name}
          </Typography>
          {reportCount && (
            <Typography component="p" variant="subtitle2">
              {selectedInsurance.reportCount
                ? selectedInsurance.reportCount > 1
                  ? `${selectedInsurance.reportCount} bordereaux`
                  : '1 bordereau'
                : 'Aucun bordereau'}
            </Typography>
          )}
        </Stack>
        <ChevronOpenIcon sx={{ ml: 'auto' }} />
      </CustomButton>
      <CustomPopover
        id="insurances-dropdown"
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Wrapper>
          <Grid container spacing={2} alignItems="stretch" columns={4}>
            {insurances.map((insurance) => (
              <Grid key={insurance.id} item xs={1}>
                <InsuranceWrapper
                  onClick={() => handleInsuranceSelection(insurance)}
                  className={`${selectedInsurance?.id === insurance.id ? 'selected' : ''}`}
                >
                  <img
                    src={getProviderLogo(insurance.provider)}
                    width={24}
                    height={24}
                    alt={`${insurance.name} logo`}
                  />
                  <Stack alignItems="start">
                    <Typography component="p" variant="subtitle1_m" textTransform="uppercase">
                      {insurance.name}
                    </Typography>
                    {reportCount && (
                      <Typography component="p" variant="subtitle2">
                        {insurance.reportCount
                          ? insurance.reportCount > 1
                            ? `${insurance.reportCount} bordereaux`
                            : '1 bordereau'
                          : 'Aucun bordereau'}
                      </Typography>
                    )}
                  </Stack>
                </InsuranceWrapper>
              </Grid>
            ))}
          </Grid>
        </Wrapper>
        {/* todo when API ready */}
        {/* <Footer>
          <Typography component="p" variant="subtitle2">
            Vous avez activé 12 assureurs sur les 16 proposés par Sendraise.
          </Typography>
          <Button color="neutralLight">
            <PlusCircleIcon sx={{ mr: 1 }} /> Activer d’autres assureurs
          </Button>
        </Footer> */}
      </CustomPopover>
    </Box>
  )
}

export default InsuranceDropdown

const CustomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.divider,
  padding: '12px 16px',
  gap: 12,
  display: 'flex',
  height: 'auto',
}))

const CustomPopover = styled(Popover)(({ theme }) => ({
  '.MuiBackdrop-invisible': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}))

const Wrapper = styled(Box)(({ theme }) => ({
  width: 912,
  backgroundColor: theme.palette.common.white,
  padding: '24px 16px',
  zIndex: 10,
}))

// const Footer = styled(Box)(({ theme }) => ({
//   width: 912,
//   backgroundColor: theme.palette.background.default,
//   padding: '12px 24px',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   borderTop: `1px solid ${theme.palette.divider}`,
//   color: theme.palette.text.primary,
// }))

const InsuranceWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  color: theme.palette.text.primary,
  padding: '8px 16px',
  cursor: 'pointer',
  borderRadius: 4,
  border: '1px solid rgba(48, 129, 219, 0)',
  transition: '0.3s ease-in-out',

  '&.selected, &:hover': {
    background: theme.palette.primaryLight.main,
    border: '1px solid rgba(48, 129, 219, 0.50)',
  },
}))
