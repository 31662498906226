import { Components, Theme } from '@mui/material'

const MuiOutlinedInput: NonNullable<Components<Theme>['MuiOutlinedInput']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.neutralLight.main,
      cursor: 'pointer',

      transition: theme.transitions.create('box-shadow', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),

      // primary defaults, overriden in variant 'color="error"'
      boxShadow: theme.helpers.stateShadows.default.formElements.primary,
      '.MuiInputAdornment-root': {
        opacity: 0.7,
      },
      '.MuiInputAdornment-positionStart': {
        '>svg': {
          color: theme.palette.primary.main,
        },
      },
      '.MuiInputAdornment-positionEnd': {
        '>svg': {
          color: theme.palette.text.secondary,
        },
      },
      '.MuiInputBase-input': {
        '&::placeholder': {
          color: theme.palette.text.primary,
          opacity: `${0.4} !important`,
        },
      },

      // States
      '&:hover': {
        boxShadow: theme.helpers.stateShadows.hover.formElements.primary,

        '.MuiInputAdornment-root': {
          opacity: 1,
        },
      },

      '&.Mui-focused': {
        boxShadow: theme.helpers.stateShadows.focus.formElements.primary,

        '.MuiInputAdornment-root': {
          opacity: 1,
        },
      },

      '&.Mui-error': {
        border: `1px solid ${theme.palette.error.main}`,
      },

      '&.Mui-disabled': {
        boxShadow: theme.helpers.stateShadows.default.formElements.primary,
        opacity: 0.5,
        '.MuiInputAdornment-root': {
          opacity: 0.7,
        },
      },
    }),

    // Reset MUI defaults
    notchedOutline: {
      top: 0,
      padding: 0,
      border: 0,
      legend: {
        display: 'none',
      },
    },
  },
  variants: [
    {
      props: { color: 'error' },
      style: ({ theme }) => ({
        // Error defaults
        boxShadow: theme.helpers.stateShadows.default.formElements.error,
        '.MuiInputAdornment-positionStart': {
          '>svg': {
            color: theme.palette.error.main,
          },
        },

        // States
        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.formElements.error,
        },

        '&.Mui-focused': {
          boxShadow: theme.helpers.stateShadows.focus.formElements.error,
        },

        '&.Mui-disabled': {
          boxShadow: theme.helpers.stateShadows.default.formElements.error,
        },
      }),
    },
  ],
}

export default MuiOutlinedInput
