import { Divider, Stack, Typography, styled } from '@mui/material'
import { IAPIInsurance, IAPIUploadedReportCommission } from 'api/interfaces'
import { AlertTriangleIcon, ArrowRightIcon, CheckCircleIcon } from 'icons'
import { COMMISSION_KINDS } from 'page/Commissions/Dockets'
import { useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { ModalContext } from 'utils/hooks/modalBehavior'
import { COMMISSION_KINDS_MAP } from 'utils/mapping'
import { REPORTING_ANALYSIS_URL } from 'utils/router/constants'

interface ICommissionDetail {
  commission: IAPIUploadedReportCommission
  insurance: IAPIInsurance
  year: number
  month: number
}

export const CommissionDetail = ({ commission, insurance, year, month }: ICommissionDetail) => {
  const history = useHistory()
  const modal = useContext(ModalContext)

  const commissionType = useMemo(() => {
    return COMMISSION_KINDS.find((c) => c.value === commission.type)
  }, [commission])

  const handleCommissionClick = useCallback(
    (e: any) => {
      e.preventDefault()
      const commisisonTypeUrl = commissionType ? COMMISSION_KINDS_MAP[commissionType.value] : ''
      const url = `${REPORTING_ANALYSIS_URL}/${insurance.provider}/${year}/${month}/${commisisonTypeUrl}`

      if (insurance.provider === 'abeille') {
        modal.setCurrentModal(null)
      } else {
        modal.setCurrentModal({
          ...modal.currentModal,
          initialSelectedInsurance: insurance,
          collapsed: true,
        })
      }
      history.push(url)
    },
    [year, month, insurance, commissionType, modal, history]
  )

  return (
    <CustomCommissionDetail
      direction="row"
      alignItems="center"
      onClick={handleCommissionClick}
      color="neutralLight.contrastText"
    >
      <Typography variant="subtitle1">
        {COMMISSION_KINDS.find((c) => c.value === commission.type)?.label || ''}
      </Typography>
      <Divider component="div" sx={{ flex: 1, mx: '12px' }} />
      <Stack
        direction="row"
        alignItems="center"
        color={commission.errors ? 'warning.main' : 'success.main'}
      >
        <Typography
          variant="subtitle2_m"
          lineHeight="20px"
          color={commission.errors ? 'warning.main' : 'success.main'}
        >
          {commission.errors
            ? `${commission.errors} ${commission.errors > 1 ? 'erreurs' : 'erreurs'}`
            : ''}
        </Typography>
        {commission.errors ? (
          <AlertTriangleIcon sx={{ mx: '12px', fontSize: 16 }} />
        ) : (
          <CheckCircleIcon sx={{ mx: '12px', fontSize: 16 }} />
        )}
      </Stack>
      <ArrowRightIcon className="arrow-icon" />
    </CustomCommissionDetail>
  )
}

const CustomCommissionDetail = styled(Stack)(({ theme }) => ({
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeOut,
  }),
  padding: `${theme.spacing(1)} 12px`,
  borderRadius: 4,
  cursor: 'pointer',

  '&:hover': {
    background: '#E4E3DD',
    '.arrow-icon': {
      opacity: 0.4,
    },
  },

  '.arrow-icon': {
    opacity: 0,
    color: theme.palette.neutralLight.contrastText,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
}))
