import * as React from 'react'

import { getProviderLogo } from 'utils/commissions'

import { Box, Typography, styled } from '@mui/material'

interface IProps {
  name: string
  provider: string
  selected: boolean
  small?: boolean
  onClick: React.MouseEventHandler
}

const InsuranceSelector: React.FC<IProps> = (props) => {
  const { provider, name, small, onClick, selected } = props

  return (
    <Wrapper small={small} selected={selected} onClick={onClick} title={name}>
      <img
        src={getProviderLogo(provider)}
        width={small ? 24 : 40}
        height={small ? 24 : 40}
        alt={`${name} logo`}
      />
      <InsuranceName small={small} selected={selected} component="p" mt={1}>
        {name}
      </InsuranceName>
    </Wrapper>
  )
}

export default InsuranceSelector

const Wrapper = styled(Box, {
  shouldForwardProp: (propName) =>
    propName !== 'selected' && propName !== 'small',
})<{ selected: boolean; small?: boolean }>(({ theme, selected, small }) => ({
  borderRadius: 2,
  cursor: 'pointer',
  textAlign: 'center',
  border: `1px solid transparent`,
  paddingTop: theme.spacing(small ? 1 : 2),
  paddingRight: theme.spacing(small ? 1 : 2),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(small ? 1 : 2),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: selected ? theme.palette.primary.light : 'transparent',
  backgroundColor: selected ? theme.palette.primaryLight.main : 'transparent',

  '&:hover': {
    backgroundColor: selected ? theme.palette.primaryLight.main : theme.palette.secondaryLight.main,
  }
}))

const InsuranceName = styled(Typography, {
  shouldForwardProp: (propName) =>
    propName !== 'small' && propName !== 'selected',
})<{
  component: string
  small?: boolean
  selected: boolean
}>(({ theme, small, selected }) => ({
  color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
  ...(small
    ? { ...theme.typography.sectionHeading, letterSpacing: 1 }
    : { ...theme.typography.subtitle1, fontWeight: 500 }),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))
