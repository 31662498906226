import * as React from 'react'

import { connect } from 'react-redux'
import { useDebounce } from 'utils'
import { getProviderLogo } from 'utils/commissions'
import EventEmitter, { UPDATE_EVENT_DOCKETS_IMPORTS } from 'utils/events'
import { Trash2Icon, DownloadIcon } from 'icons'

import { SearchInput, Dropdown } from 'component/Inputs'
import { IDropDownOption } from 'component/Inputs/Dropdown'
import { BaseTable } from 'component/Tables'
import { ModalDeleteDocket } from 'component/Modals'
import { LayoutMenu } from 'component/Layouts'
import {
  LogoCell,
  ImportNameCell,
  ImportCommissionTypeCell,
  ImportUploaderCell,
} from 'component/Layouts/TableCells'

import {
  getNoContentMessage,
  handleFilterChange,
  handleSearchChange,
  handleSearchReset,
  updateAlldata,
} from './core/Imports.helpers'

import { useCommissionTypes } from './core/Imports.hooks'

import { Stack, styled, Typography } from '@mui/material'
import { GridActionsCellItem, GridRowParams, GridRowsProp } from '@mui/x-data-grid-premium'
import { GridColDef } from '@mui/x-data-grid-premium'

import ModalDownloadRawInsuranceFeeReport from 'component/Modals/ModalDownloadRawInsuranceFeeReport'
import { handleModalTriggerClick } from './core/Imports.helpers'

// TODO treat commission type at the source of the import
export type IImportRowCommissionType =
  | 'outstandingUcits'
  | 'outstandingOverride'
  | 'outstandingDelegate'
  | 'outstandingContract'
  | 'deposit'
  | 'arbitration'
  | 'upfront'
  | 'unknown'

interface IImportRowName {
  filename: string
  startDate: string
  endDate: string
}

interface IImportRowUpload {
  uploader: string
  uploadDate: string
  uploadTime: string
}

// TODO treat commission type at the source of the import
export interface IImportRow {
  id: number
  insurance: string
  name: IImportRowName
  commissionTypes: IImportRowCommissionType[]
  upload: IImportRowUpload
}

interface IProps {
  user: any
}

const Imports: React.FC<IProps> = (props) => {
  const { user } = props

  const [search, setSearch] = React.useState('')
  const [filter, setFilter] = React.useState('')
  const [rows, setRows] = React.useState<GridRowsProp<IImportRow>>()
  const [loading, setLoading] = React.useState(false)
  const [deletingRow, setDeletingRow] = React.useState<IImportRow | null>(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [insuranceFeeReportId, setInsuranceFeeReportId] = React.useState(0)

  const debouncedSearch = useDebounce(search, 800)
  const commissionTypes = useCommissionTypes()

  const FILTER_OPTIONS: IDropDownOption[] = Array.from(commissionTypes, (type) => {
    return { value: type.value, display: type.display }
  })

  FILTER_OPTIONS.unshift({
    value: '',
    display: 'Tous',
  })

  const handleDeleteClick = React.useCallback(
    (row: IImportRow): React.MouseEventHandler => {
      return () => {
        setDeletingRow(row)
      }
    },
    [setDeletingRow]
  )

  const COLUMNS: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'insurance',
        headerName: '',
        width: 56,
        sortable: false,
        renderCell(params) {
          return <LogoCell src={getProviderLogo(params.value)} alt={`${params.value} logo`} />
        },
      },
      {
        field: 'name',
        headerName: 'Nom',
        flex: 1,
        sortable: false,
        renderCell(params) {
          return (
            <ImportNameCell
              filename={params.value.filename}
              periodStart={params.value.startDate}
              periodEnd={params.value.endDate}
            />
          )
        },
      },
      {
        field: 'commissionTypes',
        flex: 1,
        flexWrap: 'wrap',
        headerName: 'Type de commission',
        sortable: false,
        renderCell(params) {
          return (
            <ImportCommissionTypeCell
              rowCommissionTypes={commissionTypes.filter(
                (type) => params.value.indexOf(type.key) !== -1
              )}
            />
          )
        },
      },
      {
        field: 'upload',
        width: 250,
        headerName: 'Upload',
        sortable: false,
        renderCell(params) {
          return (
            <ImportUploaderCell
              uploader={params.value.uploader}
              uploadDate={params.value.uploadDate}
              uploadTime={params.value.uploadTime}
            />
          )
        },
      },
      {
        field: 'actions',
        width: 88,
        headerName: '',
        type: 'actions',
        getActions: (params: GridRowParams<IImportRow>) => {
          const actions = [
            // @ts-ignore
            <GridActionsCellItem
              icon={<Trash2Icon sx={{ color: 'error.main' }} />}
              label="delete"
              onClick={handleDeleteClick(params.row)}
            />,
          ]
          if (user.role === 'admin') {
            actions.unshift(
              // @ts-ignore
              <GridActionsCellItem
                icon={<DownloadIcon sx={{ color: 'text.secondary' }} />}
                label="download"
                onClick={handleModalTriggerClick(
                  setModalOpen,
                  true,
                  setInsuranceFeeReportId,
                  params.row.id
                )}
              />
            )
          }

          return actions
        },
      },
    ],
    [handleDeleteClick, commissionTypes, user]
  )

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_DOCKETS_IMPORTS, () => {
      updateAlldata({ setLoading, debouncedSearch, filter, setRows })
    })
    return () => EventEmitter.remove(UPDATE_EVENT_DOCKETS_IMPORTS)
  }, [debouncedSearch, filter])

  React.useEffect(() => {
    updateAlldata({ setLoading, debouncedSearch, filter, setRows })
  }, [debouncedSearch, filter])

  if (!user.organization.enableReports) {
    window.location.href = '/'
    return <></>
  }

  return (
    <LayoutMenu location="reportings" defaultMenuOpen={true}>
      <Wrapper>
        <Typography sx={{ mb: 3 }} variant="h2">
          Historique des imports
        </Typography>
        <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="flex-end">
          <Stack direction="row">
            <SearchInput
              id="search"
              value={search}
              onChange={handleSearchChange(setSearch)}
              onReset={handleSearchReset(setSearch)}
              size="small"
              sx={{ mr: 1.5 }}
            />
            <Dropdown
              id="filter"
              value={filter}
              onChange={handleFilterChange(setFilter)}
              placeholder="Filtrer par"
              options={FILTER_OPTIONS}
              size="small"
            />
          </Stack>
          {rows ? (
            <Stack direction="row" alignItems="flex-end">
              <Typography
                variant="h3"
                fontWeight={500}
                sx={{ mr: 0.5 }}
                color="text.primary"
                lineHeight="23px"
              >
                {rows.length}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                ligne{rows.length > 1 ? 's' : null}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        <Stack flex={1} height="100vh">
          {rows !== undefined ? (
            <>
              <BaseTable
                columns={COLUMNS}
                rows={rows}
                loading={loading}
                autoHeight
                getRowHeight={() => 'auto'}
                noContentMessage={getNoContentMessage({
                  debouncedSearch,
                  filter,
                })}
              />
            </>
          ) : null}
        </Stack>
      </Wrapper>
      <ModalDeleteDocket
        deletingRow={deletingRow}
        onClose={() => setDeletingRow(null)}
        commissionTypes={commissionTypes.filter((type) =>
          deletingRow ? deletingRow.commissionTypes.indexOf(type.key) !== -1 : false
        )}
      />
      <ModalDownloadRawInsuranceFeeReport
        open={modalOpen}
        onClose={handleModalTriggerClick(
          setModalOpen,
          false,
          setInsuranceFeeReportId,
          insuranceFeeReportId
        )}
        insuranceFeeReportId={insuranceFeeReportId}
      />
    </LayoutMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Imports)

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
}))
