import * as React from 'react'

import { isIsinCode } from 'utils'
import { PlusCircleIcon, CornerDownRightIcon, InfoIcon } from 'icons'
import { InsuranceProductsSelector, Modal, FeePropagationViewer } from 'component'
import { BaseInput, NumberInput, Switch } from 'component/Inputs'
import {
  IAPIInsuranceFeeArbitrage,
  IAPIInsuranceFeeDeposit,
  IAPIInsuranceFeeUpfront,
  IAPIInsuranceProduct,
} from 'api/interfaces'

import {
  addArbitrationDepositConfiguration,
  addUpfrontConfiguration,
  getArbitrationDepositNextStepDisabled,
  getChipLabel,
  getDefaults,
  getEditionChipLabel,
  getFeeRateChangeHandler,
  getModalCTA,
  getArbitrationDepositOrganizationRateChangeHandler,
  getUpfrontNextStepDisabled,
  updateAndPropagateArbitrationDepositConfiguration,
  updateAndPropagateUpfrontConfiguration,
  updateArbitrationDepositConfiguration,
  updateUpfrontConfiguration,
} from '../../core/ModalConfigureFees.helpers'

import { Box, Button, Chip, CircularProgress, Stack, SwitchProps, Typography } from '@mui/material'

interface IProps {
  providerLogo: string
  insuranceName: string
  insuranceProductId: number
  insuranceProductName: string
  insuranceProducts: IAPIInsuranceProduct[]
  setError: StateSetter<string | undefined>
  onCloseHandler: () => void
  goToNextStep: () => void
  feesType: 'arbitration' | 'deposit' | 'upfront'
  editedConfiguration:
    | IAPIInsuranceFeeArbitrage
    | IAPIInsuranceFeeDeposit
    | IAPIInsuranceFeeUpfront
    | undefined
}

const ConfigurationStep: React.FC<IProps> = (props) => {
  const {
    providerLogo,
    insuranceName,
    insuranceProductId,
    insuranceProductName,
    insuranceProducts,
    setError,
    onCloseHandler,
    goToNextStep,
    feesType,
    editedConfiguration,
  } = props

  const edition = editedConfiguration !== undefined
  const defaults = getDefaults(editedConfiguration, edition, edition)

  const [loading, setLoading] = React.useState(false)
  const [propagate, setPropagate] = React.useState(defaults.propagate)
  const [feeRate, setFeeRate] = React.useState<number | undefined>(defaults.feeRate)
  const [comment, setComment] = React.useState(defaults.comment)
  const [isin, setIsin] = React.useState(defaults.isin)
  const [checkIsin, setCheckIsin] = React.useState(false)
  const [isStructuredProduct, setIsStructuredProduct] = React.useState(defaults.isStructuredProduct)
  const [insuranceRate, setInsuranceRate] = React.useState<number | undefined>(
    defaults.insuranceRate
  )
  const [organizationRate, setOrganizationRate] = React.useState<number | undefined>(
    defaults.organizationRate
  )
  const [intermediaryRate, setIntermediaryRate] = React.useState<number | undefined>(
    defaults.intermediaryRate
  )
  const [insuranceMinimumAmount, setInsuranceMinimumAmount] = React.useState<number | undefined>(
    defaults.insuranceMinimumAmount
  )
  const [selectedInsuranceProductsIds, setSelectedInsuranceProductsIds] = React.useState<number[]>(
    []
  )
  const [organizationMinimumAmount, setOrganizationMinimumAmount] = React.useState<
    number | undefined
  >(defaults.organizationMinimumAmount)

  const handleCommentChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setComment(e.target.value)
  }

  const handleIsinChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsin(e.target.value)
    setCheckIsin(false)
  }

  const handleIsinCheck = () => {
    setCheckIsin(true)
  }

  const handleInsuranceProductsSelectionChange = (newSelectedInsuranceProductsIds: number[]) => {
    setSelectedInsuranceProductsIds(newSelectedInsuranceProductsIds)
  }

  const propagateChangeHandler: SwitchProps['onChange'] = (e) => {
    setPropagate(e.target.checked)
  }

  const isStructuredProductChangeHandler: SwitchProps['onChange'] = (e) => {
    setIsStructuredProduct(e.target.checked)

    if (!e.target.checked) {
      setIntermediaryRate(undefined)
    }
  }

  const addConfigurationBtnClickHandler = () => {
    if (feesType === 'arbitration' || feesType === 'deposit') {
      if (
        feeRate === undefined ||
        organizationRate === undefined ||
        organizationMinimumAmount === undefined ||
        insuranceMinimumAmount === undefined
      ) {
        return
      }

      addArbitrationDepositConfiguration({
        setError,
        setLoading,
        insuranceProductId,
        selectedInsuranceProductsIds,
        feeRate,
        organizationRate,
        organizationMinimumAmount,
        insuranceMinimumAmount,
        comment,
        goToNextStep,
        feesType,
      })
    } else {
      if (!organizationRate) {
        return
      }

      addUpfrontConfiguration({
        setError,
        setLoading,
        insuranceProductId,
        selectedInsuranceProductsIds,
        organizationRate,
        comment,
        goToNextStep,
        isin,
        intermediaryRate,
      })
    }
  }

  const updateConfigurationBtnClickHandler = () => {
    if (feesType === 'arbitration' || feesType === 'deposit') {
      if (
        feeRate === undefined ||
        organizationRate === undefined ||
        organizationMinimumAmount === undefined ||
        insuranceMinimumAmount === undefined ||
        editedConfiguration === undefined ||
        insuranceProductsPropagatedToIds === undefined
      ) {
        return
      }

      if (propagate) {
        updateAndPropagateArbitrationDepositConfiguration({
          setError,
          setLoading,
          feeRate,
          organizationRate,
          organizationMinimumAmount,
          insuranceMinimumAmount,
          comment,
          editedConfigurationId: editedConfiguration.id,
          goToNextStep,
          feesType,
        })
      } else {
        updateArbitrationDepositConfiguration({
          setError,
          setLoading,
          insuranceProductId,
          insuranceProductsPropagatedToIds,
          feeRate,
          organizationRate,
          organizationMinimumAmount,
          insuranceMinimumAmount,
          comment,
          editedConfigurationId: editedConfiguration.id,
          goToNextStep,
          feesType,
        })
      }
    } else {
      if (
        organizationRate === undefined ||
        editedConfiguration === undefined ||
        insuranceProductsPropagatedToIds === undefined
      ) {
        return
      }

      if (propagate) {
        updateAndPropagateUpfrontConfiguration({
          setError,
          setLoading,
          organizationRate,
          comment,
          editedConfigurationId: editedConfiguration.id,
          goToNextStep,
          isin,
          intermediaryRate,
        })
      } else {
        updateUpfrontConfiguration({
          setError,
          setLoading,
          insuranceProductId,
          insuranceProductsPropagatedToIds,
          organizationRate,
          comment,
          editedConfigurationId: editedConfiguration.id,
          goToNextStep,
          isin,
          intermediaryRate,
        })
      }
    }
  }

  const organizationMinimumAmountChangeHandler: (value: number | undefined) => void = (value) => {
    setOrganizationMinimumAmount(value)
  }

  const insuranceMinimumAmountChangeHandler: (value: number | undefined) => void = (value) => {
    setInsuranceMinimumAmount(value)
  }

  const upfrontOrganizationRateChangeHandler: (value: number | undefined) => void = (value) => {
    setOrganizationRate(value)
  }

  const upfrontIntermediaryRateChangeHandler: (value: number | undefined) => void = (value) => {
    setIntermediaryRate(value)
  }

  const nextStepDisabled =
    feesType !== 'upfront'
      ? getArbitrationDepositNextStepDisabled({
          feeRate,
          organizationRate,
          insuranceRate,
          organizationMinimumAmount,
          insuranceMinimumAmount,
          comment,
        })
      : getUpfrontNextStepDisabled({
          organizationRate,
          comment,
          isin,
          isStructuredProduct,
          intermediaryRate,
        })

  const insuranceProductsPropagatedTo = edition
    ? editedConfiguration.insuranceProducts.filter((product) => product.id !== insuranceProductId)
    : undefined

  const insuranceProductsPropagatedToIds = insuranceProductsPropagatedTo
    ? insuranceProductsPropagatedTo.map((product) => product.id)
    : undefined

  return (
    <>
      <Modal.Content>
        <Stack direction="row" gap={1.5} mb={2}>
          <img height={32} width={32} src={providerLogo} alt={`${insuranceName} logo`} />
          <Typography variant="h2b" fontWeight={500}>
            {insuranceProductName}
          </Typography>
        </Stack>
        {feesType !== 'upfront' ? (
          <>
            <Stack
              p={3}
              gap={1.5}
              borderRadius={0.5}
              sx={(theme) => ({
                backgroundColor: 'background.default',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <NumberInput
                id="fee-rate"
                value={feeRate}
                placeholder="1,00%"
                label="Taux de frais client (%)"
                onChange={getFeeRateChangeHandler({
                  setFeeRate,
                  setOrganizationRate,
                  setInsuranceRate,
                })}
              />
              {feeRate !== undefined ? (
                <Stack direction="row" alignItems="flex-end" gap={1.5}>
                  <CornerDownRightIcon
                    sx={(theme) => ({
                      fontSize: 16,
                      color: 'text.secondary',
                      mb: theme.spacing(1.5),
                    })}
                  />
                  <NumberInput
                    id="organization-rate"
                    value={organizationRate}
                    placeholder="0,52%"
                    label="Taux de commission attribué au CGP"
                    onChange={getArbitrationDepositOrganizationRateChangeHandler({
                      feeRate,
                      setOrganizationRate,
                      setInsuranceRate,
                    })}
                    sx={{ flexGrow: 1 }}
                  />
                  <PlusCircleIcon
                    sx={(theme) => ({
                      fontSize: 16,
                      color: 'text.secondary',
                      mb: theme.spacing(1.5),
                    })}
                  />
                  <NumberInput
                    id="insurance-rate"
                    value={insuranceRate}
                    placeholder="0,48%"
                    label="Taux de commission attribué à l’assureur"
                    disabled
                    sx={{ flexGrow: 1 }}
                  />
                </Stack>
              ) : null}
            </Stack>
            <Stack
              direction="row"
              p={3}
              gap={2}
              mb={2}
              borderRadius={0.5}
              sx={{
                backgroundColor: 'background.default',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <NumberInput
                id="organization-minimum-amount"
                value={organizationMinimumAmount}
                onChange={organizationMinimumAmountChangeHandler}
                placeholder="0€"
                label="Incompressible CGP (€)"
                sx={{ flexGrow: 1 }}
              />
              <NumberInput
                id="insurance-minimum-amount"
                value={insuranceMinimumAmount}
                onChange={insuranceMinimumAmountChangeHandler}
                placeholder="0€"
                label="Incompressible assureur (€)"
                sx={{ flexGrow: 1 }}
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack
              p={3}
              gap={1.5}
              borderRadius={0.5}
              sx={(theme) => ({
                backgroundColor: '#F4F4F1',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              <BaseInput
                id="isin"
                value={isin}
                placeholder="Entrez votre code ISIN."
                label="Code ISIN"
                onChange={handleIsinChange}
                onBlur={handleIsinCheck}
              />
              {isin !== undefined && !isIsinCode(isin) && checkIsin && (
                <>
                  <Box mb={1}>
                    <Typography variant="subtitle1" sx={{ color: '#BC5F06' }}>
                      Le code ISIN doit comporter 2 lettres, puis 9 chiffres ou lettres, puis 1
                      chiffre (e.g.: AB123HI000Z1).
                    </Typography>
                  </Box>
                </>
              )}
              <Switch
                checked={isStructuredProduct}
                onChange={isStructuredProductChangeHandler}
                label="Il s’agit d’un produit structuré donnant lieu au versement d’une commission par un courtier en produit structuré."
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="end"
              flexWrap="wrap"
              p={3}
              rowGap={2}
              columnGap={4}
              mb={2}
              borderRadius={0.5}
              sx={{
                backgroundColor: '#F4F4F1',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <NumberInput
                id="organization-rate"
                value={organizationRate}
                placeholder="0,52%"
                label="Taux de commission versé par l'assureur (%)"
                onChange={upfrontOrganizationRateChangeHandler}
                sx={{ flex: 1, label: { whiteSpace: 'normal' } }}
              />
              {isStructuredProduct ? (
                <NumberInput
                  id="intermediate-rate"
                  value={intermediaryRate}
                  placeholder="0,48%"
                  label="Taux de commission versé par le courtier en produit structuré (%)"
                  onChange={upfrontIntermediaryRateChangeHandler}
                  sx={{ flex: 1, label: { whiteSpace: 'normal' } }}
                />
              ) : null}
              {isStructuredProduct ? (
                <Stack
                  direction="row"
                  gap={1}
                  borderRadius={0.5}
                  sx={(theme) => ({
                    backgroundColor: '#EBF3FD',
                    color: '#3081DB',
                    padding: theme.spacing(1, 1.5),
                  })}
                >
                  <InfoIcon sx={{ fontSize: 20 }} />
                  <Box>
                    <Typography variant="subtitle1" sx={{ color: '#3081DB' }}>
                      Les bordereaux de commissions complémentaires versées par les courtiers en
                      produit structuré ne sont pas prises en charge par Sendraise. En indiquant ici
                      le taux versé par le courtier, le reporting par client sera complété avec le
                      calcul automatique de la commission complémentaire.
                    </Typography>
                  </Box>
                </Stack>
              ) : null}
            </Stack>
          </>
        )}
        <Stack>
          <BaseInput
            id="comment"
            value={comment}
            placeholder="Décrire votre règle pour qu'elle soit identifiable et utilisable par les autres utilisateurs de votre cabinet."
            label="Description de la règle"
            onChange={handleCommentChange}
          />
        </Stack>
        {!edition || insuranceProductsPropagatedTo!.length > 0 ? (
          <>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mt={3.5}>
              <Switch
                checked={propagate}
                onChange={propagateChangeHandler}
                label={
                  edition
                    ? 'Propager la modification'
                    : 'Propager la règle à d’autres produits ou familles'
                }
              />
              <Chip
                color="secondary"
                label={
                  edition
                    ? getEditionChipLabel(insuranceProductsPropagatedTo!.length)
                    : getChipLabel(selectedInsuranceProductsIds.length)
                }
                size="small"
                sx={(theme) => ({
                  opacity: propagate ? 1 : 0,
                  transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.easeOut,
                  }),
                })}
              />
            </Stack>
            {propagate ? (
              edition ? (
                <FeePropagationViewer
                  insuranceProducts={insuranceProductsPropagatedTo!}
                  sx={{ mt: 2.5 }}
                />
              ) : (
                <InsuranceProductsSelector
                  insuranceProducts={insuranceProducts}
                  onChange={handleInsuranceProductsSelectionChange}
                  sx={{ mt: 2.5 }}
                />
              )
            ) : null}
          </>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <Button
            disabled={nextStepDisabled}
            onClick={edition ? updateConfigurationBtnClickHandler : addConfigurationBtnClickHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ height: `20px !important`, width: `20px !important`, color: 'white' }}
                />
              ) : undefined
            }
          >
            {getModalCTA(edition)}
          </Button>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default ConfigurationStep
