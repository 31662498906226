import React, { Component, createContext } from 'react'

export const ModalContext = createContext({
  component: null,
  props: {},
  showModal: (component: any, props = {}) => {},
  setCurrentModal: (currentModal: { [key: string]: any } | null) => {},
  hideModal: () => {},
  currentModal: null as { [key: string]: any } | null,
})

export class ModalProvider extends Component<React.PropsWithChildren> {
  showModal = (component: any, props = {}) => {
    const body = document.querySelector('body')
    body && body.classList.add('body--modal')

    this.setState({
      component,
      props,
    })
  }

  hideModal = () => {
    const body = document.querySelector('body')
    body && body.classList.remove('body--modal')

    this.setState({
      component: null,
      props: {},
    })
  }

  setCurrentModal = (currentModal: { [key: string]: any } | null) => {
    this.setState({
      currentModal,
    })
  }

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal,
    setCurrentModal: this.setCurrentModal,
    currentModal: null,
  }

  render() {
    return <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>
  }
}

export const ModalConsumer = ModalContext.Consumer
