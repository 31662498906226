import * as React from 'react'

import { ErrorDisabler, Modal } from 'component'
import { IFlag, IInvalidationFlag, isManualErrorRaisingDisabled } from 'utils/commissions'
import { IAPIDocketItem } from 'api/interfaces'

import {
  getCtaDisabled,
  getDisabledErrorsCount,
  getProcessedFlags,
  IDocketItemProcessedFlag,
  updateDocketItemErrors,
} from '../../core/ModalErrorHandling.helpers'

import { Button, CheckboxProps, CircularProgress, Stack, Typography } from '@mui/material'

interface IProps {
  onCloseHandler: () => void
  contractFirstName: string
  contractLastName: string
  contractNumber: string
  productName: string
  docketItemFlags: IFlag[]
  docketItemInvalidationFlags: IInvalidationFlag[]
  docketItem: IAPIDocketItem
  setError: StateSetter<string | undefined>
  setStep: StateSetter<number>
  setIsRefreshable?: StateSetter<boolean>
  tableRef?: any
}

const ErrorsStep: React.FC<IProps> = (props) => {
  const {
    onCloseHandler,
    contractFirstName,
    contractLastName,
    contractNumber,
    productName,
    docketItemFlags,
    docketItemInvalidationFlags,
    docketItem,
    setError,
    setStep,
    setIsRefreshable,
    tableRef,
  } = props

  const [loading, setLoading] = React.useState(false)
  const [processedFlags, setProcessedFlags] = React.useState<IDocketItemProcessedFlag[]>(
    getProcessedFlags(docketItemFlags, docketItemInvalidationFlags)
  )

  const disabledErrorsCount = getDisabledErrorsCount(processedFlags)
  const ctaDisabled = getCtaDisabled({
    disabledErrorsCount,
    invalidationFlagCount: docketItemInvalidationFlags.length,
    processedFlags,
  })

  const getDisabledChangeHandler = (processedFlagIdx: number): CheckboxProps['onChange'] => {
    return (e, checked) => {
      setProcessedFlags(
        processedFlags.map<IDocketItemProcessedFlag>((processedFlag, idx) =>
          idx === processedFlagIdx
            ? {
                ...processedFlag,
                status: checked ? 'disable' : 'enable',
              }
            : processedFlag
        )
      )
    }
  }

  const getCommentChangeHandler = (
    processedFlagIdx: number
  ): React.ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      setProcessedFlags(
        processedFlags.map<IDocketItemProcessedFlag>((processedFlag, idx) =>
          idx === processedFlagIdx
            ? {
                ...processedFlag,
                comment: e.target.value || undefined,
              }
            : processedFlag
        )
      )
    }
  }

  const updateDocketItemErrorsClickhandler = () => {
    updateDocketItemErrors({
      setError,
      setLoading,
      docketId: docketItem.insuranceFeeReport.id,
      docketItemId: docketItem.id,
      processedFlags,
      setStep,
      setIsRefreshable,
      tableRef,
    })
  }

  return (
    <>
      <Modal.Content>
        <Stack
          direction="row"
          px={2}
          py={1.5}
          mb={3}
          borderRadius={1}
          gap={0.5}
          sx={(theme) => ({
            backgroundColor: 'background.default',
          })}
        >
          <Typography
            variant="subtitle1"
            fontWeight={500}
            sx={{ color: 'text.primary' }}
          >{`${contractFirstName} ${contractLastName}`}</Typography>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            •
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {contractNumber}
          </Typography>
          {productName && (
            <>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                •
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {productName}
              </Typography>
            </>
          )}
        </Stack>
        <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 500 }} mb={1.5}>
          Sélectionner les erreurs à désactiver
        </Typography>
        <Stack gap={2}>
          {processedFlags.map((processedFlag, idx) => {
            let value = Number(docketItem[processedFlag.field])

            // Sum grouped items values
            if (
              docketItem.groupedItems !== null &&
              docketItem.groupedItems !== undefined &&
              docketItem.groupedItems.length > 0
            ) {
              docketItem.groupedItems.forEach((groupedItem) => {
                let groupValue = Number(groupedItem[processedFlag.field])

                if (!isNaN(groupValue)) {
                  value += groupValue
                }
              })
            }

            return !isManualErrorRaisingDisabled(docketItem, processedFlag.type) ? (
              <ErrorDisabler
                key={processedFlag.field}
                field={processedFlag.field}
                value={value}
                type={processedFlag.type}
                message={processedFlag.message}
                disabled={processedFlag.status === 'disable'}
                comment={processedFlag.comment}
                onDisabledChange={getDisabledChangeHandler(idx)}
                onCommentChange={getCommentChangeHandler(idx)}
              />
            ) : null
          })}
        </Stack>
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <Button
            disabled={ctaDisabled}
            onClick={updateDocketItemErrorsClickhandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ height: `20px !important`, width: `20px !important`, color: 'white' }}
                />
              ) : undefined
            }
          >
            Confirmer les désactivations / réactivations
          </Button>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default ErrorsStep
