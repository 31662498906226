import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Modal } from 'component'

import { CrmStep, ImportStep, FinalStep } from './components'
import {
  getModalTitle,
  handleModalClose,
  handleRetryClick,
} from './core/modalUploadContracts.helpers'

import { ModalProps } from '@mui/material'

interface IProps extends RouteComponentProps {
  open: boolean
  onClose: NonNullable<ModalProps['onClose']>
  updateDataBase?: boolean
}

const ModalUploadContracts: React.FC<IProps> = (props) => {
  const { open, onClose, history, updateDataBase } = props

  const [error, setError] = React.useState<string>()
  const [progress, setProgress] = React.useState(0)
  const [file, setFile] = React.useState<File>()
  const [step, setStep] = React.useState(1)
  const [crm, setCrm] = React.useState<'o2s' | 'prisme'>()

  const onCloseHandler = handleModalClose({
    onClose,
    setProgress,
    setFile,
    setStep,
    setCrm,
    setError,
  })

  const modalStepTitle = React.useMemo(() => {
    if (updateDataBase) {
      return `Mettre à jour la base contrats - ${step}/3`
    } else {
      return `Importer la base d'analyse contrats - ${step}/3`
    }
  }, [updateDataBase, step])

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      stepTitle={modalStepTitle}
      title={getModalTitle(step) || ''}
      modalId="ModalUploadContracts"
    >
      {step === 1 ? (
        <CrmStep
          file={file}
          setFile={setFile}
          setProgress={setProgress}
          setError={setError}
          setStep={setStep}
          setCrm={setCrm}
          crm={crm}
          onClose={onCloseHandler}
        />
      ) : step === 2 && file !== undefined ? (
        <ImportStep progress={progress} file={file} error={error} />
      ) : (
        <>
          <FinalStep
            history={history}
            onClose={onClose}
            error={error}
            setError={setError}
            setProgress={setProgress}
            setFile={setFile}
            setStep={setStep}
            setCrm={setCrm}
            onRetry={handleRetryClick({
              setError,
              setProgress,
              setFile,
              setStep,
              setCrm,
            })}
          />
        </>
      )}
    </Modal>
  )
}

export default withRouter(ModalUploadContracts)
