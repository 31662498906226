import { Typography, Chip, styled } from '@mui/material'
import Navbar from 'component/Navbar'
import NavbarContent from 'component/Navbar/NavbarContent'
import NavbarLink from 'component/Navbar/NavbarLink'
import { handleTabChange } from 'page/Commissions/Contracts/core/Contracts.helpers'
import { createElement, FC } from 'react'
import { useHistory } from 'react-router-dom'
import { UCITS_NAVBAR_ITEMS } from './typescript/reportingSettingsNavbar.constants'
import { useReportingSettingsNavbar } from './typescript/reportingSettingsNavbar.hooks'
import { AlertTriangleIcon } from 'icons'

interface IProps {
  insuranceProvider: string | undefined
  insuranceFamily: string | undefined
  insuranceProductIdStr: string | undefined
  selectedCommissionType: string
  setSelectedCommissionType: StateSetter<string>
}

const ReportingSettingsNavbar: FC<IProps> = (props) => {
  const {
    insuranceProvider,
    insuranceFamily,
    insuranceProductIdStr,
    selectedCommissionType,
    setSelectedCommissionType,
  } = props

  const history = useHistory()
  const [showWarning] = useReportingSettingsNavbar()

  return (
    <Navbar
      activeLink={selectedCommissionType}
      setActiveLink={setSelectedCommissionType}
      className="navbar--ucits"
    >
      <Navbar.Header variant="dark">
        {UCITS_NAVBAR_ITEMS.map((item) => (
          <NavbarLink
            key={item.id}
            disabled={item.disabled}
            id={item.id}
            variant="dark"
            onChange={handleTabChange({
              history,
              insuranceProvider,
              insuranceFamily,
              insuranceProductIdStr,
            })}
          >
            <Typography variant="subtitle1" component="a">
              {item.link}
            </Typography>
            {showWarning(item.id) && (
              <IconChip
                icon={<AlertTriangleIcon />}
                size="small"
                color="error"
                sx={{ marginLeft: 0.5 }}
              />
            )}
          </NavbarLink>
        ))}
      </Navbar.Header>
      {UCITS_NAVBAR_ITEMS.map((item) => (
        <NavbarContent key={item.id} id={item.id}>
          {item.content && createElement(item.content)}
        </NavbarContent>
      ))}
    </Navbar>
  )
}

export default ReportingSettingsNavbar

// TODO : Create a custom chip component implementing label hidding if not provided
const IconChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',

  '.MuiChip-label': {
    display: 'none',
  },
}))
