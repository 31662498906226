import * as React from 'react'

import { AlertTriangleIcon, ChevronDownIcon } from 'icons'
import { IInsrPrdsGroupedByFam } from 'utils'
import { IAPIInsurance } from 'api/interfaces'

import { handleInsrFamClick, handleInsrPrdClick } from '../../core/Contracts.helpers'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  styled,
  Tooltip,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import InsuranceDropdown from 'component/InsuranceDropdown'
import { REPORTING_CONFIGURATION_URL } from 'utils/router/constants'

interface IProps {
  insrs: IAPIInsurance[] | undefined
  insrsLoading: boolean
  insrsError: string | undefined
  selectedInsr: IAPIInsurance | undefined
  selectedInsrFamIdx: number | undefined
  setSelectedInsrFamIdx: StateSetter<number | undefined>
  selectedInsrPrdIdx: number | undefined
  setSelectedInsrPrdIdx: StateSetter<number | undefined>
  insrPrdsFamGroupings: IInsrPrdsGroupedByFam[] | undefined
  insrPrdsLoading: boolean
  insrPrdsError: string | undefined
  insuranceProvider: string | undefined
  insuranceFamily: string | undefined
}

const ContractsSidePanel: React.FC<IProps> = (props) => {
  const {
    insrs,
    insrsLoading,
    insrsError,
    selectedInsr,
    selectedInsrFamIdx,
    setSelectedInsrFamIdx,
    selectedInsrPrdIdx,
    setSelectedInsrPrdIdx,
    insrPrdsFamGroupings,
    insrPrdsLoading,
    insrPrdsError,
    insuranceProvider,
    insuranceFamily,
  } = props

  const history = useHistory()

  const getInsuranceChangeHandler = (insurance: IAPIInsurance) => {
    if (!insurance) {
      return
    }

    setSelectedInsrPrdIdx(undefined)
    setSelectedInsrFamIdx(undefined)
    history.push(`${REPORTING_CONFIGURATION_URL}/${insurance.provider}`)
  }

  return (
    <SidePanel>
      <Box pt={4} pr={3} pl={3}>
        <Typography
          variant="h2b"
          component="h1"
          fontWeight={500}
          mb={insrs === undefined || insrs.length !== 0 ? 2 : 1}
          sx={{ color: 'text.primary' }}
        >
          Paramétrage des contrats
        </Typography>
        {insrs === undefined || insrs.length !== 0 ? (
          <>
            {insrsLoading ? (
              <Stack justifyContent="center" alignItems="center">
                <CircularProgress sx={{ color: 'text.primary' }} />
              </Stack>
            ) : insrsError ? (
              <Typography variant="subtitle1" component="p" sx={{ color: 'error.text' }}>
                {insrsError}
              </Typography>
            ) : insrs !== undefined ? (
              insrs.length !== 0 ? (
                <InsuranceDropdown
                  insurances={insrs}
                  getInsuranceChangeHandler={getInsuranceChangeHandler}
                  selectedInsurance={selectedInsr || insrs[0]}
                  reportCount={false}
                />
              ) : (
                <div className="mosaic-menu mt-3 mosaic-menu-small">
                  <div className="body-label-m ft-truncat">Aucun assureur paramétré.</div>
                </div>
              )
            ) : null}
          </>
        ) : null}
      </Box>
      <Divider sx={{ mt: 3 }} />
      {selectedInsr !== undefined ? (
        <>
          <InsrPrdsScrollZone flexGrow={1}>
            <InsrPrdsWrapper flexGrow={1}>
              {insrPrdsLoading ? (
                <Stack justifyContent="center" alignItems="center" flexGrow={1}>
                  <CircularProgress sx={{ color: 'text.primary' }} />
                </Stack>
              ) : insrPrdsError ? (
                <Typography variant="subtitle1" component="p" sx={{ color: 'error.text' }}>
                  {insrPrdsError}
                </Typography>
              ) : insrPrdsFamGroupings ? (
                insrPrdsFamGroupings.map((famGrouping, famIdx) => (
                  <SidePanelAccordion
                    key={famIdx}
                    expanded={selectedInsrFamIdx === famIdx}
                    lastItemSelected={
                      selectedInsrFamIdx === famIdx &&
                      selectedInsrPrdIdx === famGrouping.insrPrds.length - 1
                    }
                  >
                    <SidePanelAccordionSummary
                      expandIcon={<ChevronDownIcon />}
                      onClick={handleInsrFamClick({
                        history,
                        insuranceProvider,
                        insuranceFamily,
                        insrPrdsFams: insrPrdsFamGroupings.map(
                          (insrPrdsFamGrouping) => insrPrdsFamGrouping.insrPrdsFamName
                        ),
                        famIdx,
                        setSelectedInsrPrdIdx,
                      })}
                    >
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flexGrow={1}
                      >
                        <Typography variant="body2" fontWeight={500} sx={{ color: 'text.primary' }}>
                          {famGrouping.insrPrdsFamName}
                        </Typography>
                        <Chip
                          label={famGrouping.insrPrds.length}
                          size="small"
                          color="default"
                          sx={() => ({
                            fontWeight: 700,
                            minWidth: '24px',
                          })}
                        />
                      </Stack>
                    </SidePanelAccordionSummary>
                    <SidePanelAccordionDetails>
                      {famGrouping.insrPrds.map((insrPrd, insrPrdIdx, insrPrds) => (
                        <SidePanelAccordionItem
                          key={insrPrdIdx}
                          onClick={handleInsrPrdClick({
                            history,
                            insuranceProvider,
                            insuranceFamily,
                            insrPrds,
                            insrPrdIdx,
                          })}
                          selected={selectedInsrPrdIdx === insrPrdIdx}
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight={500}
                            sx={{
                              color:
                                selectedInsrPrdIdx === insrPrdIdx
                                  ? 'text.primary'
                                  : 'text.secondary',
                            }}
                          >
                            {insrPrd.name}
                          </Typography>
                          {insrPrd.missingInsuranceFeeArbitrage ||
                          insrPrd.missingInsuranceFeeDeposit ||
                          insrPrd.missingInsuranceFeeUpfront ? (
                            <Tooltip
                              disableInteractive
                              title={
                                <>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={500}
                                    mb={1}
                                    sx={{ color: 'error.text' }}
                                  >
                                    Données manquantes
                                  </Typography>
                                  {insrPrd.missingInsuranceFeeArbitrage ? (
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ color: 'text.secondary' }}
                                    >
                                      - Taux de rétrocession pour les arbitrages
                                    </Typography>
                                  ) : null}
                                  {insrPrd.missingInsuranceFeeDeposit ? (
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ color: 'text.secondary' }}
                                    >
                                      - Taux de rétrocession pour les versements
                                    </Typography>
                                  ) : null}
                                  {/* {insrPrd.missingUcits ? (
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ color: 'text.secondary' }}
                                    >
                                      - Taux de rétrocession pour les OPCVM
                                    </Typography>
                                  ) : null} */}
                                  {insrPrd.missingInsuranceFeeUpfront ? (
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ color: 'text.secondary' }}
                                    >
                                      - Taux de rétrocession pour les upfronts
                                    </Typography>
                                  ) : null}
                                </>
                              }
                            >
                              <IconChip
                                icon={<AlertTriangleIcon />}
                                size="small"
                                color="error"
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : null}
                        </SidePanelAccordionItem>
                      ))}
                    </SidePanelAccordionDetails>
                  </SidePanelAccordion>
                ))
              ) : null}
            </InsrPrdsWrapper>
          </InsrPrdsScrollZone>
        </>
      ) : (
        <>
          <Stack justifyContent="center" alignItems="center" flexGrow={1} pl={3} pr={3}>
            <img
              src={`${process.env.PUBLIC_URL}/static/images/logo-sendraise-light-s.svg`}
              alt="Sendraise logo"
            />
            <Typography
              variant="subtitle1"
              component="p"
              textAlign="center"
              mt={3}
              sx={{ color: 'text.tertiary' }}
            >
              {insrs === undefined || insrs.length !== 0
                ? 'Selectionnez un assureur pour consulter ses contrats'
                : `Aucun assureur n'a été paramétré pour le moment`}
            </Typography>
          </Stack>
        </>
      )}
    </SidePanel>
  )
}

export default ContractsSidePanel

const SidePanel = styled(Stack)(({ theme }) => ({
  height: '100vh',
  width: 325,
  minWidth: 325,
  position: 'fixed',
  background: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
}))

const SidePanelAccordion = styled(Accordion, {
  shouldForwardProp: (propName) => propName !== 'lastItemSelected',
})<{ lastItemSelected: boolean }>(({ theme, lastItemSelected }) => ({
  boxShadow: 'none',
  borderRadius: '0 !important',
  background: 'transparent',
  borderWidth: `1px 0 1px 0`,
  borderStyle: 'solid',
  borderColor: lastItemSelected
    ? `${theme.palette.divider} transparent transparent`
    : theme.palette.divider,

  '.MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.text.tertiary,
    marginLeft: theme.spacing(2.5),

    svg: {
      fontSize: 16,
    },
  },

  '&.Mui-expanded': {
    marginBottom: 0,
    marginTop: 0,
  },

  '&+.MuiAccordion-root, &:first-of-type': {
    borderTop: 'none',
  },

  '&::before': {
    display: 'none',
  },
}))

const SidePanelAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  transition: theme.transitions.create('backgroundColor', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeOut,
  }),

  '.MuiAccordionSummary-content': {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },

  '&:hover': {
    backgroundColor: theme.palette.secondaryLight.main,
  },
}))

const SidePanelAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}))

const SidePanelAccordionItem = styled(Stack, {
  shouldForwardProp: (propName) => propName !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  cursor: 'pointer',
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: selected ? theme.palette.primary.light : 'transparent',
  borderLeft: 'none',
  borderRight: 'none',
  backgroundColor: selected ? theme.palette.primaryLight.main : 'transparent',
  transition: theme.transitions.create('backgroundColor, borderColor', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeOut,
  }),

  '&:hover': {
    backgroundColor: selected ? theme.palette.primaryLight.main : theme.palette.secondaryLight.main,
  },
}))

const InsrPrdsScrollZone = styled(Stack)(() => ({
  overflow: 'hidden',
}))

const InsrPrdsWrapper = styled(Stack)(() => ({
  overflowY: 'auto',
}))

// TODO : Create a custom chip component implementing label hidding if not provided
const IconChip = styled(Chip)(() => ({
  cursor: 'pointer',

  '.MuiChip-label': {
    display: 'none',
  },
}))
