import React, { ReactNode } from 'react'
import Icon from 'component/Icon'
import { withRouter } from 'react-router'
import {
  PREFERENCES_PROFILE_URL,
  PREFERENCES_CABINET_URL,
  PREFERENCES_SECURITY_URL,
  HOMEPAGE_URL,
} from 'utils/router/constants'
import { Link } from 'react-router-dom'

interface IProps {
  children?: ReactNode
  history: any
  location: {
    pathname: string
  }
}

function LayoutPreferences(props: IProps) {
  const { children, location } = props
  const url = location.pathname

  return (
    <>
      <div className="preferences-profile__heading">
        <div className="row container">
          <div className="col col-12">
            <Link
              to={HOMEPAGE_URL}
              className="sidebar__backlink mt-6 mb-3 c-blue-400"
            >
              <Icon className="icon-arrow-left" />
              <span className="ml-2 ft-500">Retour</span>
            </Link>
            <h4 className="h3 mb-6 c-blue-900 ft-500">Préférences</h4>
            <nav>
              <ul className="ft-secondary">
                <li>
                  <Link
                    to={PREFERENCES_PROFILE_URL}
                    className={`mr-3 c-blue-400 ft-500 ${
                      PREFERENCES_PROFILE_URL === url ? 'is-active' : ''
                    }`}
                  >
                    Profil
                  </Link>
                </li>
                <li>
                  <Link
                    to={PREFERENCES_CABINET_URL}
                    className={`ml-3 mr-3 c-blue-400 ft-500 ${
                      PREFERENCES_CABINET_URL === url ? 'is-active' : ''
                    }`}
                  >
                    Cabinet
                  </Link>
                </li>
                <li>
                  <Link
                    to={PREFERENCES_SECURITY_URL}
                    className={`ml-3 mr-3 c-blue-400 ft-500 ${
                      PREFERENCES_SECURITY_URL === url ? 'is-active' : ''
                    }`}
                  >
                    Sécurité
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

export default withRouter(LayoutPreferences)
