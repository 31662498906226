import * as React from 'react'

import { Button, Stack, Typography, styled } from '@mui/material'
import HelpContainer from 'component/HelpContainer'

interface IProps {
  debouncedSearch: string
  onImportClick: React.MouseEventHandler
}

const NoRowsOverlay: React.FC<IProps> = (props) => {
  const { debouncedSearch, onImportClick } = props
  return (
    <NoContentWrapper flex={1} justifyContent="center" alignItems="center">
      <Typography color="text.primary" variant="subtitle1_m">
        {debouncedSearch === ''
          ? `Vous n’avez pas encore importé de base de données client.`
          : `Aucun client ne correspond à vos critères de recherche`}
      </Typography>
      {debouncedSearch === '' && (
        <Typography color="text.primary" variant="subtitle1" mb={2}>
          Suivez notre guide pour exporter les bonnes données depuis votre CRM
        </Typography>
      )}
      {debouncedSearch === '' ? (
        <>
          <Button onClick={onImportClick} sx={{ mb: 2 }}>
            Importer une base de données client
          </Button>
          <HelpContainer
            message="Comment exporter et importer ma première base clients ?"
            helpURL="https://busy-stop-fcc.notion.site/Guide-utilisateur-du-Contr-le-de-Gel-des-Avoirs-803d7ecd4f65423ba9f9ae4b6469defa?pvs=4"
            size="small"
            light={true}
          />
        </>
      ) : null}
    </NoContentWrapper>
  )
}

export default NoRowsOverlay

const NoContentWrapper = styled(Stack)(() => ({
  height: '100%',
  position: 'relative',
  zIndex: 1,
}))
