import * as React from 'react'

import { HomeIcon } from 'icons'
import { HOMEPAGE_URL } from 'utils/router/constants'

import { getLocationTitle, getLocationNav, getHideableElementSx } from '../../core/Menu.helpers'
import { NavMenuItem } from '../../components'

import { Box, Stack, Typography, useTheme } from '@mui/material'

interface IProps {
  location: 'reportings' | 'asset-freeze' | 'preferences'
  open: boolean
}

const DefaultMenu: React.FC<IProps> = (props) => {
  const { location, open } = props

  const theme = useTheme()
  const hideableElementSx = getHideableElementSx({ theme, open })

  return (
    <Stack gap={4}>
      <NavMenuItem
        icon={HomeIcon}
        name="Accueil"
        link={HOMEPAGE_URL}
        menuOpen={open}
        sx={{ flexGrow: 1 }}
        exact={true}
      />
      <Stack gap={2}>
        <Box px={3}>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
              ...(hideableElementSx as object),
            })}
          >
            {getLocationTitle(location)}
          </Typography>
        </Box>
        <Stack>
          {getLocationNav(location).map((nav) => (
            <NavMenuItem
              key={nav.name}
              icon={nav.icon}
              name={nav.name}
              link={nav.link}
              menuOpen={open}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DefaultMenu
