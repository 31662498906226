import { Components, Theme } from '@mui/material'

const MuiIconButton: NonNullable<Components<Theme>['MuiIconButton']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 4,
      transition: theme.transitions.create(
        ['border-color', 'background-color', 'box-shadow'],
        {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }
      ),

      '&.Mui-disabled': {
        opacity: 0.5
      },

      '.MuiTouchRipple-child': {
        borderRadius: '4px !important',
      },
    }),
  },
  variants: [
    {
      props: { size: 'medium' },
      style: ({ theme }) => ({
        height: theme.helpers.sizing.button.medium.height,
        width: theme.helpers.sizing.button.medium.height,
        padding: theme.helpers.sizing.button.medium.padding,

        '.MuiSvgIcon-root': {
          fontSize: 20,
        },
        
      })
    },
    {
      props: { size: 'small' },
      style: ({ theme }) => ({
        height: theme.helpers.sizing.button.small.height,
        width: theme.helpers.sizing.button.small.height,
        padding: theme.helpers.sizing.button.small.padding,

        '.MuiSvgIcon-root': {
          fontSize: 16,
        },
        
      })
    },
    {
      props: { color: 'primary' },
      style: ({ theme }) => ({
        background: theme.palette.primary.main,
        boxShadow: theme.helpers.stateShadows.default.primary,

        '.MuiSvgIcon-root': {
          color: theme.palette.primary.contrastText
        },

        '&:hover': {
          background: theme.palette.primary.dark,
          boxShadow: theme.helpers.stateShadows.hover.primary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.primary,
        },

        '&:disabled': {
          background: theme.palette.primary.main,
        },
      })
    },
    {
      props: { color: 'secondary' },
      style: ({ theme }) => ({
        background: theme.palette.secondary.main,
        boxShadow: theme.helpers.stateShadows.default.secondary,

        '.MuiSvgIcon-root': {
          color: theme.palette.secondary.contrastText
        },

        '&:hover': {
          background: theme.palette.secondary.dark,
          boxShadow: theme.helpers.stateShadows.hover.secondary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.secondary,
        },

        '&:disabled': {
          background: theme.palette.secondary.main,
        },
      })
    },
    {
      props: { color: 'neutral' },
      style: ({ theme }) => ({
        background: theme.palette.neutralLight.main,
        boxShadow: theme.helpers.stateShadows.default.tertiary,
        boxSizing: 'border-box',

        '.MuiSvgIcon-root': {
          color: theme.palette.neutralLight.contrastText
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.tertiary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.tertiary,
        },

        '&:disabled': {
          background: theme.palette.neutralLight.main,
        },
      })
    },
    {
      props: { color: 'neutralLight' },
      style: ({ theme }) => ({
        background: 'transparent',

        '.MuiSvgIcon-root': {
          color: theme.palette.text.secondary
        },

        '&:hover': {
          background: theme.palette.neutralLight.dark,
          boxShadow: theme.helpers.stateShadows.hover.quaternary,
        },

        '&:active': {
          background: theme.palette.neutralLight.dark,
          boxShadow: theme.helpers.stateShadows.pressed.quaternary,
        },
      })
    },
    {
      props: { color: 'errorLight' },
      style: ({ theme }) => ({
        background: 'transparent',

        '.MuiSvgIcon-root': {
          color: theme.palette.errorLight.contrastText
        },

        '&:hover': {
          background: theme.palette.errorLight.main,
          boxShadow: theme.helpers.stateShadows.hover.quaternary,
        },

        '&:active': {
          background: theme.palette.errorLight.main,
          boxShadow: theme.helpers.stateShadows.pressed.quaternary,
        },
      })
    }
    
  ]
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true
    neutralLight: true
    errorLight: true
  }
}

export default MuiIconButton
