import { IAPIInsuranceProduct } from 'api/interfaces'

// TODO : Replace IInsrPrdsGroupedByFam & getInsrPrdsFamGroupings by
// IInsuranceProductsGroupedByFamily & getInsuranceProductsGroupedByFamily
// where it's still used

export interface IInsrPrdsGroupedByFam {
  insrPrdsFamName: string
  insrPrds: IAPIInsuranceProduct[]
}

export const getInsrPrdsFamGroupings = (
  insrPrds: IAPIInsuranceProduct[] | undefined
): IInsrPrdsGroupedByFam[] | undefined => {
  if (insrPrds === undefined) {
    return
  }

  return Array.from(
    insrPrds
      .reduce<Map<number | null, IInsrPrdsGroupedByFam>>((acc, curr) => {
        const { insuranceProductFamily } = curr

        const famGroupingKey = curr.insuranceProductFamily ? curr.insuranceProductFamily.id : null
        const famGrouping = acc.get(famGroupingKey)

        if (famGrouping !== undefined) {
          acc.set(famGroupingKey, {
            ...famGrouping,
            insrPrds: [...famGrouping.insrPrds, curr],
          })
        } else {
          const insrPrdsFamName = insuranceProductFamily
            ? insuranceProductFamily.name
            : 'Sans famille'

          acc.set(famGroupingKey, { insrPrdsFamName, insrPrds: [curr] })
        }

        return acc
      }, new Map())
      .values()
  )
}

export interface IInsuranceProductsGroupedByFamily {
  familyName: string
  products: Partial<IAPIInsuranceProduct> &
    Required<Pick<IAPIInsuranceProduct, 'insuranceProductFamily' | 'id' | 'name'>>[]
}

export const getInsuranceProductsGroupedByFamily = (
  insuranceProducts: IInsuranceProductsGroupedByFamily['products']
): IInsuranceProductsGroupedByFamily[] => {
  return Array.from(
    insuranceProducts
      .reduce<Map<number | null, IInsuranceProductsGroupedByFamily>>((acc, curr) => {
        const { insuranceProductFamily } = curr

        const familyGroupKey = curr.insuranceProductFamily ? curr.insuranceProductFamily.id : null
        const familyGroup = acc.get(familyGroupKey)

        if (familyGroup !== undefined) {
          acc.set(familyGroupKey, {
            ...familyGroup,
            products: [...familyGroup.products, curr],
          })
        } else {
          const familyName = insuranceProductFamily ? insuranceProductFamily.name : 'Sans famille'

          acc.set(familyGroupKey, { familyName, products: [curr] })
        }

        return acc
      }, new Map())
      .values()
  )
}
