import { Components, Theme } from '@mui/material'
const MuiButton: NonNullable<Components<Theme>['MuiButton']> = {
  defaultProps: {
    variant: 'contained',
    size: 'medium',
    color: 'primary',
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 4,
      fontWeight: 500,
      minWidth: 'unset',

      '&.Mui-disabled': {
        opacity: 0.5,
      },

      '&:hover': {
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          opacity: 0.7,
        }
      },

      transition: theme.transitions.create(
        ['border-color', 'background-color', 'box-shadow'],
        {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }
      ),
    }),
    startIcon: ({ theme }) => ({
      opacity: 0.5,
      marginLeft: 0,
    }),
    endIcon: ({ theme }) => ({
      opacity: 0.5,
      marginRight: 0,
    }),
  },
  variants: [
    // Size variants
    {
      props: { size: 'medium' },
      style: ({ theme }) => ({
        height: theme.helpers.sizing.button.medium.height,
        padding: theme.helpers.sizing.button.medium.padding,
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: theme.typography.subtitle1.lineHeight,

        '.MuiSvgIcon-root': {
          fontSize: `20px !important`
        },

        '.MuiButton-startIcon': {
          marginRight: theme.helpers.sizing.button.medium.gap,
        },
        '.MuiButton-endIcon': {
          marginLeft: theme.helpers.sizing.button.medium.gap,
        }
        
      })
    },
    {
      props: { size: 'small' },
      style: ({ theme }) => ({
        height: theme.helpers.sizing.button.small.height,
        padding: theme.helpers.sizing.button.small.padding,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,

        '.MuiSvgIcon-root': {
          fontSize: `16px !important`
        },

        '.MuiButton-startIcon': {
          marginRight: theme.helpers.sizing.button.small.gap,
        },
        '.MuiButton-endIcon': {
          marginLeft: theme.helpers.sizing.button.small.gap,
        }
        
      })
    },

    // Priority
    // TODO possibilité de rationnaliser les box shadows des variants
    // TODO handle borders as actual borders, not box shadows
    {
      props: { color: 'primary', variant: 'contained' },
      style: ({ theme }) => ({
        background: theme.palette.primary.main,
        boxShadow: theme.helpers.stateShadows.default.primary,
        color: theme.palette.primary.contrastText,

        '.MuiSvgIcon-root': {
          color: theme.palette.primary.contrastText
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.primary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.primary,
        },

        '&:disabled': {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        },
      })
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: ({ theme }) => ({
        background: theme.palette.secondary.main,
        boxShadow: theme.helpers.stateShadows.default.secondary,
        color: theme.palette.secondary.contrastText,

        '.MuiSvgIcon-root': {
          color: theme.palette.secondary.contrastText
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.secondary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.secondary,
        },

        '&:disabled': {
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText
        },
      })
    },
    {
      props: { color: 'error', variant: 'contained' },
      style: ({ theme }) => ({
        background: theme.palette.error.main,
        boxShadow: theme.helpers.stateShadows.default.secondary,
        color: theme.palette.error.contrastText,

        '.MuiSvgIcon-root': {
          color: theme.palette.error.contrastText
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.secondary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.secondary,
        },

        '&:disabled': {
          background: theme.palette.error.main,
          color: theme.palette.error.contrastText
        },
      })
    },
    {
      props: { color: 'neutralLight', variant: 'contained' },
      style: ({ theme }) => ({
        background: theme.palette.neutralLight.main,
        boxShadow: theme.helpers.stateShadows.default.tertiary,
        color: theme.palette.neutralLight.contrastText,

        '.MuiSvgIcon-root': {
          color: theme.palette.text.secondary
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.tertiary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.tertiary,
        },

        '&:disabled': {
          background: theme.palette.neutralLight.main,
          boxShadow: theme.helpers.stateShadows.default.tertiary,
          color: theme.palette.neutralLight.contrastText,
        },
      })
    },
    {
      props: { color: 'errorLight', variant: 'contained' },
      style: ({ theme }) => ({
        background: theme.palette.errorLight.main,
        boxShadow: theme.helpers.stateShadows.default.tertiary,
        color: theme.palette.errorLight.contrastText,

        '.MuiSvgIcon-root': {
          color: theme.palette.errorLight.contrastText,
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.tertiary,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.tertiary,
        },

        '&:disabled': {
          background: theme.palette.errorLight.main,
          boxShadow: theme.helpers.stateShadows.default.tertiary,
          color: theme.palette.errorLight.contrastText,
        },
      })
    },
    {
      props: { color: 'neutralLight', variant: 'text' },
      style: ({ theme }) => ({
        color: theme.palette.text.secondary,

        '.MuiSvgIcon-root': {
          color: theme.palette.text.tertiary
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.quaternary,
          background: theme.palette.neutralLight.dark,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.quaternary,
          background: theme.palette.neutralLight.dark,
        },

        '&:disabled': {
          color: theme.palette.text.secondary,
        },
      })
    },
    {
      props: { color: 'errorLight', variant: 'text' },
      style: ({ theme }) => ({
        color: theme.palette.errorLight.contrastText,

        '.MuiSvgIcon-root': {
          color: theme.palette.errorLight.contrastText
        },

        '&:hover': {
          boxShadow: theme.helpers.stateShadows.hover.quaternary,
          background: theme.palette.errorLight.dark,
        },

        '&:active': {
          boxShadow: theme.helpers.stateShadows.pressed.quaternary,
          background: theme.palette.errorLight.dark,
        },

        '&:disabled': {
          color: theme.palette.errorLight.contrastText,
        },
      })
    },
  ],
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
    neutralLight: true
    errorLight: true
  }
}

export default MuiButton
