import { Components, Theme } from '@mui/material'

const MuiFormControl: NonNullable<Components<Theme>['MuiFormControl']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiFormLabel-root': {
        '& + .MuiInputBase-root': {
          '.MuiInputBase-input': {
            '&::placeholder': {
              color: theme.palette.text.primary,
              opacity: `${0.4} !important`,
            },
          },
        },
      },
      '.MuiFormHelperText-root': {
        margin: '0 0 8px',
      },
    }),
  },
}

export default MuiFormControl
