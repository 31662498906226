import { Components, Theme } from '@mui/material'

const MuiPaper: NonNullable<Components<Theme>['MuiPaper']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.MuiCustomSelectMenuPaper': {
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
        backgroundImage: 'none',
        background: theme.palette.background.paper,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        marginTop: theme.spacing(1),
      },
      '&.MuiCustomDatePickerPaper': {
        background: theme.palette.background.paper,
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
      },
      '.MuiDataGrid-menu &': {
        border: `1px solid ${theme.palette.divider}`,
        boxShadow:
          '0px 1px 5px rgba(28, 47, 63, 0.08), 0px 3px 18px rgba(28, 47, 63, 0.04), 0px 15px 80px rgba(28, 47, 63, 0.03)',
        '.MuiMenuItem-root': {
          fontSize: 14,
        },
        '.MuiListItemIcon-root': {
          color: 'inherit',
          minWidth: 24,
        },
        '.MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    }),
  },
}

export default MuiPaper
