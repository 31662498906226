import { Box, Typography } from '@mui/material'
import * as React from 'react'

interface IProps {
  uploader: string
  uploadDate: string
  uploadTime: string
}

const ImportUploaderCell: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { uploader, uploadDate, uploadTime } = props

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        title={uploader}
        variant="subtitle1"
        color="text.primary"
        fontWeight={500}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {uploader}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {uploadDate} • {uploadTime}
      </Typography>
    </Box>
  )
}

export default ImportUploaderCell
