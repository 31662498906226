import React from 'react'
import { IAPIInsurance, IAPIInsuranceProduct } from 'api/interfaces'
import { styled, Typography, Stack } from '@mui/material'
import { getProviderLogo } from 'utils/commissions'
import { capitalize } from 'utils'

interface IProps {
  insuranceProduct: IAPIInsuranceProduct
  insurance: IAPIInsurance
}

const ReportingSettingsHeader: React.FC<IProps> = ( props ) => {

  const { insuranceProduct, insurance } = props

  return (
    <ReportingSettingsHeaderContainer>
      <Stack
        direction='column'
        spacing={0}
      >
        <Stack
          direction='row'
          spacing={1.5}
        >
          <Typography variant='h2' color='secondary.contrastText'>{capitalize(insuranceProduct.name)}</Typography>
          <img
            width='32px'
            height='32px'
            src={getProviderLogo(insurance.provider)}
            alt="Axa"
          />
        </Stack>
      </Stack>
    </ ReportingSettingsHeaderContainer>
  )
}

const ReportingSettingsHeaderContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(4)}`,
  background: theme.palette.background.inverted,
}))

export default ReportingSettingsHeader