import { Components, Theme } from '@mui/material'

const MuiChip: NonNullable<Components<Theme>['MuiChip']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '4px',

      '.MuiChip-deleteIcon': {
        marginLeft: 0,
        marginRight: 0,
        color: theme.palette.common.white,
      },
    }),
    icon: () => ({
      color: 'inherit',
    }),
    label: () => ({
      padding: 0
    }),
    sizeSmall: ({ theme }) => ({
      ...theme.typography.subtitle2,
      fontWeight: 500,
      height: '24px',
      paddingLeft: 4,
      paddingRight: 4,
      svg: {
        width: '16px',
        height: '16px',
        margin: `0 !important`,
      },

      'svg + span': {
        marginLeft: 6,
      }
    }),
    labelSmall: {
      textAlign: 'center',
      margin: '0 2px',
    },
    sizeMedium: ({ theme }) => ({
      ...theme.typography.body2,
      fontWeight: 500,
      height: '40px',
      paddingLeft: 8,
      paddingRight: 8,
      svg: {
        width: '20px',
        height: '20px',
        margin: `0 !important`,
      },

      'svg + span': {
        marginLeft: 8,
      }
    }),
    labelMedium: {
      textAlign: 'center',
      margin: '0 4px',
    },
    outlined: {
      border: 'none',
    },
  },
  variants: [
    {
      props: { color: 'default' },
      style: ({ theme }) => ({
        '&.MuiChip-filled': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.neutral.contrastText,
        },
        '&.MuiChip-outlined': {
          backgroundColor: theme.palette.neutralLight.main,
          color: theme.palette.neutralLight.contrastText,
        }
      }),
    },
    {
      props: { color: 'info' },
      style: ({ theme }) => ({
        '&.MuiChip-filled': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        '&.MuiChip-outlined': {
          backgroundColor: theme.palette.primaryLight.main,
          color: theme.palette.primaryLight.contrastText,
        }
      }),
    },
    {
      props: { color: 'success' },
      style: ({ theme }) => ({
        '&.MuiChip-filled': {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        },
        '&.MuiChip-outlined': {
          backgroundColor: theme.palette.successLight.main,
          color: theme.palette.successLight.contrastText,
        }
      }),
    },
    {
      props: { color: 'error' },
      style: ({ theme }) => ({
        '&.MuiChip-filled': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
        '&.MuiChip-outlined': {
          backgroundColor: theme.palette.errorLight.main,
          color: theme.palette.errorLight.contrastText,
        }
      }),
    },
    {
      props: { color: 'warning' },
      style: ({ theme }) => ({
        '&.MuiChip-filled': {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        },
        '&.MuiChip-outlined': {
          backgroundColor: theme.palette.warningLight.main,
          color: theme.palette.warningLight.contrastText,
        }
      }),
    },
    {
      props: { variant: 'tag' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.neutralLight.main,
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.neutralLight.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.neutralLight.dark,
        },
        '&.active, &.selected': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.neutral.contrastText,
        },
      }),
    },
  ],
}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    large: true
    micro: true
  }
  interface ChipPropsVariantOverrides {
    tag: true
  }
}

export default MuiChip
