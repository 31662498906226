import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const RepeatIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79968 3C6.35197 3 6.79968 3.44772 6.79968 4V4.65369C8.26786 3.61271 10.0625 3 11.9999 3C16.9704 3 20.9999 7.02944 20.9999 12C20.9999 12.3801 20.9763 12.7552 20.9303 13.1237C20.862 13.6718 20.3623 14.0607 19.8142 13.9923C19.2662 13.924 18.8773 13.4243 18.9457 12.8763C18.9814 12.5896 18.9999 12.2972 18.9999 12C18.9999 8.13401 15.8659 5 11.9999 5C10.6801 5 9.44574 5.36484 8.39191 5.99999L8.79971 6C9.35199 6.00001 9.7997 6.44774 9.79968 7.00002C9.79967 7.55231 9.35194 8.00001 8.79966 8L5.79966 7.99993C5.24738 7.99992 4.79968 7.55221 4.79968 6.99993V4C4.79968 3.44772 5.2474 3 5.79968 3ZM4.18551 10.0077C4.73355 10.076 5.12243 10.5757 5.05409 11.1237C5.01834 11.4104 4.99988 11.7028 4.99988 12C4.99988 15.866 8.13388 19 11.9999 19C13.3198 19 14.5541 18.6352 15.6079 18H14.9999C14.4476 18 13.9999 17.5523 13.9999 17C13.9999 16.4477 14.4476 16 14.9999 16H17.9999C18.5522 16 18.9999 16.4477 18.9999 17V20C18.9999 20.5523 18.5522 21 17.9999 21C17.4476 21 16.9999 20.5523 16.9999 20V19.4842C15.57 20.4412 13.8499 21 11.9999 21C7.02932 21 2.99988 16.9706 2.99988 12C2.99988 11.6199 3.0235 11.2448 3.06946 10.8763C3.1378 10.3282 3.63747 9.93935 4.18551 10.0077Z"
      />
    </SvgIcon>
  )
}

export default RepeatIcon
