import * as React from 'react'
import moment from 'moment'

import { getSearchMethod } from 'utils'
import { API_FROZEN_ASSET_ENTITIES_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIFrozenAssetEntity } from 'api/interfaces'

export const getFae = async ({
  setLoading,
  debouncedSearch,
  page,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  debouncedSearch: string
  page: number
}): Promise<
  | {
      faes: IAPIFrozenAssetEntity[]
      faeCount: number
    }
  | undefined
> => {
  setLoading(true)

  const params = new URLSearchParams()

  params.append('page', page.toString())

  if (getSearchMethod(debouncedSearch) === 'name') {
    params.append('q', debouncedSearch)
  } else {
    params.append(
      'birth',
      moment(debouncedSearch, ['DD/MM/YYYY', 'DD-MM-YYYY']).toISOString()
    )
  }

  const res = await customFetch
    .get(
      `${API_FROZEN_ASSET_ENTITIES_URL}${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      console.log(err)
    })

  if (!res || !res.ok) {
    setLoading(false)

    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    console.log(err)
  })

  setLoading(false)

  return jsonRes
    ? {
        faes: jsonRes.results,
        faeCount: jsonRes.totalCount,
      }
    : undefined
}
