import { Dispatch, SetStateAction, useEffect } from 'react'
import { fetchIfas } from './arbitrageNavbarContent.helpers'
import EventEmitter, { UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE } from 'utils/events'
import { IAPIInsuranceFeeArbitrage, IAPIInsuranceProduct } from 'api/interfaces'

type IHook = (params: {
  selectedInsuranceProduct: IAPIInsuranceProduct | null | undefined
  setIfas: Dispatch<SetStateAction<IAPIInsuranceFeeArbitrage[] | null | undefined>>
}) => void

export const useArbitrageNavbarContent: IHook = ({ selectedInsuranceProduct, setIfas }) => {
  useEffect(() => {
    if (!selectedInsuranceProduct) {
      return
    }

    // setIfas(undefined)

    fetchIfas(selectedInsuranceProduct.id).then((ifas) => setIfas(ifas))
  }, [selectedInsuranceProduct, setIfas])

  useEffect(() => {
    if (!selectedInsuranceProduct) {
      return
    }

    EventEmitter.subscribe(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE, () =>
      fetchIfas(selectedInsuranceProduct.id).then((ifas) => setIfas(ifas))
    )

    return () => EventEmitter.remove(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE)
  })
}
