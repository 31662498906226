import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Checkbox } from 'component/Inputs'
import { ArrowLeftIcon, ArrowRightIcon } from 'icons'
import ExternalLinkIcon from 'icons/ExternalLinkIcon'
import { useFormik } from 'formik'
import { schemaOptin } from 'utils/schema'
import { putRegisteredUser } from '../core/Subscription.service'
import SubscriptionError from '../SubscriptionError'
import { SubscriptionContext } from '../SubscriptionManager'
import { SUBSCRIPTION_PAYMENT_URL } from 'utils/router/constants'

function SubscriptionOptin() {
  let history = useHistory()
  const { registration, setRegistration } = useContext(SubscriptionContext)
  const [error, setError] = useState<string | null>(null)
  const formik = useFormik({
    initialValues: {
      billingEmail: '',
      acceptTerms: false,
      optInNews: false,
      optInCompliance: false,
      isSameEmail: false,
    },
    validationSchema: schemaOptin,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      try {
        const optins = {
          ...values,
          email: registration?.userEmail || '',
          billingEmail: values.isSameEmail ? registration?.userEmail : values?.billingEmail,
        }
        delete optins.isSameEmail
        await putRegisteredUser(optins)
        setRegistration({ ...registration, ...optins })

        history.push(SUBSCRIPTION_PAYMENT_URL)
      } catch (e: any) {
        setError(e.message)
      }
    },
  })

  useEffect(() => {
    if (registration) {
      formik.setValues({
        billingEmail: registration?.billingEmail || '',
        acceptTerms: !!registration?.termsAcceptedAt || false,
        optInNews: registration?.optInNews || false,
        optInCompliance: registration?.optInCompliance || false,
        isSameEmail:
          (registration?.userEmail && registration?.billingEmail === registration?.userEmail) ||
          false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration])

  return (
    <>
      <Box sx={{ ml: 6 }}>
        <Typography variant="body2" color="text.secondary" mb={0.5}>
          Étape 4
        </Typography>
        <Typography variant="h2b" mb={4} component="h2">
          Finalisation de l’inscription
        </Typography>
      </Box>

      <Paper elevation={2} sx={{ p: '32px 48px', mb: 4 }}>
        {error ? (
          <SubscriptionError error={error} clearError={() => setError(null)} />
        ) : (
          <form onSubmit={formik.handleSubmit} id="form-optin">
            <FormControl variant="outlined" fullWidth sx={{ mb: 4 }}>
              <InputLabel htmlFor="email" sx={{ mb: 1.5 }}>
                E-mail de facturation
              </InputLabel>
              <Checkbox
                sx={{ mb: 1.5 }}
                label={`Identique à l’e-mail de connexion (${registration?.userEmail || ''})`}
                size="small"
                name="isSameEmail"
                checked={formik.values.isSameEmail}
                onChange={formik.handleChange}
              />
              <OutlinedInput
                id="billingEmail"
                type="email"
                placeholder="johndoe@doe.com"
                disabled={formik.values.isSameEmail}
                value={formik.values.billingEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.billingEmail && Boolean(formik.errors.billingEmail)}
              />
              {formik.touched.billingEmail && (
                <FormHelperText
                  error={formik.touched.billingEmail && Boolean(formik.errors.billingEmail)}
                >
                  {formik.errors.billingEmail}
                </FormHelperText>
              )}
            </FormControl>

            <Typography variant="subtitle1" fontWeight={500} mb={1.5}>
              Préférences de contact
            </Typography>
            <Box
              sx={{
                borderRadius: 1,
                p: 3,
                mb: 4,
                bgcolor: 'background.default',
              }}
            >
              <Checkbox
                sx={{
                  mb: 1.5,
                  alignItems: 'start',
                  '.MuiFormControlLabel-label': { fontWeight: 400 },
                }}
                label="Je souhaite être informé des nouvelles fonctionnalités de Sendraise."
                name="optInNews"
                checked={formik.values.optInNews}
                onChange={formik.handleChange}
                size="medium"
              />
              <Checkbox
                sx={{
                  alignItems: 'start',
                  '.MuiFormControlLabel-label': { fontWeight: 400 },
                }}
                label="J’accepte de recevoir des communications sur l’évolution du cadre règlementaire des Conseillers en Gestion de Patrimoine par e-mail."
                name="optInCompliance"
                checked={formik.values.optInCompliance}
                onChange={formik.handleChange}
                size="medium"
              />
            </Box>
            <CardCheckbox checked={true}>
              <Checkbox
                label="J’accepte les conditions générales d’utilisation"
                size="medium"
                name="acceptTerms"
                checked={formik.values.acceptTerms}
                onChange={formik.handleChange}
              />
              <Typography variant="subtitle2" color="text.secondary" mb={2} mt={0.5} ml="28px">
                En cochant la case, vous confirmez avoir lu et accepté les conditions générales
                d’utilisation de Sendraise.
              </Typography>
              <Link
                fontSize={12}
                sx={{ ml: '28px' }}
                href="https://sendraise.s3.eu-west-3.amazonaws.com/documents/CGV_Sendraise_02-2024.pdf"
                target="_blank"
              >
                Conditions générales d’utilisation
                <ExternalLinkIcon sx={{ ml: 0.5 }} fontSize="small" />
              </Link>
            </CardCheckbox>
          </form>
        )}
      </Paper>

      {!error && (
        <Stack direction="row" justifyContent="space-between">
          <Button
            color="neutralLight"
            onClick={() => history.push('/souscription/coordonnees')}
            startIcon={<ArrowLeftIcon />}
          >
            Précédent
          </Button>
          <Button
            color="secondary"
            endIcon={<ArrowRightIcon />}
            type="submit"
            form="form-optin"
            disabled={!formik.isValid || !formik.dirty}
          >
            Continuer
          </Button>
        </Stack>
      )}
    </>
  )
}

export default SubscriptionOptin

const CardCheckbox = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'checked',
})<{ checked: boolean; small?: boolean }>(({ theme, checked }) => ({
  borderRadius: 4,
  padding: '16px',
  border: `1px solid ${theme.palette.divider}`,
  ...(checked && {
    backgroundColor: theme.palette.primaryLight.main,
    borderColor: 'rgba(48, 129, 219, 0.50)',
  }),
}))
