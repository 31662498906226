import { API_USER_INSURANCE_URL } from 'api/constants'
import { IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'
import { IAPIQueueResponse } from 'api/interfaces/responses'
import { get } from 'utils'

export const getRevalidateDockets = async ({
  selectedPeriod,
  selectedInsurance,
  commissionType,
}: {
  selectedPeriod: IAPIReportPeriod
  selectedInsurance: IAPIInsurance
  commissionType: string
}) => {
  let queryParams = new URLSearchParams()

  queryParams.append('action', 'revalidate')
  queryParams.append('type', commissionType)
  queryParams.append('year', selectedPeriod.year.toString())
  queryParams.append('month', selectedPeriod.month.toString())

  return get<IAPIQueueResponse>({
    url: `${API_USER_INSURANCE_URL}/${
      selectedInsurance.id
    }/insurance-fee-report-items?${queryParams.toString()}`,
  })
}
