import * as React from 'react'

import { Accordion, AccordionProps, styled } from '@mui/material'

const AccordionBase: React.FC<AccordionProps> = (props) => {
  return <StyledAccordion {...props} />
}

export default AccordionBase

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  background: theme.palette.neutralLight.main,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  marginBottom: theme.spacing(1),

  '&:hover': {
    background: theme.palette.neutralLight.dark
  },

  '&:last-of-type': {
    marginBottom: 0,
  },

  '&.Mui-expanded': {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
}))
