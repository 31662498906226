import * as React from 'react'

import { Modal } from 'component'
import { Checkbox } from 'component/Inputs'
import { IAPIInsuranceFeeCommissionType } from 'api/interfaces'

import { handleCheckboxChange } from '../../core/ModalExportErrors.helpers'

import { Button, Grid, Stack, Typography } from '@mui/material'

interface IProps {
  insuranceProviderLogo: string
  insuranceName: string
  period: string
  selectedCommissionType: IAPIInsuranceFeeCommissionType
  activeCommissionTypes: IAPIInsuranceFeeCommissionType[]
  onCloseHandler: () => void
  setSelectedCommissionsTypes: StateSetter<IAPIInsuranceFeeCommissionType[]>
  goToNextStep: React.MouseEventHandler
}

const CommissionsTypeSelectionStep: React.FC<IProps> = (props) => {
  const {
    insuranceProviderLogo,
    insuranceName,
    period,
    selectedCommissionType,
    activeCommissionTypes,
    onCloseHandler,
    setSelectedCommissionsTypes,
    goToNextStep,
  } = props

  const [oustandingUcitsChecked, setOustandingUcitsChecked] = React.useState(
    selectedCommissionType === 'outstanding_ucits' &&
      activeCommissionTypes.includes('outstanding_ucits')
  )
  const [oustandingContractChecked, setOustandingContractChecked] = React.useState(
    selectedCommissionType === 'outstanding_contract' &&
      activeCommissionTypes.includes('outstanding_contract')
  )
  const [oustandingDelegateChecked, setOustandingDelegateChecked] = React.useState(
    selectedCommissionType === 'outstanding_delegate' &&
      activeCommissionTypes.includes('outstanding_delegate')
  )
  const [depositChecked, setDepositChecked] = React.useState(
    selectedCommissionType === 'deposit' && activeCommissionTypes.includes('deposit')
  )
  const [arbitrationChecked, setArbitrationChecked] = React.useState(
    selectedCommissionType === 'arbitration' && activeCommissionTypes.includes('arbitration')
  )
  const [upfrontChecked, setUpfrontChecked] = React.useState(
    selectedCommissionType === 'upfront' && activeCommissionTypes.includes('upfront')
  )
  const [outstandingOverrideChecked, setOutstandingOverrideChecked] = React.useState(
    selectedCommissionType === 'outstanding_override' &&
      activeCommissionTypes.includes('outstanding_override')
  )

  React.useEffect(() => {
    if (activeCommissionTypes.includes(selectedCommissionType)) {
      setSelectedCommissionsTypes([selectedCommissionType])
    }
  }, [activeCommissionTypes, selectedCommissionType, setSelectedCommissionsTypes])

  const COMMISSIONS_TYPE_CHECKBOXES: {
    commissionsType: IAPIInsuranceFeeCommissionType
    label: string
    checked: boolean
    disabled: boolean
    setChecked: StateSetter<boolean>
  }[] = [
    {
      commissionsType: 'outstanding_contract',
      label: 'Encours',
      checked: oustandingContractChecked,
      disabled: !activeCommissionTypes.includes('outstanding_contract'),
      setChecked: setOustandingContractChecked,
    },
    {
      commissionsType: 'outstanding_override',
      label: 'Majorations',
      checked: outstandingOverrideChecked,
      disabled: !activeCommissionTypes.includes('outstanding_override'),
      setChecked: setOutstandingOverrideChecked,
    },
    {
      commissionsType: 'outstanding_delegate',
      label: 'Mandats',
      checked: oustandingDelegateChecked,
      disabled: !activeCommissionTypes.includes('outstanding_delegate'),
      setChecked: setOustandingDelegateChecked,
    },
    {
      commissionsType: 'outstanding_ucits',
      label: 'Rétrocessions OPCVM',
      checked: oustandingUcitsChecked,
      disabled: !activeCommissionTypes.includes('outstanding_ucits'),
      setChecked: setOustandingUcitsChecked,
    },
    {
      commissionsType: 'arbitration',
      label: 'Arbitrages',
      checked: arbitrationChecked,
      disabled: !activeCommissionTypes.includes('arbitration'),
      setChecked: setArbitrationChecked,
    },
    {
      commissionsType: 'deposit',
      label: 'Versements',
      checked: depositChecked,
      disabled: !activeCommissionTypes.includes('deposit'),
      setChecked: setDepositChecked,
    },
    {
      commissionsType: 'upfront',
      label: 'Upfronts',
      checked: upfrontChecked,
      disabled: !activeCommissionTypes.includes('upfront'),
      setChecked: setUpfrontChecked,
    },
  ]

  const isNextStepDisabled =
    !oustandingUcitsChecked &&
    !oustandingContractChecked &&
    !oustandingDelegateChecked &&
    !depositChecked &&
    !arbitrationChecked &&
    !upfrontChecked &&
    !outstandingOverrideChecked

  return (
    <>
      <Modal.Content>
        <Stack direction="row" gap="12px" mb={4}>
          <img width={32} height={32} src={insuranceProviderLogo} alt={`${insuranceName} logo`} />
          <Typography variant="h2b" textTransform="capitalize" fontWeight={500}>
            {period}
          </Typography>
        </Stack>
        <Typography variant="body2" fontWeight={500} mb={0.5}>
          Choisissez le type de commissions à exporter
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" mb={2}>
          Vous obtiendrez un fichier par type sélectionné.
        </Typography>
        <Grid container spacing={2}>
          {COMMISSIONS_TYPE_CHECKBOXES.map(
            ({ commissionsType, label, checked, disabled, setChecked }) => (
              <Grid key={commissionsType} item xs={4}>
                <Checkbox
                  label={label}
                  checked={checked}
                  disabled={disabled}
                  onChange={handleCheckboxChange({
                    setChecked,
                    commissionsType,
                    setSelectedCommissionsTypes,
                  })}
                />
              </Grid>
            )
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <Button disabled={isNextStepDisabled} onClick={goToNextStep}>
            Exporter les erreurs
          </Button>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default CommissionsTypeSelectionStep
