import * as React from 'react'

import { NavLink } from 'react-router-dom'
import { LogoIcon, HomeIcon } from 'icons'

import { MiniSidebarProfile } from './components'

import { Box, Divider, Drawer, Stack, styled, SvgIconProps } from '@mui/material'

interface IProps {
  className?: string
  links: MiniSidebarLink[]
}

interface MiniSidebarLink {
  icon: React.FC<React.PropsWithChildren<SvgIconProps>>
  to: string
}

const LayoutMiniSidebar: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { className, children, links } = props

  return (
    <Wrapper className={className} direction="row">
      <MiniSidebar variant="permanent">
        <Stack flex={1} justifyContent="space-between">
          <Box>
            <Stack p={2.5} mb={4}>
              <LogoIcon sx={{ fontSize: 32 }} />
            </Stack>
            <NavItem exact to="/">
              <HomeIcon />
            </NavItem>
            <Box pl="15px" pr="15px" mt={6} mb={6}>
              <Divider sx={{ borderColor: 'secondary.light' }} />
            </Box>
            {links.map((link) => (
              <NavItem exact to={link.to} key={link.to}>
                {React.createElement(link.icon)}
              </NavItem>
            ))}
          </Box>
          <Box pl={2.5} pr={2.5} pb={5}>
            <MiniSidebarProfile />
          </Box>
        </Stack>
      </MiniSidebar>
      <Stack direction="row" flex={1} component="main">
        {children}
      </Stack>
    </Wrapper>
  )
}

export default LayoutMiniSidebar

const Wrapper = styled(Stack)(() => ({
  position: 'relative',
  zIndex: 0,
}))

const MiniSidebar = styled(Drawer)(() => ({
  width: 72,

  '.MuiDrawer-paper': {
    width: 72,
    justifyContent: 'flex-start',
    overflow: 'visible',
    borderColor: 'transparent',
  },
}))

const NavItem = styled(NavLink)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.secondary.contrastText,

  '&:hover': {
    background: theme.palette.secondary.light,
  },

  '&.active': {
    position: 'relative',
    background: theme.palette.secondary.main,

    '&::before': {
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: 4,
      background: theme.palette.primary.main,
    },
  },
}))
