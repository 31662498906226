import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const ExternalLinkIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 5C14.9477 5 14.5 4.55228 14.5 4C14.5 3.44772 14.9477 3 15.5 3H20C20.5523 3 21 3.44772 21 4V8.5C21 9.05228 20.5523 9.5 20 9.5C19.4477 9.5 19 9.05228 19 8.5V6.41421L12.7071 12.7071C12.3166 13.0976 11.6834 13.0976 11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929L17.5858 5H15.5ZM7.16146 4H11.5C12.0523 4 12.5 4.44772 12.5 5C12.5 5.55228 12.0523 6 11.5 6H7.2C6.62345 6 6.25117 6.00078 5.96784 6.02393C5.69617 6.04612 5.59546 6.0838 5.54601 6.10899C5.35785 6.20487 5.20487 6.35785 5.109 6.54601C5.0838 6.59545 5.04612 6.69617 5.02393 6.96784C5.00078 7.25117 5 7.62345 5 8.2V16.8C5 17.3766 5.00078 17.7488 5.02393 18.0322C5.04612 18.3038 5.0838 18.4045 5.109 18.454C5.20487 18.6422 5.35785 18.7951 5.54601 18.891C5.59546 18.9162 5.69617 18.9539 5.96784 18.9761C6.25117 18.9992 6.62345 19 7.2 19H15.8C16.3766 19 16.7488 18.9992 17.0322 18.9761C17.3038 18.9539 17.4045 18.9162 17.454 18.891C17.6422 18.7951 17.7951 18.6422 17.891 18.454C17.9162 18.4045 17.9539 18.3038 17.9761 18.0322C17.9992 17.7488 18 17.3766 18 16.8V12.5C18 11.9477 18.4477 11.5 19 11.5C19.5523 11.5 20 11.9477 20 12.5V16.8386C20 17.3657 20 17.8205 19.9694 18.195C19.9371 18.5904 19.8658 18.9836 19.673 19.362C19.3854 19.9265 18.9265 20.3854 18.362 20.673C17.9836 20.8658 17.5904 20.9371 17.195 20.9694C16.8205 21 16.3657 21 15.8386 21H7.16144C6.6343 21 6.17954 21 5.80497 20.9694C5.40963 20.9371 5.01641 20.8658 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3.13419 18.9836 3.06287 18.5904 3.03057 18.195C2.99997 17.8205 2.99998 17.3657 3 16.8385V8.16146C2.99998 7.63431 2.99997 7.17954 3.03057 6.80497C3.06287 6.40963 3.13419 6.01641 3.32698 5.63803C3.6146 5.07354 4.07354 4.6146 4.63803 4.32698C5.01641 4.13419 5.40963 4.06287 5.80497 4.03057C6.17955 3.99997 6.63431 3.99998 7.16146 4Z"
      />
    </SvgIcon>
  )
}

export default ExternalLinkIcon
