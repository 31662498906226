import * as React from 'react'

import { getLogout } from 'api/get'
import { PREFERENCES_PROFILE_URL } from 'utils/router/constants'

export const handleTooltipClose = (
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
): (() => void) => {
  return () => {
    setTooltipOpen(false)
  }
}

export const handleTooltipOpen = (
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
): React.MouseEventHandler => {
  return () => {
    setTooltipOpen(true)
  }
}

export const onLogoutClick: React.MouseEventHandler = async () => {
  await getLogout()
}

export const onSettingsClick = () => {
  window.location.replace(PREFERENCES_PROFILE_URL)
}
