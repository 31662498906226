import { TypographyOptions } from '@mui/material/styles/createTypography'
import React from 'react'

export const defaultFontFamily = ['dm-sans', 'Helvetica Neue', 'Arial', 'sans-serif'].join(',')

export const titleFontFamily = ['dm-sans', 'Times New Roman', 'Georgia', 'serif'].join(',')

export const numberFontFamily = ['dm-mono', 'Helvetica Neue', 'Arial', 'sans-serif'].join(',')

const typography: TypographyOptions = {
  fontFamily: defaultFontFamily,
  fontSize: 14,
  h0: {
    fontSize: 68,
    lineHeight: '76px',
    fontWeight: 400,
    fontFamily: titleFontFamily,
  },
  h1: {
    fontSize: 48,
    lineHeight: '60px',
    fontWeight: 400,
    fontFamily: titleFontFamily,
  },
  h2: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    fontFamily: titleFontFamily,
  },
  h2b: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400,
    fontFamily: defaultFontFamily,
  },
  h3: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: titleFontFamily,
  },
  h3b: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: defaultFontFamily,
  },
  body1: {
    fontSize: 18,
    lineHeight: '28px',
  },
  body2: {
    fontSize: 16,
    lineHeight: '24px',
  },
  body1_m: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 500,
  },
  body2_m: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 14,
    lineHeight: '20px',
  },
  subtitle1_m: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
  },
  subtitle2_m: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
  },
  number1: {
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: numberFontFamily,
    textAlign: "right",
  },
  number2: {
    fontSize: 12,
    lineHeight: '16px',
    fontFamily: numberFontFamily,
    textAlign: "right",
  },
  button: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    textTransform: 'none',
  },
  buttonLarge: {
    fontSize: 18,
    lineHeight: '28px',
  },
  sectionHeading: {
    fontSize: 10,
    lineHeight: '18px',
    letterSpacing: '2.4px',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h0: React.CSSProperties
    h2b: React.CSSProperties
    h3b: React.CSSProperties
    body1_m: React.CSSProperties
    body2_m: React.CSSProperties
    subtitle1_m: React.CSSProperties
    subtitle2_m: React.CSSProperties
    number1: React.CSSProperties
    number2: React.CSSProperties
    buttonLarge: React.CSSProperties
    sectionHeading: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h0: React.CSSProperties
    h2b: React.CSSProperties
    h3b: React.CSSProperties
    body1_m: React.CSSProperties
    body2_m: React.CSSProperties
    subtitle1_m: React.CSSProperties
    subtitle2_m: React.CSSProperties
    number1: React.CSSProperties
    number2: React.CSSProperties
    buttonLarge?: React.CSSProperties
    sectionHeading: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h0: true
    h2b: true
    h3b: true
    body1_m: true
    body2_m: true
    subtitle1_m: true
    subtitle2_m: true
    number1: true
    number2: true
    buttonLarge: true
    sectionHeading: true
  }

  interface TypographyPropsColorOverrides {
    white: true
    transparency: true
  }
}

export default typography
