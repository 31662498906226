import React, { FC, ReactNode } from 'react'

interface ITagProps {
  type: 'default'|'success'|'warning'|'danger'
  variant: 'default'|'outlined'
  className?: string
  children: ReactNode
}

const Tag: FC<React.PropsWithChildren<ITagProps>> = ({ type, children, variant, className }) => {
  const classNames = `tag tag--${type} tag--${variant} ${className}`

  return (
    <div className={classNames}>{children}</div>
  )
}

export default Tag;