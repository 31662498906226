import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const CalendarIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C7.55229 2 8 2.44772 8 3V4H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4.00003C18.459 4.00031 18.8593 4.00314 19.195 4.03057C19.5904 4.06287 19.9836 4.13419 20.362 4.32698C20.9265 4.6146 21.3854 5.07354 21.673 5.63803C21.8658 6.01641 21.9371 6.40963 21.9694 6.80497C22 7.17954 22 7.63429 22 8.16143V17.8386C22 18.3657 22 18.8205 21.9694 19.195C21.9371 19.5904 21.8658 19.9836 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C19.9836 21.8658 19.5904 21.9371 19.195 21.9694C18.8205 22 18.3657 22 17.8386 22H6.16144C5.6343 22 5.17954 22 4.80497 21.9694C4.40963 21.9371 4.01641 21.8658 3.63803 21.673C3.07355 21.3854 2.6146 20.9265 2.32698 20.362C2.13419 19.9836 2.06287 19.5904 2.03057 19.195C1.99997 18.8205 1.99998 18.3657 2 17.8385V8.16146C1.99998 7.63431 1.99997 7.17954 2.03057 6.80497C2.06287 6.40963 2.13419 6.01641 2.32698 5.63803C2.6146 5.07354 3.07354 4.6146 3.63803 4.32698C4.01641 4.13419 4.40963 4.06287 4.80497 4.03057C5.14075 4.00314 5.54097 4.00031 6 4.00003V3C6 2.44772 6.44772 2 7 2ZM6.2 6C5.62345 6 5.25118 6.00078 4.96784 6.02393C4.69617 6.04612 4.59546 6.0838 4.54601 6.10899C4.35785 6.20487 4.20487 6.35785 4.109 6.54601C4.0838 6.59545 4.04612 6.69617 4.02393 6.96784C4.00078 7.25117 4 7.62345 4 8.2V9H20V8.2C20 7.62345 19.9992 7.25117 19.9761 6.96784C19.9539 6.69617 19.9162 6.59545 19.891 6.54601C19.7951 6.35785 19.6422 6.20487 19.454 6.10899C19.4045 6.0838 19.3038 6.04612 19.0322 6.02393C18.7488 6.00078 18.3766 6 17.8 6H6.2ZM20 11H4V17.8C4 18.3766 4.00078 18.7488 4.02393 19.0322C4.04612 19.3038 4.0838 19.4045 4.109 19.454C4.20487 19.6422 4.35785 19.7951 4.54601 19.891C4.59546 19.9162 4.69617 19.9539 4.96784 19.9761C5.25117 19.9992 5.62345 20 6.2 20H17.8C18.3766 20 18.7488 19.9992 19.0322 19.9761C19.3038 19.9539 19.4045 19.9162 19.454 19.891C19.6422 19.7951 19.7951 19.6422 19.891 19.454C19.9162 19.4045 19.9539 19.3038 19.9761 19.0322C19.9992 18.7488 20 18.3766 20 17.8V11Z"
      />
    </SvgIcon>
  )
}

export default CalendarIcon
