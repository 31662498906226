export const CLIENT_LEGAL_FORM_DISPLAY_FILTERS = [
  {
    display: 'Tous les clients',
  },
  {
    value: 'legal_entity',
    display: 'Personnes morales',
  },
  {
    value: 'physical_person',
    display: 'Personnes physiques',
  },
]
