import { Components, Theme } from '@mui/material'

const MuiSwitch: NonNullable<Components<Theme>['MuiSwitch']> = {
  styleOverrides: {
    root: () => ({
      padding: 0,
      width: 38,
      height: 20,
    }),
    track: () => ({
      borderRadius: 10,
    }),
    switchBase: ({ theme }) => ({
      padding: 2,

      '&.Mui-checked': {
        transform: `translateX(18px)`,
        color: 'white',

        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    }),
    thumb: () => ({
      boxShadow: 'none',
      height: 16,
      width: 16,
    }),
    sizeSmall: () => ({
      width: 28,
      height: 16,

      '.MuiSwitch-switchBase': {
        padding: 2,

        '&.Mui-checked': {
          transform: `translateX(12px)`,
        },
      },

      '.MuiSwitch-thumb': {
        height: 12,
        width: 12,
      },
    }),
  },
}

export default MuiSwitch
