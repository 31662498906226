import { FC } from 'react'
import { IProps } from './typescript/layoutCommissionsContent.interfaces'

export type { IProps as ILayoutCommissionsContentProps } from './typescript/layoutCommissionsContent.interfaces'

const LayoutCommissionsContent: FC<React.PropsWithChildren<IProps>> = ({ children, className }) => {
  return <div className={`commissions__content ${className || ''}`}>{children}</div>
}

export default LayoutCommissionsContent
