import { CustomHook } from 'api/interfaces'
import { NavbarContext } from 'component/Navbar'
import { MouseEventHandler, useContext } from 'react'
import { IProps } from './navbarLink.interfaces'

export const useNavbarLink: CustomHook<
  Pick<IProps, 'id' | 'disabled'>,
  [MouseEventHandler, boolean]
> = ({ id, disabled }) => {
  const navbarContext = useContext(NavbarContext)

  const handleClick: MouseEventHandler = () => {
    if (!navbarContext || disabled) {
      return
    }

    navbarContext.setActiveLink(id)
  }

  const active = navbarContext?.activeLink === id

  return [handleClick, active]
}
