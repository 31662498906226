import { Components, Theme } from '@mui/material'

const MuiDialogContent: NonNullable<Components<Theme>['MuiDialogContent']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: `${theme.spacing(4)} !important`,
      overflow: 'auto',
    }),
  },
}

export default MuiDialogContent
