import EventEmitter, {
  UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE,
  UPDATE_EVENT_INSURANCE_FEE_DEPOSIT,
  UPDATE_EVENT_INSURANCE_PRODUCTS,
} from 'utils/events'

import { deleteConfiguration, patchConfigurationPropagation } from './ModaleDeleteFees.service'

export const removeConfiguration = async ({
  setError,
  setLoading,
  propagate,
  feesType,
  editedConfigurationId,
  insuranceProductsPropagatedToIds,
  goToNextStep,
}: {
  setError: StateSetter<string | undefined>
  setLoading: StateSetter<boolean>
  propagate: boolean
  feesType: 'arbitration' | 'deposit' | 'upfront'
  editedConfigurationId: number
  insuranceProductsPropagatedToIds: number[]
  goToNextStep: () => void
}) => {
  setError(undefined)
  setLoading(true)

  if (propagate) {
    await deleteConfiguration({ feesType, editedConfigurationId }).catch((err) => {
      setError(
        `Erreur lors de la suppression de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )
    })
  } else {
    await patchConfigurationPropagation({
      feesType,
      editedConfigurationId,
      insuranceProductIds: insuranceProductsPropagatedToIds,
    }).catch((err) => {
      setError(
        `Erreur lors de la suppression de la configuration de frais, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )
    })
  }

  setLoading(false)
  goToNextStep()

  if (feesType === 'arbitration') {
    EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE)
  } else {
    EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_FEE_DEPOSIT)
  }

  EventEmitter.dispatch(UPDATE_EVENT_INSURANCE_PRODUCTS)
}

export const getModalCTA = ({
  propagate,
  insuranceProductsPropagatedToCount,
}: {
  propagate: boolean
  insuranceProductsPropagatedToCount: number
}): string => {
  if (propagate === false) {
    return 'Supprimer la règle pour ce produit'
  }

  return insuranceProductsPropagatedToCount > 1
    ? `Supprimer la règle pour les ${insuranceProductsPropagatedToCount} produits`
    : 'Supprimer la règle pour ce produit'
}
