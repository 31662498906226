import React, { useEffect, useState } from 'react'
import { LayoutSubscription } from 'component/Layouts'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import {
  SUBSCRIPTION_ACCOUNT_URL,
  SUBSCRIPTION_ADDRESS_URL,
  SUBSCRIPTION_EMAIL_URL,
  SUBSCRIPTION_OPTIN_URL,
  SUBSCRIPTION_PAYMENT_URL,
  SUBSCRIPTION_WELCOME_URL,
} from 'utils/router/constants'
import SubscriptionAccount from '../SubscriptionAccount'
import SubscriptionEmail from '../SubscriptionEmail'
import SubscriptionAddress from '../SubscriptionAddress'
import SubscriptionPayment from '../SubscriptionPayment'
import SubscriptionWelcome from '../SubscriptionWelcome'
import { Box } from '@mui/material'
import SubscriptionOptin from '../SubscriptionOptin'
import { checkCSRFToken, getCurrentOnboardUser } from '../core/Subscription.service'
import { IAPIRegistration } from 'api/interfaces/entities'

interface ISubscriptionContext {
  registration: IAPIRegistration | null
  setRegistration: (registration: IAPIRegistration | null) => void
}

export const SubscriptionContext = React.createContext({
  registration: null,
  setRegistration: () => {},
} as ISubscriptionContext)

function SubscriptionManager() {
  let { path } = useRouteMatch()
  let location = useLocation()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const [initOnboarding, setInitOnboarding] = useState(false)
  const [registration, setRegistration] = useState<IAPIRegistration | null>(null)
  const [registrationIsFinished, setRegistrationIsFinished] = useState(false)

  useEffect(() => {
    checkCSRFToken().then(() => {
      fetchRegistration()
    })
  }, [])

  const fetchRegistration = async () => {
    try {
      const registration = await getCurrentOnboardUser()

      if (registration) {
        setRegistration(registration)
      }
    } catch (e: any) {
      const errorStatus = JSON.parse(e.message)?.status
      if (errorStatus === 410) {
        setRegistrationIsFinished(true)
      }
    } finally {
      setInitOnboarding(true)
    }
  }

  React.useEffect(() => {
    const stepsUrl = [
      SUBSCRIPTION_ACCOUNT_URL,
      SUBSCRIPTION_EMAIL_URL,
      SUBSCRIPTION_ADDRESS_URL,
      SUBSCRIPTION_OPTIN_URL,
      SUBSCRIPTION_PAYMENT_URL,
      SUBSCRIPTION_WELCOME_URL,
    ]

    if (initOnboarding) {
      if (
        (registrationIsFinished || registration?.isFinished) &&
        !location.pathname.includes(SUBSCRIPTION_WELCOME_URL) &&
        !activeStep
      ) {
        history.push(SUBSCRIPTION_WELCOME_URL)
      } else if (
        registration &&
        registration.isEmailValidated &&
        !registration.isFinished &&
        registration.stripeCustomerId &&
        !location.pathname.includes(SUBSCRIPTION_PAYMENT_URL) &&
        !activeStep
      ) {
        history.push(SUBSCRIPTION_PAYMENT_URL)
      } else if (
        registration &&
        registration.isEmailValidated &&
        !registration.isFinished &&
        !registration.stripeCustomerId &&
        !location.pathname.includes(SUBSCRIPTION_ADDRESS_URL) &&
        !activeStep
      ) {
        history.push(SUBSCRIPTION_ADDRESS_URL)
      } else if (
        registration &&
        !registration.isEmailValidated &&
        !location.pathname.includes(SUBSCRIPTION_EMAIL_URL) &&
        !activeStep
      ) {
        history.push(SUBSCRIPTION_EMAIL_URL)
      } else if (
        !registration &&
        !registrationIsFinished &&
        !location.pathname.includes(SUBSCRIPTION_ACCOUNT_URL)
      ) {
        history.push(SUBSCRIPTION_ACCOUNT_URL)
      } else {
        setActiveStep(stepsUrl.indexOf(location.pathname))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, registration, initOnboarding])

  return (
    <SubscriptionContext.Provider value={{ registration, setRegistration }}>
      <LayoutSubscription step={activeStep}>
        <Box className="container" sx={{ position: 'relative', zIndex: 1 }}>
          <Box
            className={`col ${activeStep === 4 ? 'col-md-10' : 'col-md-8'} col--center`}
            sx={{ maxWidth: activeStep === 4 ? '800px' : '600px' }}
          >
            <Switch>
              <Route exact path={path}></Route>
              <Route path={SUBSCRIPTION_ACCOUNT_URL} component={SubscriptionAccount} exact />
              <Route path={SUBSCRIPTION_EMAIL_URL} component={SubscriptionEmail} exact />
              <Route path={SUBSCRIPTION_ADDRESS_URL} component={SubscriptionAddress} exact />
              <Route path={SUBSCRIPTION_OPTIN_URL} component={SubscriptionOptin} exact />
              <Route path={SUBSCRIPTION_PAYMENT_URL} component={SubscriptionPayment} exact />
              <Route path={SUBSCRIPTION_WELCOME_URL} component={SubscriptionWelcome} exact />
            </Switch>
          </Box>
        </Box>
      </LayoutSubscription>
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionManager
