import { SxProps } from '@mui/material'
import * as React from 'react'
import { BaseInput } from 'component/Inputs'

interface IProps {
  sx?: SxProps
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  id: string
  onChange?: (value: number | undefined) => void
  size?: 'small' | 'medium'
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  color?: 'primary' | 'error'
  value: number | undefined
}

const NumberInput: React.FC<IProps> = (props) => {
  const { onChange, value: defaultValue, ...baseInputProps } = props

  const [value, setValue] = React.useState<string>(defaultValue ? defaultValue.toString() : '')

  React.useEffect(() => {
    const regex = /\.0*$/g

    if (defaultValue === undefined || defaultValue === null || regex.test(value)) {
      return
    }

    const strDefaultValue = defaultValue.toString()

    if (strDefaultValue !== value) {
      setValue(strDefaultValue)
    }
  }, [defaultValue, value])

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.replace(',', '.')

    const regex = /^-?\d*\.?\d*?$/g

    if (onChange === undefined || !regex.test(value)) {
      return
    }

    setValue(value)

    if (value === '') {
      onChange(undefined)
      return
    }

    const numberValue = Number(value)

    if (isNaN(numberValue)) {
      return
    }

    onChange(numberValue)
  }

  return <BaseInput {...baseInputProps} value={value} onChange={changeHandler} />
}

export default NumberInput
