import * as React from 'react'

import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getInitials } from 'utils'
import { LogOutIcon, MoreHorizontalIcon, SettingsIcon } from 'icons'
import { PREFERENCES_PROFILE_URL } from 'utils/router/constants'

import { getHideableElementSx, logout } from '../../core/Menu.helpers'

import {
  alpha,
  Avatar,
  ClickAwayListener,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

interface IProps {
  user: any
  open: boolean
}

const MenuProfile: React.FC<IProps> = (props) => {
  const { user, open } = props

  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const history = useHistory()
  const theme = useTheme()

  const hideableElementSx = getHideableElementSx({ theme, open })

  React.useEffect(() => {
    if (!open) {
      setTooltipOpen(false)
    }
  }, [open])

  const handleToggleTooltipClick = () => {
    setTooltipOpen((prev) => !prev)
  }

  const handleCloseTooltipClick = () => {
    setTooltipOpen(false)
  }

  const handleGoToPreferenceClick = () => {
    history.push(PREFERENCES_PROFILE_URL)
  }
  const handleLogoutClick = async () => {
    await logout(history)
  }

  return (
    <ClickAwayListener onClickAway={handleCloseTooltipClick}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <StyledAvatar
            alt={user.picture ? 'profile picture' : undefined}
            src={user.picture ? user.picture : undefined}
            onClick={!open ? handleToggleTooltipClick : undefined}
            sx={{ cursor: !open ? 'pointer' : undefined }}
          >
            {!user.picture
              ? getInitials({
                  firstName: user.firstName,
                  lastName: user.lastName,
                })
              : null}
          </StyledAvatar>
          {open && (
            <Typography sx={hideableElementSx} variant="subtitle1" color="primaryLight.main">
              {user.firstName} {user.lastName}
            </Typography>
          )}
        </Stack>
        <StyledTooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: theme.palette.secondary.main,
                padding: 1,
              },
            },
          }}
          title={
            <>
              <StyledTooltipOption
                p={1}
                pr={8}
                direction="row"
                alignItems="center"
                onClick={handleGoToPreferenceClick}
              >
                <SettingsIcon
                  sx={{ mr: 1, fontSize: 14, color: theme.palette.secondaryLight.main }}
                />
                <Typography variant="subtitle1" sx={{ color: theme.palette.secondaryLight.main }}>
                  Préférences
                </Typography>
              </StyledTooltipOption>
              <StyledTooltipOption
                p={1}
                pr={8}
                direction="row"
                alignItems="center"
                onClick={handleLogoutClick}
              >
                <LogOutIcon sx={{ mr: 1, fontSize: 14, color: '#DE9F99' }} />
                <Typography variant="subtitle1" color="#DE9F99">
                  Deconnexion
                </Typography>
              </StyledTooltipOption>
            </>
          }
          open={open && tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="top-start"
        >
          <IconButton sx={hideableElementSx} onClick={handleToggleTooltipClick}>
            <MoreHorizontalIcon
              sx={(theme) => ({
                fontSize: 20,
                color: alpha(theme.palette.secondary.contrastText, 0.7),
              })}
            />
          </IconButton>
        </StyledTooltip>
      </Stack>
    </ClickAwayListener>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(MenuProfile)

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& + .MuiTooltip-popper': {
    '.MuiTooltip-tooltip': {
      overflow: 'hidden',
      padding: 0,
      background: 'white',
      boxShadow: theme.shadows[2],
    },
  },
}))

const StyledTooltipOption = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeOut,
  }),

  '&:hover': {
    background: theme.palette.secondary.light,
  },
}))

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: 32,
  width: 32,
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  ...theme.typography.subtitle1,
}))
