import React, { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react'

import { IAPIDocketFilter, IAPIDocketItem, IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'
import {
  DEFAULT_DOCKETS_DISPLAY_FILTER_INDEX,
  DOCKETS_DISPLAY_FILTERS,
} from 'page/Commissions/Dockets/typescript/dockets.constants'
import { useDockets } from 'page/Commissions/Dockets/typescript/dockets.hooks'

import BaseTable from 'component/Tables/BaseTable'
import {
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'
import {
  ARBITRAGE_COLUMNS,
  DEFAULT_COLUMNS,
  EXPANDABLE_ARBITRAGE_COLUMNS,
  EXPANDABLE_DEFAULT_COLUMNS,
} from './columns'
import { AlertOffIcon, ChevronLeftIcon, ChevronRightIcon } from 'icons'
import { Box, Divider, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { Dropdown, SearchInput } from 'component/Inputs'
import { useDebounce } from 'utils'
import { COMMISSION_KINDS } from 'page/Commissions/Dockets'
import theme from 'theme'
import { isManualErrorRaisingDisabled } from 'utils/commissions'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'
import { useLocation } from 'react-router-dom'

interface IProps {
  selectedPeriod: IAPIReportPeriod | undefined
  selectedInsurance: IAPIInsurance | undefined
  commissionType: string
  isRefreshable: boolean
  isRevalidable: boolean
  setIsRevalidable: Dispatch<SetStateAction<boolean>>
  openModaleDeleteDocketsErrors: () => void
  setSelectedDocketItem: StateSetter<IAPIDocketItem | undefined>
  relaunchAnalysisButton: ReactElement
  setTableRef: StateSetter<any>
  refreshAnalysis: boolean
  setRefreshAnalysis: StateSetter<boolean>
}

const DocketsTable: FC<React.PropsWithChildren<IProps>> = ({
  selectedInsurance,
  selectedPeriod,
  commissionType,
  isRefreshable,
  setIsRevalidable,
  openModaleDeleteDocketsErrors,
  setSelectedDocketItem,
  relaunchAnalysisButton,
  setTableRef,
  refreshAnalysis,
  setRefreshAnalysis,
}) => {
  const apiRef = useGridApiRef()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const q = searchParams.get('q') || ''

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const [selectedDisplayFilterIndex, setSelectedDisplayFilterIndex] = useState<number>(
    DEFAULT_DOCKETS_DISPLAY_FILTER_INDEX
  )

  const selectedCommissionIndex = COMMISSION_KINDS.findIndex(
    (option) => option.value === commissionType
  )
  const selectedCommissionValue = COMMISSION_KINDS[selectedCommissionIndex].label

  const [searchTerms, setSearchTerms] = useState<string>(q)
  const [docketItems, setDocketItems] = useState<IAPIDocketItem[]>([])
  const [docketItemCount, setDocketItemCount] = useState<number>(0)
  const [contractOffset, setContractOffset] = useState<number>(0)
  const [contractCount, setContractCount] = useState<number>(0)
  const [expandedRowId, setExpandedRowId] = useState<number>(0)

  const debounceSearchTerms = useDebounce(searchTerms, 800)

  const [filterParams, setFilterParams] = useState<IAPIDocketFilter>({
    selectedInsurance,
    selectedPeriod,
    commissionType,
    isRefreshable,
    contractOffset,
    searchTerms: debounceSearchTerms,
    selectedDisplayFilterIndex,
  })

  useEffect(() => {
    if (apiRef?.current) {
      setTableRef(apiRef.current)
    }
    // eslint-disable-next-line
  }, [apiRef, apiRef.current, setTableRef])

  useEffect(() => {
    if (
      filterParams?.selectedInsurance?.id !== selectedInsurance?.id ||
      filterParams?.selectedPeriod?.year !== selectedPeriod?.year ||
      filterParams?.selectedPeriod?.month !== selectedPeriod?.month ||
      refreshAnalysis
    ) {
      setContractOffset(0)
      setFilterParams((prevState: IAPIDocketFilter) => {
        return {
          ...prevState,
          selectedInsurance,
          selectedPeriod,
          contractOffset: 0,
        }
      })
      setRefreshAnalysis(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsurance, selectedPeriod])

  useEffect(() => {
    setContractOffset(0)
    setFilterParams((prevState: IAPIDocketFilter) => {
      return {
        ...prevState,
        searchTerms: debounceSearchTerms,
        contractOffset: 0,
      }
    })
  }, [debounceSearchTerms])

  useDockets({
    setIsDataLoading,
    setContractCount,
    setDocketItems,
    setDocketItemCount,
    setIsRevalidable,
    filterParams,
  })

  const updateSearch = (value: string) => {
    setSearchTerms(value)
  }

  const updateFilter = (event: SelectChangeEvent<unknown>) => {
    const selectedIndex = DOCKETS_DISPLAY_FILTERS.findIndex(
      (option) => option.value === event.target.value
    )
    setSelectedDisplayFilterIndex(selectedIndex)
    setContractOffset(0)
    setFilterParams((prevState: IAPIDocketFilter) => {
      return {
        ...prevState,
        selectedDisplayFilterIndex: selectedIndex,
        contractOffset: 0,
      }
    })
  }

  const goToPreviousPage = () => {
    if (contractOffset >= 10) {
      setFilterParams((prevState: IAPIDocketFilter) => {
        return {
          ...prevState,
          contractOffset: contractOffset - 10,
        }
      })
      setContractOffset(contractOffset - 10)
    }
  }

  const goToNextPage = () => {
    if (contractOffset < contractCount - 10) {
      setFilterParams((prevState: IAPIDocketFilter) => {
        return {
          ...prevState,
          contractOffset: contractOffset + 10,
        }
      })
      setContractOffset(contractOffset + 10)
    }
  }

  const COLUMNS = React.useMemo<GridColDef[]>(() => {
    // WARNING : Toggle manualy added with custom css to reorder the column at the first position. Otherwise a bug with the column grouping push the toggle at the 2nd position
    const TOGGLE_COLUMNS: GridColDef[] = [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        cellClassName: 'cell-first',
        headerClassName: 'cell-first',
        maxWidth: 40,
        minWidth: 40,
      },
    ]
    const ACTION_COLUMNS: GridColDef[] = [
      {
        field: 'actions',
        type: 'actions',
        width: 45,
        getActions: (params: GridRowParams) => {
          return params.row.id
            ? [
                <GridActionsCellItem
                  icon={<AlertOffIcon />}
                  label="Désactiver / Réactiver les erreurs"
                  onClick={() => {
                    setSelectedDocketItem(params.row)
                    openModaleDeleteDocketsErrors()
                  }}
                  disabled={isManualErrorRaisingDisabled(params.row)}
                  showInMenu
                  sx={{ opacity: 0.7 }}
                />,
              ]
            : []
        },
      },
    ]

    return commissionType !== 'arbitration' && commissionType !== 'deposit'
      ? [...TOGGLE_COLUMNS, ...DEFAULT_COLUMNS, ...ACTION_COLUMNS]
      : [...TOGGLE_COLUMNS, ...ARBITRAGE_COLUMNS, ...ACTION_COLUMNS]
  }, [commissionType, openModaleDeleteDocketsErrors, setSelectedDocketItem])

  const EXPANDABLE_COLUMNS = React.useMemo<GridColDef[]>(
    () =>
      commissionType !== 'arbitration' && commissionType !== 'deposit'
        ? EXPANDABLE_DEFAULT_COLUMNS
        : EXPANDABLE_ARBITRAGE_COLUMNS,
    [commissionType]
  )

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['contract'],
      },
      columns: {
        columnVisibilityModel: { ucits: false },
      },
      // pagination: { paginationModel: { pageSize: 5 } },
    },
  })

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => {
      function DetailPanelContent({ row }: any) {
        return (
          <BaseTable
            className="expanded-table"
            autoHeight
            columns={EXPANDABLE_COLUMNS}
            rows={row}
            sx={{
              boxShadow: 'none',
              '.MuiDataGrid-columnHeaders': { display: 'none' },
              '.MuiDataGrid-cell, .MuiDataGrid-cell .cell-number, .cell--docket-direction h6': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: theme.typography.subtitle2.lineHeight,
                border: 'none',
              },
              '.MuiDataGrid-row': {
                background: 'background.default',
                borderRadius: '4px',
              },
              '.MuiDataGrid-virtualScrollerRenderZone': {
                gap: '4px',
              },
              '.MuiDataGrid-detailPanel': {
                background: '#ff0000!important',
              },
            }}
          />
        )
      }

      const { groupedItems, ...parentRow } = row
      return groupedItems.length > 0 ? (
        <DetailPanelContent row={[parentRow, ...groupedItems]} />
      ) : null
    },
    [EXPANDABLE_COLUMNS]
  )

  const handleDetailPanelExpandedRowIdsChange = (params: any) => {
    setExpandedRowId(params[0])
  }

  const setExpandedPanelHeight = () => {
    const panelContent: HTMLElement | null = document.querySelector(
      '.MuiDataGrid-row--detailPanelExpanded'
    )
    setTimeout(() => {
      const expandedTableParent: HTMLElement | null = document.querySelector(
        '.MuiDataGrid-detailPanel:has(.expanded-table)'
      )
      const expandedTable: HTMLElement | null = document.querySelector('.expanded-table')
      if (expandedTable && panelContent && expandedTableParent) {
        panelContent.style.marginBottom = `${expandedTable.scrollHeight + 16}px`
        expandedTableParent.style.height = `${expandedTable.scrollHeight + 16}px`
      }
    })
  }

  useEffect(() => {
    if (expandedRowId) {
      setExpandedPanelHeight()
    }
  }, [expandedRowId])

  const currentPage = Math.floor(contractOffset / 10) + 1
  const totalPages = Math.max(1, Math.ceil(contractCount / 10))

  return docketItems ? (
    <>
      <Typography variant="body2" fontWeight={500} mb={1.5}>
        {selectedCommissionValue}{' '}
        <Typography component="span" variant="body2" color="text.secondary">
          ({docketItemCount})
        </Typography>
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <SearchInput
            id="search"
            value={searchTerms}
            onChange={(e) => updateSearch(e.target.value)}
            onReset={(e) => updateSearch('')}
            size="small"
          />
          <Dropdown
            id="filter"
            value={DOCKETS_DISPLAY_FILTERS[selectedDisplayFilterIndex].value}
            onChange={updateFilter}
            placeholder="Filtrer par"
            options={DOCKETS_DISPLAY_FILTERS}
            size="small"
          />
          {relaunchAnalysisButton && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem />
              {relaunchAnalysisButton}
            </>
          )}
        </Stack>
        <Box
          className="table-commissions__pagination"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            {currentPage} sur {totalPages}
          </Typography>
          <IconButton
            color="neutral"
            size="small"
            disabled={currentPage <= 1}
            onClick={goToPreviousPage}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            color="neutral"
            size="small"
            disabled={currentPage >= totalPages}
            onClick={goToNextPage}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Stack>

      <BaseTable
        autoHeight
        getRowHeight={(props) => {
          if (Number.isInteger(props.id)) {
            return 68
          }
          return 40
        }}
        columnHeaderHeight={68}
        columns={COLUMNS}
        rows={docketItems}
        noContentMessage="Aucune opération à afficher."
        apiRef={apiRef}
        defaultGroupingExpansionDepth={-1}
        initialState={initialState}
        pagination={false}
        // pageSizeOptions={[5, 50, 100]}
        // hideFooter={false}
        groupingColDef={{
          leafField: 'ucits',
          hideDescendantCount: true,
        }}
        getRowClassName={(params) => {
          return params.row.id ? '' : 'MuiDataGrid-row--grouped'
        }}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {searchTerms ? (
                <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                  Aucune commission ne correspond à votre recherche.
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                    Aucune opération à afficher.
                  </Typography>
                  <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                    Soit aucun bordereau contenant ce type de commission n’a été uploadé, soit le ou
                    les bordereaux uploadés ne contenaient pas d’opération.
                  </Typography>
                </>
              )}
            </Stack>
          ),
        }}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        loading={isDataLoading}
      />
    </>
  ) : (
    <></>
  )
}

export default DocketsTable
