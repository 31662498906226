import { API_UPFRONT_FEE_URL } from 'api/constants'
import { IAPIInsuranceFeeUpfront } from 'api/interfaces'
import { get } from 'utils'

export const getUpfrontConfigurations = (insuranceProductId: number) => {
  const params = new URLSearchParams()

  params.append('insuranceProductId', insuranceProductId.toString())

  const url = `${API_UPFRONT_FEE_URL}?${params.toString()}`

  return get<IAPIInsuranceFeeUpfront[]>({ url })
}
