import * as React from 'react'

import { NavLink } from 'react-router-dom'

import { getHideableElementSx } from '../../core/Menu.helpers'

import { Stack, Typography, Link, useTheme } from '@mui/material'
import { alpha, SxProps, Theme } from '@mui/material/styles'

interface IProps {
  sx?: SxProps
  icon: React.ElementType
  name: string
  link: string
  menuOpen: boolean
  exact?: boolean
}

const NavMenuItem: React.FC<IProps> = (props) => {
  const { icon, name, link, menuOpen, exact = false, sx } = props

  const theme = useTheme()
  const hideableElementSx = getHideableElementSx({ theme, open: menuOpen })

  return (
    <Link
      underline="none"
      to={link}
      exact={exact}
      component={NavLink}
      sx={(theme) => ({
        position: 'relative',
        color: alpha(theme.palette.secondary.contrastText, 0.7),
        transition: theme.transitions.create('background-color, color', {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeOut,
        }),
        ':hover': { 
          bgcolor: 'secondary.light',
          color: 'secondary.contrastText'
        },
        '::before': {
          content: '""',
          position: 'absolute',
          height: '100%',
          width: '4px',
          bgcolor: 'primary.main',
          opacity: 0,
          transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeOut,
          }),
        },
        '&.active': {
          bgcolor: 'secondary.main',
          color: 'secondary.contrastText',
          '::before': {
            opacity: 1,
          },
        },
      })}
    >
      <Stack direction="row" alignItems="center" gap={1.5} px={3} py={1.75} sx={sx}>
        {React.createElement(icon, {
          sx: (theme: Theme) => ({
            fontSize: 20,
            color: 'inherit',
          }),
        })}
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            color: 'inherit',
            ...(hideableElementSx as object),
          })}
        >
          {name}
        </Typography>
      </Stack>
    </Link>
  )
}

export default NavMenuItem
