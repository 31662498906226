import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const CheckIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7096 4.79536C21.0987 5.18724 21.0965 5.82041 20.7046 6.20957L9.62772 17.2096C9.23776 17.5968 8.60839 17.5968 8.21844 17.2096L3.29536 12.3207C2.90348 11.9315 2.90127 11.2984 3.29043 10.9065C3.67959 10.5146 4.31276 10.5124 4.70464 10.9015L8.92308 15.0907L19.2954 4.79043C19.6872 4.40127 20.3204 4.40348 20.7096 4.79536Z"
      />
    </SvgIcon>
  )
}

export default CheckIcon
