import {
  Stack,
  Typography,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SxProps,
} from '@mui/material'
import { ChevronDownIcon } from 'icons'
import HelpCircleIcon from 'icons/HelpCircleIcon'
import React, { FC, ReactNode } from 'react'

interface IInfoPanelProps {
  title: string
  footnote?: string
  children: ReactNode
  sx?: SxProps
}

const InfoPanel: FC<React.PropsWithChildren<IInfoPanelProps>> = ({ title, children, sx }) => {
  return (
    <InfoPanelContainer sx={{ ...sx }}>
      <InfoPanelHeader expandIcon={<ChevronDownIcon sx={{ color: 'text.tertiary' }} />}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography
            variant="subtitle1_m"
            sx={{
              color: 'text.primary',
            }}
          >
            {title}
          </Typography>
          <HelpCircleIcon
            sx={{
              color: 'text.tertiary',
            }}
          />
        </Stack>
      </InfoPanelHeader>
      <InfoPanelContent>{children}</InfoPanelContent>
    </InfoPanelContainer>
  )
}

const InfoPanelContainer = styled(Accordion)(({ theme }) => ({
  background: theme.palette.neutralLight.main,
  boxShadow: theme.helpers.elevationShadows.elevation_01,
  borderRadius: 4,
  position: 'relative',
  marginBottom: theme.spacing(2),
  '&:hover': {
    background: theme.palette.neutralLight.dark,
  },
}))

const InfoPanelHeader = styled(AccordionSummary)(({ theme }) => ({
  userSelect: 'none',

  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '.MuiAccordionSummary-content': {
    margin: `${theme.spacing(2)} 0 !important`,
  },
}))

const InfoPanelContent = styled(AccordionDetails)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  paddingTop: 0,
  paddingRight: theme.spacing(4),

  br: {
    content: '""',
    display: 'block',
    marginTop: theme.spacing(1),
  },

  strong: {
    fontWeight: 500,
  },

  ul: {
    marginTop: theme.spacing(1),
    listStyle: 'disc',

    li: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  },
}))

export default InfoPanel
