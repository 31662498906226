import Icon from 'component/Icon'
import React, { FC } from 'react'
import { handleChange, handleKeyDown } from './typescript/largeSearchInput.helpers'
import { IProps } from './typescript/largeSearchInput.interfaces'

const LargeSearchInput: FC<React.PropsWithChildren<IProps>> = ({
  type,
  className,
  value,
  setValue,
  placeholder,
  label,
  name,
  onEnterPress,
  icon,
  iconPosition,
  error,
}) => {
  return (
    <div
      className={`search-input-lg search-input-lg--${type} ${
        iconPosition === 'left' ? 'search-input-lg--icon-left' : 'search-input-lg--icon-right'
      } ${className || ''}`}
    >
      {label && (
        <label className="search-input-lg__label c-blue-400 ft-secondary mb-1" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        id={name}
        value={value}
        className="search-input-lg__input"
        onChange={handleChange(setValue)}
        type={type}
        placeholder={placeholder}
        onKeyDown={handleKeyDown(onEnterPress)}
      />
      {icon && <Icon className={`${icon} search-input-lg__icon f f-center`} />}
      {error && <p className="search-input-lg__error ft-tertiary c-red">{error}</p>}
    </div>
  )
}

export default LargeSearchInput
