import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const AlertOffIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7186 22.2155C20.0955 22.5948 20.7065 22.5948 21.0834 22.2155C21.4602 21.8361 21.4602 21.221 21.0834 20.8416L3.14742 2.7861C2.77055 2.40671 2.15952 2.40671 1.78265 2.78609C1.40578 3.16548 1.40578 3.78058 1.78265 4.15996L4.02785 6.42012C3.15737 7.90557 2.65796 9.63765 2.65796 11.4872C2.65796 17.0029 7.09976 21.4743 12.579 21.4743C14.4162 21.4743 16.1368 20.9716 17.6125 20.0953L19.7186 22.2155ZM16.1509 18.624L13.4755 15.9307C13.3122 16.2569 12.9765 16.4807 12.5889 16.4807L12.579 16.4807C12.0311 16.4807 11.5869 16.0336 11.5869 15.482C11.5869 15.0892 11.8122 14.7493 12.1399 14.5862L5.48943 7.89144C4.94745 8.97221 4.64217 10.1938 4.64217 11.4872C4.64217 15.8997 8.1956 19.4769 12.579 19.4769C13.8638 19.4769 15.0773 19.1696 16.1509 18.624Z"
      />
      <path d="M13.5711 10.5392L11.5869 8.54173V7.49229C11.5869 6.94072 12.0311 6.49358 12.579 6.49358C13.1269 6.49358 13.5711 6.94072 13.5711 7.49229L13.5711 10.5392Z" />
      <path d="M21.1411 16.5353C20.8305 17.0681 20.1051 17.1167 19.6705 16.6792V16.6792C19.3255 16.332 19.285 15.7906 19.5215 15.3621C20.155 14.2144 20.5158 12.8932 20.5158 11.4872C20.5158 7.07455 16.9624 3.49743 12.579 3.49743C11.1877 3.49743 9.88006 3.85779 8.74309 4.49085C8.31023 4.73186 7.76134 4.69069 7.41218 4.3392V4.3392C6.98043 3.90457 7.02854 3.18516 7.55566 2.87299C9.02902 2.00043 10.7459 1.5 12.579 1.5C18.0582 1.5 22.5 5.9714 22.5 11.4872C22.5 13.3288 22.0048 15.0541 21.1411 16.5353Z" />
    </SvgIcon>
  )
}

export default AlertOffIcon
