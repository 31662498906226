import * as React from 'react'

import { FeePropagationViewer } from 'component'
import { Switch } from 'component/Inputs'
import Modal from 'component/Modal'
import { BaseTable } from 'component/Tables'
import { InfoIcon } from 'icons'
import { roundDecimals } from 'utils'
import {
  IAPIInsuranceFeeArbitrage,
  IAPIInsuranceFeeDeposit,
  IAPIInsuranceFeeUpfront,
} from 'api/interfaces'

import { getModalCTA, removeConfiguration } from '../../core/ModaleDeleteFees.helpers'

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  styled,
  SwitchProps,
  Typography,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'

interface IProps {
  providerLogo: string
  insuranceName: string
  insuranceProductName: string
  insuranceProductId: number
  editedConfiguration: IAPIInsuranceFeeArbitrage | IAPIInsuranceFeeDeposit | IAPIInsuranceFeeUpfront
  feesType: 'arbitration' | 'deposit' | 'upfront'
  setError: StateSetter<string | undefined>
  onCloseHandler: () => void
  goToNextStep: () => void
}

const DeletionStep: React.FC<IProps> = (props) => {
  const {
    providerLogo,
    insuranceName,
    insuranceProductName,
    insuranceProductId,
    editedConfiguration,
    feesType,
    setError,
    onCloseHandler,
    goToNextStep,
  } = props

  const [loading, setLoading] = React.useState(false)
  const [propagate, setPropagate] = React.useState(true)

  const ARBITRATION_DEPOSIT_COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'feeRate',
        headerName: `Taux de frais`,
        sortable: false,
        width: 113,
        valueFormatter: (params) => `${roundDecimals(params.value, 4)} %`,
      },
      {
        field: 'organizationRate',
        headerName: 'Taux CGP',
        sortable: false,
        width: 95,
        valueFormatter: (params) => `${roundDecimals(params.value, 4)} %`,
      },
      {
        field: 'insuranceRante',
        headerName: 'Taux Assureur',
        sortable: false,
        width: 122,
        valueGetter: (params) =>
          `${roundDecimals(Number(params.row.feeRate) - Number(params.row.organizationRate), 4)} %`,
      },
      {
        field: 'organizationMinimumAmount',
        headerName: 'Incomp. CGP',
        sortable: false,
        width: 91,
        valueFormatter: (params) => `${params.value} €`,
      },
      {
        field: 'insuranceMinimumAmount',
        headerName: 'Incomp. assur.',
        sortable: false,
        width: 91,
        valueFormatter: (params) => `${params.value} €`,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        sortable: false,
        flex: 1,
      },
    ],
    []
  )

  const UPFRONT_COLUMNS = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'organizationRate',
        headerName: 'Taux CGP',
        sortable: false,
        width: 95,
        valueFormatter: (params) => `${roundDecimals(params.value, 4)} %`,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        sortable: false,
        flex: 1,
      },
    ],
    []
  )

  const insuranceProductsPropagatedTo = editedConfiguration.insuranceProducts.filter(
    (product) => product.id !== insuranceProductId
  )

  const propagateChangeHandler: SwitchProps['onChange'] = (e) => {
    setPropagate(e.target.checked)
  }

  const deleteConfigurationClickHandler = () => {
    removeConfiguration({
      setError,
      setLoading,
      propagate,
      feesType,
      editedConfigurationId: editedConfiguration.id,
      insuranceProductsPropagatedToIds: insuranceProductsPropagatedTo.map((product) => product.id),
      goToNextStep,
    })
  }

  const insuranceProductsPropagatedToCount = insuranceProductsPropagatedTo.length

  return (
    <>
      <Modal.Content>
        <Stack direction="row" gap={1.5} mb={2}>
          <img height={32} width={32} src={providerLogo} alt={`${insuranceName} logo`} />
          <Typography variant="h2b" fontWeight={500}>
            {insuranceProductName}
          </Typography>
        </Stack>
        {insuranceProductsPropagatedToCount > 1 ? (
          <Stack
            mb={2}
            direction="row"
            gap={1}
            borderRadius={0.5}
            sx={(theme) => ({
              backgroundColor: 'primaryLight.main',
              color: 'primaryLight.contrastText',
              padding: theme.spacing(1, 1.5),
            })}
          >
            <InfoIcon sx={{ fontSize: 20 }} />
            <Box>
              <Typography variant="subtitle1" sx={{ color: 'primaryLight.contrastText' }}>
                Cette règle est partagée par{' '}
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{ textDecoration: 'underline' }}
                >
                  {insuranceProductsPropagatedToCount} autres produits.
                </Typography>
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'primaryLight.contrastText' }}>
                <>
                  Vous pouvez propager la suppression à ces {insuranceProductsPropagatedToCount}{' '}
                  autres produits ou simplement supprimer la règle pour ce produit.
                </>
              </Typography>
            </Box>
          </Stack>
        ) : null}
        <ConfigTable
          autoHeight
          columns={feesType === 'upfront' ? UPFRONT_COLUMNS : ARBITRATION_DEPOSIT_COLUMNS}
          rows={[editedConfiguration]}
          noContentMessage="Aucune commission sur arbitrage n'a été configurée pour ce produit."
        />
        {insuranceProductsPropagatedToCount > 0 ? (
          <>
            <Stack direction="row" mt={3.5}>
              <Stack direction="row" gap={1} height={24}>
                <Switch
                  checked={propagate}
                  onChange={propagateChangeHandler}
                  label="Propager la suppression"
                />
              </Stack>
            </Stack>
            {propagate ? (
              <FeePropagationViewer
                insuranceProducts={insuranceProductsPropagatedTo}
                sx={{ mt: 2.5 }}
              />
            ) : null}
          </>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Button variant="text" color="neutral" onClick={onCloseHandler}>
            Annuler
          </Button>
          <DeleteButton
            onClick={deleteConfigurationClickHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ height: `20px !important`, width: `20px !important`, color: 'white' }}
                />
              ) : undefined
            }
            color="error"
          >
            {getModalCTA({
              propagate,
              insuranceProductsPropagatedToCount: insuranceProductsPropagatedToCount + 1,
            })}
          </DeleteButton>
        </Stack>
      </Modal.Actions>
    </>
  )
}

export default DeletionStep

const ConfigTable = styled(BaseTable)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,

  '.MuiDataGrid-columnHeaders': {
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },

  '.MuiDataGrid-row': {
    '&:last-of-type': {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  '.MuiDataGrid-columnHeaderTitle': {
    overflow: 'visible',
    whiteSpace: 'normal',
    textAlign: 'center',
  },

  '.MuiDataGrid-cell, .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'center',
  },

  '.MuiDataGrid-cell': {
    '&[data-field="comment"]': {
      color: theme.palette.text.secondary,
    },
  },
}))

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: 'white',

  '&:hover': {
    backgroundColor: '#C6564D',
  },
}))
