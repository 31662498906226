import * as React from 'react'
import { Box, Drawer, Stack, Step, StepLabel, Stepper, Typography, styled } from '@mui/material'
import { LogoIcon } from 'icons'
import HelpContainer from 'component/HelpContainer'

interface IProps {
  step: number
}

const steps = [
  {
    label: 'Création du compte',
  },
  {
    label: 'Vérification de l’email',
  },
  {
    label: 'Coordonnées du cabinet',
  },
  {
    label: 'Finalisation de l’inscription',
  },
  {
    label: "Choix de l'offre",
  },
  {
    label: 'Bienvenue !',
  },
]

const LayoutSubscription: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { children, step } = props

  return (
    <Box display="flex" className="page--white">
      <Drawer
        variant="permanent"
        open={true}
        sx={(theme) => ({
          flexShrink: 0,
          width: 365,
        })}
        PaperProps={{
          sx: (theme) => ({
            borderRight: `1px solid ${theme.palette.secondary.main}`,
            overflowX: 'hidden',
            width: 365,
            padding: 6,
          }),
        }}
      >
        <Stack flexGrow={1} justifyContent="space-between">
          <Box>
            <Stack direction="row" alignItems="center" gap={1}>
              <LogoIcon sx={{ fontSize: 32 }} />
              <Typography variant="h3b" color="white">
                Sendraise
              </Typography>
            </Stack>
            <Typography variant="body2" color="white" mt={6} mb={0.5} fontWeight={500}>
              Souscription
            </Typography>
            <Typography variant="h2" color="white" mb={4}>
              Gel des Avoirs
            </Typography>

            <StyledStepper activeStep={step} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </StyledStepper>
          </Box>
          <Box>
            <HelpContainer
              helpURL="mailto:support@sendraise.eu?subject=Problème lors de la création de compte"
              message="Je rencontre un problème"
              size="small"
            />
          </Box>
        </Stack>
      </Drawer>
      <Box
        component="main"
        sx={() => ({
          flex: `0 0 calc(100vw - 365px)`,
          backgroundColor: 'background.default',
          height: '100vh',
        })}
      >
        <Box className="page__content" py={9}>
          {children}

          {/* <Box
            sx={{ width: '200px', height: '200px', background: 'red' }}
            className="test-anim"
          ></Box> */}
          <div className="page__background page__background--animated">
            <div id="rect1" className="shape__animated"></div>
            <div id="rect2" className="shape__animated"></div>
            <div id="bubble" className="shape__animated"></div>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default LayoutSubscription

const StyledStepper = styled(Stepper)(({ theme }) => ({
  color: 'white',

  '.MuiStepLabel-label': {
    color: 'white !important',
  },
  '.MuiStepIcon-text': {
    display: 'none',
  },
  '.MuiSvgIcon-root': {
    circle: {
      r: 11,
      stroke: theme.palette.secondary.main,
      strokeWidth: '2px',
      color: 'transparent',
    },
    '&.Mui-active': {
      circle: {
        stroke: theme.palette.primary.main,
      },
    },
  },
  '.MuiStepLabel-iconContainer': {
    paddingRight: '12px',
  },
  '.MuiStepConnector-root': {
    marginLeft: '9px',
    '&.Mui-completed ': {
      '.MuiStepConnector-line': {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
  '.MuiStepConnector-line': {
    borderLeft: `2px dashed ${theme.palette.secondary.main}`,
  },
  // 'MuiStepLabel-iconContainer': {
  //   width: 12px;
  //   height: 12px;
  //   border: 1px solid red;
  //   border-radius: 100%;
  //   margin-right: 8px;
  // }
}))
