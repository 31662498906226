import { Components, Theme } from '@mui/material'

const MuiInputBase: NonNullable<Components<Theme>['MuiInputBase']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.MuiInputBase-multiline': {
        padding: 0,
        height: 'auto',
        minHeight: theme.helpers.sizing.formElements.medium.height,
        paddingTop: theme.helpers.sizing.formElements.medium.padding,
        paddingBottom: theme.helpers.sizing.formElements.medium.padding,
      },

      borderRadius: `${theme.shape.borderRadius} !important`,
      height: theme.helpers.sizing.formElements.medium.height,
      paddingLeft: `${theme.helpers.sizing.formElements.medium.padding} !important`,
      paddingRight: `${theme.helpers.sizing.formElements.medium.padding} !important`,
    }),
    input: ({ theme }) => ({
      padding: '0 !important',
      ...theme.typography.subtitle1,
    }),
    sizeSmall: ({ theme }) => ({
      borderRadius: `${theme.shape.borderRadius} !important`,
      height: theme.helpers.sizing.formElements.small.height,
      paddingLeft: `${theme.helpers.sizing.formElements.small.padding} !important`,
      paddingRight: `${theme.helpers.sizing.formElements.small.padding} !important`,

      '&.MuiInputBase-multiline': {
        minHeight: theme.helpers.sizing.formElements.small.height,
        paddingTop: theme.helpers.sizing.formElements.small.padding,
        paddingBottom: theme.helpers.sizing.formElements.small.padding,
      },
    }),
    inputSizeSmall: ({ theme }) => ({
      ...theme.typography.subtitle2,
    }),
  },
}

export default MuiInputBase
