import {
  Button,
  Chip,
  Divider,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import ModalVerificationProofs from 'component/Modals/ModalDownloadClientsDocuments/ModalVerificationProofs'
import MuiTablePagination from 'component/Tables/Pagination/MuiTablePagination'
import { DownloadIcon, SnowIcon } from 'icons'
import moment from 'moment'
import { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react'
import { handleModalTriggerClick } from '../../core/Clients.helpers'
import { getSingleProofReport, getVerificationProofs } from '../../core/Clients.service'
import Loader from 'component/Loader'
import { APIFrozenAssetCheckProof } from 'api/interfaces/entities'
import useQueueProgress from 'utils/hooks/queueProgress'
import { IFetchError, downloadRemoteFile } from 'utils'
import { customFetch } from 'api/customFetch'
import { ADMIN_ACTION, API_URL } from 'api/constants'
import { Icon } from 'component'
import { connect } from 'react-redux'

interface IProps {
  user: any
}

const ProofLayout: React.FC<IProps> = (props) => {
  const { user } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<APIFrozenAssetCheckProof[]>([])
  const [error, setError] = useState<string>()
  const [jobId, setJobId] = useState<string>()
  const [downloadLoading, setDownloadLoading] = useState(false)

  const fetchData = useCallback(async () => {
    const res = await getVerificationProofs({ page, perPage, setLoading })

    if (!res) {
      return
    }

    setData(res.results)
    setTotal(res.totalCount)
  }, [page, perPage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }
  const handleChangeRowsPerPage = (event: BaseSyntheticEvent) => {
    setPerPage(event.target.value)
    setPage(0)
  }

  useQueueProgress({
    interval: 1000,
    queueId: 'generate-xlsx',
    jobId,
    setJobId,
    onError: (error) => {
      setError(
        error ||
          'Erreur durant la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le service client Sendraise'
      )
      setDownloadLoading(false)
    },
    onComplete: (data) => {
      setDownloadLoading(false)
      downloadRemoteFile(data.url)
    },
  })


  const deleteAllFrozenAssetData = () => {
    setDownloadLoading(true)

    customFetch
      .delete(`${API_URL}clients`)
      .then(() => {
        setDownloadLoading(false)
      })
      .catch(() => {
        setDownloadLoading(false)
      })
  }

  const downloadProof = async (id: number) => {
    if (downloadLoading) return
    setError(undefined)
    setDownloadLoading(true)
    const res = await getSingleProofReport(id).catch((err: IFetchError) => {
      setError(
        `Erreur lors de la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )
    })

    if (!res) {
      setError(
        `Erreur lors de la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le support Sendraise.`
      )

      return
    }

    setJobId(res.jobId)
  }

  const getFileName = (date: Date) => {
    const dateString = moment(date).format('YYYYMMDD')
    const timeString = moment(date).format('HH-mm-ss')
    return `Sendraise_GDA_Verification_${dateString}_${timeString}`
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1_m">Preuves de vérification ({total})</Typography>
        <Button color="primary" onClick={handleModalTriggerClick(setModalOpen, true)}>
          Télécharger les preuves en masse
        </Button>
      </Stack>
      <Divider sx={{ mb: 4, mt: 4 }} />
      <MuiTablePagination
        count={total}
        rowsPerPage={perPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Preuves par page"
      />
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table aria-label="Tableau des preuves de vérification">
          <TableHead>
            <TableRow>
              <StyledHeaderCell sx={{ pl: 6 }}>Nom</StyledHeaderCell>
              <StyledHeaderCell>Généré le</StyledHeaderCell>
              <StyledHeaderCell width="40%">Nombre de suspicions</StyledHeaderCell>
              <StyledHeaderCell width={3}></StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : data.length ? (
              data.map((row, index) => (
                <TableRow key={row.id} hover={true}>
                  <StyledBodyCell className={index === data.length - 1 ? 'cell--last' : ''}>
                    <img
                      src={process.env.PUBLIC_URL + '/static/images/excel-logo.svg'}
                      alt="Excel file"
                    />
                    {getFileName(row.createdAt)}
                  </StyledBodyCell>
                  <StyledBodyCell className={index === data.length - 1 ? 'cell--last' : ''}>
                    {moment(row.createdAt).format('DD/MM/YYYY')}
                    <span> • </span>
                    {moment(row.createdAt).format('HH[h]mm')}
                    <Typography component="span" fontSize={14} sx={{ opacity: 0.7, ml: 5 }}>
                      {moment(row.createdAt).fromNow()}
                    </Typography>
                  </StyledBodyCell>
                  <StyledBodyCell className={index === data.length - 1 ? 'cell--last' : ''}>
                    {row.numberOfSuspects ? (
                      <Chip
                        size="small"
                        label={`${row.numberOfSuspects} ${
                          row.numberOfSuspects > 1 ? 'suspicions' : 'suspicion'
                        }`}
                        color="warning"
                        icon={<SnowIcon />}
                      />
                    ) : (
                      <CustomNeutralChip size="small" label="Aucune suspicion" variant="outlined" />
                    )}
                  </StyledBodyCell>
                  <StyledBodyCell className={index === data.length - 1 ? 'cell--last' : ''}>
                    <IconButton
                      color="neutralLight"
                      size="small"
                      component="button"
                      onClick={() => downloadProof(row.id)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </StyledBodyCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="subtitle1">Aucune preuve de vérification</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {user.role === 'admin' && ADMIN_ACTION === "true" && (
        <Stack direction="row" justifyContent="flex-start" sx={{ mt: 4 }}>
          <Button
              color="error"
              startIcon={<Icon className={`icon-trash`} />}
              onClick={(e: any) => {
                e.preventDefault()
                deleteAllFrozenAssetData()
              }}
            >
            Supprimer les données GDA
          </Button>
        </Stack>
      )}
      <ModalVerificationProofs
        open={modalOpen}
        onClose={handleModalTriggerClick(setModalOpen, false)}
      />
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={() => setError(undefined)}
        message={error}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}


export default connect(mapStateToProps)(ProofLayout)

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  backgroundColor: theme.palette.background.inverted,
  color: theme.palette.secondary.contrastText,
}))

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  color: theme.palette.neutralLight.contrastText,
  borderBottom: `1px solid ${theme.palette.divider}`,

  '&.cell--last': {
    border: 'none',
  },

  img: {
    marginRight: 16,
  },
}))

const CustomNeutralChip = styled(Chip)(({ theme }) => ({
  background: `${theme.palette.neutralLight.dark} !important`,
  color: theme.palette.neutralLight.contrastText,
}))
