import { API_FROZEN_ASSET_CHECK_PROOFS_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIQueueResponse } from 'api/interfaces/responses'
import moment from 'moment'
import { get } from 'utils'

export const getReport = (minDate: string, maxDate: string) => {
  const searchParams = new URLSearchParams()

  searchParams.append('getReport', 'true')
  if (minDate) {
    searchParams.append('minDate', moment(minDate, ['DD/MM/YYYY', 'DD-MM-YYYY']).toISOString(true))
  }
  if (maxDate) {
    searchParams.append('maxDate', moment(maxDate, ['DD/MM/YYYY', 'DD-MM-YYYY']).toISOString(true))
  }

  return get<IAPIQueueResponse>({
    url: `${API_FROZEN_ASSET_CHECK_PROOFS_URL}?${searchParams.toString()}`,
  })
}

export const getAvailableReportYears = async () => {
  const res = await customFetch
    .get(`${API_FROZEN_ASSET_CHECK_PROOFS_URL}?groupByYear=true`)
    .catch((err) => {
      console.log(err)
    })

  if (!res || !res.ok) {
    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    console.log(err)
  })

  return jsonRes
}
