import { Stack, SxProps, Typography, styled } from '@mui/material'
import ExternalLinkIcon from 'icons/ExternalLinkIcon'
import LifeBuoyIcon from 'icons/LifeBuoyIcon'

interface IProps {
  helpURL?: string
  message: string | JSX.Element
  size?: 'small' | 'default'
  standalone?: boolean
  sx?: SxProps
  light?: boolean
  alignItems?: string
}

const HelpContainer: React.FC<IProps> = (props) => {
  const {
    helpURL,
    message,
    standalone = true,
    size = 'default',
    light,
    sx,
    alignItems = 'center',
  } = props

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }

  return (
    <HelpContainerContent
      sx={{ ...sx }}
      size={size}
      standalone={standalone}
      onClick={() => helpURL && openInNewTab(helpURL)}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      helpURL={helpURL}
      light={light}
    >
      <Stack direction="row" alignItems={alignItems} spacing={1}>
        <LifeBuoyIcon />
        <Typography
          variant="subtitle1"
          color={light ? 'accentHelp.dark' : 'accentHelp.contrastText'}
          sx={{ fontWeight: light ? 400 : 500 }}
        >
          {message}
        </Typography>
      </Stack>
      {helpURL && <ExternalLinkIcon />}
    </HelpContainerContent>
  )
}

const HelpContainerContent = styled(Stack, {
  shouldForwardProp: (propName) =>
    propName !== 'size' &&
    propName !== 'standalone' &&
    propName !== 'helpURL' &&
    propName !== 'light',
})<{ size: string; standalone: boolean; helpURL?: string; light?: boolean }>(
  ({ theme, size, standalone, helpURL, light }) => ({
    backgroundColor: theme.palette.accentHelp.main,
    flexDirection: 'row',
    padding: size === 'small' ? `${theme.spacing(1)} ${theme.spacing(1.5)}` : `${theme.spacing(2)}`,
    borderRadius: standalone ? '4px' : '0px',
    transition: 'background 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)',
    cursor: 'pointer',

    svg: {
      fill: theme.palette.accentHelp.contrastText,
      opacity: '.5',
      transition: 'opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)',
    },

    ...(helpURL && {
      '&:hover': {
        backgroundColor: theme.palette.accentHelp.dark,
        cursor: 'pointer',

        svg: {
          opacity: '1',
        },
      },
    }),

    ...(light && {
      border: '1px solid rgba(42, 103, 104, 0.50)',
      backgroundColor: theme.palette.accentHelpLight.main,
      color: theme.palette.accentHelpLight.contrastText,
      svg: {
        fill: theme.palette.accentHelpLight.contrastText,
        opacity: 1,
      },
      '&:hover': {
        backgroundColor: theme.palette.accentHelpLight.light,
        boxShadow:
          '0px 2px 6px 0px rgba(66, 65, 59, 0.10), 0px -1px 0px 0px rgba(0, 0, 0, 0.10) inset',
        h6: {
          color: theme.palette.accentHelp.dark,
        },
        svg: {
          fill: theme.palette.accentHelp.dark,
        },
      },
    }),
  })
)

export default HelpContainer
