import { Components, Theme } from '@mui/material'

const MuiDialog: NonNullable<Components<Theme>['MuiDialog']> = {
  defaultProps: {
    maxWidth: false,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&.modal--proofs': {
        '.MuiDialog-paper': {
          width: 800,
          maxWidth: 'unset',
        },
      },
    }),
    paper: ({ theme }) => ({
      width: '55vw',
      maxHeight: 'calc(100vh - 296px)',
      background: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    }),
  },
}

export default MuiDialog
