import * as React from 'react'
import { Box, Card, CardActionArea, ModalProps, Stack, styled, Typography } from '@mui/material'

import { DownloadIcon, ListIcon } from 'icons'
import { Modal } from 'component'

import { handleDownloadClick } from './core/ModalDownloadClientsDocuments.helpers'
import Loader from 'component/Loader'
import useQueueProgress from 'utils/hooks/queueProgress'
import { downloadRemoteFile } from 'utils'

interface IProps {
  open: boolean
  onClose: NonNullable<ModalProps['onClose']>
}

const ModalDownloadClientsDocuments: React.FC<IProps> = (props) => {
  const { open, onClose } = props

  const [loading, setLoading] = React.useState<boolean>(false)
  const [progress, setProgress] = React.useState<number>(0)
  const [error, setError] = React.useState<string>()
  const [jobId, setJobId] = React.useState<string>()

  useQueueProgress({
    interval: 1000,
    queueId: 'generate-xlsx',
    jobId,
    setJobId,
    setProgress,
    onError: (error) => {
      setError(
        error ||
          'Erreur durant la génération du rapport, veuillez réessayer. Si le problème persiste, contactez le service client Sendraise'
      )
      setLoading(false)
    },
    onComplete: (data) => {
      setLoading(false)
      downloadRemoteFile(data)
      onClose({}, 'backdropClick')
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      stepTitle="Téléchargement de documents"
      title="Choisir les documents à télécharger"
      modalId="ModalDownloadClientsDocuments"
    >
      <Modal.Content>
        {loading ? (
          <>
            <Wrapper justifyContent="center" alignItems="center">
              <Box mb={2}>
                <Loader />
              </Box>
              <Typography variant="body2" mb={0.5} sx={{ color: 'text.primary' }}>
                Génération du rapport : {progress}%
              </Typography>
            </Wrapper>
          </>
        ) : (
          <>
            <Card sx={{ boxShadow: 'none' }}>
              <DownloadButton
                onClick={handleDownloadClick({
                  target: 'csv',
                  setLoading: setLoading,
                  setProgress,
                  setError,
                  onClose,
                  setJobId,
                })}
              >
                <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Stack flexDirection="row" alignItems="center">
                    <ListIcon sx={{ color: 'text.tertiary', mr: 1 }} />
                    <Typography variant="subtitle1" color="text.primary">
                      Rapport complet (CSV)
                    </Typography>
                  </Stack>
                  <DownloadIcon sx={{ color: 'text.secondary', fontSize: 16 }} />
                </Stack>
              </DownloadButton>
            </Card>
            {error ? (
              <Typography variant="subtitle1" color="error.text" mt={2}>
                {error}
              </Typography>
            ) : null}
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default ModalDownloadClientsDocuments

export const DownloadButton = styled(CardActionArea)(({ theme }) => ({
  backgroundColor: theme.palette.neutralLight.main,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  padding: `${theme.spacing(1.75)} ${theme.spacing(2)} ${theme.spacing(1.75)} ${theme.spacing(
    1.5
  )}`,
}))

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default, // TOCHECK
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(6),
}))
