import * as React from 'react'

import { AlertCircleIcon, AlertOffIcon } from 'icons'
import { IFlag, IInvalidationFlag } from 'utils/commissions'

import { Stack, SxProps, Tooltip, Typography } from '@mui/material'

interface IProps {
  sx?: SxProps
  fieldFlag: IFlag
  fieldInvalidationFlag: IInvalidationFlag | undefined
}

const FlagTooltip: React.FC<IProps> = (props) => {
  const { sx, fieldFlag, fieldInvalidationFlag } = props

  const isInvalidated = fieldInvalidationFlag !== undefined

  return fieldFlag !== undefined ? (
    <Tooltip
      slotProps={{ popper: { sx: { maxWidth: 300 } } }}
      sx={sx}
      title={
        <Stack gap={1}>
          {isInvalidated ? (
            <>
              <Typography variant="body2" color="text.primary" sx={{ fontWeight: 500 }}>
                {fieldFlag.type}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                "{fieldInvalidationFlag.comment}"
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Erreur désactivée par {fieldInvalidationFlag.userName}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" fontWeight={500} color="error.main">
                {fieldFlag.type}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {fieldFlag.message?.split('\r').map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              </Typography>
            </>
          )}
        </Stack>
      }
    >
      <Stack direction="row" alignItems="center">
        {isInvalidated ? (
          <AlertOffIcon sx={{ fontSize: 16, color: 'text.secondary', cursor: 'pointer' }} />
        ) : (
          <AlertCircleIcon sx={{ fontSize: 16, color: 'warning.main', cursor: 'pointer' }} />
        )}
      </Stack>
    </Tooltip>
  ) : null
}

export default FlagTooltip
