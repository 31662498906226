import { API_CLIENTS_URL, API_QUEUE_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'

window.Buffer = window.Buffer || require("buffer").Buffer;

export const uploadClientDB = async ({ crm, file }: { crm: 'prisme' | 'o2s'; file: File }) => {
  const form = new FormData()

  form.append('crm', crm)

  if (crm === 'prisme') {
    let fileArrayBuffer = await file.arrayBuffer()
    let buffer = Buffer.from(fileArrayBuffer)
    const latinString = buffer.toString('latin1')
    const utf8String = buffer.toString()
    let fileData = latinString.indexOf('Prénom') > 0 ? latinString : utf8String

    form.append('file', fileData)
  } else {
    form.append('file', file)
  }

  const res = await customFetch.post(API_CLIENTS_URL, form, false).catch((err) => {
    console.log(err)
  })

  if (!res || !res.ok) {
    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    console.log(err)
  })

  return jsonRes ? jsonRes.jobId : undefined
}

export const getDBUploadState = async ({
  jobId,
  interval,
  setProgress,
  setError,
  setStep,
}: {
  jobId: number
  interval: NodeJS.Timeout
  setProgress: React.Dispatch<React.SetStateAction<number>>
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  customFetch
    .get(`${API_QUEUE_URL}/process-crm-import/${jobId}`)
    .then((res) => res.json())
    .then((data) => {
      if (data.state === 'completed') {
        if (data.data.clientCount === 0 && data.data.error) {
          let msg = data.data.error

          if (data.data.error.message) {
            msg = data.data.error.message
          }

          clearInterval(interval)
          setProgress(0)
          setError(msg)
          setStep((step) => step + 1)
          return
        }

        clearInterval(interval)
        setProgress(100)
        setStep((step) => step + 1)
        return
      }

      if (data.state === 'failed') {
        setError('Erreur lors du traitement du fichier, réessayez plus tard')
        setProgress(0)
        clearInterval(interval)
        return
      }

      setProgress(Math.round(data.progress * 100))
    })
    .catch((err) => {
      console.log(err)
      clearInterval(interval)
      setProgress(0)
      setError("Erreur lors de l'envoi du fichier, réessayez plus tard")
    })
}
