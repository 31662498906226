import * as React from 'react'
import moment from 'moment'

import { RouteComponentProps } from 'react-router-dom'
import { GridRowsProp } from '@mui/x-data-grid-premium'
import { IAPIClient } from 'api/interfaces'
import { FROZEN_ASSETS_ENTITIES_CLIENTS_URL } from 'utils/router/constants'

import { getClients } from './Clients.service'
import { IClientRow, IClientRowFae } from '../components/ClientsListLayout'
import { IInitialClientsValue } from '..'

//todo: delete
export const handleSearchChange = (
  setSearch: React.Dispatch<React.SetStateAction<string>>
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    setSearch(e.target.value)
  }
}

//todo: delete
export const handleSearchReset = (
  setSearch: React.Dispatch<React.SetStateAction<string>>
): React.MouseEventHandler => {
  return () => {
    setSearch('')
  }
}

//todo: delete
export const handleModalTriggerClick = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  open: boolean
): React.MouseEventHandler => {
  return () => {
    setModalOpen(open)
  }
}

export const handleSeeFaeDetailsClick = ({
  setFaeModalOpen,
  setSelectedFae,
  fae,
}: {
  setFaeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedFae: React.Dispatch<React.SetStateAction<IClientRowFae | undefined>>
  fae: IClientRowFae
}): React.MouseEventHandler => {
  return () => {
    setFaeModalOpen(true)
    setSelectedFae(fae)
  }
}

export const getNoContentMessage = (debouncedSearch: string): string => {
  return debouncedSearch === ''
    ? `Aucun client n'a été importé pour le moment`
    : `Aucun client ne correspond à vos critères de recherche`
}

export const getRows = ({
  clients,
  clientDBUpdate,
}: {
  clients: IAPIClient[]
  clientDBUpdate: string
}): GridRowsProp<IClientRow> =>
  clients.map<IClientRow>((client) => {
    const {
      id,
      prismeId,
      name,
      firstName,
      dateOfBirth,
      placeOfBirth,
      nationality,
      nexusId,
      email,
      phone,
      mobilePhone,
      address,
      frozenAssetEntity,
    } = client

    return {
      id,
      lastName: name,
      firstName,
      dateOfBirth:
        dateOfBirth !== null && moment(dateOfBirth).isValid()
          ? moment(dateOfBirth).format('DD/MM/YYYY')
          : null,
      status: frozenAssetEntity === null ? 'no-frozen-asset' : 'suspected',
      lastCheck: moment().to(clientDBUpdate),
      prismeId,
      nexusId,
      placeOfBirth,
      nationality,
      email,
      phone,
      mobilePhone,
      address,
      frozenAssetEntity:
        frozenAssetEntity !== null
          ? {
              name: frozenAssetEntity.name,
              firstNames: frozenAssetEntity.firstNames,
              titles: frozenAssetEntity.titles,
              aliases: frozenAssetEntity.aliases,
              genders: frozenAssetEntity.genders,
              datesOfBirth: frozenAssetEntity.datesOfBirth
                ? frozenAssetEntity.datesOfBirth.map((date) =>
                    moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : '-'
                  )
                : null,
              placesOfBirth: frozenAssetEntity.placesOfBirth,
              reasons: frozenAssetEntity.reasons,
              legalGrounds: frozenAssetEntity.legalGrounds,
              UEReferences: frozenAssetEntity.UEReferences,
            }
          : null,
    }
  })

export const updateAllData = async ({
  setLoading,
  setHeaderLoading,
  debouncedSearch,
  page,
  setClientCount,
  setFilteredClientCount,
  setSuspectCount,
  setClientDBUpdate,
  setFaeDBUpdate,
  setRows,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setHeaderLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined
  debouncedSearch: string
  page: number
  setClientCount: React.Dispatch<React.SetStateAction<number | undefined>>
  setFilteredClientCount: React.Dispatch<React.SetStateAction<number | undefined>>
  setSuspectCount: React.Dispatch<React.SetStateAction<number | undefined>>
  setClientDBUpdate: React.Dispatch<React.SetStateAction<string | undefined>>
  setFaeDBUpdate: React.Dispatch<React.SetStateAction<string | undefined>>
  setRows: React.Dispatch<React.SetStateAction<readonly IClientRow[] | undefined>>
}) => {
  const res = await getClients({
    setLoading,
    setHeaderLoading,
    search: debouncedSearch,
    page,
  })

  if (!res) {
    return
  }

  setClientCount(res.clientCount)
  setFilteredClientCount(res.filteredClientCount)
  setSuspectCount(res.suspectCount)
  setClientDBUpdate(res.clientDBUpdate)
  setFaeDBUpdate(res.faeDBUpdate)
  setRows(
    getRows({
      clients: res.clients,
      clientDBUpdate: res.clientDBUpdate,
    })
  )
}

export const fetchFirstClientPage = async ({
  setLoading,
  debouncedSearch,
  setInitialValues,
  setSuspectCount,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setInitialValues: React.Dispatch<React.SetStateAction<IInitialClientsValue | undefined>>
  debouncedSearch: string

  setSuspectCount: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const res = await getClients({
    setLoading,
    search: debouncedSearch,
    page: 1,
  })

  if (!res) {
    return
  }

  setSuspectCount(res.suspectCount)
  setInitialValues({
    rows: getRows({
      clients: res.clients,
      clientDBUpdate: res.clientDBUpdate,
    }),
    clientCount: res.clientCount,
    filteredClientCount: res.filteredClientCount,
    clientDBUpdate: res.clientDBUpdate,
    faeDBUpdate: res.faeDBUpdate,
  })
}

export const updateURL = ({
  debouncedSearch,
  history,
}: {
  debouncedSearch: string
  history: RouteComponentProps['history']
}) => {
  const searchParams = new URLSearchParams()

  searchParams.append('r', debouncedSearch)

  history.push({
    pathname: FROZEN_ASSETS_ENTITIES_CLIENTS_URL,
    search: debouncedSearch ? `?${searchParams.toString()}` : undefined,
  })
}
