import React, { FC, useContext } from 'react'
import { NavbarContext } from '..'
import { IProps } from './typescript/navbarContent.interfaces'

export type { IProps as INavbarContentProps } from './typescript/navbarContent.interfaces'

const NavbarContent: FC<React.PropsWithChildren<IProps>> = ({ children, id, className }) => {
  const navbarContext = useContext(NavbarContext)

  return navbarContext && navbarContext.activeLink === id ? (
    <div className={`navbar__content ${className || ''}`}>{children}</div>
  ) : (
    <></>
  )
}

export default NavbarContent
