import React, { useState, useEffect } from 'react'

function InputTag(props: any) {
  const {
    getValue,
    label,
    id,
    name,
    value,
    error,
    isChecked,
    className,
  } = props

  const [checked, setChecked] = useState(isChecked)

  const handleChange = () => {
    if (getValue) getValue(label, value, checked)
  }

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  return (
    <div
      className={`tag checkbox-tag ${className} ${checked && 'is-selected'}`}
    >
      <input
        type="checkbox"
        id={`${name}_${id}`}
        name={name}
        value={value}
        onChange={handleChange}
        checked={checked}
      />
      <label htmlFor={`${name}_${id}`}>{label}</label>
      {error && (
        <div className="input__error ft-tertiary mt-1 ft-black-700">
          {error.message}
        </div>
      )}
    </div>
  )
}

export default InputTag
