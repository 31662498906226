import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { BaseInput } from 'component/Inputs'
import { useHistory } from 'react-router-dom'
import PasswordInput from 'component/Inputs/PasswordInput'
import { ArrowRightIcon } from 'icons'
import HelpContainer from 'component/HelpContainer'
import PasswordChecklist from 'utils/form/PasswordChecklist'
import { schemaAccount } from 'utils/schema'
import { useFormik } from 'formik'
import { createRegistration, getCurrentOnboardUser } from '../core/Subscription.service'
import SubscriptionError from '../SubscriptionError'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubscriptionContext } from '../SubscriptionManager'
import { SUBSCRIPTION_EMAIL_URL } from 'utils/router/constants'

const ReCaptchaEl = ({ setToken }: { setToken: (value: string) => void }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('yourAction')
    setToken(token)
  }, [executeRecaptcha, setToken])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return <></>
}

function SubscriptionAccount() {
  const { setRegistration } = useContext(SubscriptionContext)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [token, setToken] = useState('')
  const [error, setError] = useState<string | null>(null)
  let history = useHistory()

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const fetchRegistration = async () => {
    const registration = await getCurrentOnboardUser()

    if (registration) {
      setRegistration(registration)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordRepeat: '',
    },
    validationSchema: schemaAccount,
    onSubmit: async (values: any) => {
      try {
        await createRegistration({
          email: values.email,
          password: values.password,
          recaptcha: token,
        })
        await fetchRegistration()
        history.push(SUBSCRIPTION_EMAIL_URL)
      } catch (e: any) {
        setError(e.message)
      }
    },
    validateOnBlur: true,
  })

  return (
    <>
      <Box sx={{ ml: 6 }}>
        <Typography variant="body2" color="text.secondary" mb={0.5}>
          Étape 1
        </Typography>
        <Typography variant="h2b" mb={4} component="h2">
          Création du compte
        </Typography>
      </Box>

      <Paper elevation={2} sx={{ p: '32px 48px', mb: 4 }}>
        {error ? (
          <SubscriptionError error={error} clearError={() => setError(null)} />
        ) : (
          <form onSubmit={formik.handleSubmit} id="form-account">
            <BaseInput
              id="email"
              label="E-mail de connexion"
              placeholder="johndoe@doe.com"
              fullWidth
              type="email"
              autoComplete="email"
              sx={{ mb: 1.5 }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <HelpContainer
              message="Cet e-mail sera utilisé pour vous connecter. Vous devrez le valider lors de la prochaine étape."
              size="small"
              light={true}
              sx={{ mb: 4 }}
            />

            <PasswordInput
              id="password"
              label="Mot de passe"
              placeholder="*********"
              showPassword={showPassword}
              onClickShow={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              sx={{ mb: 3 }}
              value={formik.values.password}
              onChange={formik.handleChange}
              canCopyPaste={true}
              helperText="Choisissez un mot de passe unique que vous n’utilisez pas ailleurs."
              dataFormType="password,new"
            />

            <PasswordInput
              id="passwordRepeat"
              label="Confirmation du mot de passe"
              placeholder="*********"
              showPassword={showPassword}
              onClickShow={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              canCopyPaste={true}
              sx={{ mb: 3 }}
              value={formik.values.passwordRepeat}
              onChange={formik.handleChange}
              dataFormType="password,confirmation"
            />

            <Box
              sx={{
                borderRadius: 1,
                p: 3,
                mb: 3,
                bgcolor: 'background.default',
              }}
            >
              <PasswordChecklist
                rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                minLength={8}
                value={formik.values.password}
                valueAgain={formik.values.passwordRepeat}
                onChange={(isValid) => {
                  setIsPasswordValid(isValid)
                }}
              />
            </Box>
          </form>
        )}
      </Paper>
      {!error && (
        <Box textAlign="right">
          <Button
            color="secondary"
            endIcon={<ArrowRightIcon />}
            type="submit"
            form="form-account"
            disabled={!formik.isValid || !formik.dirty || !isPasswordValid || !token}
          >
            Continuer
          </Button>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}>
            <ReCaptchaEl setToken={setToken} />
          </GoogleReCaptchaProvider>
        </Box>
      )}
    </>
  )
}

export default SubscriptionAccount
