// import UcitsNavbarContent from 'component/ReportingSettings/UcitsNavbarContent'
import ArbitrageNavbarContent from '../../ArbitrageNavbarContent'
import DepositNavbarContent from '../../DepositNavbarContent'
import UpfrontNavbarContent from '../../UpfrontNavbarContent'

export const UCITS_NAVBAR_ITEMS = [
  // {
  //   id: 'opcvm',
  //   link: 'Rétrocessions OPCVM',
  //   content: UcitsNavbarContent,
  //   disabled: false,
  // },
  {
    id: 'arbitrages',
    link: 'Arbitrages',
    content: ArbitrageNavbarContent,
    disabled: false,
  },
  {
    id: 'versements',
    link: 'Versements',
    content: DepositNavbarContent,
    disabled: false,
  },
  {
    id: 'upfronts',
    link: 'Upfronts',
    content: UpfrontNavbarContent,
    disabled: false,
  },
]
