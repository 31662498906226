import * as React from 'react'

import { Modal } from 'component'
import { AlertCircleIcon, CheckCircleIcon, RotateCcwIcon, InfoIcon } from 'icons'
import { REPORTING_ANALYSIS_URL } from 'utils/router/constants'

import { Box, Button, Link, Stack, Typography } from '@mui/material'

interface IProps {
  insuranceProductName: string
  error: string | undefined
  resetState: () => void
}

const FinalStep: React.FC<IProps> = (props) => {
  const { insuranceProductName, error, resetState } = props

  return (
    <Modal.Content>
      <Stack alignItems="center" width={450} margin="auto">
        {error !== undefined ? (
          <AlertCircleIcon color="error" sx={{ color: 'error.contrastText', mb: 2.75 }} />
        ) : (
          <CheckCircleIcon color="success" sx={{ color: 'success.main', mb: 2.75 }} />
        )}
        <Typography variant="h3b" mb={0.5} sx={{ color: 'text.primary' }}>
          {error ? `Problème lors de la creation de la configuration` : insuranceProductName}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          mb={3}
          sx={{
            color: error !== undefined ? 'error.text' : 'text.secondary',
          }}
        >
          {error !== undefined ? error : `La propagation de votre règle a bien été editée`}
        </Typography>
      </Stack>
      {error ? (
        <Stack direction="row" justifyContent="center">
          <Button startIcon={<RotateCcwIcon />} color="secondary" onClick={resetState}>
            Recommencer l'édition de la propagation
          </Button>
        </Stack>
      ) : (
        <Stack
          direction="row"
          gap={1}
          borderRadius={0.5}
          sx={(theme) => ({
            backgroundColor: 'primaryLight.main',
            color: 'primaryLight.contrastText',
            padding: theme.spacing(1, 1.5),
          })}
        >
          <InfoIcon sx={{ fontSize: 20 }} />
          <Box>
            <Typography variant="subtitle1" sx={{ color: 'primaryLight.contrastText' }}>
              Des bordereaux déjà importés pourraient être impactés par l'édition de la propagation
              de cette règle.
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'primaryLight.contrastText' }}>
              Vous pouvez relancer la vérification des données simplement depuis{' '}
              <Link sx={{ fontWeight: 400 }} href={REPORTING_ANALYSIS_URL}>
                les pages d’analyse
              </Link>
              .
            </Typography>
          </Box>
        </Stack>
      )}
    </Modal.Content>
  )
}

export default FinalStep
