import React, { MouseEventHandler } from 'react'

export default function Icon({
  className,
  onClick,
}: {
  className: string
  onClick?: MouseEventHandler
}) {
  return <i className={className} onClick={onClick} />
}
