import * as React from 'react'

import { Modal } from 'component'

import { FinalStep, StartStep } from './components'
import { IAPIInsurance, IAPIReportPeriod } from 'api/interfaces'

interface IProps {
  open: boolean
  selectedInsurance: IAPIInsurance
  selectedPeriod: IAPIReportPeriod
  setSelectedPeriod: StateSetter<IAPIReportPeriod | undefined>
  itemLabel: string
  itemValue: string
  closeModal: () => void
}

const ModalReavalidateDockets: React.FC<IProps> = (props) => {
  const {
    open,
    selectedInsurance,
    selectedPeriod,
    setSelectedPeriod,
    itemLabel,
    itemValue,
    closeModal,
  } = props

  const [step, setStep] = React.useState(1)
  const [progress, setProgress] = React.useState<number>(0)
  const [error, setError] = React.useState<string>()
  const [revalidatedErrors, setRevalidatedErrors] = React.useState<number>()

  const resetState = () => {
    setStep(1)
    setProgress(0)
    setError(undefined)
    setRevalidatedErrors(undefined)
  }

  const onCloseHandler = () => {
    closeModal()
    resetState()
  }

  return (
    <Modal
      open={open}
      title="Recalcul des erreurs"
      onClose={onCloseHandler}
      stepTitle={`Recalcul des erreurs - ${step}/2`}
      canClose={step === 2}
      modalId="ModalReavalidateDockets"
    >
      {step === 1 ? (
        <StartStep
          selectedInsurance={selectedInsurance!}
          selectedPeriod={selectedPeriod}
          itemLabel={itemLabel}
          setSelectedPeriod={setSelectedPeriod}
          setError={setError}
          setRevalidatedErrors={setRevalidatedErrors}
          setStep={setStep}
          itemValue={itemValue}
          progress={progress}
          setProgress={setProgress}
        />
      ) : step === 2 ? (
        <FinalStep
          selectedInsurance={selectedInsurance!}
          selectedPeriod={selectedPeriod}
          itemLabel={itemLabel}
          revalidatedErrors={revalidatedErrors}
          error={error}
          onClose={onCloseHandler}
        />
      ) : null}
    </Modal>
  )
}

export default ModalReavalidateDockets
