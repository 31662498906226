import * as React from 'react'

import { getInsuranceProductsGroupedByFamily } from 'utils'
import { PlusIcon } from 'icons'
import { Checkbox } from 'component/Inputs'
import { AccordionBase, AccordionContent, AccordionHeader } from 'component/Accordion'
import { IAPIInsuranceProduct } from 'api/interfaces'

import {
  getDefaultSelectedInsuranceProductsIdxs,
  getFamilyGroupChecked,
  getFamilyGroupCheckedChangeHandler,
  getFamilyGroupIndeterminate,
  getProductChecked,
  getProductCheckedChangeHandler,
  getSelectedInsuranceProductsIds,
} from './core/InsuranceProductSelector.helpers'

import { Box, Stack, styled, SxProps, Typography } from '@mui/material'

interface IProps {
  sx?: SxProps
  insuranceProducts: IAPIInsuranceProduct[]
  defaultSelectedInsuranceProductsIds?: number[]
  onChange: (selectedInsuranceProductsIds: number[]) => void
}

const InsuranceProductsSelector: React.FC<IProps> = (props) => {
  const { insuranceProducts, defaultSelectedInsuranceProductsIds, onChange, sx } = props

  const insuranceProductsGroupedByFamily = React.useMemo(() => {
    return getInsuranceProductsGroupedByFamily(insuranceProducts)
  }, [insuranceProducts])

  const defaultSelectedInsuranceProductsIdxs = getDefaultSelectedInsuranceProductsIdxs({
    defaultSelectedInsuranceProductsIds,
    insuranceProductsGroupedByFamily,
  })

  const [selectedInsuranceProductsIdxs, setSelectedInsuranceProductsIdxs] = React.useState<
    number[][]
  >(defaultSelectedInsuranceProductsIdxs)

  const handleInsuranceProductCheckboxClick: React.MouseEventHandler = (e) => {
    e.stopPropagation()
  }

  // TODO : find why onChange is causing useEffect to be called all over again and
  // add it to the dependency array

  React.useEffect(() => {
    onChange(
      getSelectedInsuranceProductsIds({
        selectedInsuranceProductsIdxs,
        insuranceProductsGroupedByFamily,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsuranceProductsIdxs, insuranceProductsGroupedByFamily])

  return (
    <Box sx={sx}>
      {insuranceProductsGroupedByFamily.map((familyGroup, familyGroupIdx) => (
        <AccordionBase key={familyGroupIdx}>
          <AccordionHeader expandIcon={<PlusIcon fontSize="small" />}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
            >
              <Typography variant="subtitle1" fontWeight={500} sx={{ color: 'text.primary' }}>
                {familyGroup.familyName}
              </Typography>
              <Checkbox
                checked={getFamilyGroupChecked({
                  selectedInsuranceProductsIdxs,
                  familyGroupIdx,
                })}
                indeterminate={getFamilyGroupIndeterminate({
                  selectedInsuranceProductsIdxs,
                  familyGroupIdx,
                  familyGroupProductCount: familyGroup.products.length,
                })}
                onClick={handleInsuranceProductCheckboxClick}
                onChange={getFamilyGroupCheckedChangeHandler({
                  selectedInsuranceProductsIdxs,
                  setSelectedInsuranceProductsIdxs,
                  familyGroupIdx,
                  familyGroupProductCount: familyGroup.products.length,
                })}
              />
            </Stack>
          </AccordionHeader>
          <AccordionContent>
            {familyGroup.products.map((product, productIdx) => (
              <InsuranceProduct
                key={product.id}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                  {product.name}
                </Typography>
                <Checkbox
                  size="small"
                  checked={getProductChecked({
                    selectedInsuranceProductsIdxs,
                    familyGroupIdx,
                    productIdx,
                  })}
                  onChange={getProductCheckedChangeHandler({
                    selectedInsuranceProductsIdxs,
                    setSelectedInsuranceProductsIdxs,
                    familyGroupIdx,
                    productIdx,
                  })}
                />
              </InsuranceProduct>
            ))}
          </AccordionContent>
        </AccordionBase>
      ))}
    </Box>
  )
}

export default InsuranceProductsSelector

const InsuranceProduct = styled(Stack)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(1.25),
  },
}))
