export const getRootClassName = (size?: 'small' | 'medium'): string => {
  const classNames = ['MuiFormControlLabel-checkbox']

  if (size === 'small') {
    classNames.push('MuiFormControlLabel-sizeSmall')
  }

  return classNames.join(' ')
}

export const getCheckboxClassName = (
  size?: 'small' | 'medium'
): string | undefined => {
  return size === 'small' ? 'MuiCheckbox-sizeSmall' : undefined
}
