import { Components, Theme } from '@mui/material'

const MuiDialogActions: NonNullable<Components<Theme>['MuiDialogActions']> = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      background: theme.palette.background.default, // TOCHECK
    }),
  },
}

export default MuiDialogActions
