import * as React from 'react'

interface IProps {
  src: string
  alt: string
}

const LogoCell: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { src, alt } = props

  return <img width={24} src={src} alt={alt} />
}

export default LogoCell
