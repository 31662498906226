import { Stack, styled } from '@mui/material'
import React, { FC, MouseEventHandler } from 'react'
import { useNavbarLink } from './typescript/navbarLink.hooks'
import { IProps } from './typescript/navbarLink.interfaces'

export type { IProps as INavbarLinkProps } from './typescript/navbarLink.interfaces'

const NavbarLink: FC<React.PropsWithChildren<IProps>> = ({ children, id, disabled, variant='light', onChange }) => {
  const [handleClick, active] = useNavbarLink({ id, disabled })

  const clickHandler: MouseEventHandler = (e) => {
    handleClick(e)

    if (onChange) {
      onChange(id)
    }
  }

  return (
    <NavbarLinkContainer
      onClick={clickHandler}
      variant={variant}
      direction='row'
      alignItems='center'
      className={`navbar__link body-l-regular ${active ? 'navbar__link--active' : ''} ${
        disabled ? 'navbar__link--disabled' : ''
      }`}
    >
      {children}
    </NavbarLinkContainer>
  )
}

const NavbarLinkContainer = styled(Stack, {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: string }>(({ theme, variant }) => ({

  position: 'relative',
  cursor: 'pointer',

  '.MuiTypography-root': {
    display: 'inline-block',
    padding: '2px 6px',
    borderRadius: '4px',
    textDecoration: 'none',
    transition: 'all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    color: variant === 'dark' ? theme.palette.secondary.contrastText : theme.palette.text.primary,
    opacity: 0.7,

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: '0',
      width: '100%',
      height: '4px',
      background: theme.palette.primary.main,
      transform: 'scaleY(0)',
      zIndex: 1,
      transition: 'transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
      borderRadius: '4px 4px 0 0',
    }
  },

  '&.navbar__link--active': {
    
    '.MuiTypography-root': {
      opacity: '1',
      '&:after': {
        transform: 'scaleY(1)',
      }
    }
  },

  '&.navbar__link--disabled': {
    pointerEvents: 'none',

    '.MuiTypography-root': {
      opacity: '.4'
    }
  },

  '&:hover': {
    '.MuiTypography-root': {
      opacity: '1',
      background: variant === 'dark' ? theme.palette.secondary.main : theme.palette.neutralLight.light,
    }
  }
}))

export default NavbarLink
