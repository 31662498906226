import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Icon from 'component/Icon'
import ProfileImagePlaceholder from 'utils/hooks/profileImagePlaceholder'
import DetectOutside from 'utils/hooks/detectOutside'
import { connect } from 'react-redux'
import { getLogout } from 'api/get'
import { PREFERENCES_PROFILE_URL } from 'utils/router/constants'

interface IProps {
  user?: any
  pictureOnly?: boolean
}

function SidebarProfile(props: IProps) {
  const { user, pictureOnly } = props

  const { picture, firstName, lastName } = user

  const [activeDropdown, setDropdown] = useState(false)

  const toggleDropdown = () => {
    setDropdown(!activeDropdown)
  }

  const logout = async (ev: any) => {
    await getLogout()
  }

  return (
    <DetectOutside callback={() => setDropdown(false)}>
      <div className="sidebar__profile" onClick={toggleDropdown}>
        <div
          className={`sidebar__profile__dropdown ${
            activeDropdown ? 'is-active' : ''
          }`}
        >
          <Link to={PREFERENCES_PROFILE_URL}>
            <div className="dropdown-option c-blue-600">
              <Icon className="icon-settings mr-3" />
              <span className="ft-500">Préférences</span>
            </div>
          </Link>
          <div
            className="dropdown-option c-blue-600"
            onClick={logout}
          >
            <Icon className="icon-log-out mr-3" />
            <span className="ft-500">Déconnexion</span>
          </div>
        </div>
        <div
          className={`sidebar__profile__name c-white-tertiary f ${
            !pictureOnly ? 'f-jc-space-b' : 'f-jc-center'
          } f-ai-center ${activeDropdown ? 'is-active' : ''}`}
        >
          <div className="f f-ai-center">
            <span className={`${pictureOnly ? '' : 'ml-3 mr-3'}`}>
              <ProfileImagePlaceholder
                firstName={firstName}
                lastName={lastName}
                picture={picture}
                classNameImage="sidebar__profile__img"
              />
            </span>
            {!pictureOnly && (
              <div className="ft-500 f f-ai-center">
                <span>Bonjour, {firstName}</span>
                <Icon
                  className={`icon-chevron-down c-blue-200 ${
                    activeDropdown ? 'is-active' : ''
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </DetectOutside>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(SidebarProfile)
