import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

const CollapseIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g opacity="0.7">
        <path
          d="M13.354 3.35403L10.2072 6.50028H12.0003C12.1329 6.50028 12.2601 6.55296 12.3538 6.64672C12.4476 6.74049 12.5003 6.86767 12.5003 7.00028C12.5003 7.13289 12.4476 7.26006 12.3538 7.35383C12.2601 7.4476 12.1329 7.50028 12.0003 7.50028H9.00028C8.86767 7.50028 8.74049 7.4476 8.64672 7.35383C8.55296 7.26006 8.50028 7.13289 8.50028 7.00028V4.00028C8.50028 3.86767 8.55296 3.74049 8.64672 3.64672C8.74049 3.55296 8.86767 3.50028 9.00028 3.50028C9.13289 3.50028 9.26006 3.55296 9.35383 3.64672C9.4476 3.74049 9.50028 3.86767 9.50028 4.00028V5.7934L12.6465 2.64653C12.7403 2.55271 12.8676 2.5 13.0003 2.5C13.133 2.5 13.2602 2.55271 13.354 2.64653C13.4478 2.74035 13.5006 2.8676 13.5006 3.00028C13.5006 3.13296 13.4478 3.26021 13.354 3.35403ZM7.00028 8.50028H4.00028C3.86767 8.50028 3.74049 8.55296 3.64672 8.64672C3.55296 8.74049 3.50028 8.86767 3.50028 9.00028C3.50028 9.13289 3.55296 9.26006 3.64672 9.35383C3.74049 9.4476 3.86767 9.50028 4.00028 9.50028H5.7934L2.64653 12.6465C2.55271 12.7403 2.5 12.8676 2.5 13.0003C2.5 13.133 2.55271 13.2602 2.64653 13.354C2.74035 13.4478 2.8676 13.5006 3.00028 13.5006C3.13296 13.5006 3.26021 13.4478 3.35403 13.354L6.50028 10.2072V12.0003C6.50028 12.1329 6.55296 12.2601 6.64672 12.3538C6.74049 12.4476 6.86767 12.5003 7.00028 12.5003C7.13289 12.5003 7.26006 12.4476 7.35383 12.3538C7.4476 12.2601 7.50028 12.1329 7.50028 12.0003V9.00028C7.50028 8.86767 7.4476 8.74049 7.35383 8.64672C7.26006 8.55296 7.13289 8.50028 7.00028 8.50028Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}

export default CollapseIcon
